import { useEffect } from 'react';

import MetricsDashboard from '../components/MetricsDashboard';
import { usePerformanceActions, usePerformanceState } from '../hooks/performanceContext';

const MetricsContainer = () => {
  const { metrics } = usePerformanceState();
  const { dispatchGetMetrics } = usePerformanceActions();

  useEffect(() => {
    dispatchGetMetrics();
  }, [dispatchGetMetrics]);

  return <MetricsDashboard metrics={metrics} />;
};

export default MetricsContainer;
