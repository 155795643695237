import { useEffect } from 'react';
import styled from '@emotion/styled';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ExtraBig, TSLogo } from '@talkspace/react-toolkit';
import Button from '../../utils/Button';
import View from '../../utils/Button/View';
import { patchRoomBooking } from '../../actions/BookingActions';
import { tokenIsValid } from '../../utils/tokenIsValid';
import storage from '../../core/storage';
import BookingErrorLogo from '../Icons/booking-error-logo.svg';
import BookingDeclinedLogo from '../Icons/booking-declined-logo.svg';
import BookingCancelledLogo from '../Icons/booking-cancelled-logo.svg';
import BookingConfirmedLogo from '../Icons/booking-confirmed-logo.svg';

const Icon = styled.img({
  width: 100,
  marginTop: 175,
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
});

const Title = styled.p({
  fontWeight: 'bold',
  fontSize: 40,
  margin: 'auto',
  width: 'fit-content',
  marginTop: 20,
  fontFamily: 'Roboto',
  textAlign: 'center',
});

const Date = styled.p({
  fontWeight: 'bold',
  fontSize: 20,
  margin: 'auto',
  width: 'fit-content',
  marginTop: 40,
  fontFamily: 'Roboto',
});

const Time = styled.p({
  fontSize: 20,
  margin: 'auto',
  width: 'fit-content',
  fontFamily: 'Roboto',
});

const ExtraBigCentered = styled(ExtraBig)({
  margin: 'auto',
  width: 'fit-content',
});

const TextContent = styled.p({
  fontSize: 20,
  margin: 'auto',
  width: 400,
  marginTop: 40,
  fontFamily: 'Roboto',
  textAlign: 'center',
});

const bookingMappings = {
  CONFIRMED: {
    logoSource: BookingConfirmedLogo,
    title: 'Booking confirmed!',
    textContent: 'Be ready in the app a few minutes before the session’s start time.',
  },
  ALREADY_CONFIRMED: {
    logoSource: BookingConfirmedLogo,
    title: 'Booking has already been confirmed',
    textContent: 'Be ready in the app a few minutes before the session’s start time.',
  },
  PREVIOUSLY_DECLINED: {
    logoSource: BookingErrorLogo,
    title: 'You previously declined this booking',
    textContent:
      'We’ve already let your client know. Please message them to arrange an alternative time for your session.',
  },
  DECLINED: {
    logoSource: BookingDeclinedLogo,
    title: 'Booking declined',
    textContent:
      'We’ve let your client know. Please message them to arrange an alternative time for your session.',
  },
  ALREADY_DECLINED: {
    logoSource: BookingDeclinedLogo,
    title: 'Booking has already been declined',
    textContent:
      'We’ve let your client know. Please message them to arrange an alternative time for your session.',
  },
  PREVIOUSLY_CONFIRMED: {
    logoSource: BookingErrorLogo,
    title: 'You previously confirmed this booking',
    textContent:
      'We’ve already told your client this session was confirmed. If you need to reschedule, message them to arrange an alternative time.',
  },
  BOOKING_NOT_FOUND: {
    logoSource: BookingErrorLogo,
    title: 'Invalid booking',
    textContent:
      'Our system doesn’t recognize this booking. Double check you’re using the right link.',
  },
  BOOKING_SERVER_ISSUE: {
    logoSource: BookingErrorLogo,
    title: 'Something went wrong',
    textContent: 'Please try again later.',
  },
  ALREADY_CANCELLED: {
    logoSource: BookingCancelledLogo,
    title: 'Cancelled booking',
    textContent:
      'This session was automatically cancelled because we didn’t hear from you earlier. Be sure to confirm sessions at least 24 hours before their start times.',
  },
  ALREADY_CANCELLED_BY_CLIENT: {
    logoSource: BookingCancelledLogo,
    title: 'Cancelled booking',
    textContent: 'The client has already cancelled or rescheduled this booking.',
  },
};

function BookingActivity({
  isUpdating,
  bookingStartTime,
  bookingEndTime,
  bookingTherapistTimezone,
  patchRoomBooking,
  match: { params: matchParams },
  bookingState,
  hasBreakAfterSession,
}) {
  const { bookingID, roomID, status } = matchParams;
  const token = new URLSearchParams(window.location.search.substring(1)).get('token');

  useEffect(() => {
    if (status === 'confirm') {
      window.location = `${window.location.origin}/room/${roomID}/lvs-tab`;
      return;
    }
    patchRoomBooking(roomID, bookingID, status, token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <TSLogo style={{ marginLeft: 8 }} variant="2024" />
      {!isUpdating && (
        <>
          <Icon alt="talkspace" src={bookingMappings[bookingState].logoSource} />
          <Title>{bookingMappings[bookingState].title}</Title>
          {['CONFIRMED', 'ALREADY_CONFIRMED'].includes(bookingState) && (
            <>
              <Date>
                {moment.tz(bookingStartTime, bookingTherapistTimezone).format('dddd, MMMM D')}
              </Date>
              <Time>{`${moment
                .tz(bookingStartTime, bookingTherapistTimezone)
                .format('h:mm A')} - ${moment
                .tz(bookingEndTime, bookingTherapistTimezone)
                .format('h:mm A z')}`}</Time>
              {!!hasBreakAfterSession && <ExtraBigCentered>(+15min break)</ExtraBigCentered>}
            </>
          )}
          <TextContent>{bookingMappings[bookingState].textContent}</TextContent>

          {!['BOOKING_SERVER_ISSUE', 'BOOKING_NOT_FOUND'].includes(bookingState) && (
            <View row>
              <a
                style={{ margin: 'auto', marginTop: '40px' }}
                href={
                  tokenIsValid() ? `${window.location.origin}/room/${roomID}` : storeRedirectUrl()
                }
              >
                <Button text="GO TO ROOM" />
              </a>
            </View>
          )}
        </>
      )}
    </div>
  );

  function storeRedirectUrl() {
    storage.setItem('redirectUrl', `/room/${roomID}`);
    return `${window.location.origin}/login`;
  }
}

BookingActivity.propTypes = {};

const mapStateToProps = (state) => {
  return {
    bookingState: state.booking.bookingState,
    bookingStartTime: state.booking.bookingStartTime,
    bookingEndTime: state.booking.bookingEndTime,
    bookingTherapistTimezone: state.booking.bookingTherapistTimezone,
    isBookingError: state.booking.isError,
    isUpdating: state.booking.isUpdating,
    hasBreakAfterSession: state.booking.hasBreakAfterSession,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ patchRoomBooking }, dispatch);

const BookingActivityContainer = connect(mapStateToProps, mapDispatchToProps)(BookingActivity);

export default BookingActivityContainer;
