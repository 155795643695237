import {
  Checkbox,
  Preferences,
  TouchableView,
  View,
  useEmotionTheme,
  useWindowWidth,
} from '@talkspace/react-toolkit';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import styled from '../../../../core/styled';
import ModalContainer from './ModalContainer';
import Analytics from '../Analytics';
import { getUserData } from '../../../../utils/token';

export type WaitingClientsFilter = 'Messaging' | 'Live';

const customizer = (a: Array<any>, b: Array<any>) => _.isEqual(_.sortBy(a), _.sortBy(b));

const Button = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 15,
  };
});

const ClosePreferencesModalButton = styled(Button)(({ theme: { window, colors } }) => {
  return {
    maxHeight: 30,
    borderRadius: 8,
    lineHeight: '30px',
    width: window.isMobile ? 91 : 156,
    backgroundColor: colors.permaBlueStoneNew,
    fontSize: 14,
    fontWeight: 700,
    color: colors.white,
    textAlign: 'center',
    alignItems: 'center',
  };
});

const getFloatingMenuStyle = (isMobile: boolean) => {
  return {
    top: isMobile ? 315 : 174,
    left: isMobile ? '50%' : 'auto',
    transform: isMobile && 'translate(-50%, 0)',
    right: !isMobile && 160,
    width: isMobile ? 355 : 455,
    marginRight: 20,
    height: 290,
    maxHeight: 290,
  };
};

const ClearButton = styled(TouchableView)<{ disabled: boolean }>(
  ({ disabled, theme: { colors } }) => {
    return {
      paddingBottom: 0,
      textDecorationLine: 'underline',
      fontWeight: 700,
      fontSize: 15,
      color: disabled ? colors.permaSlateGrayApprox : colors.permaBlueStoneNew,
      marginLeft: 0,
    };
  }
);

const PreferencesIconWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.permaFlowerBlue,
    borderRadius: 8,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 5.5,
    paddingLeft: 5.5,
    marginRight: 15,
    height: '28px',
  };
});

const ModalText = styled(View)(({ theme: { colors } }) => {
  return {
    fontSize: 21,
    color: colors.permaOuterSpaceNew,
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'right',
  };
});

const TopModalSection = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'top',
  lineHeight: '28px',
  marginLeft: 25,
});

const BottomModalSection = styled(View)(({ theme: { window } }) => {
  return {
    paddingLeft: 25,
    paddingRight: 15,
    width: window.isMobile ? 355 : 455,
    height: 60,
    boxSizing: 'border',
    boxShadow: '0px 1px 4px rgba(5, 5, 29, 0.330693)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  };
});

const Subtitle = styled(View)({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '18px',
  marginBottom: 32,
  marginTop: 11,
});

const PreferencesModalCheckbox = ({
  filterName,
  preferences,
  setVisualPreferences,
}: {
  filterName: WaitingClientsFilter;
  preferences: Array<WaitingClientsFilter>;
  setVisualPreferences: (any) => void;
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Checkbox
      checkboxStyle={{
        border: 'border: 3px solid blue',
        height: 21,
        width: 21,
      }}
      labelStyle={{ fontWeight: 400 }}
      containerStyle={{ marginBottom: 0 }}
      borderColor={colors.blueBayoux}
      checkedColor={colors.permaBlueStoneNew}
      stretch={false}
      isLabelOnRight
      label={filterName}
      isChecked={preferences.includes(filterName)}
      setIsChecked={(isChecked) => {
        setVisualPreferences((prev: WaitingClientsFilter[]) => {
          if (isChecked) {
            return prev.concat([filterName]);
          }
          return prev.filter((it) => it !== filterName);
        });
      }}
      hoverColors={{ borderColor: colors.permaBlueStoneNew, backgroundColor: colors.aliceBlue }}
    />
  );
};

const PreferencesModal = ({
  showPreferencesModal,
  setShowPreferencesModal,
  setPreferences,
  preferences,
}: {
  showPreferencesModal: boolean;
  setShowPreferencesModal: (boolean) => void;
  preferences: Array<WaitingClientsFilter>;
  setPreferences: (any) => void;
}) => {
  const [visualPreferences, setVisualPreferences] = useState<Array<WaitingClientsFilter>>([]);
  const { isMobile } = useWindowWidth();
  const onVisualPreferencesChanged = useCallback((changedPreferences: any) => {
    setVisualPreferences(changedPreferences);
  }, []);

  useEffect(() => {
    if (showPreferencesModal) {
      setVisualPreferences(preferences);
    }
  }, [showPreferencesModal, preferences]);

  const isPreferencesEqual = _.isEqualWith(visualPreferences, preferences, customizer);
  const therapistID: number = getUserData().id;

  return (
    <ModalContainer
      hide={!showPreferencesModal}
      onBackdropPress={() => {
        Analytics.trackModalActionEvent({
          modal: 'Preferences modal',
          action: 'Hide',
          therapistID,
        });
        setShowPreferencesModal(false);
      }}
      style={getFloatingMenuStyle(isMobile)}
    >
      <View>
        <TopModalSection>
          <PreferencesIconWrapper>
            <Preferences />
          </PreferencesIconWrapper>
          <ModalText>
            Preferences
            <Subtitle>Filter by your preferred modalities</Subtitle>
            <View style={{ width: '100%', marginBottom: 40 }}>
              <View style={{ marginBottom: 11 }}>
                <PreferencesModalCheckbox
                  filterName="Messaging"
                  preferences={visualPreferences}
                  setVisualPreferences={onVisualPreferencesChanged}
                />
              </View>

              <PreferencesModalCheckbox
                filterName="Live"
                preferences={visualPreferences}
                setVisualPreferences={onVisualPreferencesChanged}
              />
            </View>
          </ModalText>
        </TopModalSection>
        <BottomModalSection>
          <ClearButton
            disabled={!visualPreferences.length}
            onPress={() => {
              setVisualPreferences([]);
            }}
          >
            Clear all
          </ClearButton>
          <ClosePreferencesModalButton
            onPress={() => {
              if (!isPreferencesEqual) {
                setPreferences(visualPreferences);
              }

              Analytics.trackModalActionEvent({
                modal: 'Preferences modal',
                action: 'Hide',
                therapistID,
              });
              setShowPreferencesModal(false);
            }}
          >
            {!isPreferencesEqual ? 'Apply' : 'Close'}
          </ClosePreferencesModalButton>
        </BottomModalSection>
      </View>
    </ModalContainer>
  );
};
export default PreferencesModal;
