import { useMemo, memo } from 'react';
import { View, Table, useWindowWidthState, useEmotionTheme, Cell } from '@talkspace/react-toolkit';
import moment from 'moment';
import '../../Dashboard.css';
import styled from '../../../../core/styled';

import { DashboardCellRoomDetailV2, RoomReminders } from '../../Cells';
import DashboardTablePlaceholder from '../../Placeholders/DashboardTablePlaceholder';
import IconNewClient from '../../Placeholders/IconNewClient';
import { dashboardGetHeaderProps } from '../../DashboardTable';

import { CheckRoomPath, NewRoomsRecord } from '../../types';

const NewClientsContentWrapper = styled(View)({
  padding: 0,
});

const NewClientsTitleWrapper = styled(View)({
  marginBottom: 0,
  paddingTop: 20,
  paddingRight: 15,
  paddingBottom: 10,
  minHeight: 48,
});

const getTitle = (newRoomCount) => {
  let clientCopy = '';
  if (newRoomCount === 0) {
    clientCopy = '(no new clients)';
  }
  if (newRoomCount === 1) {
    clientCopy = '(1 new client)';
  }
  if (newRoomCount > 1) {
    clientCopy = `(${newRoomCount} new clients)`;
  }
  return `Send first message ${clientCopy}`;
};

interface NewClientsProps {
  newRoomsData: NewRoomsRecord[];
  checkRoomPath: CheckRoomPath;
  handleOpenAvailabilityBar: (value?: boolean) => void;
  isHidden: boolean;
  isLoading: boolean;
}

const NewClients = ({
  isHidden,
  newRoomsData,
  checkRoomPath,
  isLoading,
  handleOpenAvailabilityBar,
}: NewClientsProps) => {
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();

  const deskTopColumns = useMemo(
    () => [
      {
        Header: 'Joined',
        id: 'joined',
        style: {
          width: '11%',
        },
        Cell: ({ row: { original: client } }: Cell<NewRoomsRecord>) =>
          checkRoomPath(
            client.roomID,
            client.clientWaiting ? moment(client.clientWaiting).from(moment()) : 'a few seconds ago'
          ),
      },
      {
        Header: 'Last Activity',
        id: 'lastActivity',
        style: {
          width: '11%',
        },
        Cell: ({ row: { original: client } }: Cell<NewRoomsRecord>) => {
          const roomPath = checkRoomPath(client.roomID, moment(client.lastActivity).fromNow());
          return (
            <>
              {roomPath}
              <i className="unstackable fa fa-angle-right fa-2x pull-right room-arrow" />
              {client.roomReminderCount > 0 && (
                <div className="room-reminder-container">
                  <div className="unstackable room-reminder-text">
                    {checkRoomPath(client.roomID, 'Reminders ')}
                    <span className="unstackable rrCircleRed room-reminder-count-icon">
                      {checkRoomPath(
                        client.roomID,
                        client.roomReminderCount < 100
                          ? client.roomReminderCount.toString()
                          : '99+',
                        'room-reminder-count-text'
                      )}
                    </span>
                  </div>
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: 'Waiting',
        id: 'waiting',
        style: {
          width: '11%',
        },
        Cell: ({ row: { original: client } }: Cell<NewRoomsRecord>) => (
          <div className="unstackable">
            <div className="unstackable customer-waiting">
              {checkRoomPath(
                client.roomID, // passing 'true' - omits the 'ago' string
                client.clientWaiting
                  ? moment(client.clientWaiting).from(moment(), true)
                  : 'a few seconds ago'
              )}
            </div>
          </div>
        ),
      },
      {
        Header: 'ID',
        id: 'ID',
        style: {
          width: '24%',
        },
        Cell: ({
          row: { original: client },
          isHovering,
        }: Cell<NewRoomsRecord> & { isHovering: boolean }) =>
          checkRoomPath(
            client.roomID,
            <DashboardCellRoomDetailV2
              fullName={[client.clientFirstName, client.clientLastName].join(' ').trim()}
              pseudonym={client.clientNickname}
              preferredName={client.preferredName}
              userID={client.clientUserID}
              roomID={client.roomID}
              isHovering={isHovering}
            />
          ),
      },
      {
        Header: 'Status',
        id: 'status',
        style: {
          width: '11%',
        },
        Cell: ({ row: { original: client } }: Cell<NewRoomsRecord>) =>
          checkRoomPath(client.roomID, client.roomStatus),
      },
      {
        Header: 'Plan Name',
        id: 'planName',
        Cell: ({ row: { original: client } }: Cell<NewRoomsRecord>) => (
          <>
            <div className="unstackable client-plan-name">
              {checkRoomPath(client.roomID, client.planName)}
            </div>
          </>
        ),
      },
    ],
    [checkRoomPath]
  );

  const responsiveColumns = useMemo(
    () => [
      {
        Header: 'ID',
        id: 'ID',
        Cell: ({ row: { original: client } }: Cell<NewRoomsRecord>) =>
          checkRoomPath(
            client.roomID,
            <View row>
              <DashboardCellRoomDetailV2
                fullName={[client.clientFirstName, client.clientLastName].join(' ').trim()}
                pseudonym={client.clientNickname}
                preferredName={client.preferredName}
                userID={client.clientUserID}
                roomID={client.roomID}
                floatingMenuStyle={{ left: -5 }}
                isHovering
              />
              <View style={{ paddingLeft: 10, paddingTop: 20 }}>
                {client.roomReminderCount > 0 && (
                  <RoomReminders numberOfReminders={client.roomReminderCount} />
                )}
                <div>{client.planName}</div>
                <div>
                  <div>Weekdays:&nbsp;</div>
                  {client.weekdays ? client.weekdays : ''}
                </div>
                <View row align="center" style={{ height: 20 }}>
                  {moment(client.lastActivity).fromNow()}
                  <i className="unstackable fa fa-angle-right fa-2x" style={{ marginLeft: 10 }} />
                </View>
              </View>
              <View row style={{ position: 'absolute', right: 32 }}>
                {client.clientWaiting
                  ? moment(client.clientWaiting).from(moment())
                  : 'a few seconds ago'}
              </View>
            </View>
          ),
      },
    ],
    [checkRoomPath]
  );

  if (isHidden) {
    return null;
  }

  return (
    <View>
      <NewClientsTitleWrapper className="unstackable">
        <h4>{getTitle(newRoomsData.length)}</h4>
      </NewClientsTitleWrapper>
      <NewClientsContentWrapper className="unstackable">
        <div className="unstackable grid" role="grid">
          <Table
            data={newRoomsData}
            hideHeader={isMobile}
            columns={isMobile ? responsiveColumns : deskTopColumns}
            tableStyle={{ marginTop: 0 }}
            getHeaderProps={dashboardGetHeaderProps(colors)}
            initialState={{ sortBy: [{ id: 'clientWaiting', desc: false }] }}
            Empty={
              <DashboardTablePlaceholder
                title="You have no new clients!"
                subtitle="This is where you’ll greet your new clients. To receive clients, "
                icon={<IconNewClient />}
                linkText="update your new client availability."
                handleOnPress={handleOpenAvailabilityBar}
              />
            }
            isLoading={isLoading}
          />
        </div>
      </NewClientsContentWrapper>
    </View>
  );
};

export default memo(NewClients);
