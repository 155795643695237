import {
  DiagnosticProfileState,
  DiagnosticProfileActionTypes,
  RECEIVE_ALL_CLIENTS_DIAGNOSTIC_PROFILES,
  REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES,
  REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES_ERROR,
} from '../constants/DiagnosticProfileConstants';

const initialState: DiagnosticProfileState = {
  clientUserID: undefined,
  diagnosticProfiles: {},
  isLoading: false,
  isError: false,
  errorMessage: '',
};

export default function diagnosticProfile(
  state = { ...initialState },
  action: DiagnosticProfileActionTypes
): DiagnosticProfileState {
  switch (action.type) {
    case REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES:
      return {
        ...state,
        errorMessage: '',
        isLoading: true,
        isError: false,
      };
    case RECEIVE_ALL_CLIENTS_DIAGNOSTIC_PROFILES:
      return {
        ...state,
        ...action.payload,
        errorMessage: '',
        isLoading: false,
        isError: false,
      };
    case REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES_ERROR:
      return {
        ...state,
        errorMessage: action.error ? action.error.message : 'Something went wrong.',
        isError: true,
        isLoading: false,
      };
    default:
      return { ...state };
  }
}
