import { RoomRecord } from 'components/Dashboard/types';
import { useMemo, useCallback, ReactElement } from 'react';
import '../../Dashboard.css';
import moment from 'moment';
import { View, Cell, COLORS, Table, SortingRule, HeaderGroup } from '@talkspace/react-toolkit';
import { Link } from 'react-router-dom';
import { DashboardCellRoomDetailV4, StatusAndRenewDate, StatusV4 } from '../../Cells';
import { EmotionStyle } from '@/core/styled';
import useDashboardSize from '../../hooks/useDashboardSize';
import DashboardCellRoomActions from '../../Cells/DashboardCellRoomDetail/DashboardCellRoomActions';
import DashboardCellMessagePreview from '../../Cells/DashboardCellRoomDetail/DashboardCellMessagePreview';
import { waitingTimeFormatter } from './utils';

interface ClientTableProps<T> {
  data: T[];
  Empty?: ReactElement;
  tableStyle?: EmotionStyle;
  initialState?: Record<string, unknown>;
  autoResetPage?: boolean;
  isLoading: boolean;
  enablePagination?: boolean;
  enableSorting?: boolean;
  onStateChange?: (callback: {
    pageIndex: number;
    pageSize: number;
    sortBy: SortingRule[];
  }) => void;
  getHeaderProps?: (props?: HeaderGroup) => Record<string, unknown>;
  tableType: 'all-clients' | 'new-client';
}

const ClientTable = ({
  data,
  isLoading,
  tableStyle,
  onStateChange,
  autoResetPage,
  getHeaderProps,
  initialState,
  Empty,
  enablePagination = false,
  enableSorting = false,
  tableType,
}: ClientTableProps<object>) => {
  const { isDashboardMobile } = useDashboardSize();

  const ClientWaitingFormatter = useCallback(
    (cell: Date) => {
      if (cell === null) {
        return '';
      }
      return (
        <div className="unstackable">
          <div className="unstackable customer-waiting">
            {waitingTimeFormatter(cell, isDashboardMobile)}
          </div>
        </div>
      );
    },
    [isDashboardMobile]
  );

  const compareInsensitiveCase = (field1: string, field2: string) => {
    const row1 = field1 || '';
    const row2 = field2 || '';
    return row1.localeCompare(row2);
  };

  const handleCellPress = useCallback(
    ({ roomID, component, path = 'case-tab' }) => (
      <Link
        to={{
          pathname: `/room/${roomID}/${path}`,
          state: { postActionRoute: tableType === 'all-clients' ? 'chat' : null },
        }}
        className="dashboard-link"
        data-qa={`roomID-${roomID}`}
      >
        {component}
      </Link>
    ),
    [tableType]
  );

  const desktopColumns = useMemo(
    () => [
      {
        Header: 'Client',
        id: 'nickName',
        accessor: enableSorting
          ? (client: RoomRecord) => client.clientFirstName || client.clientNickname
          : undefined,
        style: {
          maxWidth: 200,
          width: '75%',
        },
        Cell: ({
          row: { original: client },
          isHovering,
        }: Cell<RoomRecord> & { isHovering: boolean }) => (
          <View row style={{ position: 'relative' }}>
            {handleCellPress({
              roomID: client.roomID,
              component: (
                <DashboardCellRoomDetailV4
                  isHovering={isHovering}
                  fullName={[client.clientFirstName, client.clientLastName].join(' ').trim()}
                  pseudonym={client.clientNickname}
                  preferredName={client.preferredName}
                  messagePreview={client.messagePreview}
                />
              ),
            })}
            {isHovering && (
              <DashboardCellRoomActions
                client={client}
                roomID={client.roomID}
                clientNickname={client.clientNickname}
                clientUserID={client.clientUserID}
              />
            )}
          </View>
        ),
      },
      {
        Header: 'Last activity',
        id: 'lastActivity',
        accessor: enableSorting ? 'lastActivity' : undefined,

        Cell: ({ row: { original: client } }: Cell<RoomRecord>) =>
          handleCellPress({
            roomID: client.roomID,
            component: <>{client.lastActivity ? moment(client.lastActivity).fromNow() : ''}</>,
          }),
      },
      {
        Header: 'Waiting',
        id: 'waiting',
        accessor: enableSorting ? 'clientWaiting' : undefined,
        Cell: ({ row: { original: client } }: Cell<RoomRecord>) =>
          handleCellPress({
            roomID: client.roomID,
            component: ClientWaitingFormatter(client.clientWaiting),
          }),
      },
      {
        Header: 'Room status',
        id: 'clientRenewDateStatus',
        accessor: enableSorting ? 'roomStatus' : undefined,
        style: {
          width: '11%',
        },
        Cell: ({ row: { original: client } }: Cell<RoomRecord>) =>
          handleCellPress({
            roomID: client.roomID,
            component: (
              <View>
                <StatusV4
                  planEnd={client.planEnd}
                  renewDate={client.renewDate}
                  roomStatus={client.roomStatus}
                />
              </View>
            ),
          }),
      },
    ],
    [handleCellPress, ClientWaitingFormatter, enableSorting]
  );

  const responsiveColumns = [
    {
      Header: 'Client',
      id: 'ID',
      accessor: 'clientWaiting',
      Cell: ({ row: { original: client } }: Cell<RoomRecord>) => (
        <View style={{ position: 'relative' }}>
          {handleCellPress({
            roomID: client.roomID,
            component: (
              <View>
                <View row justify="space-between" align="start">
                  <DashboardCellRoomDetailV4
                    fullName={[client.clientFirstName, client.clientLastName].join(' ').trim()}
                    pseudonym={client.clientNickname}
                    preferredName={client.preferredName}
                    styles={{ color: COLORS.permaRiverBed, margin: '5px 0' }}
                    isMobileView
                  />
                  <View row align="center">
                    {moment(client.lastActivity).fromNow()}
                    <i className="unstackable fa fa-angle-right fa-2x pull-right room-arrow" />
                  </View>
                </View>
                <DashboardCellMessagePreview
                  messagePreview={client.messagePreview}
                  clientWaitingTime={waitingTimeFormatter(client.clientWaiting, true)}
                />
              </View>
            ),
          })}
          <View row align="center" justify="space-between">
            <DashboardCellRoomActions
              client={client}
              roomID={client.roomID}
              clientNickname={client.clientNickname}
              clientUserID={client.clientUserID}
            />

            <StatusAndRenewDate
              renewDate={client.renewDate}
              planEnd={client.planEnd}
              roomStatus={client.roomStatus}
            />
          </View>
        </View>
      ),
    },
  ];

  return (
    <Table
      enablePagination={enablePagination}
      disableSortRemove
      hideHeader={isDashboardMobile}
      columns={isDashboardMobile ? responsiveColumns : desktopColumns}
      data={data}
      isLoading={isLoading}
      tableStyle={{ ...tableStyle }}
      onStateChange={onStateChange}
      autoResetPage={autoResetPage}
      getHeaderProps={getHeaderProps}
      initialState={initialState}
      Empty={Empty}
      compareInsensitiveCase={compareInsensitiveCase}
    />
  );
};

export default ClientTable;
