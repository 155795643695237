import { FunctionComponent, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { View, Text } from '@talkspace/react-toolkit';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import Footer from '../../../../Reusable/Footer/Footer';
import Button from '../../../../Reusable/Buttons/Button';
import {
  DiagnosticProfileState,
  ClientDiagnosticProfile,
} from '../../../../../constants/DiagnosticProfileConstants';
import {
  getDiagnosticProfiles,
  setCurrentDiagnosticProfileClient,
} from '../../../../../actions/DiagnosticProfileActions';

const mapStateToProps = (state) => {
  return {
    diagnosticProfiles: state.diagnosticProfile.diagnosticProfiles,
    errorMessage: state.diagnosticProfile.errorMessage,
    clientUserID: state.diagnosticProfile.clientUserID,
    isLoading: state.diagnosticProfile.isLoading,
    isError: state.diagnosticProfile.isError,
    customerList: state.customerList.list,
    roomID: state.room.roomID,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDiagnosticProfiles,
      setCurrentDiagnosticProfileClient,
    },
    dispatch
  );

interface DiagnosticProfileProps
  extends ReturnType<typeof mapDispatchToProps>,
    RouteComponentProps<{}, {}, { focusElement: string; selectedClientID?: string }>,
    DiagnosticProfileState {
  customerList: { userID: string; displayName: string }[];
  roomID: string;
}

interface PercentageProp {
  percentage: number;
}

const DiagnosticDescription = () => (
  <View style={{ marginBottom: 20 }}>
    <Text style={{ fontSize: 16, color: '#546074', textAlign: 'center' }}>
      An analysis suggests the following profile based on: themes, language markers, clinical
      research, and comparative text analysis. Review in conjunction with your clinical expertise to
      help guide treatment.
    </Text>
  </View>
);

const DiagnosticBarContainer = styled(View)({
  position: 'relative',
  height: 20,
  width: '100%',
  borderRadius: 10,
  overflow: 'hidden',
  backgroundColor: '#EFF3FA',
});

const DiagnosticBar = styled(View)<PercentageProp>(({ percentage }) => {
  return {
    height: '100%',
    width: `${percentage}%`,
    backgroundColor: '#5C84FB',
  };
});

const DiagnosticBarText = styled(Text)({
  left: 0,
  right: 0,
  width: 40,
  fontSize: 11,
  color: '#546074',
  textAlign: 'center',
});

const DiagnosticBarPercentage: FunctionComponent<PercentageProp> = ({ percentage }) => (
  <View row style={{ marginTop: 5, alignItems: 'stretch' }}>
    <View style={{ flex: percentage }} />
    <View
      style={{
        width: 1,
        overflow: 'visible',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <DiagnosticBarText>{percentage}%</DiagnosticBarText>
    </View>
    <View style={{ flex: 100 - percentage }} />
  </View>
);

const DiagnosticRow: FunctionComponent<{ diagnostic: ClientDiagnosticProfile }> = ({
  diagnostic,
}) => (
  <View align="stretch" style={{ marginLeft: 3, marginRight: 3, marginTop: 5, marginBottom: 5 }}>
    <Text style={{ marginLeft: 4, color: '#93A1BA', fontWeight: 'bold', fontSize: 14 }}>
      {diagnostic.label}
    </Text>
    <DiagnosticBarContainer>
      <DiagnosticBar percentage={diagnostic.value} />
    </DiagnosticBarContainer>
    <DiagnosticBarPercentage percentage={diagnostic.value} />
  </View>
);

const DiagnosticProfile: FunctionComponent<DiagnosticProfileProps> = ({
  roomID,
  history,
  location,
  isError,
  isLoading,
  customerList,
  clientUserID,
  errorMessage,
  diagnosticProfiles,
  ...otherProps
}) => {
  const { state } = location;
  const isCouple = customerList.length > 1;
  const selectedClientUserID =
    (state && state.selectedClientID) || clientUserID || customerList[0].userID;

  useEffect(() => {
    if (!clientUserID) otherProps.setCurrentDiagnosticProfileClient(selectedClientUserID);
    otherProps.getDiagnosticProfiles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const getFooterComponent = () => (
    <Footer
      childComponents={[
        <Button key={1} title="Cancel" isSecondary clickHandler={() => history.goBack()} />,
        <Button
          key={2}
          title="Update diagnosis"
          isSecondary={false}
          clickHandler={() => {
            history.push(`/room/${roomID}/clinical-tab`, {
              focusElement: 'diagnosis',
              selectedClientID: isCouple ? selectedClientUserID : undefined,
            });
          }}
        />,
      ]}
      isError={isError}
      isUpdating={isLoading}
      showSpinner={isLoading}
      spinnerMessage={errorMessage}
    />
  );

  const content = [
    <DiagnosticDescription key={1} />,
    ...(diagnosticProfiles[selectedClientUserID] || []).map((diagnostic) => (
      <DiagnosticRow key={diagnostic.label} diagnostic={diagnostic} />
    )),
  ];
  return (
    <Submenu
      // @ts-ignore
      prevRoute="clinical-tab"
      childComponents={content}
      title="Diagnostic profile"
      footerComponent={getFooterComponent()}
      bodyStyle={{ paddingLeft: 22, paddingRight: 22 }}
    />
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiagnosticProfile));
