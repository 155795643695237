import { View, useWindowWidth, HoverInfoBox } from '@talkspace/react-toolkit';
import { useState } from 'react';
import IDView from '../../CaseLoadMenu/IDView';
import styled from '../../../../core/styled';

interface IDViewButtonProps {
  roomID: number;
  clientName: string;
  clientUserID: number;
  canReopen: boolean;
}

export const IDContainer = styled(View)<{ isHovering?: boolean }>(
  ({
    theme: {
      colors,
      window: { isDesktop },
    },
    isHovering,
  }) => {
    return {
      width: 20,
      height: 20,
      color: isHovering && !isDesktop ? colors.white : colors.accessibilityGreenDark,
      borderRadius: 6,
      border: `2px solid ${
        !isDesktop && isHovering ? colors.white : colors.accessibilityGreenDark
      }`,
      fontWeight: 'bold',
      lineHeight: '16px',
      textAlign: 'center',
      fontSize: 10,
    };
  }
);

export const IDWrapper = styled(View)<{ isHovering?: boolean }>(
  ({
    theme: {
      colors,
      window: { isDesktop },
    },
    isHovering,
  }) => {
    return {
      width: 24,
      height: 24,
      borderRadius: 6,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 2,
      background: isHovering && isDesktop && colors.aquaSqueeze,
    };
  }
);

const Container = styled(View)(
  ({
    theme: {
      colors,
      window: { isDesktop },
    },
  }) => {
    if (isDesktop) {
      return {
        width: 22,
        height: 22,
        borderRadius: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          background: colors.aquaSqueeze,
        },
      };
    }
    return {
      padding: '12px 16px',
      color: colors.accessibilityGreenDark,
      fontSize: 13,
      fontWeight: 500,
      width: '100%',
      '&:hover': {
        background: colors.deepTeal,
        color: colors.white,
      },
    };
  }
);

const getModalStyles = (canReopen: boolean) => {
  return {
    left: 'auto',
    right: canReopen ? 170 : 130,
    top: 35,
  };
};

const IDViewButton = ({ roomID, clientName, clientUserID, canReopen }: IDViewButtonProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const { isDesktop } = useWindowWidth();

  const modalStyles = getModalStyles(canReopen);

  return (
    <IDView
      roomID={roomID}
      pseudonym={clientName}
      userID={clientUserID}
      isHovering
      floatingMenuStyle={{ ...modalStyles }}
      customIcon={
        <Container
          style={{ marginRight: canReopen && 13 }}
          row
          align="center"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={() => setIsHovering(true)}
        >
          <IDWrapper isHovering={isHovering}>
            <IDContainer isHovering={isHovering}>ID</IDContainer>
          </IDWrapper>

          {isDesktop && (
            <HoverInfoBox
              toolTipText="View client ID"
              isTooltipOpen={isHovering}
              styles={{ left: canReopen ? -120 : -80 }}
            />
          )}
        </Container>
      }
    />
  );
};

export default IDViewButton;
