import * as actionTypes from '../constants/QuickMatchConstants';

const initialState = {
  answers: [],

  spinnerMessage: ' ',
  isUpdating: false,
  isError: false,
};

export default function quickMatch(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_GET_QUICK_MATCH_ANSWERS:
      return {
        ...state,
        spinnerMessage: 'Loading quick match answers',
        isUpdating: true,
        isError: false,
      };
    case actionTypes.REQUEST_GET_QUICK_MATCH_ANSWERS_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };
    case actionTypes.RECEIVE_GET_QUICK_MATCH_ANSWERS:
      return {
        ...state,
        answers: action.data,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    default:
      return state;
  }
}
