import { VoidFunctionComponent } from 'react';
import {
  Standard,
  View,
  BaseButton,
  TextAreaRounded,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { keyframes } from '@emotion/core';
import styled from '../../../../../core/styled';
import SubmitButton from '../../TaskActionButtons/SubmitButton';

interface SnoozeDescriptionScreenProps {
  content?: JSX.Element;
  disableSubmit?: boolean;
  providerSnoozeDescription: string;
  handleProviderSnoozeDescription: (userInput: string) => void;
  handleReset: () => void;
  handleSubmitSnooze: () => void;
}

const fadeIn = keyframes`
 0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const CommentWrapper = styled(View)(() => {
  return {
    animation: `${fadeIn} 500ms cubic-bezier(.65,-0.01,.38,1) both`,
  };
});

const ActionTypeButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    marginLeft: 15,
    '& > p': {
      fontWeight: 500,
      color: colors.slateGrey,
    },
  };
});

const SnoozeDescriptionScreen: VoidFunctionComponent<SnoozeDescriptionScreenProps> = ({
  content,
  disableSubmit,
  providerSnoozeDescription,
  handleProviderSnoozeDescription,
  handleReset,
  handleSubmitSnooze,
}) => {
  const { colors } = useEmotionTheme();
  return (
    <CommentWrapper>
      <View style={{ marginBottom: 20, display: 'inline-block' }}>{content}</View>
      <TextAreaRounded
        style={{
          height: 102,
          minHeight: 102,
          borderColor: colors.permaWildBlueYonder,
          padding: 11,
        }}
        textAreaStyle={{
          width: 252,
          margin: 0,
          fontSize: 14,
          padding: 0,
          caretColor: colors.permaTalkspaceDarkGreen,
        }}
        value={providerSnoozeDescription}
        onChangeText={handleProviderSnoozeDescription}
        hasLimit
        limit={500}
      />
      <View row justify="end" style={{ marginTop: 15 }}>
        <ActionTypeButton onPress={handleReset}>
          <Standard>Cancel</Standard>
        </ActionTypeButton>
        <SubmitButton
          style={{ width: 134, marginLeft: 20 }}
          onPress={() => {
            handleReset();
            handleSubmitSnooze();
          }}
          {...(disableSubmit && { disabled: true })}
        >
          <Standard variant="standardWhite">Submit</Standard>
        </SubmitButton>
      </View>
    </CommentWrapper>
  );
};
export default SnoozeDescriptionScreen;
