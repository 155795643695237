import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { getVideoCredits } from '../../../../../actions/VideoCreditsActions';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import ListItem from '../../../../Reusable/ListItems/ListItem';
import ItemList from '../../../../Reusable/ItemList/ItemList';
import { formatDateShort, formatDateTimeShort } from '../../../../../utils/date';
import { getActiveCustomerName } from '../../../../../utils/customerHelper';

class VideoCredits extends Component {
  constructor(props) {
    super(props);

    this.getItemList = this.getItemList.bind(this);
    this.getAllVideoCredits = this.getAllVideoCredits.bind(this);
    this.getVideoCallHistoryButtons = this.getVideoCallHistoryButtons.bind(this);
    this.getContent = this.getContent.bind(this);
  }

  componentDidMount() {
    this.props.getVideoCredits();
  }

  getAllVideoCredits() {
    const formatRedeemed = (redeemedAt) => {
      if (redeemedAt) return formatDateTimeShort(redeemedAt);
      return 'No';
    };
    const getItemContent = (videoCredit, key) => {
      const itemData = {
        validBetween:
          videoCredit.purchasedInRoomID === 0
            ? 'Always valid'
            : `${formatDateShort(videoCredit.validFrom)} - ${moment(videoCredit.validTo)
                .subtract(1, 'd')
                .format('MMM DD')}`,
        duration:
          videoCredit.creditMinutesOptions && !videoCredit.redeemedAt
            ? `[${videoCredit.creditMinutesOptions}]min`
            : `${videoCredit.creditMinutes} min`,
        redeemed: formatRedeemed(videoCredit.redeemedAt),
      };

      return (
        <ListItem
          key={key}
          itemDataOrder={['validBetween', 'duration', 'redeemed']}
          itemDisplayData={itemData}
          status={videoCredit.redeemedAt}
          widths={[105, 92, 107]}
        />
      );
    };

    return this.props.videoCredits.data.map(getItemContent);
  }

  getContent() {
    return (
      <UserActionCard
        preferredName={getActiveCustomerName(
          this.props.customerInformation,
          this.props.customerList
        )}
        content={this.getItemList()}
        buttons={this.getVideoCallHistoryButtons()}
        isError={this.props.videoCredits.requestError}
        showSpinner={this.props.videoCredits.isRequesting}
        spinnerMessage="Loading live session credits"
      />
    );
  }

  // TODO: This is supposed to be a static method, so not sure how this works
  // eslint-disable-next-line react/no-typos
  getDerivedStateFromProps(nextProps) {
    if (nextProps.videoCredits !== this.props.videoCredits) {
      return {
        videoCredits: nextProps.videoCredits,
      };
    }
    return undefined;
  }

  getItemList() {
    return (
      <ItemList
        items={this.getAllVideoCredits()}
        titles={['Valid between', 'Duration', 'Redeemed']}
        noDataMessage="No video credits found"
        widths={[105, 92, 107]}
      />
    );
  }

  getVideoCallHistoryButtons() {
    const { history } = this.props;

    return [
      {
        isSecondary: false,
        title: 'View live session history',
        clickHandler: () => {
          history.push(`/room/${this.props.roomID}/video-call-history`);
        },
      },
    ];
  }

  render() {
    return (
      <Submenu
        childComponents={[this.getContent()]}
        title="Live session credits"
        prevRoute="case-tab"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    videoCredits: state.videoCredits,
    customerInformation: state.customerInformation,
    roomID: state.room.roomID,
    customerList: state.customerList.list,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getVideoCredits }, dispatch);

const VideoCreditsContainer = connect(mapStateToProps, mapDispatchToProps)(VideoCredits);

export default VideoCreditsContainer;
