import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { startCase } from 'lodash';
import FreeFormInput from '../../../../Reusable/FreeFormInput/FreeFormInput.js';
import { getEmergencyContact } from '../../../../../actions/EmergencyContactActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';

const formatField = (field) => {
  if (field === 'zipcode') return 'ZIP code';
  return startCase(field)
    .split(' ')
    .map((s, i) => (i === 0 ? s : s.toLowerCase()))
    .join(' ');
};

class EmergencyContactDetails extends Component {
  componentDidMount() {
    this.props.getEmergencyContact(this.props.location.state.clientUserID);
  }

  renderEmergencyContactDetails() {
    const { clientUserID } = this.props.location.state;
    const { emergencyContactByClientUserID } = this.props;
    const emergencyContact = emergencyContactByClientUserID[clientUserID] || {};
    const fields = Object.keys(emergencyContact);
    const contactFields = fields.slice(0, 8);
    const emergencyContactFields = fields.slice(8);
    return (
      <div className="list-body">
        <div className="list-header">My contact information</div>
        <div>
          {contactFields.map((field, i) => (
            <FreeFormInput
              key={i}
              disabled
              placeholder={formatField(field)}
              value={emergencyContact[field]}
            />
          ))}
        </div>
        <div className="list-header" style={{ marginTop: 40 }}>
          Primary contacts information
        </div>
        <div>
          {emergencyContactFields.map((field, i) => (
            <FreeFormInput
              key={i}
              disabled
              placeholder={formatField(field)}
              value={emergencyContact[field]}
            />
          ))}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Submenu
        childComponents={[this.renderEmergencyContactDetails()]}
        title="Emergency contact"
        prevRoute="emergency-contact"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emergencyContactByClientUserID: state.emergencyContact.emergencyContactByClientUserID,
    clientList: state.customerList.list,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({ getEmergencyContact }, dispatch);
const EmergencyContactDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmergencyContactDetails);

export default EmergencyContactDetailsContainer;
