import * as actionTypes from '../constants/QuickMatchConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

function requestGetQuickMatchAnswers() {
  return { type: actionTypes.REQUEST_GET_QUICK_MATCH_ANSWERS };
}

function receiveGetQuickMatchAnswers(response) {
  return {
    type: actionTypes.RECEIVE_GET_QUICK_MATCH_ANSWERS,
    ...response.data,
  };
}

function requestGetQuickMatchAnswersError(error) {
  return {
    type: actionTypes.REQUEST_GET_QUICK_MATCH_ANSWERS_ERROR,
    error,
  };
}

// eslint-disable-next-line import/prefer-default-export
export const getQuickMatchAnswers = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestGetQuickMatchAnswers());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/quickmatch-answers`)
    .then((response) => dispatch(receiveGetQuickMatchAnswers(response)))
    .catch((error) => dispatch(requestGetQuickMatchAnswersError(error)));
};
