import { View, Small } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const ReminderBubble = styled(View)(({ theme: { colors } }) => {
  return {
    borderRadius: '50%',
    display: 'inline-block',
    textAlign: 'center',
    padding: '0 6px',
    marginLeft: 2,
    backgroundColor: colors.permaRibbonRed,
    color: colors.white,
  };
});

interface Props {
  numberOfReminders: number;
}

const RoomReminders = ({ numberOfReminders }: Props) => (
  <View row align="center">
    <Small variant="smallTSBlack">Reminders</Small>
    <ReminderBubble>{numberOfReminders > 99 ? '99+' : numberOfReminders}</ReminderBubble>
  </View>
);

export default RoomReminders;
