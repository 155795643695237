import { useMutation, useQueryClient } from 'react-query';
import dashboardKeys from 'hooks/dashboard/queryKeys';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import {
  notesListQueryKey,
  progressNoteHelperDataQueryKey,
  progressNoteQueryKey,
} from './queryKeys';

interface ReopenSessionMutateVariables {
  roomID: string;
  caseID: number;
  noteID?: number;
  clientUserID?: number;
  sessionReportID: number;
  currentSessionReportID?: number;
  therapistID?: number;
  reason?: string;
  reasonDescription?: string;
}

const reopenSession = async (params: ReopenSessionMutateVariables): Promise<void> => {
  const { roomID, caseID, sessionReportID, currentSessionReportID, reason, reasonDescription } =
    params;
  await apiWrapper.post(
    `${
      apiHelper().apiEndpoint
    }/v4/rooms/${roomID}/cases/${caseID}/session-reports/${sessionReportID}/reopen-request`,
    { reason: reason || '', currentSessionReportID, reasonDescription }
  );
};

const useMutationReopenSession = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, ReopenSessionMutateVariables>(reopenSession, {
    onSuccess: (_, { roomID, noteID, clientUserID, therapistID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
      queryClient.invalidateQueries(progressNoteHelperDataQueryKey(roomID));

      if (noteID) {
        queryClient.invalidateQueries(progressNoteQueryKey(clientUserID, noteID));
      }
      if (therapistID) {
        queryClient.invalidateQueries(dashboardKeys.tasklist(therapistID));
      }
    },
  });
};

export default useMutationReopenSession;
