import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactFrameService from '../../../../../modules/auth/reactFrame/ReactFrameService';
import {
  postMatchingIntakeSurveyMessage,
  getMatchingIntakeSurveyStatus,
} from '../../../../../actions/MatchingActions';
import SocketService from '../../../../../utils/socket/SocketService';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import { getActiveCustomerName } from '../../../../../utils/customerHelper';

class MatchingIntake extends Component {
  constructor(props) {
    super(props);
    this.state = { showSpinner: false };
  }

  componentDidMount() {
    const { roomID } = this.props.match.params;
    this.props.getMatchingIntakeSurveyStatus();
    SocketService.instance().on('submittedIntakeSurvey', this.handleSubmittedIntakeSurveyEvent);
  }

  componentWillUnmount() {
    SocketService.instance().off('submittedIntakeSurvey', this.handleSubmittedIntakeSurveyEvent);
  }

  handleSubmittedIntakeSurveyEvent = (data) => {
    const { roomID } = this.props.match.params;

    if (data.type !== 'matching') {
      return;
    }

    if (Number(data.roomID) !== Number(roomID)) {
      return;
    }

    this.props.getMatchingIntakeSurveyStatus();
  };

  requestIntakeSurveyMessage = () => {
    this.setState({ showSpinner: true });
    setTimeout(() => {
      const { roomID } = this.props.match.params;
      this.setState({ showSpinner: false });

      if (ReactFrameService.instance().isInFrame()) {
        ReactFrameService.instance().closePopup({
          navigateTo: 'room',
          metadata: { roomID },
        });
      }
    }, 3000);
    this.props.postMatchingIntakeSurveyMessage();
  };

  renderIntakeSurveyCards = () => {
    const {
      matching: { matchingIntakeStatus: intakeStatus, isUpdating, isError },
    } = this.props;
    const isCompleted = intakeStatus === 'submitted';
    const hasRequested = intakeStatus === 'requested';
    const isPending = intakeStatus && !isCompleted && hasRequested;
    const buttons = [
      {
        title:
          isCompleted || hasRequested || isPending
            ? 'Request matching intake again'
            : 'Send matching survey',
        isSecondary: isCompleted,
        clickHandler: () => this.requestIntakeSurveyMessage(),
      },
    ];
    let cardText =
      hasRequested || isPending
        ? 'You have already sent the intake survey to the client, but the client hasn’t completed it yet. Please reach out to the client to discuss any questions or concerns they may have. You can click below to send it again or wait for the client to submit their information.'
        : 'For CT use only. The intake survey is designed to help CT’s gather essential demographic information about the client. Click here to send this to the client. Once completed, all of the information will appear in the appropriate fields in the CRM.';
    if (isCompleted)
      cardText =
        'The client has completed the intake survey. The information has populated into the appropriate fields in the CRM.';
    return (
      <UserActionCard
        key={1}
        preferredName={getActiveCustomerName(
          this.props.customerInformation,
          this.props.customerList
        )}
        content={cardText}
        buttons={buttons}
        isUpdating={isUpdating}
        isError={isError}
        showSpinner={this.state.showSpinner}
      />
    );
  };

  render() {
    return (
      <Submenu
        childComponents={[this.renderIntakeSurveyCards()]}
        title="Matching intake"
        prevRoute="case-tab"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    matching: state.matching,
    customerList: state.customerList.list,
    customerInformation: state.customerInformation,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ postMatchingIntakeSurveyMessage, getMatchingIntakeSurveyStatus }, dispatch);
const MatchingIntakeContainer = connect(mapStateToProps, mapDispatchToProps)(MatchingIntake);

export default MatchingIntakeContainer;
