import { Component } from 'react';
import PropTypes from 'prop-types';
import FreeFormInputView from './FreeFormInputView';

class FreeFormInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      inputValue: undefined,
      placeholderState: '',
      disabled: false,
      isFocused: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    this.setState({
      inputValue: this.props.value,
      placeholderState: this.props.value ? 'up' : 'down',
    });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.value !== this.state.inputValue && this.state.isFocused === false) {
      this.setState({
        inputValue: nextProps.value,
        placeholderState: nextProps.value ? 'up' : 'down',
      });
    }
  }

  handleBlur(event, data) {
    event.persist();
    if (this.state.inputValue === '') this.setState({ placeholderState: 'down' });
    if (this.state.inputValue !== this.props.value) {
      this.props.saveChange(event);
    }
    this.setState({ isFocused: false });
    this.handleChange(event);
  }

  handleChange(event) {
    if (this.state.isFocused === true) {
      this.setState(
        {
          inputValue: event.target.value,
        },
        () => {
          let isValid = this.state.inputValue != '';
          if (this.props.isValid !== undefined) isValid = this.props.isValid;
          this.setState({
            valid: isValid,
          });
        }
      );
    }
  }

  handleFocus(event) {
    this.setState({ placeholderState: 'up', isFocused: true });
  }

  render() {
    let isValid = this.state.valid;
    if (this.props.isValid !== undefined) isValid = this.props.isValid;
    return (
      <FreeFormInputView
        id={this.props.id}
        placeholderState={this.state.placeholderState}
        placeholder={this.props.placeholder}
        valid={isValid}
        value={this.props.value}
        changeHandler={this.handleChange}
        blurHandler={this.handleBlur}
        focusHandler={this.handleFocus}
        inputValue={this.state.inputValue}
        disabled={this.props.disabled}
      />
    );
  }
}

FreeFormInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

export default FreeFormInput;
