import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactFrameService from '../../../../../modules/auth/reactFrame/ReactFrameService';
import './MatchingTherapist.css';
import {
  getCustomerInformation,
  setCustomerInformation,
} from '../../../../../actions/CustomerInformationActions';
import {
  getFieldsOfExpertiseOptions,
  switchRoomTherapist,
  suggestTherapists,
} from '../../../../../actions/MatchingActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';
import SocketService from '../../../../../utils/socket/SocketService';
import MatchingView from './MatchingView';

function getTherapistYearsOfExperienceOptions() {
  return [
    { value: 'any', label: 'Any' },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
  ];
}

function isCTInCouplesRoom(therapistType, roomType) {
  return (
    ['consultation', 'consultation_and_primary'].includes(therapistType) &&
    roomType === 'couples_room'
  );
}
class SuggestTherapistComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSpecialties: [],
      selectedLanguages: [{ value: 15, label: 'English' }], // select english by default
      selectedAgeGroups: getOptionsByField('therapistAgeGroups'),
      selectedYearsOfExperience: { value: 'any', label: 'Any' },
      ageGroup: [],
      suggestInitiated: false,
      ...this.getInitialStateFromProps(props),
    };
  }

  componentDidMount() {
    SocketService.instance().on('submittedIntakeSurvey', this.handleSubmittedIntakeSurveyEvent);
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);

    SocketService.instance().off('submittedIntakeSurvey', this.handleSubmittedIntakeSurveyEvent);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // TODO: delete this and use static getDerivedStateFromProps function
    if (nextProps.noTherapistsFound)
      this.hideSpinnerIfNoTherapistsFound(nextProps.noTherapistsFound);
    else this.redirectToDashboardIfSuggestSuccessful(nextProps.isSuggesting, nextProps.isError);
    this.displaySpinnerIfSuggesting(nextProps.isSuggesting);

    const existingInitialState = this.getInitialStateFromProps(this.props);
    const nextInitialState = this.getInitialStateFromProps(nextProps);

    if (JSON.stringify(existingInitialState) !== JSON.stringify(nextInitialState)) {
      this.setState(nextInitialState);
    }
  }

  callSuggestTherapistsRPC = () => {
    this.props.suggestTherapists(this.formatRequestBody());
  };

  continueButtonClickHandler = () => () => {
    const isMissingInfo = this.isStateMissing() || this.isCountryMissing();
    if (!isMissingInfo) this.setState({ displayAlert: false });
  };

  createSaveFieldHandler =
    (field, isMulti = false) =>
    (value) => {
      this.setState({ [field]: isMulti ? value || [] : value });
    };

  displaySpinnerIfSuggesting = (nextPropsIsSuggesting) => {
    if (!this.props.isSuggesting && nextPropsIsSuggesting)
      this.setState({ suggestInitiated: true });
  };

  formatRequestBody = () => {
    const {
      selectedSpecialties = [],
      selectedTreatmentPreferences = [],
      selectedClientFocus = [],
      selectedAgeGroups = [],
      selectedLanguages = [],
      selectedTherapistGender,
      selectedTherapistEthnicity,
      selectedYearsOfExperience,
    } = this.state;
    const fieldsOfExpertiseArray = [
      ...selectedSpecialties.map((v) => v.value),
      ...selectedTreatmentPreferences.map((v) => v.value),
      ...selectedClientFocus.map((v) => v.value),
    ];
    const fieldsOfExpertise = fieldsOfExpertiseArray.length
      ? { fieldsOfExpertise: fieldsOfExpertiseArray }
      : undefined;
    const languages = selectedLanguages.length
      ? { languages: selectedLanguages.map((v) => v.value) }
      : undefined;
    const yearsOfExperience =
      selectedYearsOfExperience.value !== 'any'
        ? { yearsOfExperience: selectedYearsOfExperience.value }
        : undefined;
    return {
      ageGroups: selectedAgeGroups.map((v) => v.value),
      therapistGender: selectedTherapistGender && selectedTherapistGender.value,
      therapistEthnicity: selectedTherapistEthnicity && selectedTherapistEthnicity.value,
      ...fieldsOfExpertise,
      ...languages,
      ...yearsOfExperience,
    };
  };

  getInitialStateFromProps = (props) => {
    const result = {
      selectedTreatmentPreferences: isCTInCouplesRoom(props.therapistType, props.roomType)
        ? [{ value: 128, label: 'Couples' }]
        : [],
      disabledTreatmentPreferences: isCTInCouplesRoom(props.therapistType, props.roomType)
        ? [{ value: 128, label: 'Couples' }]
        : [],
      selectedClientFocus:
        (props.country || {}).value !== 'US' ? [{ value: 177, label: 'International' }] : [], // if international than pre-select "International" it in client focus
      selectedTherapistGender: props.therapistGenderPreference || {
        value: null,
        label: 'No Preference',
      },
      selectedTherapistEthnicity: props.therapistEthnicityPreference || {
        value: null,
        label: 'No Preference',
      },
    };

    return result;
  };

  handleSubmittedIntakeSurveyEvent = (data) => {
    const { clientUserID, roomID } = this.props;

    if (data.type !== 'matching') {
      return;
    }

    if (Number(data.roomID) !== Number(roomID)) {
      return;
    }

    this.props.getCustomerInformation(clientUserID);
  };

  hideSpinnerIfNoTherapistsFound = (nextPropsNoTherapistsFound) => {
    if (nextPropsNoTherapistsFound && !this.props.noTherapistsFound)
      this.timeout = setTimeout(() => this.setState({ suggestInitiated: false }), 3000);
  };

  isCountryMissing = () => !this.props.country;

  isStateMissing = () => (this.props.country || {}).value === 'US' && !this.props.state;

  redirectToDashboardIfSuggestSuccessful = (nextPropsIsSuggesting, nextPropsIsError) => {
    const suggestWasSuccessful =
      this.props.isSuggesting && !this.props.isError && !nextPropsIsSuggesting && !nextPropsIsError;
    if (suggestWasSuccessful)
      this.timeout = setTimeout(() => {
        if (ReactFrameService.instance().isInFrame()) {
          ReactFrameService.instance().closePopup({
            navigateTo: 'room',
            metadata: { roomID: this.props.roomID },
          });
        } else {
          this.props.history.push(`/room/${this.props.roomID}/case-tab`);
        }
      }, 2000);
  };

  render() {
    const inPlatformMatchingOptions = {
      yearsOfExperienceOptions: getTherapistYearsOfExperienceOptions(),
    };
    const selectedOptions = {
      selectedSpecialties: this.state.selectedSpecialties,
      selectedTreatmentPreferences: this.state.selectedTreatmentPreferences,
      disabledTreatmentPreferences: this.state.disabledTreatmentPreferences,
      selectedClientFocus: this.state.selectedClientFocus,
      selectedLanguages: this.state.selectedLanguages,
      selectedTherapistGender: this.state.selectedTherapistGender,
      selectedTherapistEthnicity: this.state.selectedTherapistEthnicity,
      selectedAgeGroups: this.state.selectedAgeGroups,
      selectedYearsOfExperience: this.state.selectedYearsOfExperience,
    };
    return (
      <Submenu
        childComponents={[
          <MatchingView.ChildComponents
            key={4123}
            isSuggestATherapist
            getUpdateFunctionForComponent={this.getUpdateFunctionForComponent}
            displayAlert={this.isStateMissing() || this.isCountryMissing()}
            isStateMissing={this.isStateMissing()}
            isCountryMissing={this.isCountryMissing()}
            suggestInitiated={this.state.suggestInitiated}
            callSuggestTherapistsRPC={this.callSuggestTherapistsRPC}
            continueButtonClickHandler={this.continueButtonClickHandler}
            createSaveFieldHandler={this.createSaveFieldHandler}
            {...this.props}
            selectedOptions={selectedOptions}
            inPlatformMatchingOptions={inPlatformMatchingOptions}
          />,
        ]}
        footerComponent={
          <MatchingView.FooterComponent
            key={4123}
            isSuggestATherapist
            getUpdateFunctionForComponent={this.getUpdateFunctionForComponent}
            displayAlert={this.isStateMissing() || this.isCountryMissing()}
            isStateMissing={this.isStateMissing()}
            isCountryMissing={this.isCountryMissing()}
            suggestInitiated={this.state.suggestInitiated}
            callSuggestTherapistsRPC={this.callSuggestTherapistsRPC}
            continueButtonClickHandler={this.continueButtonClickHandler}
            createSaveFieldHandler={this.createSaveFieldHandler}
            {...this.props}
            selectedOptions={selectedOptions}
            inPlatformMatchingOptions={inPlatformMatchingOptions}
          />
        }
        title="Suggest a provider"
        prevRoute="case-tab"
      />
    );
  }
}

const mapStateToProps = ({ availability, room, customerInformation, customerList, matching }) => {
  return {
    therapistType: availability.therapistType,
    roomID: room.roomID,
    roomType: room.roomType,
    country: customerInformation.country,
    state: customerInformation.state,
    therapistGenderPreference: customerInformation.therapistGenderPreference,
    therapistEthnicityPreference: customerInformation.therapistEthnicityPreference,
    clientUserID: customerInformation.clientUserID,
    customerList: customerList.list,
    isRequestingCustomer: customerInformation.isRequestingCustomer,
    isPatchingCustomer: customerInformation.isPatchingCustomer,
    invalidComponents: customerInformation.invalidComponents,
    disabledComponents: customerInformation.disabledComponents,
    isError: matching.isError,
    isSuggesting: matching.isSuggesting,
    noTherapistsFound: matching.noTherapistsFound,
    inPlatformMatchingOptions: matching.inPlatformMatchingOptions,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFieldsOfExpertiseOptions,
      suggestTherapists,
      getCustomerInformation,
      setCustomerInformation,
      switchRoomTherapist,
    },
    dispatch
  );
const SuggestTherapist = connect(mapStateToProps, mapDispatchToProps)(SuggestTherapistComponent);

export default SuggestTherapist;
