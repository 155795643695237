import {
  useUniqueID,
  View,
  TouchableView,
  PanelManager,
  usePanelManagerState,
  usePanelManagerActions,
  useWindowWidthState,
  TaskList,
  GrowCaseloadIcon,
  Tiles,
  withHover,
  Clock,
} from '@talkspace/react-toolkit';
import { Calendar } from '@talkspace/react-toolkit/src/designSystems/icons';
import { Link } from 'react-router-dom';
import DashboardNonCT from 'components/Dashboard/DashboardNonCT';
import DashboardNonCTV4 from 'components/Dashboard/DashboardNonCTV4';
import Tasks from 'components/Dashboard/Tasks/Tasks';
import TasksV2 from 'components/Dashboard/TasksV2';
import GrowCaseload from 'components/Dashboard/GrowCaseload/GrowCaseload';
import ClinicalHours from 'components/Dashboard/ClinicalHours';
import SideBar from 'components/Reusable/SideBar';
import NavBar from 'components/Reusable/NavBar/NavBar';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { SideBarItems } from 'components/Reusable/SideBar/SideBar';
import useQueryTaskList from 'hooks/dashboard/useQueryTaskList';
import useQueryTaskListV3 from 'hooks/dashboard/useQueryTaskListV3';
import useQueryTherapistAvailability from 'hooks/availability/useQueryTherapistAvailability';
import { routePromise } from 'ts-frontend/helpers';
import { useQueryClient } from 'react-query';
import dashboardKeys from 'hooks/dashboard/queryKeys';
import availabilityKeys from 'hooks/availability/queryKeys';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { Route, Switch, useLocation } from '../../core/routerLib';
import { getUserData, removeTokenFromLocalStore } from '../../utils/token';
import styled, { EmotionStyle } from '../../core/styled';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { tokenIsValid } from '../../utils/tokenIsValid';
import Coin from '../Icons/Coin';
import PaymentReportsContainer from '../PaymentReports/PaymentReportsContainer';
import ProviderCalendar from '../Dashboard/Calendar/components/Calendar';
import ProviderCalendarV2 from '../Dashboard/ProviderCalendarV2/components/ProviderCalendarV2';
import { isIonic } from '../../modules/auth/reactFrame/helpers';

const Wrapper = styled(View)({
  flex: 1,
  flexDirection: 'row',
});

const BackDrop = styled(TouchableView)<{ isVisible: boolean; isLeftPanelOnTop: boolean }>(
  ({ theme: { colors }, isVisible, isLeftPanelOnTop }) => {
    return {
      position: 'fixed',
      background: colors.permaCadetGrey,
      width: '100%',
      height: '100%',
      bottom: 0,
      left: 0,
      zIndex: isVisible ? 5 : 1,
      opacity: 0,
      visibility: isVisible ? 'visible' : 'hidden',
      transition: 'opacity 400ms',
      transitionTimingFunction: `cubic-bezier(.65,-0.01,.38,1)`,
      ...(isLeftPanelOnTop && {
        opacity: 0.65,
      }),
    };
  }
);

const BaseHoverDetectingView = ({ children, isHovering, onHovering }) => {
  useEffect(() => onHovering(isHovering), [isHovering, onHovering]);
  return children;
};
const HoverDetectingView = withHover(BaseHoverDetectingView);

const TherapistWebPanelManager = () => {
  const queryClient = useQueryClient();
  const { isMobile, isDesktop, isLarge } = useWindowWidthState();
  const [isVisible, setIsVisibility] = useState<boolean>(false);
  const therapistID = getUserData().id;
  const {
    data: therapistAvailability,
    isLoading: isTherapistAvailabilityLoading,
    isError: isTherapistAvailabilityError,
  } = useQueryTherapistAvailability(therapistID);
  const [countBadge, setCountBadge] = useState<number>(0);
  const [shouldShowExpandCollapseIcon, setShouldShowExpandCollapseIcon] = useState(false);
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const { pathname, search } = useLocation();
  const isHidden =
    Boolean(new URLSearchParams(search).get('noHeader')) ||
    ReactFrameService.instance().isInFrame();
  const {
    growCaseload,
    therapistClientList,
    calendarAvailability,
    accessAndSubmitAsyncTasks: { accessAndSubmitAsyncTasks } = {},
    icpBonusVisualizationPreferences: { featureOn, showMobile },
    nppClinicalHours: isNPPClinicalHoursActive,
  } = useFlags();

  const isInFrame = ReactFrameService.instance().isInFrame();

  const isMobileOrInFrame = isMobile || isInFrame;
  const icpBonusVisualizationSwitch =
    featureOn && (!isMobileOrInFrame || (isMobileOrInFrame && showMobile));

  const fetchTasks = accessAndSubmitAsyncTasks ? useQueryTaskListV3 : useQueryTaskList;

  const taskList = fetchTasks(therapistID);

  const { setIsLeftPanelOnTop } = usePanelManagerActions();
  const { isLeftPanelOnTop } = usePanelManagerState();

  const dashID = useUniqueID('dashID');
  const tableID = useUniqueID('tableID');

  useEffect(() => {
    queryClient.invalidateQueries(dashboardKeys.all);
    queryClient.invalidateQueries(availabilityKeys.capacity(therapistID));
  }, [queryClient, therapistID]);

  useEffect(() => {
    if (isMobile || isLarge) {
      setIsSidebarMinimized(true);
    }
    if (isDesktop) {
      setIsSidebarMinimized(false);
    }
  }, [isMobile, isDesktop, isLarge]);

  useEffect(() => {
    setCountBadge(taskList?.data?.length || 0);
  }, [taskList]);

  const checkAuthBeforeRedirect = useCallback((event: React.MouseEvent) => {
    if (!tokenIsValid()) {
      event.preventDefault();
      removeTokenFromLocalStore();
      routePromise('/login');
    }
  }, []);

  const checkRoomPath = useCallback(
    (
      roomID,
      linkText: JSX.Element | string,
      className: string = 'dashboard-link',
      path: string = 'case-tab'
    ) => (
      <Link
        to={{
          pathname: `/room/${roomID}/${path}`,
          state: { postActionRoute: 'chat' },
        }}
        onMouseEnter={(event) => checkAuthBeforeRedirect(event)}
        className={className}
      >
        {linkText}
      </Link>
    ),
    [checkAuthBeforeRedirect]
  );

  const leftPanelSize: EmotionStyle = useMemo(() => {
    return {
      height: isMobile ? '100vh' : '100%',
      transitionTimingFunction: `cubic-bezier(.65,-0.01,.38,1)`,
      ...(isMobile && {
        width: 320,
        zIndex: 10,
        transition: 'left 500ms',
        position: 'fixed',
      }),
      ...((isDesktop || isLarge) && {
        width: isSidebarMinimized ? 74 : 200,
        transitionProperty: 'width',
        transition: 'width 300ms',
      }),
    };
  }, [isDesktop, isLarge, isMobile, isSidebarMinimized]);

  const handleMenuPress = useCallback(() => {
    setIsLeftPanelOnTop(true);
    setIsVisibility((visible) => !visible);
  }, [setIsLeftPanelOnTop]);

  const handleExpandCollapsePress = useCallback(() => {
    setIsSidebarMinimized(!isSidebarMinimized);
  }, [isSidebarMinimized]);

  const nonW2OnlySidebarItems = [
    {
      icon: <Coin isActive={pathname === '/payment-reports'} />,
      label: icpBonusVisualizationSwitch ? 'Earnings' : 'Payment',
      path: icpBonusVisualizationSwitch ? '/earnings' : '/payment-reports',
    },
  ];

  const showNPPClinicalHours = !!(isNPPClinicalHoursActive && therapistAvailability?.isW2);

  const baseSideBarItems: SideBarItems[] = [
    {
      icon: <Tiles titleText="rooms" isActive={pathname === '/rooms'} />,
      label: 'Dashboard',
      path: '/rooms',
      dataQa: 'dashboardSideBarLink',
    },
    {
      countBadge,
      icon: <TaskList />,
      label: 'Tasks',
      path: '/tasks',
      iconStyle: { left: 15 },
      dataQa: 'tasksSideBarLink',
      ...(countBadge > 0 && { menuNameStyle: { fontWeight: 'bold', color: '#222F2D' } }),
    },
    {
      icon: (
        <Calendar
          size="major"
          variant={pathname === '/calendar' ? 'filled' : 'default'}
          colorType="brand"
        />
      ),
      label: 'Calendar',
      path: '/calendar',
      iconStyle: { left: 15 },
      dataQa: 'calendarSideBarLink',
    },

    ...(showNPPClinicalHours
      ? [
          {
            icon: <Clock variant={pathname === '/clinical-hours' ? 'filled' : 'default'} />,
            label: 'Clinical hours',
            path: '/clinical-hours',
            iconStyle: { left: 17 },
            dataQa: 'clinicalHoursSideBarLink',
          },
        ]
      : []),

    ...(growCaseload && therapistAvailability?.therapistType !== 'psychiatrist'
      ? [
          {
            icon: <GrowCaseloadIcon />,
            label: 'Grow caseload',
            path: '/grow-caseload',
            iconStyle: { left: 17 },
            dataQa: 'growCaseloadSideBarLink',
          },
        ]
      : []),
  ];

  const sideBarItems =
    therapistAvailability?.isW2 === 0 &&
    (therapistAvailability?.therapistType === 'primary' ||
      therapistAvailability?.therapistType === 'psychiatrist')
      ? [
          ...baseSideBarItems.slice(0, baseSideBarItems.length - 1),
          ...nonW2OnlySidebarItems,
          baseSideBarItems[baseSideBarItems.length - 1],
        ]
      : baseSideBarItems;

  const heightStyle = isHidden
    ? { ...(isIonic() && { height: '100dvh' }) }
    : { height: `calc(100dvh - 68px)` };

  return (
    <>
      <NavBar
        isCT={false}
        handleMenuPress={handleMenuPress}
        isDashboard
        isHidden={isHidden}
        showIndicator={countBadge > 0}
      />
      <Wrapper>
        {!isHidden && (
          <HoverDetectingView onHovering={setShouldShowExpandCollapseIcon}>
            <PanelManager.LeftPanel
              id={dashID}
              style={leftPanelSize}
              role="navigation"
              aria-label="sidebar"
            >
              <SideBar
                sideBarItems={sideBarItems}
                handleMenuItemPress={() => setIsLeftPanelOnTop(false)}
                shouldShowExpandCollapseIcon={shouldShowExpandCollapseIcon}
                handleExpandCollapsePress={handleExpandCollapsePress}
                isSidebarMinimized={isSidebarMinimized}
              />
            </PanelManager.LeftPanel>
          </HoverDetectingView>
        )}
        <PanelManager.MiddlePanel
          id={tableID}
          aria-label="dashboard tables"
          style={{
            ...(isLeftPanelOnTop && { overflowX: 'visible', flex: 1 }),
            ...(isVisible && { overflowY: 'hidden' }),
            ...heightStyle,
          }}
        >
          {!isTherapistAvailabilityLoading && (
            <Switch>
              <Route
                path="/rooms"
                render={() => {
                  if (therapistClientList) {
                    if (!therapistAvailability && isTherapistAvailabilityError) {
                      return <DashboardNonCTV4 isError={isTherapistAvailabilityError} />;
                    }
                    if (therapistAvailability) {
                      return (
                        <DashboardNonCTV4
                          therapistType={therapistAvailability.therapistType}
                          isNPP={!!therapistAvailability?.isW2}
                          isError={isTherapistAvailabilityError}
                        />
                      );
                    }
                  } else {
                    if (!therapistAvailability && isTherapistAvailabilityError) {
                      return (
                        <DashboardNonCT
                          isError={isTherapistAvailabilityError}
                          checkRoomPath={checkRoomPath}
                        />
                      );
                    }
                    if (therapistAvailability) {
                      return (
                        <DashboardNonCT
                          therapistType={therapistAvailability.therapistType}
                          isNPP={!!therapistAvailability?.isW2}
                          isError={isTherapistAvailabilityError}
                          checkRoomPath={checkRoomPath}
                        />
                      );
                    }
                  }

                  return null;
                }}
              />
              <Route
                exact
                path={[
                  '/tasks',
                  '/tasks/room/:roomID/progress-notes/new',
                  '/tasks/room/:roomID/progress-notes/:noteID/edit',
                ]}
                render={() => (accessAndSubmitAsyncTasks ? <TasksV2 /> : <Tasks />)}
              />
              {growCaseload && (
                <Route
                  path="/grow-caseload"
                  render={() => (
                    <GrowCaseload
                      therapistType={therapistAvailability?.therapistType}
                      isError={isTherapistAvailabilityError}
                    />
                  )}
                />
              )}

              {showNPPClinicalHours && <Route path="/clinical-hours" component={ClinicalHours} />}

              <Route
                path="/calendar"
                component={calendarAvailability ? ProviderCalendarV2 : ProviderCalendar}
              />
              {icpBonusVisualizationSwitch && (
                <Route path="/earnings" component={PaymentReportsContainer} />
              )}
              <Route path="/payment-reports" component={PaymentReportsContainer} />
            </Switch>
          )}
        </PanelManager.MiddlePanel>
      </Wrapper>
      <BackDrop
        isVisible={isVisible}
        isLeftPanelOnTop={isLeftPanelOnTop}
        onPress={() => setIsLeftPanelOnTop(false)}
        onTransitionEnd={() => {
          if (!isLeftPanelOnTop) {
            setIsVisibility((visible) => !visible);
          }
        }}
      />
    </>
  );
};

export default TherapistWebPanelManager;
