import ReviewsSummaryContainer from '../containers/ReviewsSummaryContainer';
import ReviewsContainer from '../containers/ReviewsContainer';

const ReviewsView = () => (
  <>
    <ReviewsSummaryContainer />
    <ReviewsContainer />
  </>
);

export default ReviewsView;
