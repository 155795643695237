import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { SSLHostname } from './ip';

export type Environment = 'local' | 'localSSL' | 'mime' | 'test' | 'canary' | 'prod';

const { protocol, host } = globalThis.document?.location || {};
const origin = `${protocol}//${host}`;

interface ConfigSet {
  appVersion: string;
  platform: 'web-therapist' | 'ios-therapist';
  endpoints: {
    showDevTools: boolean;
    socketEndpoint: string;
    apiEndpoint: string;
    authAPIEndpoint: string;
    surveyAPIEndpoint: string;
    chatAPIEndpoint: string;
    homePageEndpoint: string;
    clientWebEndpoint: string;
    cdnEndpoint: string;
    publicEndpoint: string;
    liveVideoEndpoint: string;
  };
  agora: {
    appID: string;
    logLevel: 0 | 2 | 1 | 3 | 4;
  };
  zendesk: {
    subDomain: string;
    ssoDomain: string;
  };
  analytics: {
    mixpanel: {
      token: string;
      tsAnalyticsEndpoint: string;
      librarySRC?: string;
      apiHost?: string;
    };
  };
  featureFlags: {
    addToCalendar: boolean;
    clientListV4: boolean;
    cognitoLogin: boolean;
  };
  sessionReport: {
    reopenPeriodDays: number;
  };
  launchDarkly: {
    sdkKey: string;
  };
  doseSpot: {
    uiURL: string;
  };
}

const {
  REACT_APP_IS_MIME,
  REACT_APP_TS_API_HOST,
  REACT_APP_AUTH_API_HOST,
  REACT_APP_SURVEY_API_HOST,
  REACT_APP_CHAT_API_HOST,
  REACT_APP_LIVEVIDEO_API_HOST,
  REACT_APP_HOMEPAGE_HOST,
  REACT_APP_CLIENT_WEB_HOST,
} = process.env;

const config: {
  local: ConfigSet;
  test: ConfigSet;
  mime: ConfigSet;
  canary: ConfigSet;
  prod: ConfigSet;
} = {
  local: {
    appVersion: '1.0.0',
    platform: 'web-therapist',
    endpoints: {
      showDevTools: true,
      socketEndpoint: 'http://localhost:3000',
      apiEndpoint: 'http://localhost:3000',
      authAPIEndpoint: 'http://localhost:3002',
      surveyAPIEndpoint: 'http://localhost:3005',
      chatAPIEndpoint: 'http://localhost:3000',
      homePageEndpoint: 'http://localhost:3000',
      clientWebEndpoint: 'https://localhost:4001',
      cdnEndpoint: 'https://cdn.dev.talkspace.com',
      publicEndpoint: 'http://localhost:3000',
      liveVideoEndpoint: 'http://localhost:3008',
    },
    agora: {
      appID: '8a0d6d66454e450cacecb7733554ae95',
      logLevel: 0,
    },
    zendesk: {
      subDomain: 'https://talkspacecanary1617805623-providers.zendesk.com',
      ssoDomain: 'https://talkspacecanary1617805623.zendesk.com',
    },
    analytics: {
      mixpanel: {
        tsAnalyticsEndpoint: 'http://localhost:3013/local',
        token: 'fa79581068ccb3a9cd3cb59fbc760b0d',
        librarySRC: 'https://apx.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.talkspace.com/mp',
      },
    },
    featureFlags: {
      addToCalendar: false,
      clientListV4: true,
      cognitoLogin: false,
    },
    sessionReport: {
      reopenPeriodDays: 5,
    },
    launchDarkly: {
      sdkKey: '63188b38630c5d117991ce73',
    },
    doseSpot: {
      uiURL: 'https://my.staging.dosespot.com/LoginSingleSignOn.aspx',
    },
  },
  mime: {
    appVersion: '1.0.0',
    platform: 'web-therapist',
    endpoints: {
      showDevTools: true,
      socketEndpoint: REACT_APP_TS_API_HOST as string,
      apiEndpoint: REACT_APP_TS_API_HOST as string,
      authAPIEndpoint: REACT_APP_AUTH_API_HOST as string,
      surveyAPIEndpoint: REACT_APP_SURVEY_API_HOST as string,
      chatAPIEndpoint: REACT_APP_CHAT_API_HOST as string,
      homePageEndpoint: REACT_APP_HOMEPAGE_HOST as string,
      clientWebEndpoint: REACT_APP_CLIENT_WEB_HOST as string,
      cdnEndpoint: 'https://cdn.dev.talkspace.com',
      publicEndpoint: REACT_APP_TS_API_HOST as string,
      liveVideoEndpoint: REACT_APP_LIVEVIDEO_API_HOST as string,
    },
    agora: {
      appID: '8a0d6d66454e450cacecb7733554ae95',
      logLevel: 0,
    },
    zendesk: {
      subDomain: 'https://talkspacecanary1617805623-providers.zendesk.com',
      ssoDomain: 'https://talkspacecanary1617805623.zendesk.com',
    },
    analytics: {
      mixpanel: {
        tsAnalyticsEndpoint: 'https://eventsapi.dev.talkspace.com',
        token: '50c9563ae17591f1111c48654ed7773d',
        librarySRC: 'https://apx.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.talkspace.com/mp',
      },
    },
    featureFlags: {
      addToCalendar: false,
      clientListV4: true,
      cognitoLogin: false,
    },
    sessionReport: {
      reopenPeriodDays: 5,
    },
    launchDarkly: {
      sdkKey: '62c15ef8fe8f4a152d29e46c',
    },
    doseSpot: {
      uiURL: 'https://my.staging.dosespot.com/LoginSingleSignOn.aspx',
    },
  },
  test: {
    appVersion: '1.0.0',
    platform: 'web-therapist',
    endpoints: {
      showDevTools: true,
      socketEndpoint: 'https://therapistapi.dev.talkspace.com',
      apiEndpoint: 'https://therapistapi.dev.talkspace.com',
      authAPIEndpoint: 'https://therapistapi.dev.talkspace.com',
      surveyAPIEndpoint: 'https://survey.dev.talkspace.com',
      chatAPIEndpoint: 'https://api.dev.talkspace.com',
      homePageEndpoint: 'https://www.dev.talkspace.com',
      clientWebEndpoint: 'https://app.dev.talkspace.com',
      cdnEndpoint: 'https://cdn.dev.talkspace.com',
      publicEndpoint: 'https://publicapi.dev.talkspace.com',
      liveVideoEndpoint: 'https://livevideo.dev.talkspace.com',
    },
    agora: {
      appID: '8a0d6d66454e450cacecb7733554ae95',
      logLevel: 0,
    },
    zendesk: {
      subDomain: 'https://talkspacecanary1617805623-providers.zendesk.com',
      ssoDomain: 'https://talkspacecanary1617805623.zendesk.com',
    },
    analytics: {
      mixpanel: {
        tsAnalyticsEndpoint: 'https://eventsapi.dev.talkspace.com',
        token: '50c9563ae17591f1111c48654ed7773d',
        librarySRC: 'https://apx.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.talkspace.com/mp',
      },
    },
    featureFlags: {
      addToCalendar: false,
      clientListV4: true,
      cognitoLogin: false,
    },
    sessionReport: {
      reopenPeriodDays: 5,
    },
    launchDarkly: {
      sdkKey: '62c15ef8fe8f4a152d29e46c',
    },
    doseSpot: {
      uiURL: 'https://my.staging.dosespot.com/LoginSingleSignOn.aspx',
    },
  },
  canary: {
    appVersion: '1.0.0',
    platform: 'web-therapist',
    endpoints: {
      showDevTools: true,
      socketEndpoint: 'https://therapistapi.canary.talkspace.com',
      apiEndpoint: 'https://therapistapi.canary.talkspace.com',
      authAPIEndpoint: 'https://therapistapi.canary.talkspace.com',
      surveyAPIEndpoint: 'https://survey.canary.talkspace.com',
      chatAPIEndpoint: 'https://api.canary.talkspace.com',
      homePageEndpoint: 'https://www.canary.talkspace.com',
      clientWebEndpoint: 'https://app.canary.talkspace.com',
      cdnEndpoint: 'https://cdn.canary.talkspace.com',
      publicEndpoint: 'https://publicapi.canary.talkspace.com',
      liveVideoEndpoint: 'https://livevideo.canary.talkspace.com',
    },
    agora: {
      appID: '8a0d6d66454e450cacecb7733554ae95',
      logLevel: 0,
    },
    zendesk: {
      subDomain: 'https://talkspace-canary-providers.zendesk.com/',
      ssoDomain: 'https://talkspace-canary.zendesk.com',
    },
    analytics: {
      mixpanel: {
        tsAnalyticsEndpoint: 'https://eventsapi.canary.talkspace.com',
        token: 'b2b83bd1e273fb7125936cc158c5e749',
        librarySRC: 'https://apx.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.talkspace.com/mp',
      },
    },
    featureFlags: {
      addToCalendar: false,
      clientListV4: true,
      cognitoLogin: false,
    },
    sessionReport: {
      reopenPeriodDays: 5,
    },
    launchDarkly: {
      sdkKey: '63188b1d6dbe1c11ce5dd39c',
    },
    doseSpot: {
      uiURL: 'https://my.staging.dosespot.com/LoginSingleSignOn.aspx',
    },
  },
  prod: {
    appVersion: '1.0.0',
    platform: 'web-therapist',
    endpoints: {
      showDevTools: false,
      socketEndpoint: 'https://therapistapi.talkspace.com',
      apiEndpoint: 'https://therapistapi.talkspace.com',
      authAPIEndpoint: 'https://therapistapi.talkspace.com',
      surveyAPIEndpoint: 'https://survey.talkspace.com',
      chatAPIEndpoint: 'https://api.talkspace.com',
      homePageEndpoint: 'https://www.talkspace.com',
      clientWebEndpoint: 'https://app.talkspace.com',
      cdnEndpoint: 'https://cdn.talkspace.com',
      publicEndpoint: 'https://publicapi.talkspace.com',
      liveVideoEndpoint: 'https://livevideo.talkspace.com',
    },
    agora: {
      appID: '9694662d6a1740e7ac3498b61a4d8578',
      logLevel: 3,
    },
    zendesk: {
      subDomain: 'https://providers-help.talkspace.com',
      ssoDomain: 'https://talkspace.zendesk.com',
    },
    analytics: {
      mixpanel: {
        tsAnalyticsEndpoint: 'https://eventsapi.talkspace.com',
        token: 'c18be99a70104cfc390ad47fa5f44df6',
        librarySRC: 'https://apx.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.talkspace.com/mp',
      },
    },
    featureFlags: {
      addToCalendar: false,
      clientListV4: false,
      cognitoLogin: false,
    },
    sessionReport: {
      reopenPeriodDays: 5,
    },
    launchDarkly: {
      sdkKey: '62c15ef8fe8f4a152d29e46d',
    },
    doseSpot: {
      uiURL: 'https://my.dosespot.com/LoginSingleSignOn.aspx',
    },
  },
};

const configSSL: Record<Environment, ConfigSet> = {
  ...config,
  localSSL: {
    ...config.local,
    endpoints: {
      showDevTools: true,
      socketEndpoint: `https://${SSLHostname}:8009`,
      apiEndpoint: `https://${SSLHostname}:8000`,
      authAPIEndpoint: `https://${SSLHostname}:8002`,
      surveyAPIEndpoint: `https://${SSLHostname}:8005`,
      chatAPIEndpoint: `https://${SSLHostname}:8000`,
      homePageEndpoint: `https://${SSLHostname}:8000`,
      clientWebEndpoint: `https://${SSLHostname}:4001`,
      cdnEndpoint: 'https://cdn.dev.talkspace.com',
      publicEndpoint: `https://${SSLHostname}:8000`,
      liveVideoEndpoint: `https://${SSLHostname}:8008`,
    },
  },
};

export const detectEnv = (): Environment => {
  if (REACT_APP_IS_MIME === '1') {
    return 'mime';
  }
  switch (origin) {
    case 'http://localhost:3001':
    case 'http://127.0.0.1:3001':
    case `http://${SSLHostname}:3001`:
    case `http://${SSLHostname}:8100`:
      return 'local';
    case `https://${SSLHostname}:8100`:
      return 'localSSL';
    case 'https://therapist.dev.talkspace.com':
      return 'test';
    case 'https://therapist.canary.talkspace.com':
      return 'canary';
    case 'capacitor://localhost':
      switch (process.env.REACT_APP_CAPACITOR_BACKEND) {
        case 'prod':
          return 'prod';
        case 'canary':
          return 'canary';
        default:
          return 'test';
      }
    default:
      return 'prod';
  }
};

const appConfig = configSSL[detectEnv()];

if (Capacitor.isNativePlatform()) {
  appConfig.platform = 'ios-therapist';
  appConfig.appVersion = '10.1.1';
  App.getInfo().then((info) => {
    if (info.version) appConfig.appVersion = info.version;
  });
}

export default appConfig;
