import { Route, Switch } from 'react-router-dom';
import Booking from './components/Public/Booking';

export default function PublicRoutes() {
  return (
    <Switch>
      <Route path="/public/booking/:bookingID/room/:roomID/:status" component={Booking} />
      <Route render={() => <div>No path found</div>} />
    </Switch>
  );
}
