import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner, Text, View, useEmotionTheme } from '@talkspace/react-toolkit';
import BadgedNotesIcon from 'components/Icons/BadgedNotesIcon';
import styled from '@/core/styled';
import { RouteComponentProps } from '../../../../core/routerLib';
import LVSIcon from '../../../Icons/LVSIcon';
import CaseIcon from '../../../Icons/CaseIcon';
import ClinicalIcon from '../../../Icons/ClinicalIcon';
import JourneyIcon from '../../../Icons/JourneyIcon';
import NotesIcon from '../../../Icons/NotesIcon';
import UpcomingLVSIcon from '../../../Icons/UpcomingLVSIcon';
import useQueryNotes from '../../../../hooks/notes/useQueryNotes';

enum CrmTabHorizontalPadding {
  Agenda = 11.5,
  Case = 15,
  Clinical = 15,
  Notes = 15,
  Live = 14,
}

interface RoomProps {
  roomID: string;
}

interface CrmNavBarComponentProps {
  therapistType: string;
}
interface NavLinkWithIconBaseProps extends RouteComponentProps {
  to: string;
  text: string;
  hasUpcomingLVS?: boolean;
  icon: (props: { isActive: boolean }) => JSX.Element;
}

const CrmTabsWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
      colors,
    },
  }) => {
    return {
      width: '100%',
      marginTop: isMobile ? 1 : 0,
      borderBottom: isMobile ? 'none' : '0.5px solid #d3d3d3',
      borderTop: isMobile ? '0.5px solid #d3d3d3' : 'none',
      boxShadow: '0px 1px 3px -2px #d3d3d3',
      alignItems: 'center',
      position: 'sticky',
      backgroundColor: colors.white,
      order: isMobile ? 2 : 0,
    };
  }
);

const CrmTabsContainer = styled(View)<{
  isProviderCTOrPsych: boolean;
}>(({ isProviderCTOrPsych }) => {
  return {
    flexDirection: 'row',
    height: 65,
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: 'center',
    transition: '0.3s',
    maxWidth: isProviderCTOrPsych ? {} : 355,
    width: isProviderCTOrPsych ? '100%' : {},
    paddingLeft: isProviderCTOrPsych ? 15 : {},
    paddingRight: isProviderCTOrPsych ? 15 : {},
  };
});

const CrmTabsIconContainer = styled(View)<{
  horizontalPadding: string;
  textColor: string;
  isActive: boolean;
}>(({ horizontalPadding, textColor, isActive, theme: { colors } }) => {
  return {
    height: 55,
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
    marginLeft: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 10,
    alignItems: 'center',
    // TODO: R&D a fix for withHover breaking on mobile and replace media/child queries
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: colors.a11yLinkWaterGrey,
        '& > div > svg > path:first-child': {
          fill: !isActive && colors.permaBlueBayoux,
        },
        '& > div > div > p': {
          color: !isActive && colors.permaBlueBayoux,
        },
      },
    },
    '& > div > div > p': {
      color: textColor,
    },
  };
});

const UpcomingLVSContainer = styled(View)({
  position: 'absolute',
  top: 11,
  justifyContent: 'center',
  left: -9,
});

const NavLinkWithIcon: FunctionComponent<NavLinkWithIconBaseProps> = ({
  match,
  to,
  children,
  text,
  icon: CrmIcon,
  hasUpcomingLVS,
  ...props
}) => {
  const linkTo = `${match.url}${to}`;
  const isActive = window.location.pathname === linkTo;
  const { colors } = useEmotionTheme();

  return (
    <NavLink {...props} to={linkTo} isActive={() => isActive} style={{ textDecoration: 'none' }}>
      <CrmTabsIconContainer
        horizontalPadding={CrmTabHorizontalPadding[text]}
        textColor={isActive ? colors.black : colors.dimGray}
        isActive={isActive}
      >
        <View align="center">
          <CrmIcon isActive={isActive} />
          <View style={{ position: 'relative' }}>
            <UpcomingLVSContainer>
              {text === 'Live' && hasUpcomingLVS ? <UpcomingLVSIcon /> : null}
            </UpcomingLVSContainer>
            <Text style={{ marginTop: 2, fontWeight: 'bold', fontSize: 15 }}>{text}</Text>
          </View>
        </View>
      </CrmTabsIconContainer>
    </NavLink>
  );
};

const CrmNavBarComponent = (props: RouteComponentProps<RoomProps> & CrmNavBarComponentProps) => {
  const {
    match: {
      params: { roomID },
    },
    therapistType,
  } = props;

  const IconContainer = ({ isProviderCTOrPsych, children }) => (
    <View style={{ flexGrow: isProviderCTOrPsych ? 100 : {} }}>{children}</View>
  );

  const { data: useNotesResponse } = useQueryNotes(roomID);
  const isProviderCTOrPsych =
    therapistType === 'psychiatrist' ||
    therapistType === 'consultation' ||
    therapistType === 'consultation_and_primary';
  return (
    <CrmTabsWrapper>
      <CrmTabsContainer isProviderCTOrPsych={isProviderCTOrPsych}>
        {therapistType === undefined ? (
          <Spinner />
        ) : (
          <>
            {!isProviderCTOrPsych && (
              <IconContainer isProviderCTOrPsych={isProviderCTOrPsych}>
                <NavLinkWithIcon
                  {...props}
                  data-qa="cRMAgendaTab"
                  to="/agenda-tab"
                  text="Agenda"
                  icon={JourneyIcon}
                />
              </IconContainer>
            )}
            <IconContainer isProviderCTOrPsych={isProviderCTOrPsych}>
              <NavLinkWithIcon
                {...props}
                to="/case-tab"
                data-qa="cRMCaseTab"
                text="Case"
                icon={CaseIcon}
              />
            </IconContainer>
            <IconContainer isProviderCTOrPsych={isProviderCTOrPsych}>
              <NavLinkWithIcon
                {...props}
                to="/clinical-tab"
                data-qa="cRMClinicalTab"
                text="Clinical"
                icon={ClinicalIcon}
              />
            </IconContainer>
            <IconContainer isProviderCTOrPsych={isProviderCTOrPsych}>
              <NavLinkWithIcon
                {...props}
                to="/notes-tab"
                data-qa="cRMNotesTab"
                text="Notes"
                icon={useNotesResponse?.warningMessage ? BadgedNotesIcon : NotesIcon}
              />
            </IconContainer>
            <IconContainer isProviderCTOrPsych={isProviderCTOrPsych}>
              <NavLinkWithIcon
                {...props}
                to="/lvs-tab"
                data-qa="cRMLiveTab"
                text="Live"
                icon={LVSIcon}
              />
            </IconContainer>
          </>
        )}
      </CrmTabsContainer>
    </CrmTabsWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    therapistType: state.availability.therapistType,
  };
};

const CrmNavBar = connect(mapStateToProps)(CrmNavBarComponent);

export default CrmNavBar;
