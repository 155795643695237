import { isEqual } from 'lodash';

/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from 'react';
import '../../Dashboard.css';
import moment, { Moment } from 'moment';
import {
  ButtonGroup,
  Button,
  FormControl,
  FormGroup,
  MenuItem,
  Dropdown,
  InputGroup,
  DropdownButton,
} from 'react-bootstrap';
import {
  SingleDatePickerWithInput,
  Small,
  Table,
  useEmotionTheme,
  useObjectState,
  useWindowWidthState,
  View,
  Cell,
  TableColumn,
  SortingRule,
} from '@talkspace/react-toolkit';
import { MetricsResponse } from 'hooks/dashboard/useQueryMetrics';
import saveLocalStorageData from 'utils/saveLocalStorageData';
import getInitialTableState from 'utils/getInitialTableState';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import styled from '../../../../core/styled';
import { dashboardGetHeaderProps } from '../../DashboardTable';
import {
  DashboardCellRoomDetailV2,
  RoomReminders,
  StatusAndRenewDate,
  Overdue,
  StatusV2,
} from '../../Cells';
import DashboardTablePlaceholder from '../../Placeholders/DashboardTablePlaceholder';
import IconGrow from '../../Placeholders/IconGrow';
import {
  CaseLoadView,
  localStorageTableNames,
  CheckRoomPath,
  Counters,
  RoomRecord,
} from '../../types';
import trackEvent from '../../../../modules/analytics/trackEvent';
import { DashboardInteraction } from '../../../../modules/analytics/types';

interface AllClientState {
  searchString: string;
  currentRooms: number;
  searchType: string;
  searchTypeOpen: boolean;
  caseLoadOpen: boolean;
  sortBy: SortingRule[];
  startDateFocused: boolean;
  endDateFocused: boolean;
  pageIndex: number;
  pageSize: number;
  view: string;
  filterToDate: string;
  filterFromDate: string;
  isUserSearching: boolean;
}

interface AllClientProps {
  allRoomsData: RoomRecord[];
  counters: Counters;
  view: CaseLoadView;
  slaFeatureActive: boolean;
  isHidden: boolean;
  isLoading: boolean;
  filterToDate: Moment;
  filterFromDate: Moment;
  metrics?: MetricsResponse;
  isNPP?: boolean;
  getRoomsData: (view: CaseLoadView) => void;
  shouldCloseDropDown: boolean;
  checkRoomPath: CheckRoomPath;
  updateFilterToDate: (date: Moment | null) => void;
  updateFilterFromDate: (date: Moment | null) => void;
  handleOpenAvailabilityBar: (value: boolean) => void;
}

const AllClientsContentWrapper = styled(View)({
  paddingBottom: 20,
});

const AllClientsTitleWrapper = styled(View)({
  marginBottom: 0,
  paddingTop: 14,
  minHeight: 48,
});

const DEFAULT_INITIAL_TABLE_STATE = {
  pageIndex: 0,
  pageSize: 25,
  sortBy: [{ id: 'waiting', desc: true }],
};

const AllClients = ({
  allRoomsData,
  counters,
  view,
  slaFeatureActive,
  isHidden,
  isLoading,
  filterToDate,
  filterFromDate,
  metrics,
  isNPP,
  getRoomsData,
  shouldCloseDropDown,
  checkRoomPath,
  updateFilterToDate,
  updateFilterFromDate,
  handleOpenAvailabilityBar,
}: AllClientProps) => {
  const { isDesktop, width } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const isMobile = width < 991;

  const [shouldAutoResetPage, setShouldAutoResetPage] = useState<boolean>(false);

  const [state, setState] = useObjectState<AllClientState>({
    view,
    searchString: '',
    currentRooms: allRoomsData.length,
    searchType: 'Nickname',
    isUserSearching: false,
    searchTypeOpen: false,
    caseLoadOpen: false,
    startDateFocused: false,
    endDateFocused: false,
    filterToDate: filterToDate.toISOString(),
    filterFromDate: filterFromDate.toISOString(),
    ...getInitialTableState(localStorageTableNames.caseLoad, DEFAULT_INITIAL_TABLE_STATE),
  });

  const getProperties = (): DashboardInteraction => {
    const tableState = getInitialTableState(
      localStorageTableNames.caseLoad,
      DEFAULT_INITIAL_TABLE_STATE
    );

    const properties: DashboardInteraction = {
      searchCategory: state.searchType,
      dateRangeStartAt: filterFromDate.toISOString(),
      dateRangeEndAt: filterToDate.toISOString(),
      sortName: tableState.sortBy[tableState.sortBy.length - 1].id,
      sortDirection: tableState.sortBy[tableState.sortBy.length - 1].desc ? 'desc' : 'asc',
      activeFilterList: view,
      dashboardSection: 'caseload',
    };
    return properties;
  };

  useDidUpdateEffect(() => {
    trackEvent('clientListFiltered', { actionName: 'activeExperiments' }, getProperties());
  }, [view]);

  useEffect(() => {
    if (
      view !== state.view ||
      filterToDate.toISOString() !== state.filterToDate ||
      filterFromDate.toISOString() !== state.filterFromDate ||
      state.isUserSearching
    ) {
      setState({
        view,
        filterToDate: filterToDate.toISOString(),
        filterFromDate: filterFromDate.toISOString(),
        pageIndex: 0,
        isUserSearching: false,
      });
      setShouldAutoResetPage(true);
    } else {
      setShouldAutoResetPage(false);
    }
  }, [
    filterFromDate,
    filterToDate,
    setState,
    state.filterFromDate,
    state.filterToDate,
    state.isUserSearching,
    state.searchString,
    state.view,
    view,
  ]);

  useEffect(() => {
    const closeAllDropDown = () => {
      setState({
        searchTypeOpen: false,
        caseLoadOpen: false,
      });
    };

    if (shouldCloseDropDown) {
      closeAllDropDown();
    }
  }, [shouldCloseDropDown, setState]);

  const getViewDisplayName = useCallback((currentView: string) => {
    const displayNameMapping = {
      all: 'All',
      paying: 'Paying',
      consultation: 'Consultation',
    };

    return displayNameMapping[currentView] || '';
  }, []);

  const setSearchType = useCallback(
    (event: React.MouseEvent<{ name: string }>) => {
      const userInput = event?.currentTarget?.name || 'Nickname';

      setState({
        searchType: userInput,
        searchString: '',
        searchTypeOpen: false,
        isUserSearching: true,
      });
      saveLocalStorageData(localStorageTableNames.caseLoad, { searchType: userInput });
    },
    [setState]
  );

  const toggleSearchTypeOpen = useCallback(() => {
    setState({
      searchTypeOpen: !state.searchTypeOpen,
    });
  }, [setState, state.searchTypeOpen]);

  const toggleCaseLoadOpen = useCallback(() => {
    setState({
      caseLoadOpen: !state.caseLoadOpen,
    });
  }, [setState, state.caseLoadOpen]);

  const matchesSearchString = useCallback(
    (client: RoomRecord) => {
      switch (state.searchType) {
        case 'Name': {
          const nameString = `${client.clientFirstName || ''} ${client.clientLastName || ''}`;
          return nameString.toLowerCase().includes(state.searchString);
        }
        case 'Nickname': {
          const nameString = client.clientNickname || '';
          return nameString.toLowerCase().includes(state.searchString);
        }
        case 'User': {
          const userId = client.clientUserID !== null ? String(client.clientUserID) : '';
          return userId.includes(state.searchString);
        }
        case 'Room': {
          const roomId = client.roomID !== null ? String(client.roomID) : '';
          return roomId.includes(state.searchString);
        }
        default:
          return false;
      }
    },
    [state.searchString, state.searchType]
  );

  const filterData = useCallback(
    (data: RoomRecord[]) => data.filter((client) => matchesSearchString(client)),
    [matchesSearchString]
  );

  const handleSearch = useCallback(
    (event: React.FormEvent<FormControl>) => {
      const searchTerm: string = (event.target as HTMLInputElement).value;

      setState({ searchString: searchTerm.toLowerCase(), isUserSearching: true });
      saveLocalStorageData(localStorageTableNames.caseLoad, {
        searchString: searchTerm.toLowerCase(),
        pageIndex: 0,
      });
    },
    [setState]
  );

  const getHiddenColumns = useCallback((currentView: string) => {
    let hiddenColumns: string[] = [];
    if (currentView === 'paying') {
      hiddenColumns = ['renewDate', 'offerDate', 'planEnd', 'roomStatus'];
    }
    if (['consultation', 'all'].includes(currentView)) {
      hiddenColumns = ['clientRenewDateStatus'];
    }

    return hiddenColumns;
  }, []);

  const getColumnOrder = useCallback((currentView: CaseLoadView) => {
    switch (currentView) {
      case 'all':
        return [
          'lastActivity',
          'waiting',
          'nickName',
          'roomStatus',
          'planName',
          'renewDate',
          'offerDate',
          'planEnd',
        ];
      case 'consultation':
        return [
          'lastActivity',
          'waiting',
          'nickName',
          'roomStatus',
          'planName',
          'renewDate',
          'offerDate',
          'planEnd',
        ];
      case 'paying':
        return ['nickName', 'lastActivity', 'waiting', 'clientRenewDateStatus', 'planName'];
      default:
        return [];
    }
  }, []);

  const getColumnSize = useCallback(
    (currentView: CaseLoadView, { all, consultation, paying, defaultSize }) => {
      let columnSize: { style: { width: string | number } };
      if (!isDesktop) {
        return null;
      }

      switch (currentView) {
        case 'all':
          columnSize = all && { style: { width: all } };
          break;
        case 'consultation':
          columnSize = consultation && { style: { width: consultation } };
          break;
        case 'paying':
          columnSize = paying && { style: { width: paying } };
          break;
        default:
          columnSize = defaultSize && { style: { width: defaultSize } };
      }
      return columnSize;
    },
    [isDesktop]
  );

  const fromNoworNow = useCallback((inputTime: Date) => {
    if (moment().diff(inputTime) < 1000) {
      // 1000 milliseconds
      return 'just now';
    }
    return moment(inputTime).fromNow(true);
  }, []);

  const ClientWaitingFormatter = useCallback(
    (cell: Date) => {
      if (cell === null) {
        return '';
      }
      return (
        <div className="unstackable">
          <div className="unstackable customer-waiting">{fromNoworNow(cell)}</div>
        </div>
      );
    },
    [fromNoworNow]
  );

  const timeFormatter = useCallback((cell: Date) => {
    if (cell === null) {
      return '';
    }
    const lastActivityTime = moment(cell).fromNow();
    return lastActivityTime;
  }, []);

  const monthFormatter = useCallback((cell: Date) => {
    if (cell === null) {
      return '';
    }
    const lastActivityTime = moment(cell).format('L');
    return lastActivityTime;
  }, []);

  const desktopColumns = useMemo(
    () => [
      {
        Header: 'Renew Date',
        id: 'renewDate',
        accessor: 'renewDate',
        ...getColumnSize(view, { defaultSize: '9%' }),
        Cell: ({ row: { original: client } }: Cell<RoomRecord>) => (
          <div>
            <div className="unstackable client-renews-date-mobile">Renews At:&nbsp;</div>
            {checkRoomPath(client.roomID, monthFormatter(client.renewDate))}
          </div>
        ),
      },
      {
        Header: 'Offer Date',
        id: 'offerDate',
        accessor: 'offerDate',
        ...getColumnSize(view, { defaultSize: '9%' }),
        Cell: ({ row: { original: client } }: Cell<RoomRecord>) =>
          checkRoomPath(client.roomID, monthFormatter(client.offerDate)),
      },
      {
        Header: 'Plan End',
        id: 'planEnd',
        accessor: 'planEnd',
        Cell: ({ row: { original: client } }: Cell<RoomRecord>) =>
          checkRoomPath(client.roomID, timeFormatter(client.planEnd)),
      },
      {
        Header: 'ID',
        id: 'nickName',
        accessor: 'clientNickname',
        ...getColumnSize(view, { paying: '25%', defaultSize: '15%' }),
        Cell: ({
          row: { original: client },
          isHovering,
        }: Cell<RoomRecord> & { isHovering: boolean }) =>
          checkRoomPath(
            client.roomID,
            <DashboardCellRoomDetailV2
              fullName={[client.clientFirstName, client.clientLastName].join(' ').trim()}
              pseudonym={client.clientNickname}
              preferredName={client.preferredName}
              roomID={client.roomID}
              userID={client.clientUserID}
              isHovering={isHovering}
              floatingMenuStyle={{ left: -30 }}
            />
          ),
      },
      {
        Header: 'Last activity',
        id: 'lastActivity',
        accessor: 'lastActivity',
        ...getColumnSize(view, { paying: '12%', defaultSize: '10%' }),
        Cell: ({ row: { original: client } }: Cell<RoomRecord>) =>
          checkRoomPath(
            client.roomID,
            <>
              {client.lastActivity ? moment(client.lastActivity).fromNow() : ''}
              <i className="unstackable fa fa-angle-right fa-2x pull-right room-arrow" />
              {client.roomReminderCount > 0 && (
                <RoomReminders numberOfReminders={client.roomReminderCount} />
              )}
            </>
          ),
      },
      {
        Header: view === 'paying' ? 'Waiting' : 'Client Waiting',
        id: 'waiting',
        accessor: 'clientWaiting',
        ...getColumnSize(view, { paying: '12%', defaultSize: '9%' }),
        Cell: ({ row: { original: client } }: Cell<RoomRecord>) =>
          checkRoomPath(client.roomID, ClientWaitingFormatter(client.clientWaiting)),
      },
      {
        Header: 'Status',
        id: 'roomStatus',
        accessor: 'roomStatus',
        ...getColumnSize(view, { defaultSize: '9%' }),
        Cell: ({ row: { original: client } }: Cell<RoomRecord>) =>
          checkRoomPath(client.roomID, client.roomStatus),
      },
      {
        Header: 'Status',
        id: 'clientRenewDateStatus',
        accessor: 'roomStatus',
        ...getColumnSize(view, { paying: '12%' }),
        Cell: ({ row: { original: client } }: Cell<RoomRecord>) =>
          checkRoomPath(
            client.roomID,
            <View>
              <StatusV2 client={client} />
            </View>
          ),
      },
      {
        Header: 'Plan Name',
        id: 'planName',
        accessor: 'planName',
        ...getColumnSize(view, { defaultSize: '25%' }),
        Cell: ({ row: { original: client } }: Cell<RoomRecord>) =>
          ['all', 'consultation'].includes(view)
            ? checkRoomPath(
                client.roomID,
                <>
                  <div className="unstackable client-plan-name">{client.planName}</div>
                  <div className="unstackable selectedDaysOfWeek">
                    <div className="unstackable client-plan-weekdays">Weekdays:&nbsp;</div>
                    {client.weekdays ? client.weekdays : ''}
                  </div>
                </>
              )
            : checkRoomPath(
                client.roomID,
                <div className="unstackable client-plan-name">{client.planName}</div>
              ),
      },
    ],
    [ClientWaitingFormatter, checkRoomPath, getColumnSize, monthFormatter, timeFormatter, view]
  );

  const responsiveColumns = [
    {
      Header: 'ID',
      id: 'waiting',
      accessor: 'clientWaiting',
      Cell: ({ row: { original: client } }: Cell<RoomRecord>) =>
        checkRoomPath(
          client.roomID,
          <View row>
            <DashboardCellRoomDetailV2
              fullName={[client.clientFirstName, client.clientLastName].join(' ').trim()}
              pseudonym={client.clientNickname}
              preferredName={client.preferredName}
              roomID={client.roomID}
              userID={client.clientUserID}
              floatingMenuStyle={{ left: -5 }}
              isHovering
            />
            <View style={{ position: 'relative', maxWidth: '70%', top: 20, paddingBottom: 15 }}>
              <p className={view === 'paying' ? 'bold-sla-plan' : ''}>{client.planName}</p>
              {view !== 'paying' && (
                <div className="unstackable">
                  <div className="unstackable client-plan-weekdays">Weekdays:&nbsp;</div>
                  {client.weekdays ? client.weekdays : ''}
                </div>
              )}
              {view === 'paying' && client.roomReminderCount > 0 && (
                <RoomReminders numberOfReminders={client.roomReminderCount} />
              )}
              {view === 'paying' && (
                <div className="unstackable client-renews-date-mobile">
                  <div className="unstackable ">
                    <StatusAndRenewDate
                      renewDate={client.renewDate}
                      planEnd={client.planEnd}
                      roomStatus={client.roomStatus}
                    />
                  </div>
                  <div hidden={!slaFeatureActive} className="unstackable client-overdue-mobile">
                    <Overdue user={client} isMobile />
                  </div>
                </div>
              )}
              <div className="unstackable client-renews-date">
                <StatusV2 client={client} />
              </div>
              {view !== 'paying' && (
                <div className="unstackable client-renews-date-mobile">
                  Renews At:&nbsp; {monthFormatter(client.renewDate)}
                </div>
              )}
            </View>
            <View row align="center" style={{ position: 'absolute', right: 32 }}>
              {moment(client.lastActivity).fromNow()}
              <i className="unstackable fa fa-angle-right fa-2x pull-right room-arrow" />
            </View>
          </View>
        ),
    },
  ];

  const filteredData = filterData(allRoomsData);
  const hasAnyClients = Number(counters.all) > 0;
  const column: TableColumn[] = isMobile ? responsiveColumns : desktopColumns;
  const hasMetrics = metrics && Object.values(metrics)?.every((value) => value !== undefined);

  if (isHidden) {
    return null;
  }

  return (
    <View style={{ backgroundColor: 'white', paddingTop: 20 }}>
      <AllClientsTitleWrapper row style={{ alignItems: 'baseline' }} className="unstackable">
        <h4>Your caseload</h4>
        {!isNPP && hasMetrics && hasAnyClients && isDesktop && (
          <div style={{ marginLeft: 120 }}>
            <Small variant="smallDarkGrey" style={{ marginRight: 3 }} inline>
              You have:
            </Small>
            <Small variant="smallBoldTSBlack" inline>{`${metrics.engagingRooms} engaged and ${
              metrics.notEngaging
            } unengaged ${metrics.notEngaging === 1 ? 'client' : 'clients'}`}</Small>
          </div>
        )}
      </AllClientsTitleWrapper>
      {(isLoading || !hasAnyClients) && (
        <DashboardTablePlaceholder
          title="You have no client caseload yet!"
          subtitle="This is where you’ll provide ongoing treatment. To grow your clientele, "
          icon={<IconGrow />}
          linkText="update your new client availability."
          isLoading={isLoading}
          handleOnPress={handleOpenAvailabilityBar}
        />
      )}
      {!isLoading && hasAnyClients && (
        <AllClientsContentWrapper>
          <div className="all-clients-head">
            <div className="unstackable col-xs-12 col-sm-12 col-md-9 col-lg-9 button-rows">
              <ButtonGroup
                className="clients-options hidden-xs hidden-sm"
                style={{ width: '100%' }}
              >
                <Button
                  active={view === 'all'}
                  onClick={() => {
                    saveLocalStorageData(localStorageTableNames.caseLoad, { view: 'all' });
                    getRoomsData('all');
                  }}
                  style={{ width: '13%' }}
                >
                  All
                  <span className="unstackable number-indication">{counters.all}</span>
                </Button>
                <Button
                  active={view === 'consultation'}
                  onClick={() => {
                    saveLocalStorageData(localStorageTableNames.caseLoad, { view: 'consultation' });
                    getRoomsData('consultation');
                  }}
                  style={{ width: '24%' }}
                >
                  Consultation{' '}
                  <span className="unstackable number-indication">{counters.consultation}</span>
                </Button>
                <Button
                  active={view === 'paying'}
                  onClick={() => {
                    saveLocalStorageData(localStorageTableNames.caseLoad, { view: 'paying' });
                    getRoomsData('paying');
                  }}
                  style={{ width: '18%' }}
                >
                  Paying <span className="unstackable number-indication">{counters.paying}</span>
                </Button>
              </ButtonGroup>
              <Dropdown
                id="viewDropdown"
                className="mobile-view-dropdown"
                open={state.caseLoadOpen}
              >
                <Dropdown.Toggle onClick={toggleCaseLoadOpen}>
                  {getViewDisplayName(view)}{' '}
                  <span className="unstackable number-indication">{counters[view]}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu title={view}>
                  <MenuItem
                    onClick={() => {
                      setState({ caseLoadOpen: false });
                      getRoomsData('all');
                      saveLocalStorageData(localStorageTableNames.caseLoad, { view: 'all' });
                    }}
                  >
                    All
                    <span className="unstackable number-indication">{counters.all}</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setState({ caseLoadOpen: false });
                      getRoomsData('consultation');
                      saveLocalStorageData(localStorageTableNames.caseLoad, {
                        view: 'consultation',
                      });
                    }}
                  >
                    Consultation
                    <span className="unstackable number-indication">{counters.consultation}</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setState({ caseLoadOpen: false });
                      getRoomsData('paying');
                      saveLocalStorageData(localStorageTableNames.caseLoad, { view: 'paying' });
                    }}
                  >
                    Paying
                    <span className="unstackable number-indication">{counters.paying}</span>
                  </MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="unstackable room-search col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <FormGroup>
                <InputGroup>
                  <FormControl
                    onChange={handleSearch}
                    onBlur={() => {
                      trackEvent(
                        'clientListSearched',
                        { actionName: 'activeExperiments' },
                        getProperties()
                      );
                    }}
                    type="text"
                    placeholder="Search"
                    value={state.searchString}
                  />
                  <DropdownButton
                    componentClass={InputGroup.Button}
                    id="search-type-dropdown"
                    bsStyle="Default"
                    title={state.searchType}
                    noCaret={false}
                    onClick={toggleSearchTypeOpen}
                    open={state.searchTypeOpen}
                  >
                    <MenuItem eventKey="3" onClick={setSearchType} name="Name">
                      Name
                    </MenuItem>
                    <MenuItem eventKey="1" onClick={setSearchType} name="Nickname">
                      Nickname
                    </MenuItem>
                    <MenuItem eventKey="2" onClick={setSearchType} name="User">
                      User ID
                    </MenuItem>
                    <MenuItem eventKey="3" onClick={setSearchType} name="Room">
                      Room ID
                    </MenuItem>
                  </DropdownButton>
                </InputGroup>
              </FormGroup>
            </div>
            <div className="button-rows hidden-xs hidden-sm col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="date-filter-label">From: </div>
              <SingleDatePickerWithInput
                id="from-date"
                date={filterFromDate}
                onDateChange={(newDate) => {
                  if (newDate?.isBefore(filterToDate)) {
                    updateFilterFromDate(newDate);
                    saveLocalStorageData(localStorageTableNames.caseLoad, {
                      filterFromDate: newDate,
                    });
                    trackEvent(
                      'clientListFiltered',
                      { actionName: 'activeExperiments' },
                      {
                        ...getProperties(),
                        dateRangeStartAt: newDate,
                        filterName: 'Date range',
                      }
                    );
                  }
                }}
                focused={state.startDateFocused}
                onFocusChange={({ focused }) => setState({ startDateFocused: focused })}
              />
              <div className="date-filter-label">To: </div>
              <SingleDatePickerWithInput
                id="end-date"
                date={filterToDate}
                onDateChange={(newDate) => {
                  if (newDate?.isAfter(filterFromDate)) {
                    updateFilterToDate(newDate);
                    saveLocalStorageData(localStorageTableNames.caseLoad, {
                      filterToDate: newDate,
                    });
                    trackEvent(
                      'clientListFiltered',
                      { actionName: 'activeExperiments' },
                      {
                        ...getProperties(),
                        dateRangeEndAt: newDate,
                        filterName: 'Date range',
                      }
                    );
                  }
                }}
                focused={state.endDateFocused}
                onFocusChange={({ focused }) => setState({ endDateFocused: focused })}
              />
            </div>
          </div>
          <div className="unstackable ibox-content">
            <div className="unstackable grid" role="grid">
              <Table
                enablePagination
                disableSortRemove
                hideHeader={isMobile}
                data={filteredData}
                columns={column}
                tableStyle={{ marginTop: 0 }}
                onStateChange={(tableState) => {
                  const prevSortByState = getInitialTableState(
                    localStorageTableNames.caseLoad,
                    DEFAULT_INITIAL_TABLE_STATE
                  ).sortBy;

                  saveLocalStorageData(localStorageTableNames.caseLoad, tableState);
                  if (!isEqual(tableState.sortBy, prevSortByState)) {
                    trackEvent(
                      'clientListSorted',
                      { actionName: 'activeExperiments' },
                      getProperties()
                    );
                  }
                }}
                autoResetPage={shouldAutoResetPage}
                getHeaderProps={dashboardGetHeaderProps(colors)}
                initialState={{
                  pageIndex: state.pageIndex,
                  pageSize: state.pageSize,
                  sortBy: state.sortBy,
                }}
                isLoading={isLoading}
                {...(!isMobile && {
                  hiddenColumns: getHiddenColumns(view),
                  columnOrder: getColumnOrder(view),
                })}
              />
            </div>
          </div>
        </AllClientsContentWrapper>
      )}
    </View>
  );
};

export default AllClients;
