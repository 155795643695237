import { Component } from 'react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import './SingleSelect.css';
import './SimpleSingleSelect.css';
import SingleSelectView from './SingleSelectView';

class SimpleSingleSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || undefined,
      placeholderState: this.props.value ? 'up' : 'down',
      isFocused: false,
      field: this.props.field,
      disabled: false,
    };

    this.changeFocus = this.changeFocus.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value,
        placeholderState: nextProps.value ? 'up' : 'down',
      });
    }
  }

  changeFocus(isFocused, event, data) {
    this.setState({
      isFocused,
      placeholderState: data && data.value !== undefined ? 'up' : 'down',
    });
  }

  handleClose(event, data) {
    this.setState({
      placeholderState: data.value === undefined ? 'down' : 'up',
    });
  }

  handleEvent(event, data) {
    this.setState(
      {
        value: data ? data.value : undefined,
        placeholderState: data && data.value !== undefined ? 'up' : 'down',
      },
      () => {
        this.props.callback(event, { ...data, field: this.props.field });
      }
    );
  }

  render() {
    return (
      <div className="ts-simple-single-select">
        <SingleSelectView
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          placeholderState={this.state.placeholderState}
          changeFocus={this.changeFocus}
          handleChange={this.handleEvent}
          handleClose={this.handleClose}
          handleDelete={this.handleEvent}
          isValid={this.props.isValid}
          value={this.props.value}
          options={this.props.options}
          isFocused={this.state.isFocused}
        />
      </div>
    );
  }
}

SimpleSingleSelect.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  options: PropTypes.array,
};

export default SimpleSingleSelect;
