import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { withRouter } from 'react-router';
import MultiSelectSearch from '../../../../Reusable/MultiSelectSearch/MultiSelectSearch';
import CheckListItem from '../../../../Reusable/ListItems/CheckListItem';
import { editObjective, editIntervention } from '../../../../../actions/TreatmentPlannerActions';
import './TreatmentPlanView.css';
import ThreeDots from '../../../../Icons/ThreeDots';

const adapterPresentingProblemOptions = (options) =>
  options.map(({ id, text }) => {
    return {
      key: id,
      value: text,
      text,
    };
  });

const getDisplayValueObjectMultiSelectSearch = (values) => {
  const displayOption = values.map((value) => value.text);
  return displayOption;
};

class TreatmenPlanView extends Component {
  constructor(props) {
    super(props);
    this.state = { isHover: false, isOpen: false };

    this.checkboxObjectiveChangeHandler = this.checkboxObjectiveChangeHandler.bind(this);
    this.checkboxInterventionChangeHandler = this.checkboxInterventionChangeHandler.bind(this);
    this.mouseEnterHandler = this.mouseEnterHandler.bind(this);
    this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this);
  }

  checkboxInterventionChangeHandler(event, data) {
    const {
      treatmentPlan: { id: treatmentPlanID },
    } = this.props;
    const { id: interventionID, completed } = data;
    this.props.editIntervention(treatmentPlanID, interventionID, { completed });
  }

  checkboxObjectiveChangeHandler(event, data) {
    const {
      treatmentPlan: { id: treatmentPlanID },
    } = this.props;
    const { id: objectiveID, completed } = data;
    this.props.editObjective(treatmentPlanID, objectiveID, { completed });
  }

  mouseEnterHandler(e) {
    this.setState({ isHover: true });
  }

  mouseLeaveHandler(e) {
    this.setState({ isHover: false });
  }

  toggleSearchOpen = (event) => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const actions = [
      {
        text: 'Edit plan',
        method: () => {
          this.props.history.push(`/room/${this.props.roomID}/treatment-planner-goals`);
        },
        cssClass: 'default',
      },
      {
        text: 'Create new plan',
        method: () => {
          this.props.history.push(`/room/${this.props.roomID}/create-new-treatment-plan`);
        },
        cssClass: 'success',
      },
    ];

    return (
      <div className="treatment-planner-multiselect-container">
        <div
          key={1}
          className="treatment-planner-multiselect grayed"
          onMouseEnter={this.mouseEnterHandler}
          onMouseLeave={this.mouseLeaveHandler}
        >
          <MultiSelectSearch
            placeholder="Presenting problems"
            options={adapterPresentingProblemOptions(this.props.treatmentPlan.presentingProblems)}
            selected={getDisplayValueObjectMultiSelectSearch(
              this.props.treatmentPlan.presentingProblems
            )}
            placeholderDataQa="presentingProblemsPlaceholder"
          />
          <div
            className={
              this.props.treatmentPlan.archived ? 'list-item-dropdown hidden' : 'list-item-dropdown'
            }
          >
            <DropdownButton
              bsStyle="link"
              title={<ThreeDots active={this.state.isHover || this.state.isOpen} />}
              noCaret
              id="list-item-dropdown"
              onClick={this.toggleSearchOpen}
              open={this.state.isOpen}
            >
              {actions.map((action, key) => (
                <MenuItem
                  key={key}
                  eventKey={key}
                  onSelect={() => action.method()}
                  className={action.cssClass}
                >
                  {action.text}
                </MenuItem>
              ))}
            </DropdownButton>
          </div>
        </div>
        <div key={2}>
          {this.props.treatmentPlan.goals && !!this.props.treatmentPlan.goals.length && (
            <div className="treatment-planner-goals-title">Goals</div>
          )}
          {this.props.treatmentPlan.goals &&
            !!this.props.treatmentPlan.goals.length &&
            this.props.treatmentPlan.goals.map((goal, key) => (
              <div key={goal.id} className="treatment-plan-goal">
                {goal.description}
              </div>
            ))}
        </div>
        <div key={3}>
          {this.props.treatmentPlan.objectives &&
            !!this.props.treatmentPlan.objectives.length &&
            this.props.treatmentPlan.objectives.map((objective, key) => (
              <div key={key} className="treatment-planner-objective-container">
                <div className="treatment-planner-objectives-title">Objective</div>
                <div
                  className={
                    objective.completed
                      ? 'treatment-planner-objective checked'
                      : 'treatment-planner-objective '
                  }
                >
                  <CheckListItem
                    item={objective}
                    changeHandler={this.checkboxObjectiveChangeHandler}
                    displayField="description"
                    disabled={this.props.treatmentPlan.archived}
                    checkField="completed"
                  />
                </div>
                {objective.interventions && !!objective.interventions.length && (
                  <div className="treatment-planner-interventions-container">
                    <div className="treatment-planner-interventions-title">Intervention</div>
                    {objective.interventions.map((intervention, key) => (
                      <div
                        key={key}
                        className={
                          intervention.completed
                            ? 'treatment-planner-intervention checked'
                            : 'treatment-planner-intervention '
                        }
                      >
                        <CheckListItem
                          item={intervention}
                          changeHandler={this.checkboxInterventionChangeHandler}
                          displayField="description"
                          disabled={this.props.treatmentPlan.archived}
                          checkField="completed"
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editObjective, editIntervention }, dispatch);
const TreatmentPlanViewContainer = connect(mapStateToProps, mapDispatchToProps)(TreatmenPlanView);

export default withRouter(TreatmentPlanViewContainer);
