import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Select, useWindowWidthState } from '@talkspace/react-toolkit';
import { changeRoomType } from '../../../../../actions/MatchingActions';
import { getRoomProperties } from '../../../../../actions/RoomActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import Button from '../../../../Reusable/Buttons/Button';
import Footer from '../../../../Reusable/Footer/Footer';

const dbEnumToValueEnum = {
  couples_room: 'couplesRoom',
  private_room: 'privateRoom',
  psychiatry_room: 'psychiatryRoom',
};

const roomTypeOptions = [
  { value: 'couplesRoom', label: 'Couples room' },
  { value: 'privateRoom', label: 'Private room' },
  { value: 'psychiatryRoom', label: 'Psychiatry room' },
];

function TSChangeRoomType(props) {
  const [roomType, setRoomType] = useState('');
  const [wasChangeInitiated, setWasChangeInitiated] = useState(false);

  const {
    history,
    isChangingRoomType,
    isError,
    changeRoomType,
    roomID,
    getRoomProperties,
    roomType: currentRoomType,
  } = props;

  const isButtonDisabled = !roomType || roomType === dbEnumToValueEnum[currentRoomType];

  useEffect(() => {
    setRoomType(dbEnumToValueEnum[currentRoomType]);
  }, [currentRoomType]);

  useEffect(() => {
    if (!wasChangeInitiated && isChangingRoomType && !isError) {
      setWasChangeInitiated(true);
    }
    if (wasChangeInitiated && !isChangingRoomType && !isError) {
      getRoomProperties(roomID).then(() => setTimeout(history.goBack, 3000));
    }
    if (wasChangeInitiated && !isChangingRoomType && isError) {
      setTimeout(() => setWasChangeInitiated(false), 3000);
    }
  }, [wasChangeInitiated, isChangingRoomType, isError, getRoomProperties, roomID, history.goBack]);

  const { isMobile } = useWindowWidthState();

  return (
    <Submenu
      title="Change room type"
      childComponents={[
        <Select
          key="roomType"
          placeholder="Room type"
          onChange={(selectedObj) => setRoomType(selectedObj ? selectedObj.value : null)}
          options={roomTypeOptions}
          value={roomTypeOptions.find(({ value }) => value === roomType)}
          isDisabled={false}
          showIndicatorBeforeClick={isMobile}
        />,
      ]}
      footerComponent={
        <Footer
          key="changeRoomTypeFooter"
          showSpinner={wasChangeInitiated}
          isUpdating={isChangingRoomType}
          isError={isError}
          spinnerMessage="Room type successfully changed"
          childComponents={[
            <Button key={0} clickHandler={history.goBack} title="Cancel" isSecondary />,
            <Button
              key={1}
              style={{ marginLeft: 13 }}
              clickHandler={() => changeRoomType(roomType)}
              isSecondary={isButtonDisabled}
              isActive={!isButtonDisabled}
              title="Change type"
            />,
          ]}
        />
      }
      prevRoute="case-tab"
    />
  );
}

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
    roomType: state.room.roomType,
    isError: state.matching.isError,
    isChangingRoomType: state.matching.isChangingRoomType,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeRoomType, getRoomProperties }, dispatch);

const ChangeRoomType = connect(mapStateToProps, mapDispatchToProps)(TSChangeRoomType);

export default ChangeRoomType;
