import {
  REQUEST_GET_CUSTOMER_INFORMATION_LIST,
  RECEIVE_GET_CUSTOMER_INFORMATION_LIST,
  REQUEST_GET_CUSTOMER_INFORMATION_LIST_ERROR,
} from '../constants/CustomerListConstants';

const initialState = {
  list: [],
};

export default function customerList(state = initialState, action) {
  switch (action.type) {
    case REQUEST_GET_CUSTOMER_INFORMATION_LIST:
      return {
        ...state,
        roomID: action.roomID,
        isRequestingCustomerList: true,
        requestCustomerListError: false,
      };
    case REQUEST_GET_CUSTOMER_INFORMATION_LIST_ERROR:
      return {
        ...state,
        isRequestingCustomerList: false,
        requestCustomerListError: true,
      };
    case RECEIVE_GET_CUSTOMER_INFORMATION_LIST:
      return {
        ...state,
        list: action.responseBody.data,
        isRequestingCustomerList: false,
        requestCustomerListError: false,
      };
    default:
      return state;
  }
}
