/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from 'react';
import '../../Dashboard.css';
import { Moment } from 'moment';
import {
  Small,
  useEmotionTheme,
  useObjectState,
  View,
  SortingRule,
  Large,
} from '@talkspace/react-toolkit';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import { isEqual } from 'lodash';
import {
  Counters,
  CaseLoadView,
  localStorageTableNames,
  RoomRecord,
  AllClientState,
} from '../../types';
import { MetricsResponse } from '../../../../hooks/dashboard/useQueryMetrics';
import saveLocalStorageData from '../../../../utils/saveLocalStorageData';
import getInitialTableState from '../../../../utils/getInitialTableState';
import { getInitialFormState, getSortedArray, matchesFilter } from '../../DashboardFilter/utils';
import {
  THERAPIST_CASELOAD_FILTERS,
  PSYCHIATRIST_CASELOAD_FILTERS,
} from '../../DashboardFilter/constants';
import { matchesSearchString } from './utils';
import styled from '@/core/styled';
import { dashboardGetHeaderProps } from '../../DashboardTable';
import DashboardTablePlaceholder from '../../Placeholders/DashboardTablePlaceholder';
import IconGrow from '../../Placeholders/IconGrow';
import trackEvent from '../../../../modules/analytics/trackEvent';
import { DashboardInteraction } from '@/analytics/types';
import storage from '../../../../core/storage';
import { FilterState, SortField } from '../../DashboardFilter/types';
import ClientTable from './ClientTable';
import DashboardFilter from '../../DashboardFilter';
import useDashboardSize from '../../hooks/useDashboardSize';

const DASHBOARD_FILTER_TYPE = 'DASHBOARD_FILTER_TYPE';
interface AllClientsV4Props {
  allRoomsData: RoomRecord[];
  view: CaseLoadView;
  slaFeatureActive: boolean;
  isHidden: boolean;
  isLoading: boolean;
  filterToDate: Moment | null;
  filterFromDate: Moment | null;
  therapistType?: TherapistType;
  metrics?: MetricsResponse;
  isNPP?: boolean;
  shouldCloseDropDown: boolean;
  updateFilterFromDate: (date: Moment | null) => void;
  updateFilterToDate: (date: Moment | null) => void;
  handleOpenAvailabilityBar: (value: boolean) => void;
}

interface AllClientsTitleProps {
  isNPP?: boolean;
  hasAnyClients: boolean;
  isDesktop: boolean;
  hasMetrics?: boolean;
  engagingRooms?: string;
  notEngaging?: number;
}

const AllClientsContentWrapper = styled(View)({
  paddingBottom: 20,
});

const AllClientsTitleWrapper = styled(View)({
  marginBottom: 0,
  paddingTop: 14,
  minHeight: 48,
});

const DEFAULT_INITIAL_TABLE_STATE = {
  pageIndex: 0,
  pageSize: 25,
  sortBy: [{ id: 'waiting', desc: true }],
};

const AllClientsTitle = ({
  hasMetrics,
  engagingRooms,
  notEngaging,
  isNPP,
  hasAnyClients,
  isDesktop,
}: AllClientsTitleProps) => (
  <AllClientsTitleWrapper row style={{ alignItems: 'baseline' }} className="unstackable">
    <Large style={{ fontSize: 18 }}>Your caseload</Large>
    {!isNPP && hasMetrics && hasAnyClients && isDesktop && (
      <View style={{ marginLeft: 16, flexDirection: 'row' }}>
        <Small variant="smallDarkGrey" style={{ marginRight: 3 }} inline>
          You have:
        </Small>
        <Small
          variant="smallBoldTSBlack"
          inline
        >{`${engagingRooms} engaged and ${notEngaging} unengaged ${
          notEngaging === 1 ? 'client' : 'clients'
        }`}</Small>
      </View>
    )}
  </AllClientsTitleWrapper>
);

const AllClientsV4 = ({
  allRoomsData,
  view,
  slaFeatureActive,
  isHidden,
  isLoading,
  filterToDate,
  filterFromDate,
  metrics,
  isNPP,
  therapistType,
  shouldCloseDropDown,
  updateFilterFromDate,
  updateFilterToDate,
  handleOpenAvailabilityBar,
}: AllClientsV4Props) => {
  const { colors } = useEmotionTheme();
  const { isDesktop } = useDashboardSize();
  const [shouldAutoResetPage, setShouldAutoResetPage] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<FilterState>({});
  const [sortField, setSortField] = useState<SortField>('waiting');
  const [state, setState] = useObjectState<AllClientState>({
    view,
    searchString: '',
    currentRooms: allRoomsData.length,
    searchType: 'Nickname',
    isUserSearching: false,
    searchTypeOpen: false,
    caseLoadOpen: false,
    startDateFocused: false,
    endDateFocused: false,
    filterToDate: filterToDate ? filterToDate.toISOString() : null,
    filterFromDate: filterFromDate ? filterFromDate.toISOString() : null,
    ...getInitialTableState(localStorageTableNames.caseLoad, DEFAULT_INITIAL_TABLE_STATE),
  });

  useEffect(() => {
    setSortField((storage.getItem(DASHBOARD_FILTER_TYPE) as SortField) || 'waiting');
  }, []);

  const handleClientState = (allClientState: AllClientState) => {
    setState(allClientState);
  };

  const handleChangeSortField = (value: SortField) => {
    setSortField(value);
    storage.setItem(DASHBOARD_FILTER_TYPE, value);
  };

  const handleResetTable = useCallback(() => {
    setState({
      view,
      filterToDate: filterToDate?.toISOString() || null,
      filterFromDate: filterFromDate?.toISOString() || null,
      pageIndex: 0,
      isUserSearching: false,
    });
    setShouldAutoResetPage(true);
  }, [filterFromDate, filterToDate, setState, view]);

  const handleClientFilterState = (newFilterState: FilterState) => {
    handleResetTable();
    setFilterState(newFilterState);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      switch (therapistType) {
        case 'psychiatrist': {
          const stringifyFilterState = storage.getItem(PSYCHIATRIST_CASELOAD_FILTERS);
          setFilterState(
            stringifyFilterState
              ? JSON.parse(stringifyFilterState)
              : getInitialFormState('psychiatrist')
          );
          break;
        }
        case 'primary':
        case 'consultation_and_primary': {
          const stringifyFilterState = storage.getItem(THERAPIST_CASELOAD_FILTERS);
          setFilterState(
            stringifyFilterState
              ? JSON.parse(stringifyFilterState)
              : getInitialFormState('therapist')
          );
          break;
        }
        default:
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [therapistType]);

  const getEventProperties = (): DashboardInteraction => {
    const tableState = getInitialTableState(
      localStorageTableNames.caseLoad,
      DEFAULT_INITIAL_TABLE_STATE
    );

    const properties: DashboardInteraction = {
      searchCategory: state.searchType,
      dateRangeStartAt: filterFromDate ? filterFromDate.toISOString() : null,
      dateRangeEndAt: filterToDate ? filterToDate.toISOString() : null,
      sortName: tableState.sortBy[tableState.sortBy.length - 1].id,
      sortDirection: tableState.sortBy[tableState.sortBy.length - 1].desc ? 'desc' : 'asc',
      dashboardSection: 'caseload',
    };
    return properties;
  };

  const handleSendEvent = (
    activeFilterList?: Array<string>,
    filteredResultCount?: number
  ): void => {
    trackEvent(
      'clientListFiltered',
      { actionName: 'activeExperiments' },
      { ...getEventProperties(), activeFilterList, filteredResultCount }
    );
  };

  useEffect(() => {
    if (
      filterToDate?.toISOString() !== state.filterToDate ||
      filterFromDate?.toISOString() !== state.filterFromDate ||
      state.isUserSearching
    ) {
      handleResetTable();
    } else {
      setShouldAutoResetPage(false);
    }
  }, [
    filterFromDate,
    filterToDate,
    state.filterFromDate,
    state.filterToDate,
    state.isUserSearching,
    state.searchString,
    handleResetTable,
  ]);

  useEffect(() => {
    const closeAllDropDown = () => {
      setState({
        searchTypeOpen: false,
        caseLoadOpen: false,
      });
    };

    if (shouldCloseDropDown) {
      closeAllDropDown();
    }
  }, [shouldCloseDropDown, setState]);

  const filterData = useCallback(
    (data: RoomRecord[]) => data.filter((client) => matchesSearchString(state, client)),
    [state]
  );

  const counters: Counters = { all: allRoomsData.length };
  const searchBarFilteredData = filterData(allRoomsData);
  const filteredData = matchesFilter(filterState, searchBarFilteredData);
  const sortedData = getSortedArray(sortField, filteredData);
  const hasAnyClients = Number(counters?.all) > 0;
  const hasMetrics = metrics && Object.values(metrics)?.every((value) => value !== undefined);

  const handleClientListStateChange = (tableState: {
    pageIndex: number;
    pageSize: number;
    sortBy: SortingRule[];
  }) => {
    const prevSortByState = getInitialTableState(
      localStorageTableNames.caseLoad,
      DEFAULT_INITIAL_TABLE_STATE
    ).sortBy;

    saveLocalStorageData(localStorageTableNames.caseLoad, tableState);
    if (!isEqual(tableState.sortBy, prevSortByState)) {
      trackEvent('clientListSorted', { actionName: 'activeExperiments' }, getEventProperties());
    }
  };

  if (isHidden) {
    return null;
  }

  return (
    <View style={{ backgroundColor: 'white', paddingTop: 20 }}>
      <AllClientsTitle
        isNPP={isNPP}
        hasAnyClients={hasAnyClients}
        isDesktop={isDesktop}
        hasMetrics={hasMetrics}
        engagingRooms={metrics?.engagingRooms}
        notEngaging={metrics?.notEngaging}
      />
      {isLoading || !hasAnyClients ? (
        <DashboardTablePlaceholder
          title="You have no client caseload yet!"
          subtitle="This is where you’ll provide ongoing treatment. To grow your clientele, "
          icon={<IconGrow />}
          linkText="update your new client availability."
          isLoading={isLoading}
          handleOnPress={handleOpenAvailabilityBar}
        />
      ) : (
        <AllClientsContentWrapper>
          <DashboardFilter
            sortField={sortField}
            handleChangeSortField={(value: SortField) => handleChangeSortField(value)}
            handleSendEvent={handleSendEvent}
            filterFromDate={filterFromDate}
            filterToDate={filterToDate}
            handleClientState={handleClientState}
            currentState={state}
            updateFilterFromDate={updateFilterFromDate}
            updateFilterToDate={updateFilterToDate}
            getEventProperties={getEventProperties}
            handleClientFilterState={handleClientFilterState}
            rooms={allRoomsData}
            filteredData={sortedData}
            caseLoadFilterType={therapistType === 'psychiatrist' ? 'psychiatrist' : 'therapist'}
          />
          <div className="unstackable ibox-content">
            <div className="unstackable grid" role="grid">
              <ClientTable
                tableType="all-clients"
                enablePagination
                enableSorting
                data={sortedData}
                tableStyle={{ marginTop: 0 }}
                onStateChange={handleClientListStateChange}
                autoResetPage={shouldAutoResetPage}
                getHeaderProps={dashboardGetHeaderProps(colors)}
                initialState={{
                  pageIndex: state.pageIndex,
                  pageSize: state.pageSize,
                  sortBy: state.sortBy,
                }}
                isLoading={isLoading}
              />
            </div>
          </div>
        </AllClientsContentWrapper>
      )}
    </View>
  );
};

export default AllClientsV4;
