import { TouchableView, View } from '@talkspace/react-toolkit';
import { useHistory } from 'react-router';
import Lottie from 'react-lottie';
import { getUserData } from '../../../../utils/token';

import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import styled from '../../../../core/styled';
import { SuccessfulClaimClientInfo } from '../types';
import ModalContainer from './ModalContainer';
import checkMarkAnimation from '../../../../assets/animations/newClientAvailabilityBarCheckmark.json';
import Analytics from '../Analytics';

const Button = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 15,
  };
});

const SendAMessageButton = styled(Button)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: 50,
      color: 'white',
      backgroundColor: colors.permaBlueStoneNew,
      width: isMobile ? 295 : 335,
      borderRadius: 10,
      height: 50,
      textAlign: 'center',
      lineHeight: '50px',
      letterSpacing: ' 0.5px',
      fontWeight: 700,
      fontSize: 17,
    };
  }
);

const CloseButton = styled(Button)(({ theme: { colors } }) => {
  return {
    marginTop: 25,
    fontSize: 17,
    backgroundColor: colors.white,
    color: colors.permaBlueStoneNew,
    letterSpacing: ' 0.5px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const ContentWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: isMobile ? 30 : 58,
      paddingRight: isMobile ? 30 : 57,
      paddingBottom: 50,
    };
  }
);

const Title = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? '100%' : '90%',
      marginTop: 19,
      fontSize: 21,
      lineHeight: '28px',
      fontWeight: 500,
      color: colors.permaOuterSpaceNew,
      textAlign: 'center',
    };
  }
);

const Subtitle = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 15,
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'center',
    color: colors.permaRiverBed,
    lineHeight: '18px',
  };
});

const ClaimRoomSuccessModal = ({
  onBackdropPress,
  hide,
  clientDetails,
}: {
  onBackdropPress: () => void;
  hide: boolean;
  clientDetails: SuccessfulClaimClientInfo | null;
}) => {
  const history = useHistory();
  const isNativeWebview = ReactFrameService.instance().isInFrame();
  return (
    <ModalContainer onBackdropPress={onBackdropPress} hide={hide}>
      <ContentWrapper>
        <Lottie
          ariaRole="presentation"
          ariaLabel="check mark"
          title="check mark"
          options={{
            loop: false,
            autoplay: true,
            animationData: checkMarkAnimation,
          }}
          height={41}
          width={41}
          speed={1.6}
        />
        <Title>{`Your new client ${clientDetails?.name.firstName} has been added to your caseload!`}</Title>
        {isNativeWebview ? (
          <Subtitle>{`We recommend messaging ${clientDetails?.name.firstName} to introduce yourself and schedule your first session`}</Subtitle>
        ) : (
          <Subtitle>{`Message ${clientDetails?.name.firstName} now to introduce yourself and schedule your first session`}</Subtitle>
        )}

        {!isNativeWebview && (
          <SendAMessageButton
            onPress={() => {
              Analytics.trackModalButtonPressedEvent({
                modal: 'Claim room success modal',
                button: 'Send a message button',
                roomID: clientDetails?.roomID,
                therapistID: getUserData().id,
              });
              history.push(`/room/${clientDetails?.roomID}`);
            }}
          >
            Send a message
          </SendAMessageButton>
        )}
        <CloseButton onPress={onBackdropPress}>
          {isNativeWebview ? 'Close' : 'I’ll do this later'}
        </CloseButton>
      </ContentWrapper>
    </ModalContainer>
  );
};

export default ClaimRoomSuccessModal;
