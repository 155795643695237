import './MultiRowListItem.css';

const MultiRowListItem = ({ displayData, cssClass }) => (
  <div className={cssClass}>
    {displayData.index && <div className="index"> {displayData.index} </div>}
    <div className="item-data-container">
      {displayData.dataOrder.map((attr, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={key}>{displayData[attr].replace(/,(\s+)?/g, ', ')}</div>
      ))}
    </div>
  </div>
);

export default MultiRowListItem;
