import { useQuery } from 'react-query';
import { adminConfigQueryKey } from './queryKeys';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

const fetchPublicAdminConfigValue = (configKey: string) => async (): Promise<boolean> => {
  const { data } = await apiWrapper.get(
    `${apiHelper().publicEndpoint}/public/v1/get-admin-config-value?key=${configKey}`
  );
  return Boolean(data.data);
};

const useQueryPublicAdminConfig = (configKey: string) =>
  useQuery<boolean>({
    queryKey: adminConfigQueryKey(configKey),
    queryFn: fetchPublicAdminConfigValue(configKey),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: true,
  });

export default useQueryPublicAdminConfig;
