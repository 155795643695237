import { useQuery } from 'react-query';
import moment from 'moment';

import { clinicalHoursQueryKey } from './queryKeys';
import { getClinicalHours, GetClinicalHoursOptions } from '../api/clinicalHours';
import { IClinicalHours, formatClinicalHoursDTO } from '../entities/clinicalHours';

const getDefaultOptions = (): GetClinicalHoursOptions => {
  const nextWeek = moment().utc().add(1, 'week');
  return {
    anchorDate: nextWeek.format('YYYY-MM-DD'),
    numberOfWeeks: 3,
  };
};

const fetchClinicalHours =
  (therapistID: number, options: GetClinicalHoursOptions) => async (): Promise<IClinicalHours> => {
    const clinicalHoursDTO = await getClinicalHours(therapistID, options);

    return formatClinicalHoursDTO(clinicalHoursDTO);
  };

const SIXTY_MINUTES_IN_MS = 1000 * 60 * 60;

const useQueryClinicalHours = (
  therapistID: number,
  options: GetClinicalHoursOptions = getDefaultOptions()
) =>
  useQuery<IClinicalHours, Error>({
    queryKey: clinicalHoursQueryKey(therapistID),
    queryFn: fetchClinicalHours(therapistID, options),
    // The data refreshes once a day hence cacheTime Infinity
    cacheTime: Infinity,
    staleTime: SIXTY_MINUTES_IN_MS,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: true,
  });

export default useQueryClinicalHours;
