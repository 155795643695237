import axios from 'axios';
import * as actionTypes from '../constants/BookingConstants';
import apiHelper from '../utils/api';

function requestBooking() {
  return { type: actionTypes.REQUEST_PATCH_BOOKING };
}

function getBookingState(previousBookingState, status) {
  let bookingState = previousBookingState;
  if (bookingState === 'ALREADY_CONFIRMED' && status === 'declined') {
    bookingState = 'PREVIOUSLY_CONFIRMED';
  } else if (bookingState === 'ALREADY_DECLINED' && status === 'confirmed') {
    bookingState = 'PREVIOUSLY_DECLINED';
  }
  return bookingState;
}

function receiveBooking(response, status) {
  const bookingData = response.data;
  bookingData.state = getBookingState(bookingData.state, status);

  return {
    type: actionTypes.RECEIVE_PATCH_BOOKING,
    ...bookingData,
  };
}

function requestBookingError(error) {
  return {
    type: actionTypes.REQUEST_PATCH_BOOKING_ERROR,
    error,
  };
}

export const patchRoomBooking = (roomID, bookingID, status, token) => (dispatch) => {
  dispatch(requestBooking());
  const xhr = axios.create();
  return xhr
    .request({
      baseURL: apiHelper().apiEndpoint,
      headers: {
        'Content-Type': 'application/json',
      },
      url: `/v3/rooms/${roomID}/bookings/${bookingID}/${status}?token=${token}`,
      method: 'patch',
      body: {},
    })
    .then((response) => dispatch(receiveBooking(response, status)))
    .catch((error) => dispatch(requestBookingError(error)));
};
