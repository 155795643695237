import { AllClientState, RoomRecord } from 'components/Dashboard/types';
import moment from 'moment';

export const matchesSearchString = (state: AllClientState, client: RoomRecord) => {
  switch (state.searchType) {
    case 'Name': {
      const nameString = `${client.clientFirstName || ''} ${client.clientLastName || ''}`;
      return nameString.toLowerCase().includes(state.searchString);
    }
    case 'Nickname': {
      const nameString = client.clientNickname || '';
      return nameString.toLowerCase().includes(state.searchString);
    }
    case 'User': {
      const userId = client.clientUserID !== null ? String(client.clientUserID) : '';
      return userId.includes(state.searchString);
    }
    case 'Room': {
      const roomId = client.roomID !== null ? String(client.roomID) : '';
      return roomId.includes(state.searchString);
    }
    default:
      return false;
  }
};

export const waitingTimeFormatter = (inputTime: Date, isDashboardMobile: boolean) => {
  if (!inputTime) {
    return '';
  }
  if (moment().diff(inputTime) < 1000) {
    // 1000 milliseconds
    return 'just now';
  }
  return isDashboardMobile
    ? `${moment(inputTime).fromNow(true)} ago`
    : moment(inputTime).fromNow(true);
};

const utils = {
  matchesSearchString,
  waitingTimeFormatter,
};

export default utils;
