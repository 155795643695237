import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getInvites,
  resendInvite,
  revokeInvite,
  postInvite,
} from '../../../../../actions/InviteActions';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import ListItem from '../../../../Reusable/ListItems/ListItem';
import ItemList from '../../../../Reusable/ItemList/ItemList';
import { formatDateMedium } from '../../../../../utils/date';
import { getActiveCustomerName } from '../../../../../utils/customerHelper';
import './Invites.css';

class Invites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      invites: [],
    };
    this.getInternalContent = this.getInternalContent.bind(this);
    this.processItems = this.processItems.bind(this);
    this.getItemActions = this.getItemActions.bind(this);
    this.getUserActionCardContent = this.getUserActionCardContent.bind(this);
    this.getInviteButtons = this.getInviteButtons.bind(this);
  }

  getInternalContent() {
    const { invites, roomType } = this.props;
    if (roomType !== 'couples_room')
      return (
        <div className="no-invitations">
          <p>
            You can only invite additional clients to rooms with an active couples plan
            subscription. Please have the client subscribe on a couples plan first.
          </p>
        </div>
      );
    return !!invites && !!invites.length ? (
      <ItemList
        items={this.processItems(invites)}
        titles={['Created', 'Redeemed', 'Modality']}
        noDataMessage="No invitations."
        cssClass="invitations grey"
        widths={[98, 94, 92]}
      />
    ) : (
      <div className="no-invitations">
        <p>
          Post a partner invitation link in the room for your client to see. Once the partner taps
          on the link they will be able to create their own Talkspace account and automatically join
          the room
        </p>
      </div>
    );
  }

  getInviteButtons() {
    const { roomType } = this.props;
    if (roomType !== 'couples_room') return [];
    return [
      {
        isSecondary: false,
        title: 'Post invitation link',
        clickHandler: () => this.props.postInvite(),
      },
    ];
  }

  getItemActions() {
    const actions = [
      {
        text: 'Resend',
        method: (item) => this.props.resendInvite(item.privateTalkInvitationID),
        cssClass: 'default',
      },
      {
        text: 'Delete',
        method: (item) => this.props.revokeInvite(item.privateTalkInvitationID),
        cssClass: 'danger',
      },
    ];
    return actions;
  }

  getUserActionCardContent() {
    const isCouplesRoom = this.props.roomType === 'couples_room';
    return (
      <UserActionCard
        preferredName={getActiveCustomerName(this.props.customerInformation, this.props.clientList)}
        content={this.getInternalContent()}
        buttons={this.getInviteButtons()}
        isError={this.props.isError}
        isUpdating={isCouplesRoom ? this.props.isUpdating : false}
        showSpinner={isCouplesRoom ? this.state.showSpinner : false}
        spinnerMessage={this.props.spinnerMessage}
      />
    );
  }

  UNSAFE_componentWillMount() {
    this.props.getInvites();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.invites !== this.props.invites)
      this.setState({
        invitations: nextProps.invitations,
      });

    if (nextProps.isUpdating !== this.props.isUpdating) {
      if (nextProps.isUpdating) {
        this.setState({
          showSpinner: nextProps.isUpdating,
        });
      } else {
        setTimeout(() => {
          this.setState({ showSpinner: nextProps.isUpdating });
        }, 2000);
      }
    }
  }

  processItems(items) {
    return this.props.invites.map((item, key) => {
      const itemDisplayData = {};

      itemDisplayData.time = formatDateMedium(item.createdAt);
      itemDisplayData.status =
        item.status == 'Pending' ? item.status : formatDateMedium(item.status);
      itemDisplayData.modality = item.modality;

      const actions = item.status == 'Pending' ? this.getItemActions() : undefined;
      const icon = actions ? undefined : { className: 'fa-check', type: 'success' };

      return (
        <ListItem
          key={key}
          itemDataOrder={['time', 'status', 'modality']}
          itemDisplayData={itemDisplayData}
          item={item}
          cssClass={item.status == 'Pending' ? 'list-item grayed' : 'list-item'}
          actions={actions}
          icon={icon}
          widths={[98, 94, 80]}
        />
      );
    });
  }

  render() {
    return (
      <Submenu
        childComponents={[this.getUserActionCardContent()]}
        title="Room invitations"
        prevRoute="case-tab"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    invites: state.invite.invites,
    showSpinner: state.invite.showSpinner,
    isUpdating: state.invite.isUpdating,
    spinnerMessage: state.invite.spinnerMessage,
    isError: state.invite.isError,
    clientList: state.customerList.list,
    roomID: state.room.roomID,
    roomType: state.room.roomType,
  };
};

Invites.propTypes = {
  invites: PropTypes.array,
  showSpinner: PropTypes.bool,
  isUpdating: PropTypes.bool,
  spinnerMessage: PropTypes.string,
  isError: PropTypes.bool,
  getInvites: PropTypes.func,
  revokeInvite: PropTypes.func,
  resendInvite: PropTypes.func,
  updateView: PropTypes.func,
  postInvite: PropTypes.func,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getInvites, revokeInvite, resendInvite, postInvite }, dispatch);

const InvitesContainer = connect(mapStateToProps, mapDispatchToProps)(Invites);

export default InvitesContainer;
