import { VoidFunctionComponent } from 'react';
import { Standard, View, Small, Button, TouchableView, COLORS } from '@talkspace/react-toolkit';
import styled from '../../../../../core/styled';

interface ReopenAreYouSureProps {
  handleSubmit: () => void;
  handleCancel: () => void;
}

const Container = styled(View)(() => {
  return {
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 17,
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

export const SubmitButton = styled(Button)(({ theme: { colors } }) => {
  return {
    width: 134,
    minHeight: 30,
    height: 30,
    borderRadius: 5,
    margin: 0,
    background: colors.permaEden,
    fontWeight: 700,
    fontSize: 15,
  };
});

const CancelButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    fontWeight: 500,
    fontSize: 15,
    color: colors.slateGrey,
    marginRight: 20,
  };
});

const ReopenAreYouSureScreen: VoidFunctionComponent<ReopenAreYouSureProps> = ({
  handleSubmit,
  handleCancel,
}) => (
  <Container onClick={(e) => e.preventDefault()}>
    <Standard style={{ marginBottom: 20 }}>
      Are you sure you want to reopen this EAP session?
    </Standard>
    <Small style={{ marginBottom: 20, color: COLORS.permaRiverBed }}>
      If the client did not engage, it is not required for you to reopen the session. An EAP session
      is defined as 7 days of access to the platform.
    </Small>
    <Small style={{ marginBottom: 30, color: COLORS.permaRiverBed }}>
      If you reopen the session, you will not be paid out for this session until you resubmit the
      session as complete.
    </Small>
    <View row align="center" justify="end">
      <CancelButton onPress={handleCancel}>Cancel</CancelButton>
      <SubmitButton onPress={handleSubmit} text="I'm sure" />
    </View>
  </Container>
);

export default ReopenAreYouSureScreen;
