import { useState } from 'react';
import { connect } from 'react-redux';

import { useParams } from '@/core/routerLib';
import ReactFrameService from '../../../../../modules/auth/reactFrame/ReactFrameService';
import * as roomCaseActions from '../../../../../actions/RoomCaseActions';
import useQueryNotes from '../../../../../hooks/notes/useQueryNotes';
import Submenu from '../../../../Reusable/Submenu';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import { getActiveCustomerName } from '../../../../../utils/customerHelper';

interface Customer {
  displayName: string;
  role: string;
  timezone: string;
  userID: number;
}

interface Props {
  postNewRoomCase: () => void;
  isError: boolean;
  isUpdating: boolean;
  isSuccess: boolean;
  customerList: Array<Customer>;
  customerInformation: Record<string, unknown>;
}

const RestartCare = ({
  postNewRoomCase,
  isError,
  isUpdating,
  isSuccess,
  customerList,
  customerInformation,
}: Props) => {
  const { roomID } = useParams<{ roomID: string }>();

  const [showSpinner, setShowSpinner] = useState(false);

  useQueryNotes(roomID, isSuccess);

  const actionCardButtons = [
    {
      title: 'Send another session',
      clickHandler: () => {
        setShowSpinner(true);
        setTimeout(() => {
          setShowSpinner(false);

          if (ReactFrameService.instance().isInFrame()) {
            ReactFrameService.instance().closePopup({
              navigateTo: 'room',
              metadata: { roomID: Number(roomID) },
            });
          }
        }, 3000);

        postNewRoomCase();
      },
    },
  ];

  const cardText =
    'If your client has a copay they will be asked to accept another session so you can re-engage in care. Once the client accepts the session you will notified and you can resume services. If your client doesn’t have a copay services can resume immediately.';

  return (
    <Submenu
      title="Restart care"
      childComponents={[
        <UserActionCard
          key={1}
          preferredName={getActiveCustomerName(customerInformation, customerList)}
          content={cardText}
          buttons={actionCardButtons}
          isUpdating={isUpdating}
          isError={isError}
          showSpinner={showSpinner}
        />,
      ]}
      prevRoute="case-tab"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    isError: state.roomCase.requestError,
    isUpdating: state.roomCase.isRequesting,
    isSuccess: state.roomCase.roomCaseCreated,
    customerList: state.customerList.list,
    customerInformation: state.customerInformation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postNewRoomCase: () => dispatch(roomCaseActions.postNewRoomCase()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestartCare);
