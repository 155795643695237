import { useEmotionTheme, View, DatePickerInput, ExtraTiny } from '@talkspace/react-toolkit';
import moment, { Moment } from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { useCallback } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { getIcon } from '../../../CTA/CTACard';
import {
  Container,
  Title,
  Subtitle,
  WordCountText,
  getDateRangeCalendarStyle,
  getDateRangeInputStyle,
  DateInputLabel,
} from '../styles';
import {
  useProgressNoteFormState,
  useProgressNoteFormActions,
} from '../../context/TherapistProgressNoteFormContext';
import useQueryUsersAdminConfigValue from '../../../../../../../hooks/useQueryUsersAdminConfigValue';

interface MessagingSessionProps {
  wordCount: number | null;
  wordDuration: number | null;
  error: string;
  isEndDateFixed: boolean;
  isStartDateFixed: boolean;
  setError: (value: string) => void;
  isReopend: boolean;
}

const MessaginSession = ({
  wordCount,
  wordDuration,
  error,
  isEndDateFixed,
  setError,
  isReopend,
  isStartDateFixed,
}: MessagingSessionProps) => {
  const { colors } = useEmotionTheme();
  const icon = getIcon('messaging');

  const { serviceStartDate, serviceEndDate, unsubmittedSession } = useProgressNoteFormState();
  const { data: eapTextSessionDays } = useQueryUsersAdminConfigValue({
    key: 'eap_text_session_days',
  });
  const { setFormState } = useProgressNoteFormActions();

  const { accountType } = unsubmittedSession || {};

  const getMinEndDate = (): Moment => {
    if (accountType === 'eap' && eapTextSessionDays) {
      return moment(serviceStartDate).add(eapTextSessionDays as number, 'days');
    }
    return moment(serviceStartDate).startOf('day');
  };

  const handleChangeDate = (newEndDate: Moment | null) => {
    if (newEndDate) {
      setFormState({ serviceEndDate: newEndDate?.toDate() });
      setError('');
    }
  };

  const handleChangeStartDate = (newStartDate: Moment | null) => {
    if (newStartDate) {
      setFormState({ serviceStartDate: newStartDate?.toDate() });
      setError('');
    }
  };

  const getTitle = () => {
    if (accountType === 'eap' && serviceEndDate && !isReopend) {
      return `${moment(serviceStartDate).format('MMMM D')} - ${moment(serviceEndDate).format(
        'MMMM D'
      )}`;
    }

    return `Started ${moment(serviceStartDate).format('MMMM D')}`;
  };

  const shouldDisplayWordCount =
    accountType === 'bh' && wordCount !== null && wordCount !== undefined;
  const {
    calculatingAndCommunicatingAsyncEngagement: { featureOn: shouldShowMinutesOnly },
  } = useFlags();

  const getWordCountText = useCallback(
    () =>
      shouldShowMinutesOnly
        ? wordDuration && `${wordDuration.toLocaleString()} min`
        : wordCount && `${wordCount.toLocaleString()} words`,
    [shouldShowMinutesOnly, wordCount, wordDuration]
  );

  return (
    <>
      <Container style={{ height: shouldDisplayWordCount ? 96 : 68 }}>
        <View row>
          <View style={{ marginRight: 11 }}>{icon}</View>
          <View>
            <Title>{getTitle()}</Title>
            <Subtitle>Messaging session</Subtitle>
            <View row style={{ marginTop: 10 }}>
              {shouldDisplayWordCount && <WordCountText>{getWordCountText()}</WordCountText>}
            </View>
          </View>
        </View>
      </Container>
      <View row align="center" style={{ marginBottom: 10, position: 'relative' }}>
        <DateInputLabel style={{ marginRight: 10 }}>From: </DateInputLabel>
        <DatePickerInput
          arrowPosition={73}
          inputStyles={getDateRangeInputStyle(colors, !!error, isStartDateFixed)}
          currentValue={moment(serviceStartDate)}
          handleInputValueChange={(date) => handleChangeStartDate(date)}
          disabled={isStartDateFixed}
          calendarWrapperStyles={getDateRangeCalendarStyle(true)}
          calendarDayWidth={48}
          primaryColor={colors.accessibilityGreenDark}
          secondaryColor={colors.aquaSqueeze}
          minDate={moment(serviceStartDate).startOf('day')}
          maxDate={moment()}
        />
        <DateInputLabel style={{ marginRight: 10, marginLeft: 10 }}>To: </DateInputLabel>
        <DatePickerInput
          arrowPosition={250}
          inputStyles={getDateRangeInputStyle(colors, !!error, isEndDateFixed)}
          currentValue={moment(serviceEndDate)}
          handleInputValueChange={(date) => handleChangeDate(date)}
          calendarWrapperStyles={getDateRangeCalendarStyle(false)}
          calendarDayWidth={48}
          disabled={isEndDateFixed}
          primaryColor={colors.accessibilityGreenDark}
          secondaryColor={colors.aquaSqueeze}
          minDate={getMinEndDate()}
          maxDate={moment()}
        />
      </View>
      <ExtraTiny style={{ marginTop: -7, marginBottom: 5 }} variant="extraTinyError">
        {error}
      </ExtraTiny>
    </>
  );
};

export default MessaginSession;
