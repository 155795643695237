import { FunctionComponent, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import { DiagnosticProfileState } from '../../../../../constants/DiagnosticProfileConstants';
import {
  getDiagnosticProfiles,
  setCurrentDiagnosticProfileClient,
} from '../../../../../actions/DiagnosticProfileActions';

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
    customerList: state.customerList.list,
    isError: state.diagnosticProfile.isError,
    isLoading: state.diagnosticProfile.isLoading,
    errorMessage: state.diagnosticProfile.errorMessage,
    diagnosticProfiles: state.diagnosticProfile.diagnosticProfiles,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDiagnosticProfiles,
      setCurrentDiagnosticProfileClient,
    },
    dispatch
  );

interface DiagnosticProfilePickerProps
  extends ReturnType<typeof mapDispatchToProps>,
    RouteComponentProps {
  roomID: string;
  customerList: { userID: string; displayName: string }[];
  isError: DiagnosticProfileState['isError'];
  isLoading: DiagnosticProfileState['isLoading'];
  errorMessage: DiagnosticProfileState['errorMessage'];
  diagnosticProfiles: DiagnosticProfileState['diagnosticProfiles'];
}

const DiagnosticProfilePicker: FunctionComponent<DiagnosticProfilePickerProps> = ({
  roomID,
  history,
  isError,
  isLoading,
  customerList,
  errorMessage,
  diagnosticProfiles,
  ...otherProps
}) => {
  useEffect(() => {
    otherProps.getDiagnosticProfiles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const pickUser = (clientUserID: string) => {
    otherProps.setCurrentDiagnosticProfileClient(clientUserID);
    history.push(`/room/${roomID}/diagnostic-profile`, { selectedClientID: clientUserID });
  };
  const cards = customerList
    .filter((client) => !!diagnosticProfiles[client.userID])
    .map((client) => {
      const buttons = [
        {
          title: 'Show profile',
          clickHandler: () => pickUser(client.userID),
        },
      ];
      return (
        <UserActionCard
          buttons={buttons}
          isError={isError}
          key={client.userID}
          isUpdating={isLoading}
          spinnerMessage={errorMessage}
          preferredName={client.displayName}
          content="Review your clients diagnostic profile in conjunction with your clinical expertise to help guide treatment."
        />
      );
    });
  // @ts-ignore: TODO: Fix this below
  return <Submenu childComponents={cards} title="Diagnostic profile" prevRoute="clinical-tab" />;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiagnosticProfilePicker));
