export const REQUEST_AVAILABLE_THERAPISTS = 'REQUEST_AVAILABLE_THERAPISTS';
export const REQUEST_AVAILABLE_THERAPISTS_ERROR = 'REQUEST_AVAILABLE_THERAPISTS_ERROR';
export const RECEIVE_AVAILABLE_THERAPISTS = 'RECEIVE_AVAILABLE_THERAPISTS';
export const REQUEST_SEND_INTAKE_MESSAGE = 'REQUEST_SEND_INTAKE_MESSAGE';
export const REQUEST_SEND_INTAKE_MESSAGE_ERROR = 'REQUEST_SEND_INTAKE_MESSAGE_ERROR';
export const RECEIVE_SEND_TREATMENT_INTAKE_MESSAGE = 'RECEIVE_SEND_TREATMENT_INTAKE_MESSAGE';
export const RECEIVE_SEND_MATCHING_INTAKE_MESSAGE = 'RECEIVE_SEND_MATCHING_INTAKE_MESSAGE';
export const REQUEST_INTAKE_SURVEY_STATUS = 'REQUEST_INTAKE_SURVEY_STATUS';
export const REQUEST_INTAKE_SURVEY_STATUS_ERROR = 'REQUEST_INTAKE_SURVEY_STATUS_ERROR';
export const RECEIVE_TREATMENT_INTAKE_SURVEY_STATUS = 'RECEIVE_TREATMENT_INTAKE_SURVEY_STATUS';
export const RECEIVE_MATCHING_INTAKE_SURVEY_STATUS = 'RECEIVE_MATCHING_INTAKE_SURVEY_STATUS';
export const SWITCH_THERAPIST = 'SWITCH_THERAPIST';
export const SWITCH_THERAPIST_SUCCESS = 'SWITCH_THERAPIST_SUCCESS';
export const SWITCH_THERAPIST_ERROR = 'SWITCH_THERAPIST_ERROR';
export const REQUEST_IN_PLATFORM_MATCHING_OPTIONS = 'REQUEST_IN_PLATFORM_MATCHING_OPTIONS';
export const REQUEST_IN_PLATFORM_MATCHING_OPTIONS_ERROR =
  'REQUEST_IN_PLATFORM_MATCHING_OPTIONS_ERROR';
export const RECEIVE_IN_PLATFORM_MATCHING_OPTIONS = 'RECEIVE_IN_PLATFORM_MATCHING_OPTIONS';
export const SUGGESTING_THERAPISTS = 'SUGGESTING_THERAPISTS';
export const SUGGEST_THERAPISTS_SUCCESS = 'SUGGEST_THERAPISTS_SUCCESS';
export const SUGGEST_THERAPISTS_ERROR = 'SUGGEST_THERAPISTS_ERROR';
export const NO_SUGGESTED_THERAPISTS_FOUND = 'NO_SUGGESTED_THERAPISTS_FOUND';
export const CHANGING_ROOM_TYPE = 'CHANGING_ROOM_TYPE';
export const CHANGE_ROOM_TYPE_SUCCESS = 'CHANGE_ROOM_TYPE_SUCCESS';
export const CHANGE_ROOM_TYPE_ERROR = 'CHANGE_ROOM_TYPE_ERROR';
export const REQUEST_SEND_ELIGIBILITY_MESSAGE = 'REQUEST_SEND_ELIGIBILITY_MESSAGE';
export const RECEIVE_SEND_ELIGIBILITY_MESSAGE = 'RECEIVE_SEND_ELIGIBILITY_MESSAGE';
export const REQUEST_SEND_ELIGIBILITY_MESSAGE_ERROR = 'REQUEST_SEND_ELIGIBILITY_MESSAGE_ERROR';
