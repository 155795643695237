import { View } from '@talkspace/react-toolkit';

const ClientHoldTooltip = (
  <View style={{ fontFamily: 'roboto' }}>
    <View
      style={{
        fontWeight: 700,
        fontSize: 15,
        lineHeight: '20px',
        marginBottom: 10,
        color: '#222F2D',
      }}
    >
      What is a new client hold?
    </View>
    <View style={{ fontWeight: 400, fontSize: 14 }}>
      New clients can now find and reserve their first session with you through select external
      directories. If the client doesn’t complete our onboarding within 24 hours of making the
      reservation, the hold will be released.
      <br />
      <br />
      Once client onboarding is complete, we’ll notify you so you can confirm or decline this
      session.
    </View>
  </View>
);

export default ClientHoldTooltip;
