import {
  Big,
  ExtraHuge,
  Mini,
  Small,
  Spinner,
  Standard,
  View,
  styled,
  useWindowWidthState,
} from '@talkspace/react-toolkit';

import { usePerformanceState } from '../hooks/performanceContext';
import { MetricName, Metric } from '../types';
import useMetricDisplayParameters from '../hooks/useMetricsDisplayParameters';

const CELL_WIDTH = 367;
const CELL_WIDTH_MOBILE = 190;
const COLUMN_SPACING = 24;

const Container = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    maxWidth: isMobile ? undefined : CELL_WIDTH,
    marginRight: isMobile ? 0 : COLUMN_SPACING,
    marginTop: 24,
    marginBottom: 24,
  };
});
interface Props {
  metricName: MetricName;
  metric: Metric;
  canFlex?: boolean;
}
const MetricCell = ({ metricName, metric, canFlex }: Props) => {
  const { isMobile, isDesktop } = useWindowWidthState();
  const { isLoading } = usePerformanceState();
  const { title, subtitle, description, titleColor, valueColor, levelTitle, displayValue } =
    useMetricDisplayParameters(metricName, metric);
  return (
    <Container isMobile={isMobile}>
      <View>
        <Big>{title}</Big>
        <Mini>{subtitle}</Mini>
      </View>
      <View row={canFlex && isDesktop}>
        <View>
          {isLoading ? (
            <Spinner />
          ) : (
            <ExtraHuge
              style={{
                color: valueColor,
                marginTop: isMobile ? 14 : 4,
                minWidth: isDesktop ? CELL_WIDTH + COLUMN_SPACING : CELL_WIDTH_MOBILE,
              }}
            >
              {displayValue}
            </ExtraHuge>
          )}
        </View>
        <View style={{ width: canFlex && !isMobile ? CELL_WIDTH * 2 : 'auto' }}>
          {isLoading ? (
            <Spinner />
          ) : (
            <Standard style={{ color: titleColor, marginTop: 8 }}>{levelTitle}</Standard>
          )}
          <Small style={{ marginTop: 4 }}>{description}</Small>
        </View>
      </View>
    </Container>
  );
};
export default MetricCell;
