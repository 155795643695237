import { VoidFunctionComponent, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import Chat from 'chat';
import { useSharedChatState } from 'chat/hooks/sharedChatContext';
import useClosedCaptionsReceivedSocket from 'chat/hooks/useClosedCaptionsReceivedSocket';
import { ChatBannerType } from 'chat/redux/constants/chatTypes';
import ChatBannerManager from 'chat/components/ChatBannerManager';
import { EmotionStyle } from '@talkspace/react-toolkit';
import useQueryParentalConsent from 'ts-frontend/hooks/parentalConsent/useQueryParentalConsent';
import { useOpenModal } from '@/utils/ModalsContextProvider';
import SocketService from '@/utils/socket/SocketService';

type Props = {
  style?: EmotionStyle;
  stopInputAutofocus?: boolean;
};

const TherapistChatContainer: VoidFunctionComponent<Props> = ({
  stopInputAutofocus,
  style = {},
}) => {
  const {
    therapistID,
    therapistFirstName,
    therapistType = 'primary',
    sessionStatus,
    sharedBanners = [],
    primaryClientID,
  } = useSharedChatState();

  const { data: parentConsentData } = useQueryParentalConsent(primaryClientID);

  const match = useRouteMatch<{ roomID: string }>();
  const { roomID } = match.params;
  const openModal = useOpenModal();

  useClosedCaptionsReceivedSocket(SocketService.instance(), therapistID);

  useEffect(() => {
    const keepMessagingModal = () => {
      openModal('/keep-messaging', { roomID }, false, true);
    };
    document.addEventListener('keepMessagingModal', keepMessagingModal);
    return () => {
      document.removeEventListener('keepMessagingModal', keepMessagingModal);
    };
  }, [openModal, roomID]);

  const buildBanners = () =>
    sharedBanners.map((bannerType) => (
      <ChatBannerManager
        clientUserID={primaryClientID}
        key={bannerType}
        therapistFirstName={therapistFirstName}
        therapistType={therapistType}
        banner={bannerType as ChatBannerType}
        sessionStatusProps={sessionStatus}
        roomID={roomID}
        isTherapist
      />
    ));

  const buildParentalConsentBanners = () => {
    if (
      parentConsentData &&
      ['emailSent', 'consentSkippedByTeen'].includes(parentConsentData.consentStatus)
    ) {
      return [
        <ChatBannerManager
          clientUserID={primaryClientID}
          therapistFirstName={therapistFirstName}
          therapistType={therapistType}
          sessionStatusProps={sessionStatus}
          roomID={roomID}
          isTherapist
        />,
      ];
    }

    return undefined;
  };

  return (
    <>
      <Chat
        stopInputAutofocus={stopInputAutofocus}
        isTherapistChat
        style={style}
        isAcking
        banners={sharedBanners.length > 0 ? buildBanners() : buildParentalConsentBanners()}
      />
    </>
  );
};

export default TherapistChatContainer;
