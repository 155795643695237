import * as actionTypes from '../constants/RoomCaseConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

export function postNewRoomCaseRequest() {
  return {
    type: actionTypes.POST_NEW_ROOM_CASE_REQUEST,
  };
}

export function postNewRoomCaseSuccess() {
  return {
    type: actionTypes.POST_NEW_ROOM_CASE_SUCCESS,
  };
}

export function postNewRoomCaseError() {
  return {
    type: actionTypes.POST_NEW_ROOM_CASE_ERROR,
  };
}

export const postNewRoomCase = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  dispatch(postNewRoomCaseRequest());

  apiWrapper
    .post(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/cases`)
    .then(() => dispatch(postNewRoomCaseSuccess()))
    .catch(() => dispatch(postNewRoomCaseError()));
};
