import { Form, TextArea } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import './TextArea.css';

const TextAreaComponent = ({ style, onChange, placeholder, value }) => (
  <TextArea
    className="text-area"
    placeholder={placeholder}
    style={style}
    onChange={onChange}
    value={value}
  />
);

export default TextAreaComponent;
