import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import './MultiSelectSearch.css';

const MultiSelectSearchView = (props) => (
  <div
    className={`ts-component-multi-select ${props.placeholderState} ${
      props.isMissing ? 'is-missing' : ''
    } ts-${props.placeholder.replace(/\s+/g, '')}-dropdown
    `}
  >
    <div className="faux-placeholder" data-qa={props.placeholderDataQa}>
      {props.placeholder}
    </div>
    <Dropdown
      onBlur={props.handleBlur}
      onFocus={props.handleTouch}
      options={props.options}
      onChange={props.handleChange}
      value={props.selected}
      fluid
      multiple
      search
      basic
    />
  </div>
);

MultiSelectSearchView.propTypes = {
  handleBlur: PropTypes.func,
  handleTouch: PropTypes.func,
  handleChange: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.array,
  isMissing: PropTypes.bool,
  placeholderDataQa: PropTypes.string,
};

export default MultiSelectSearchView;
