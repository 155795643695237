import { FunctionComponent } from 'react';
import { View, styled, ExtraBig, Tooltip, COLORS } from '@talkspace/react-toolkit';
import { Goal } from './types';
import { progressIcons } from './utils';

interface GoalsProps {
  goals: Goal[];
}

const GoalWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontFamily: 'Roboto',
    color: colors.black,
    marginTop: 10,
  };
});

const GoalIconWrapper = styled(View)({
  width: '13%',
});

const GoalText = styled(View)({
  width: '82%',
});

const Goals: FunctionComponent<GoalsProps> = ({ goals }) => (
  <View>
    {goals.length > 0 && <ExtraBig>Goals</ExtraBig>}
    {goals.map((goal) => {
      const progressIcon =
        progressIcons[goal.overallProgress ? goal.overallProgress : 'no_progress'];
      return (
        <GoalWrapper key={goal.id}>
          <GoalIconWrapper>
            <Tooltip
              messageStyle={{
                backgroundColor: `${COLORS.white} !important`,
                color: `${COLORS.veryDarkGray} !important`,
                border: `1px solid ${COLORS.veryLightGray}`,
                boxShadow: `-3px 3px 3px ${COLORS.transparentLightGray}`,
                paddingLeft: '20px',
              }}
              tip={progressIcon.iconTooltipText}
            >
              {progressIcon.icon}
            </Tooltip>
          </GoalIconWrapper>
          <GoalText>{goal.description}</GoalText>
        </GoalWrapper>
      );
    })}
  </View>
);
export default Goals;
