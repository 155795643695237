import { VoidFunctionComponent, useState, useMemo, useCallback } from 'react';
import {
  Standard,
  FloatingMenu,
  useWindowWidthState,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { TasksResponse } from 'hooks/dashboard/useQueryTaskList';
import useMutationUpdateTask from 'hooks/dashboard/useMutationUpdateTask';
import { taskActionEnum, providerSnoozeReasonEnum } from '../../types';
import trackEvent from '../../../../modules/analytics/trackEvent';
import SnoozeOptionsScreen from './screens/SnoozeOptionsScreen';
import SnoozeDescriptionScreen from './screens/SnoozeDescriptionScreen';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';

interface TaskActionButtonsProps {
  note: TasksResponse;
  isHidden: boolean;
  handleCloseMenu: () => void;
}

const getFloatingMenuStyle = (isMobile: boolean) => {
  return {
    top: 27,
    left: 'auto',
    right: isMobile ? 0 : -10,
    width: 306,
    height: 'fit-content',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 17,
    paddingBottom: 8,
  };
};

const TaskActionSnoozeContainer: VoidFunctionComponent<TaskActionButtonsProps> = ({
  note,
  isHidden,
  handleCloseMenu,
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [snoozeReason, setSnoozeReason] = useState<string>('');
  const [providerSnoozeDescription, setProviderSnoozeDescription] = useState<string>('');
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const { mutate: updateTask } = useMutationUpdateTask();

  const { taskID, therapistID } = note;

  const handleSubmitSnooze = useCallback(() => {
    const baseBody: { eventDate: string; type: string; taskID: number } = {
      eventDate: new Date().toISOString(),
      type: 'progressNote',
      taskID: Number(taskID),
    };
    updateTask({
      action: taskActionEnum.SNOOZE,
      snoozeReason,
      providerSnoozeDescription,
      therapistID,
      taskID,
    });
    trackEvent(
      'taskClick',
      { actionName: 'providerTaskInteraction' },
      {
        ...baseBody,
        ...(snoozeReason && { reason: snoozeReason }),
        action: 'snooze',
      }
    );
  }, [providerSnoozeDescription, snoozeReason, taskID, therapistID, updateTask]);

  useDidUpdateEffect(() => {
    if (
      [providerSnoozeReasonEnum.TECHNICAL_ISSUE, providerSnoozeReasonEnum.OTHER].includes(
        snoozeReason
      )
    ) {
      setActiveStep((prevStep) => prevStep + 1);
    } else if (snoozeReason !== '') {
      handleSubmitSnooze();
    }
  }, [snoozeReason]);

  const mappedMenuItems = useMemo(
    () =>
      new Map([
        [providerSnoozeReasonEnum.NOT_ENOUGH_ENGAGEMENT, { label: 'Not enough engagement' }],
        [
          providerSnoozeReasonEnum.TECHNICAL_ISSUE,
          {
            label: 'Technical issue',
            content: (
              <>
                <Standard inline>Describe the technical issue you’re experiencing</Standard>{' '}
                <Standard inline style={{ color: colors.slateGrey, fontWeight: 400 }}>
                  (optional)
                </Standard>
              </>
            ),
            height: 260,
          },
        ],
        [providerSnoozeReasonEnum.NO_TREATMENT_GOALS, { label: 'Didn’t cover treatment goals' }],
        [
          providerSnoozeReasonEnum.OTHER,
          {
            label: 'Other',
            content: <Standard>Describe your reason for snoozing</Standard>,
            disableSubmit: providerSnoozeDescription.length === 0,
            height: 247,
          },
        ],
      ]),
    [colors.slateGrey, providerSnoozeDescription.length]
  );

  const handleReset = useCallback(() => {
    handleCloseMenu();
    setActiveStep(0);
    setSnoozeReason('');
    setProviderSnoozeDescription('');
  }, [handleCloseMenu]);

  const getStepContent = useCallback(
    (step: number) => {
      switch (step) {
        case 0:
          return <SnoozeOptionsScreen menuItems={mappedMenuItems} handleClick={setSnoozeReason} />;
        case 1: {
          const menuItem = mappedMenuItems.get(snoozeReason);
          return (
            menuItem && (
              <SnoozeDescriptionScreen
                content={menuItem.content}
                disableSubmit={menuItem.disableSubmit}
                providerSnoozeDescription={providerSnoozeDescription}
                handleProviderSnoozeDescription={setProviderSnoozeDescription}
                handleReset={handleReset}
                handleSubmitSnooze={handleSubmitSnooze}
              />
            )
          );
        }
        default:
          return null;
      }
    },
    [
      setSnoozeReason,
      handleReset,
      handleSubmitSnooze,
      mappedMenuItems,
      providerSnoozeDescription,
      snoozeReason,
    ]
  );

  const floatingMenuStyle = getFloatingMenuStyle(isMobile);

  return (
    <FloatingMenu style={floatingMenuStyle} hide={isHidden} onBackdropPress={handleReset}>
      {getStepContent(activeStep)}
    </FloatingMenu>
  );
};

export default TaskActionSnoozeContainer;
