import { FunctionComponent, useState } from 'react';
import { View, styled, Small } from '@talkspace/react-toolkit';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import { useHistory, useParams, Link } from '@/core/routerLib';
import ThreeDots from '../../../../Icons/ThreeDots';
import Goals from './Goals';
import Objectives from './Objectives';
import { TreatmentPlannerProps, PresentingProblem } from './types';
import MultiSelectSearch from '../../../../Reusable/MultiSelectSearch/MultiSelectSearch';

const TreatmentPlannerMessageWrapper = styled(View)({ display: 'inline' });

const TreatmentPlannerV2: FunctionComponent<TreatmentPlannerProps> = ({ treatmentPlan }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const { roomID } = useParams<{ roomID: string }>();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLine } = useQueryBusinessLine(roomID, clientUserID);
  const history = useHistory();
  const adapterPresentingProblemOptions = (options: PresentingProblem[]) =>
    options.map(({ id, text }) => {
      return {
        key: id,
        value: text,
        text,
      };
    });

  const { goals, objectives, presentingProblems, archived, id: treatmentPlanId } = treatmentPlan;

  const getDisplayValueObjectMultiSelectSearch = (values: PresentingProblem[]) => {
    const displayOption = values.map((value) => value.text);
    return displayOption;
  };

  const mouseEnterHandler = () => {
    setIsHover(true);
  };

  const mouseLeaveHandler = () => {
    setIsHover(false);
  };

  const actions = [
    {
      text: 'Edit plan',
      method: () => {
        history.push(`/room/${roomID}/treatment-planner-goals`);
      },
      cssClass: 'default',
      dataQa: 'editTreatmentPlanLink',
    },
    {
      text: 'Create new plan',
      method: () => {
        history.push(`/room/${roomID}/create-new-treatment-plan`);
      },
      cssClass: 'success',
      dataQa: 'createTreatmentPlanLink',
    },
  ];
  return (
    <View>
      {treatmentPlan && (
        <View>
          {businessLine && (
            <TreatmentPlannerMessageWrapper>
              To record new progress on the treatment planner
              <Link to="notes-tab" data-qa="createProgressNoteLink">
                <Small variant="smallBoldGreen" style={{ display: 'inline' }}>
                  {businessLine.isEAP || businessLine.isBH
                    ? ' create a session summary note'
                    : ' create a progress note'}
                </Small>
              </Link>
            </TreatmentPlannerMessageWrapper>
          )}

          <View
            key={1}
            className="treatment-planner-multiselect grayed"
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
          >
            <MultiSelectSearch
              placeholder="Presenting problems"
              options={adapterPresentingProblemOptions(presentingProblems)}
              selected={getDisplayValueObjectMultiSelectSearch(presentingProblems)}
              placeholderDataQa="presentingProblemsPlaceholder"
            />
            <View className={archived ? 'list-item-dropdown hidden' : 'list-item-dropdown'}>
              <DropdownButton
                bsStyle="link"
                title={<ThreeDots active={isHover || isOpen} style={{}} />}
                noCaret
                id="list-item-dropdown"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                open={isOpen}
                data-qa="treatmentPlanActionsButton"
              >
                {actions.map((action, key) => (
                  <MenuItem
                    key={action.text}
                    eventKey={key}
                    onSelect={() => action.method()}
                    className={action.cssClass}
                    data-qa={action.dataQa}
                  >
                    {action.text}
                  </MenuItem>
                ))}
              </DropdownButton>
            </View>
          </View>
          <Goals goals={goals} />
          <Objectives objectives={objectives} treatmentPlannerID={treatmentPlanId} />
        </View>
      )}
    </View>
  );
};

export default TreatmentPlannerV2;
