import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  A11yPhoneInput,
  isPhoneNumberValid,
  Select,
  TextInput,
  WindowWidthStateContext,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import { teenContactRelationship } from '@talkspace/configs';
import {
  getCustomerInformation,
  setCustomerInformation,
} from '../../../../../actions/CustomerInformationActions';
import {
  getEmergencyContact,
  setEmergencyContact,
} from '../../../../../actions/EmergencyContactActions';
import UserBadge from '../../../../Reusable/UserBadge/UserBadge';
import AccordionContainer from '../../../../Reusable/AccordionContainer/AccordionContainer';
import SocketService from '../../../../../utils/socket/SocketService';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';
import { checkIfShouldBeSearchable } from '../../../../../utils/optionsHelpers';

const MIN_CLIENT_AGE = 13;
const MAX_CLIENT_AGE = 120;

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateOfBirthIsValid: true,
      phoneNumber: '',
      contactPhoneNumber: '',
      phoneEditedByProvider: false,
      isPhoneNumberError: false,
      isContactPhoneNumberError: false,
    };
    const { clientUserID } = this.props.customerInformation;
    if (clientUserID) this.props.getEmergencyContact(clientUserID);
  }

  componentDidMount = () => {
    SocketService.instance().on('submittedIntakeSurvey', this.handleSubmittedIntakeSurveyEvent);
  };

  componentWillUnmount() {
    SocketService.instance().off('submittedIntakeSurvey', this.handleSubmittedIntakeSurveyEvent);
  }

  getClientDisplayName = (clientUserID) => {
    const {
      customerInformation: { preferredName },
      customerList,
    } = this.props;
    return preferredName === null
      ? customerList.find((client) => +client.userID === +clientUserID).displayName
      : preferredName;
  };

  createHandleEmergencyContactFieldOnBlur = (field) => {
    const {
      emergencyContact,
      setEmergencyContact,
      customerInformation: { clientUserID },
    } = this.props;
    return (e) => {
      let { value } = e.target;
      if (field === 'phone') {
        value = this.state.phoneNumber;
        const isPhoneNumberError = this.state.phoneNumber && !isPhoneNumberValid(value);
        this.setState({ isPhoneNumberError });
        if (isPhoneNumberError) return;
      } else if (field === 'contactPhone') {
        value =
          this.state.contactPhoneNumber ||
          emergencyContact?.emergencyContactByClientUserID[clientUserID]?.contactPhone;
        const isContactPhoneNumberError =
          this.state.contactPhoneNumber && !isPhoneNumberValid(value);
        this.setState({ isContactPhoneNumberError });
        if (isContactPhoneNumberError) return;
      }

      setEmergencyContact(clientUserID, { [field]: value });
    };
  };

  createHandleEmergencyContactSelectOnChange = (field, sideEffect) => {
    const {
      setEmergencyContact,
      customerInformation: { clientUserID },
    } = this.props;
    return (selectObject) => {
      setEmergencyContact(clientUserID, {
        [field]: selectObject,
        ...(sideEffect && sideEffect(selectObject)),
      });
    };
  };

  createHandleSelectChange = (field, sideEffect) => {
    const {
      setCustomerInformation,
      customerInformation: { clientUserID },
    } = this.props;
    return (selectObject) => {
      setCustomerInformation(clientUserID, {
        [field]: selectObject,
        ...(sideEffect && sideEffect(selectObject)),
      });
    };
  };

  createHandleTextInputBlur = (field) => {
    const {
      setCustomerInformation,
      customerInformation: { clientUserID },
    } = this.props;
    return (e) => {
      setCustomerInformation(clientUserID, {
        [field]: e.target.value,
      });
    };
  };

  createUserButtons = (customerList) => {
    const {
      customerInformation: { clientUserID },
      getCustomerInformation,
    } = this.props;
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {customerList.map((customer, index) => (
          <UserBadge
            key={index}
            onClick={() => {
              if (customer.userID !== clientUserID) this.handleUserToggle(customer.userID);
            }}
            preferredName={customer.displayName}
            active={customer.userID === clientUserID}
          />
        ))}
      </div>
    );
  };

  handleCountrySideEffect = (selectObject) => {
    if (!selectObject || selectObject.value !== 'US') {
      return { state: null };
    }
    return {};
  };

  handleDateOfBirthOnBlur = (e) => {
    const {
      setCustomerInformation,
      customerInformation: { clientUserID, dateOfBirth },
    } = this.props;
    const format = 'MM/DD/YYYY';
    const { value } = e.target;
    if (!value) {
      this.setState({ dateOfBirthIsValid: true });
      if (value !== dateOfBirth) {
        setCustomerInformation(clientUserID, { dateOfBirth: value });
      }
      return;
    }
    if (!moment(value, format).isValid()) {
      this.setState({ dateOfBirthIsValid: false });
      return;
    }
    const years = moment().diff(moment(value, format), 'years');
    if (years >= MIN_CLIENT_AGE && years <= MAX_CLIENT_AGE) {
      this.setState({ dateOfBirthIsValid: true });
      setCustomerInformation(clientUserID, {
        dateOfBirth: moment(value, format).format('Y-MM-DD'),
      });
    } else {
      this.setState({ dateOfBirthIsValid: false });
    }
  };

  handleOnChangePhoneNumber = (phoneNumber) =>
    this.setState({ phoneNumber, phoneEditedByProvider: true });

  handleOnChangeContactPhoneNumber = (contactPhoneNumber) => this.setState({ contactPhoneNumber });

  handleSubmittedIntakeSurveyEvent = (data) => {
    const {
      customerInformation: { clientUserID },
      getCustomerInformation,
      getEmergencyContact,
      roomID,
    } = this.props;

    if (Number(data.roomID) !== Number(roomID)) {
      return;
    }

    getCustomerInformation(clientUserID);
    getEmergencyContact(clientUserID);
  };

  handleUserToggle = (clientUserID) => {
    this.props.getCustomerInformation(clientUserID);
    this.props.getEmergencyContact(clientUserID);
  };

  isDisabled = (componentName) => {
    const { country } = this.props.customerInformation;
    if (componentName === 'state') {
      return country && country.value !== 'US';
    }
    return false;
    // return disabledComponents.indexOf(componentName) >= 0 || isRequestingCustomer;
  };

  render() {
    const {
      customerInformation: {
        clientUserID,
        gender,
        dateOfBirth,
        country,
        state,
        relationshipStatus,
        educationLevel,
        workStatus,
        ethnicity,
      },
      emergencyContact,
      customerList,
      parentalConsent,
    } = this.props;

    const clientEmergencyContact =
      emergencyContact.emergencyContactByClientUserID[clientUserID] || {};
    const {
      firstName,
      middleName,
      lastName,
      address,
      address2,
      city,
      zipcode,
      phone,
      contactName,
      contactPhone,
      relationship,
    } = clientEmergencyContact;
    const { dateOfBirthIsValid } = this.state;
    const preferredName = this.getClientDisplayName(clientUserID);
    const showUserButtons = !!customerList && !!customerList.length && customerList.length > 1;
    const showIndicatorBeforeClick = this.context.isMobile;
    return (
      <AccordionContainer title="Customer Information">
        {showUserButtons && this.createUserButtons(customerList)}
        <TextInput
          value={preferredName}
          placeholder="Preferred name"
          onBlur={this.createHandleTextInputBlur('preferredName')}
          disabled={this.isDisabled('preferredName')}
        />
        <TextInput
          value={firstName}
          placeholder="First name"
          onBlur={this.createHandleEmergencyContactFieldOnBlur('firstName')}
        />
        <TextInput
          value={middleName}
          placeholder="Middle name"
          onBlur={this.createHandleEmergencyContactFieldOnBlur('middleName')}
        />
        <TextInput
          value={lastName}
          placeholder="Last name"
          onBlur={this.createHandleEmergencyContactFieldOnBlur('lastName')}
        />
        <Select
          value={gender}
          options={getOptionsByField('gender')}
          placeholder="Gender"
          onChange={this.createHandleSelectChange('gender')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={checkIfShouldBeSearchable('gender')}
        />
        <TextInput
          placeholder="Date of birth"
          value={(dateOfBirth && moment(dateOfBirth).format('MM/DD/Y')) || undefined}
          isValid={dateOfBirthIsValid}
          onBlur={this.handleDateOfBirthOnBlur}
          maskType="date"
          alwaysShowPlaceholder
        />
        <Select
          value={country}
          options={getOptionsByField('country')}
          placeholder="Country"
          onChange={this.createHandleSelectChange('country', this.handleCountrySideEffect)}
          isDisabled={this.isDisabled('country')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={checkIfShouldBeSearchable('country')}
        />
        <TextInput
          value={address}
          placeholder="Street address"
          onBlur={this.createHandleEmergencyContactFieldOnBlur('address')}
          multiline
        />
        <TextInput
          value={address2}
          placeholder="Street address line 2"
          onBlur={this.createHandleEmergencyContactFieldOnBlur('address2')}
          multiline
        />
        <TextInput
          value={city}
          placeholder="City"
          onBlur={this.createHandleEmergencyContactFieldOnBlur('city')}
        />
        <Select
          value={state}
          options={getOptionsByField('state')}
          placeholder="State"
          onChange={this.createHandleSelectChange('state')}
          isDisabled={this.isDisabled('state')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={checkIfShouldBeSearchable('state')}
        />
        <TextInput
          value={zipcode}
          placeholder="Zipcode"
          onBlur={this.createHandleEmergencyContactFieldOnBlur('zipcode')}
          maskType="zipcode"
          alwaysShowPlaceholder
        />
        <A11yPhoneInput
          height={33}
          dataQa="customerInformationPhoneInput"
          value={phone || this.state.phoneNumber}
          disabled={!!phone && !this.state.phoneEditedByProvider}
          onBlur={this.createHandleEmergencyContactFieldOnBlur('phone')}
          handleInputValueChange={this.handleOnChangePhoneNumber}
          isError={this.state.isPhoneNumberError}
          isTherapistCRM
        />
        <Select
          value={relationshipStatus}
          options={getOptionsByField('relationshipStatus')}
          placeholder="Relationship Status"
          onChange={this.createHandleSelectChange('relationshipStatus')}
          isDisabled={this.isDisabled('relationshipStatus')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={checkIfShouldBeSearchable('relationshipStatus')}
        />
        <Select
          value={educationLevel}
          options={getOptionsByField('educationLevel')}
          placeholder="Level of education"
          onChange={this.createHandleSelectChange('educationLevel')}
          isDisabled={this.isDisabled('educationLevel')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={checkIfShouldBeSearchable('educationLevel')}
        />
        <Select
          value={workStatus}
          options={getOptionsByField('workStatus')}
          placeholder="Work status"
          onChange={this.createHandleSelectChange('workStatus')}
          isDisabled={this.isDisabled('workStatus')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={checkIfShouldBeSearchable('workStatus')}
        />
        <Select
          value={ethnicity}
          options={getOptionsByField('ethnicity')}
          placeholder="Race or ethinicity"
          onChange={this.createHandleSelectChange('ethnicity')}
          isDisabled={this.isDisabled('ethnicity')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={checkIfShouldBeSearchable('ethnicity')}
        />
        {parentalConsent && (
          <>
            <div className="ts-component-accordion-container-title" style={{ marginTop: 40 }}>
              Parent or guardian contact details
            </div>
            <TextInput
              value={`${parentalConsent?.consenterFirstName || ''} ${
                parentalConsent?.consenterLastName || ''
              }`}
              placeholder="Parent or guardian's full name"
              disabled
            />
            <TextInput
              value={
                parentalConsent?.consenterRelationship &&
                teenContactRelationship[parentalConsent?.consenterRelationship]
              }
              placeholder="Relationship to client"
              disabled
            />
            <TextInput
              value={parentalConsent?.consenterEmail}
              placeholder="Parent or guardian's email address"
              disabled
            />
          </>
        )}
        <div className="ts-component-accordion-container-title" style={{ marginTop: 40 }}>
          Emergency contact details
        </div>
        <TextInput
          value={contactName}
          placeholder="Emergency contact's full name"
          onBlur={this.createHandleEmergencyContactFieldOnBlur('contactName')}
        />
        <Select
          value={relationship || null}
          options={getOptionsByField('relationship')}
          placeholder="Relationship to client"
          onChange={this.createHandleEmergencyContactSelectOnChange('relationship')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={checkIfShouldBeSearchable('relationship')}
        />
        <A11yPhoneInput
          height={33}
          dataQa="customerInformationEmergencyContactPhoneInput"
          value={contactPhone || this.state.contactPhoneNumber}
          onBlur={this.createHandleEmergencyContactFieldOnBlur('contactPhone')}
          handleInputValueChange={this.handleOnChangeContactPhoneNumber}
          label="Emergency contact's phone number"
          isError={this.state.isContactPhoneNumberError}
          isTherapistCRM
        />
      </AccordionContainer>
    );
  }
}
Customer.contextType = WindowWidthStateContext;
Customer.propTypes = {
  customerInformation: PropTypes.any,
  getCustomerInformation: PropTypes.func,
  setCustomerInformation: PropTypes.func,
  customerList: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    customerList: state.customerList.list,
    emergencyContact: state.emergencyContact,
    roomID: state.room.roomID,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setCustomerInformation, getCustomerInformation, getEmergencyContact, setEmergencyContact },
    dispatch
  );
const CustomerInformationContainer = connect(mapStateToProps, mapDispatchToProps)(Customer);

export default CustomerInformationContainer;
