export type DiagnosticProfileLabel =
  | 'Anxiety'
  | 'Bipolar Disorder'
  | 'Borderline Personality Disorder'
  | 'Depression'
  | 'Eating Disorder'
  | 'Generalized Anxiety Disorder'
  | 'Non-Borderline Personality Disorder'
  | 'Obsessive-Compulsive Disorder'
  | 'Panic Disorder'
  | 'Substance Abuse'
  | 'Trauma';

export interface ClientDiagnosticProfile {
  label: DiagnosticProfileLabel;
  value: number;
}

export interface ClientDiagnosticProfiles {
  [key: string]: ClientDiagnosticProfile[];
}

export interface DiagnosticProfileState {
  diagnosticProfiles: ClientDiagnosticProfiles;
  clientUserID: string | undefined;
  errorMessage: string;
  isLoading: boolean;
  isError: boolean;
}

export const REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES = 'REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES';
export const RECEIVE_ALL_CLIENTS_DIAGNOSTIC_PROFILES = 'RECEIVE_ALL_CLIENTS_DIAGNOSTIC_PROFILES';
export const REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES_ERROR =
  'REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES_ERROR';

export const SET_CURRENT_DIAGNOSTIC_PROFILE_CLIENT = 'SET_CURRENT_DIAGNOSTIC_PROFILE_CLIENT';

interface DiagnosticProfileAction {
  type:
    | typeof REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES
    | typeof RECEIVE_ALL_CLIENTS_DIAGNOSTIC_PROFILES
    | typeof REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES_ERROR
    | typeof SET_CURRENT_DIAGNOSTIC_PROFILE_CLIENT;
  payload?: Partial<DiagnosticProfileState>;
  error?: Error;
}

export interface DiagnosticProfileErrorAction extends DiagnosticProfileAction {
  type: typeof REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES_ERROR;
  error: Error;
}

interface RequestGetDiagnosticProfiles {
  type: typeof REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES;
}

interface ReceiveGetDiagnosticProfiles extends DiagnosticProfileAction {
  type: typeof RECEIVE_ALL_CLIENTS_DIAGNOSTIC_PROFILES;
  payload: Pick<DiagnosticProfileState, 'diagnosticProfiles'>;
}

export type DiagnosticProfileActionTypes =
  | DiagnosticProfileAction
  | DiagnosticProfileErrorAction
  | RequestGetDiagnosticProfiles
  | ReceiveGetDiagnosticProfiles;
