import { useEffect } from 'react';

import ReviewsSummary from '../components/ReviewsSummary';
import { usePerformanceActions, usePerformanceState } from '../hooks/performanceContext';

const ReviewsSummaryContainer = () => {
  const { reviewMetrics, isError, isLoading } = usePerformanceState();
  const { dispatchGetReviewMetrics } = usePerformanceActions();

  useEffect(() => {
    dispatchGetReviewMetrics();
  }, [dispatchGetReviewMetrics]);

  return <ReviewsSummary data={reviewMetrics} isLoading={!!isLoading} isError={!!isError} />;
};

export default ReviewsSummaryContainer;
