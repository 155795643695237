import {
  View,
  Text,
  Spinner,
  Large,
  Small,
  Huge,
  RatingStarsField,
  BricksField,
  useWindowWidthState,
  useEmotionTheme,
} from '@talkspace/react-toolkit';

import styled from '@/core/styled';

import { ReviewMetricsRecord } from '../types';
import { getRoundedPercentileText } from '../utils/metricsHelpers';

const Container = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    const mobileStyles = isMobile
      ? {
          padding: '30px 10px',
          margin: '0px 10px',
        }
      : {};
    return {
      backgroundColor: colors.white,
      padding: '30px 20px',
      overflowY: 'visible',
      borderColor: colors.extraLightGrey,
      borderWidth: '1px 1px 0 1px',
      borderStyle: 'solid',
      margin: '0px 20px',
      borderRadius: '5px 5px 0 0',
      ...mobileStyles,
    };
  }
);

const CenterView = styled(View)({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
});

const ReviewsSummaryContainer = styled(View)(() => {
  return {
    padding: 0,
  };
});
const ReviewsSummaryHeaderRow = styled(View)({
  paddingBottom: 15,
});
const ReviewsSummaryRow = styled(View)({
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
});
const ReviewsSummaryCol = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: isMobile ? 0 : 10,
      paddingRight: 40,
    };
  }
);
const PercentileText = styled(Large)(({ color }: { color: string }) => {
  return {
    color,
    fontSize: 14,
    paddingTop: 8,
  };
});
const BrickListHeading = styled(Small)(({ theme: { colors } }) => {
  return {
    paddingBottom: 9,
    color: colors.black,
    fontWeight: 900,
  };
});

interface ReviewsSummaryViewProps {
  data: ReviewMetricsRecord | null | undefined;
  isLoading: boolean;
  isError: boolean;
}

const getSummaryColor = (value: number | null, colors: { [key: string]: string }) => {
  if (!value) return colors.baliHaiGrey;
  if (value < 3) return colors.jellybeanRed;
  if (value < 4.5) return colors.yellowSun;
  if (value <= 5) return colors.limaGreen;
  return colors.baliHaiGrey;
};

const ReviewsSummaryView = ({ data, isLoading, isError }: ReviewsSummaryViewProps) => {
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const renderSwitch = () => {
    const defaultMessage = <CenterView>Something went wrong, please try again later</CenterView>;
    if (isError) {
      return defaultMessage;
    }
    if (isLoading) {
      return (
        <CenterView>
          <Text>Loading review metrics...</Text>
          <Spinner isLoading />
        </CenterView>
      );
    }
    if (data) {
      const { averageRating, percentile, qualities, improvements } = data;
      const title = getRoundedPercentileText(percentile);
      const color = getSummaryColor(averageRating, colors);
      return (
        <ReviewsSummaryContainer>
          <ReviewsSummaryHeaderRow>
            <Huge>Average rating from clients in the last 30 days</Huge>
          </ReviewsSummaryHeaderRow>
          <ReviewsSummaryRow row={!isMobile} justify="space-between">
            <ReviewsSummaryCol style={{ paddingTop: 0, paddingRight: isMobile ? 10 : 92 }}>
              <RatingStarsField initialRating={averageRating || 0} color={color} displayRating />
              {percentile && (
                <PercentileText variant="largeBoldWide" color={color}>
                  {title}
                </PercentileText>
              )}
            </ReviewsSummaryCol>
            <ReviewsSummaryCol style={isMobile ? { paddingTop: 20 } : {}}>
              <BrickListHeading>Qualities your clients appreciate most</BrickListHeading>
              {qualities && qualities.length ? (
                <BricksField bricks={qualities} brickColor={colors.limaGreen} />
              ) : (
                <Huge variant="hugeLightGrey">-</Huge>
              )}
            </ReviewsSummaryCol>
            <ReviewsSummaryCol style={isMobile ? { paddingTop: 20 } : {}}>
              <BrickListHeading>Areas for improvement</BrickListHeading>
              {improvements && improvements.length ? (
                <BricksField bricks={improvements} brickColor={colors.jellybeanRed} />
              ) : (
                <Huge variant="hugeLightGrey">-</Huge>
              )}
            </ReviewsSummaryCol>
          </ReviewsSummaryRow>
        </ReviewsSummaryContainer>
      );
    }

    return defaultMessage;
  };

  return <Container>{renderSwitch()}</Container>;
};

export default ReviewsSummaryView;
