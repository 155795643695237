import { Mini, Small } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import moment from 'moment';
import { DashboardRoomStatus } from 'components/Dashboard/types';

interface StatusV4Props {
  renewDate: Date;
  planEnd: Date;
  roomStatus: DashboardRoomStatus;
}

const StatusV4: VoidFunctionComponent<StatusV4Props> = ({ renewDate, planEnd, roomStatus }) => {
  let renewContent = '';

  if (renewDate) {
    renewContent = `Renew: ${moment(renewDate).format('MMM DD')}`;
  }

  if (planEnd) {
    renewContent = `Plan end: ${moment(planEnd).format('MMM DD')}`;
  }

  return (
    <>
      <Small variant="smallTSBlack">{roomStatus}</Small>
      <Mini>{renewContent}</Mini>
    </>
  );
};

export default StatusV4;
