import { connect } from 'react-redux';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useQueryParentalConsent from 'ts-frontend/hooks/parentalConsent/useQueryParentalConsent';
import AccordionContainer from '../../../Reusable/AccordionContainer/AccordionContainer';
import AccordionMenuItem from '../../../Reusable/AccordionMenuItem/AccordionMenuItem';
import CustomerInformationContainer from './CustomerInformation/CustomerInformationContainer';
import MarketingContainer from './Marketing/MarketingContainer';
import PlanInformation from './Plan/PlanInformation';
import { useParams } from '../../../../core/routerLib';

function CaseTabComponent({
  isEAP,
  isQuickmatch,
  therapistType,
  openStarredMessagesHandler,
  openSharedFilesHandler,
  roomStatus,
  isClientDischarged,
}) {
  const { roomID } = useParams();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLineData } = useQueryBusinessLine(roomID, clientUserID);
  const { data: parentalConsent } = useQueryParentalConsent(clientUserID);
  return (
    <div className="ts-crm-panel">
      <PlanInformation />
      <CustomerInformationContainer parentalConsent={parentalConsent} />
      <MarketingContainer />
      {therapistType === 'consultation_and_primary' || therapistType === 'consultation' ? (
        <AccordionContainer
          title="Matching actions"
          childComponents={[
            <AccordionMenuItem
              key={1}
              path="case-tab"
              view="matching-intake"
              title="Send matching intake"
            />,
            <AccordionMenuItem
              key={2}
              path="case-tab"
              view="suggest-provider"
              title="Suggest a provider"
            />,
            <AccordionMenuItem
              key={3}
              path="case-tab"
              view="switch-provider"
              title="Switch a provider"
            />,
            roomStatus === 5 && (
              <AccordionMenuItem
                key={4}
                path="case-tab"
                view="change-room-type"
                title="Change room type"
              />
            ),
          ]}
        />
      ) : null}
      <AccordionContainer
        title="Actions"
        childComponents={[
          <AccordionMenuItem
            key={12}
            title="View starred messages"
            clickHandler={openStarredMessagesHandler}
            dataQa="viewStarredMessagesButton"
          />,
          <AccordionMenuItem
            key={13}
            title="View shared files"
            clickHandler={openSharedFilesHandler}
            dataQa="viewSharedFilesButton"
          />,
          <AccordionMenuItem
            key={11}
            path="case-tab"
            view="intake-questionnaire"
            title="Treatment intake questionnaire"
            dataQa="treatmentIntakeQuestionnaireButton"
          />,
          <AccordionMenuItem
            key={14}
            path="case-tab"
            view="eligibility-questionnaire"
            title="Eligibility questionnaire"
            dataQa="eligibilityQuestionnaireButton"
          />,
          <AccordionMenuItem
            key={1}
            path="case-tab"
            view="informed-consent"
            title="Informed consent"
            dataQa="informedConsentButton"
          />,
          <AccordionMenuItem
            key={2}
            path="case-tab"
            view="emergency-contact-login"
            title="Emergency contact login"
            dataQa="emergencyContactLoginButton"
          />,
          <AccordionMenuItem
            key={4}
            path="case-tab"
            view="invites"
            title="Room invitations"
            dataQa="roomInvitationsButton"
          />,
          <AccordionMenuItem
            key={5}
            path="case-tab"
            view="create-room"
            title="Create new room"
            dataQa="createNewRoomButton"
          />,
          <AccordionMenuItem
            key={8}
            path="case-tab"
            view="video-credits"
            title="Live session credits"
            dataQa="liveSessionCreditsButton"
          />,
          <AccordionMenuItem
            key={9}
            hidden={!isQuickmatch}
            path="case-tab"
            view="quick-match-answers"
            title="View QuickMatch answers"
            dataQa="viewQuickMatchAnswersButton"
          />,
          <AccordionMenuItem
            key={15}
            hidden={therapistType !== 'psychiatrist'}
            path="case-tab"
            view="discharge-and-transfer"
            title="Transfer client"
            dataQa="transferClientButton"
          />,
          !!businessLineData?.isBH && isClientDischarged && (
            <AccordionMenuItem
              key={16}
              path="case-tab"
              view="restart-care"
              title="Restart care"
              dataQa="restartCareButton"
            />
          ),
        ]}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    roomStatus: state.room.status,
  };
};

const CaseTab = connect(mapStateToProps, null)(CaseTabComponent);

export default CaseTab;
