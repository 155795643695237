import * as actionTypes from '../constants/MatchingConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

function mapConfigFieldToSelectField(config) {
  return config.map(({ value, text }) => {
    return { value, label: text };
  });
}

export function requestAvailableTherapists(roomID) {
  return {
    type: actionTypes.REQUEST_AVAILABLE_THERAPISTS,
    roomID,
  };
}

export function requestAvailableTherapistsError(error) {
  return {
    type: actionTypes.REQUEST_AVAILABLE_THERAPISTS_ERROR,
    error,
  };
}

export function receiveAvailableTherapists(availableTherapists) {
  return {
    type: actionTypes.RECEIVE_AVAILABLE_THERAPISTS,
    availableTherapists,
  };
}

export function requestSendIntakeMessage() {
  return {
    type: actionTypes.REQUEST_SEND_INTAKE_MESSAGE,
  };
}

export function requestSendIntakeMessageError(error) {
  return {
    type: actionTypes.REQUEST_SEND_INTAKE_MESSAGE_ERROR,
    error,
  };
}

export function receiveSendTreatmentIntakeMessage() {
  return {
    type: actionTypes.RECEIVE_SEND_TREATMENT_INTAKE_MESSAGE,
  };
}

export function receiveSendMatchingIntakeMessage() {
  return {
    type: actionTypes.RECEIVE_SEND_MATCHING_INTAKE_MESSAGE,
  };
}

export function requestIntakeSurveyStatus() {
  return {
    type: actionTypes.REQUEST_INTAKE_SURVEY_STATUS,
  };
}

export function requestIntakeSurveyStatusError(error) {
  return {
    type: actionTypes.REQUEST_INTAKE_SURVEY_STATUS_ERROR,
    error,
  };
}

export function receiveTreatmentIntakeSurveyStatus({ intakeStatus }) {
  return {
    type: actionTypes.RECEIVE_TREATMENT_INTAKE_SURVEY_STATUS,
    treatmentIntakeStatus: intakeStatus,
  };
}

export function receiveMatchingIntakeSurveyStatus({ intakeStatus }) {
  return {
    type: actionTypes.RECEIVE_MATCHING_INTAKE_SURVEY_STATUS,
    matchingIntakeStatus: intakeStatus,
  };
}

export function switchTherapist() {
  return {
    type: actionTypes.SWITCH_THERAPIST,
  };
}

export function switchTherapistSuccess() {
  return {
    type: actionTypes.SWITCH_THERAPIST_SUCCESS,
  };
}

export function switchTherapistError(error) {
  return {
    type: actionTypes.SWITCH_THERAPIST_ERROR,
    error,
  };
}

export function requestInPlatformMatchingOptions() {
  return {
    type: actionTypes.REQUEST_IN_PLATFORM_MATCHING_OPTIONS,
  };
}

export function receiveInPlatformMatchingOptions(
  specialties,
  treatmentPreferences,
  clientFocus,
  languages
) {
  const payload = {
    inPlatformMatchingOptions: {
      specialties: mapConfigFieldToSelectField(specialties),
      treatmentPreferences: mapConfigFieldToSelectField(treatmentPreferences),
      clientFocus: mapConfigFieldToSelectField(clientFocus),
      languages: mapConfigFieldToSelectField(languages),
    },
  };
  return {
    type: actionTypes.RECEIVE_IN_PLATFORM_MATCHING_OPTIONS,
    payload,
  };
}

export function receiveInPlatformMatchingOptionsError(error) {
  return {
    type: actionTypes.REQUEST_IN_PLATFORM_MATCHING_OPTIONS_ERROR,
    error,
  };
}

export function suggestingTherapists() {
  return {
    type: actionTypes.SUGGESTING_THERAPISTS,
  };
}

export function suggestTherapistsSuccess() {
  return {
    type: actionTypes.SUGGEST_THERAPISTS_SUCCESS,
  };
}

export function suggestTherapistsError(error) {
  return {
    type: actionTypes.SUGGEST_THERAPISTS_ERROR,
    error,
  };
}

export function noSuggestedTherapistsFound(error) {
  return {
    type: actionTypes.NO_SUGGESTED_THERAPISTS_FOUND,
    error,
  };
}

export function changingRoomType() {
  return {
    type: actionTypes.CHANGING_ROOM_TYPE,
  };
}

export function changeRoomTypeSuccess() {
  return {
    type: actionTypes.CHANGE_ROOM_TYPE_SUCCESS,
  };
}

export function changeRoomTypeError() {
  return {
    type: actionTypes.CHANGE_ROOM_TYPE_ERROR,
  };
}
export function requestSendEligibilityMessage() {
  return {
    type: actionTypes.REQUEST_SEND_ELIGIBILITY_MESSAGE,
  };
}

export function requestSendEligibilityMessageError() {
  return {
    type: actionTypes.REQUEST_SEND_ELIGIBILITY_MESSAGE_ERROR,
  };
}

export function receiveSendEligibilityMessage() {
  return {
    type: actionTypes.RECEIVE_SEND_ELIGIBILITY_MESSAGE,
  };
}

export const getFieldsOfExpertiseOptions = (therapistID) => (dispatch) => {
  dispatch(requestInPlatformMatchingOptions());
  const specialtiesPromise = apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/therapist/${therapistID}/clinical-expertise-info`
  );
  const treatmentPreferencesPromise = apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/therapist/${therapistID}/treatment-preferences-info`
  );
  const clientFocusPromise = apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/therapist/${therapistID}/client-focus-info`
  );
  const languagesPromise = apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/therapist/${therapistID}/language-info`
  );
  Promise.all([
    specialtiesPromise,
    treatmentPreferencesPromise,
    clientFocusPromise,
    languagesPromise,
  ])
    .then(
      ([
        specialtiesResponse,
        treatmentPreferencesResponse,
        clientFocusResponse,
        languagesResponse,
      ]) =>
        dispatch(
          receiveInPlatformMatchingOptions(
            specialtiesResponse.data.data.issues,
            treatmentPreferencesResponse.data.data,
            clientFocusResponse.data.data,
            languagesResponse.data.data.language
          )
        )
    )
    .catch((error) => dispatch(receiveInPlatformMatchingOptionsError(error)));
};

export const getAvailableTherapists = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestAvailableTherapists(roomID));

  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/available-therapists`)
    .then((response) => dispatch(receiveAvailableTherapists(response.data.data)))
    .catch((error) => dispatch(requestAvailableTherapistsError(error)));
};

export const switchRoomTherapist = (therapistID) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(switchTherapist());

  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}`, { therapistID })
    .then(() => dispatch(switchTherapistSuccess()))
    .catch((error) => dispatch(switchTherapistError(error)));
};

export const suggestTherapists = (requestBody) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(suggestingTherapists());
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/suggest-therapists`, requestBody)
    .then(() => dispatch(suggestTherapistsSuccess()))
    .catch((error) => {
      if (error.message === '404') {
        return dispatch(noSuggestedTherapistsFound(error));
      }
      return dispatch(suggestTherapistsError(error));
    });
};

export const changeRoomType = (roomType) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(changingRoomType());

  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}`, { roomType })
    .then(() => dispatch(changeRoomTypeSuccess()))
    .catch((error) => dispatch(changeRoomTypeError(error)));
};

export const postTreatmentIntakeSurveyMessage = () => (dispatch, getState) => {
  const {
    room: { roomID },
    customerInformation: { clientUserID },
  } = getState();
  dispatch(requestSendIntakeMessage());
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/intake/${clientUserID}?type=treatment`)
    .then(() => dispatch(receiveSendTreatmentIntakeMessage()))
    .catch((error) => dispatch(requestSendIntakeMessageError(error)));
};

export const postMatchingIntakeSurveyMessage = () => (dispatch, getState) => {
  const {
    room: { roomID },
    customerInformation: { clientUserID },
  } = getState();

  dispatch(requestSendIntakeMessage());
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/intake/${clientUserID}?type=matching`)
    .then(() => dispatch(receiveSendMatchingIntakeMessage()))
    .catch(() => dispatch(requestSendIntakeMessageError()));
};

export const getTreatmentIntakeSurveyStatus = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestIntakeSurveyStatus());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/intake-available?type=treatment`)
    .then((res) => dispatch(receiveTreatmentIntakeSurveyStatus(res.data.data)))
    .catch((error) => dispatch(requestIntakeSurveyStatusError(error)));
};

export const getMatchingIntakeSurveyStatus = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestIntakeSurveyStatus());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/intake-available?type=matching`)
    .then((res) => dispatch(receiveMatchingIntakeSurveyStatus(res.data.data)))
    .catch((error) => dispatch(requestIntakeSurveyStatusError(error)));
};

export const sendEligibilityMessage = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestSendEligibilityMessage());
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/eligibility/message`)
    .then(() => dispatch(receiveSendEligibilityMessage()))
    .catch((error) => dispatch(requestSendEligibilityMessageError(error)));
};
