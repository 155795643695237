import * as actionTypes from '../constants/VideoCallHistoryConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

export function requestVideoCallHistory(roomID) {
  return {
    type: actionTypes.REQUEST_VIDEO_CALL_HISTORY,
    roomID,
  };
}

export function receiveVideoCallHistory(videoCallHistory) {
  return {
    type: actionTypes.RECEIVE_VIDEO_CALL_HISTORY,
    videoCallHistory: videoCallHistory.data,
  };
}

export function requestVideoCallHistoryError(error) {
  return {
    type: actionTypes.REQUEST_VIDEO_CALL_HISTORY_ERROR,
    error,
  };
}

export const getVideoCallHistory = () => (dispatch, getState) => {
  const {
    room: { roomID },
    customerInformation: { clientUserID },
  } = getState();

  dispatch(requestVideoCallHistory(roomID));
  apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/video-calls?userID=${clientUserID}`)
    .then((response) => {
      dispatch(receiveVideoCallHistory(response.data));
    })
    .catch((error) => {
      dispatch(requestVideoCallHistoryError(error));
    });
};
