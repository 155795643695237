import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

function MetricsIcon({
  color,
  width = 22,
  height = 17,
  title,
  ...rest
}: {
  color?: string;
  width?: number;
  height?: number;
  title: string;
}) {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={`${width}px`} height={`${height}px`} viewBox="0 0 22 17" title={title} {...rest}>
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G transform="translate(-155.000000, -304.000000)">
          <G
            transform="translate(19.000000, 268.000000)"
            fill={colors.white}
            stroke={colors.heatherGrey}
            strokeWidth="0.5"
          >
            <Path d="M7,-0.25 L979,-0.25 C983.004064,-0.25 986.25,2.99593556 986.25,7 L986.25,596 C986.25,600.004064 983.004064,603.25 979,603.25 L7,603.25 C2.99593556,603.25 -0.25,600.004064 -0.25,596 L-0.25,7 C-0.25,2.99593556 2.99593556,-0.25 7,-0.25 Z" />
          </G>
          <Path
            d="M175,309 C176.1,309 177,309.9 177,311 C177,312.45 175.56,313.26 174.49,312.93 L174.49,312.93 L170.93,316.48 C171.27,317.56 170.45,319 169,319 C167.54,319 166.73,317.55 167.07,316.48 L167.07,316.48 L164.52,313.93 C164.22,314.02 163.78,314.02 163.48,313.93 L163.48,313.93 L158.93,318.49 C159.26,319.56 158.45,321 157,321 C155.9,321 155,320.1 155,319 C155,317.55 156.44,316.74 157.51,317.07 L157.51,317.07 L162.07,312.52 C161.73,311.44 162.55,310 164,310 C165.46,310 166.27,311.45 165.93,312.52 L165.93,312.52 L168.48,315.07 C168.78,314.98 169.22,314.98 169.52,315.07 L169.52,315.07 L173.07,311.51 C172.74,310.44 173.55,309 175,309 Z M157.5,307 L158,309 L160,309.5 L158,310 L157.5,312 L157,310 L155,309.5 L157,309 L157.5,307 Z M169,304 L169.94,306.07 L172,307 L169.94,307.93 L169,310 L168.08,307.93 L166,307 L168.08,306.07 L169,304 Z"
            fill={color || colors.green}
            fillRule="nonzero"
          />
        </G>
      </G>
    </Svg>
  );
}

export default MetricsIcon;
