import { Spinner, View, useWindowWidth } from '@talkspace/react-toolkit';
import {
  DynamicTask,
  ReviewableLiveTask,
  UnsubmittedSessionTask,
} from 'hooks/dashboard/useQueryTaskListV3';
import { VoidFunctionComponent, useState } from 'react';
import DashboardTablePlaceholder from 'components/Dashboard/Placeholders/DashboardTablePlaceholder';
import IconSubmitNotes from 'components/Dashboard/Placeholders/IconSubmitNotes';
import { Link } from 'react-router-dom';
import styled from '../../../../core/styled';
import DateOfSessionCell from './DateOfSessionCell';
import ModalityCell, { getIcon } from './ModalityCell';
import SessionStatusCell from './SessionStatusCell';
import ClientCell from './ClientCell';
import TaskActions from './TaskActions';
import { constructURL, getTaskListTableColumns } from '../utils';
import TaskButton from './TaskButton';

interface TasksTableProps {
  tasks: DynamicTask[];
  isLoading: boolean;
  isError: boolean;
}

const GreyBox = styled(View)<{ isMobile: boolean }>(({ theme: { colors }, isMobile }) => {
  return {
    padding: 15,
    borderWidth: 1,
    borderRadius: 8,
    borderStyle: 'solid',
    borderColor: colors.permaPowderBlue,
    backgroundColor: colors.permaGhostWhite,
    minHeight: 119,
    width: '100%',
    textAlign: isMobile ? 'center' : 'left',
    alignItems: 'center',
  };
});

const AccountType = styled(View)(
  ({
    theme: {
      colors,
      window: { isDesktop, isMobile },
    },
  }) => {
    return {
      fontSize: 14,
      fontWeight: 400,
      color: colors.purple600,
      textTransform: 'uppercase',
      height: isDesktop && 30,
      display: 'flex',
      justifyContent: isMobile ? 'left' : 'center',
    };
  }
);

const TableHeader = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    background: colors.greyBubble,
    padding: 10,
    borderRadius: '7px 7px 0px 0px',
    fontSize: 14,
    fontWeight: 700,
    borderBottom: `1px solid ${colors.permaLondonGray}`,
    justifyContent: 'space-between',
  };
});

const TableContainer = styled(View)(({ theme: { colors } }) => {
  return {
    background: colors.white,
    borderRadius: 7,
    border: `1px solid ${colors.permaLondonGray}`,
  };
});

const TableRow = styled(Link)<{ isHovering: boolean }>(
  ({ theme: { colors, window }, isHovering }) => {
    const { isMobile } = window;
    return {
      padding: isMobile ? 15 : 10,
      borderBottom: `1px solid ${colors.permaLondonGray}`,
      justifyContent: 'space-between',
      background: isHovering && colors.permaGreyed,
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
    };
  }
);

const TasksTable: VoidFunctionComponent<TasksTableProps> = ({ tasks, isLoading, isError }) => {
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const { isMobile, isDesktop } = useWindowWidth();
  const columns = getTaskListTableColumns();

  const handleRowPress = (row: UnsubmittedSessionTask) => {
    const {
      roomID,
      draftProgressNoteID,
      sessionStatus,
      id: unsubmittedSessionID,
      isTransferred,
    } = row;
    if (['noShow', 'lateCancellation'].includes(sessionStatus)) {
      return `/room/${roomID}/agenda-tab`;
    }

    return constructURL({ isTransferred, roomID, draftProgressNoteID, unsubmittedSessionID });
  };

  const renderHeader = () => (
    <TableHeader>
      <View style={{ width: 'calc(100% - 550px)' }} row>
        {columns.map((col) => (
          <View key={col.name} style={{ minWidth: col.width }}>
            {col.label}
          </View>
        ))}
      </View>
    </TableHeader>
  );

  const renderRows = () => (
    <View>
      {tasks.map((row: UnsubmittedSessionTask, index) => (
        <TableRow
          to={() => handleRowPress(row)}
          isHovering={hoveredRow === index.toString()}
          style={{ border: index === tasks.length - 1 && 'none' }}
          key={row.id || (row as unknown as ReviewableLiveTask).bookingID.toString()}
          onMouseEnter={() => setHoveredRow(index.toString())}
          onMouseLeave={() => setHoveredRow(null)}
        >
          <View row style={{ width: 'calc(100% - 550px)' }}>
            <View style={{ width: 130 }}>
              <DateOfSessionCell
                startDate={row.serviceStartDate}
                endDate={row.serviceEndDate}
                shouldDisplayRange={row.sessionModality === 'messaging'}
              />
            </View>
            <View style={{ width: '50%' }}>
              <ClientCell
                isDischarged={row.isDischarged}
                fullName={[row.firstName, row.lastName].join(' ').trim()}
                nickname={row.clientNickname}
                preferredName={row.preferredName}
                isTransferred={row.isTransferred}
              />
            </View>
            <View style={{ width: '30%' }}>
              <ModalityCell
                sessionModality={row.sessionModality}
                liveSessionType={row.liveSessionType}
              />
            </View>
            <View style={{ width: '20%' }}>
              <AccountType>{row.accountType}</AccountType>
            </View>
            <View style={{ width: 290 }}>
              <SessionStatusCell
                accountType={row.accountType}
                sessionModality={row.sessionModality}
                roomID={row.roomID}
                sessionStatus={row.sessionStatus}
                wordCount={row.accountType === 'bh' ? row.wordCount : null}
                wordDuration={row.accountType === 'bh' ? row.wordDuration : null}
                clientUserID={row.userID}
                maxSessionWords={row.maxSessionWords}
                minSessionWords={row.minSessionWords}
                isHovering={hoveredRow === index.toString()}
              />
            </View>
          </View>

          <View row style={{ position: 'relative' }}>
            <View style={{ width: 5 }}>
              <TaskActions
                isHovering={hoveredRow === index.toString()}
                canReopen={row.canReopen}
                clientName={row.clientNickname || [row.firstName, row.lastName].join(' ').trim()}
                note={{
                  taskID: row.id,
                  clientUserID: row.userID,
                  roomID: row.roomID,
                  therapistID: row.therapistID,
                  task: {
                    sessionReportID: row.sessionReportID,
                    caseID: row.caseID,
                    draftProgressNoteID: row.draftProgressNoteID,
                    bookingID: (row as unknown as ReviewableLiveTask).bookingID,
                  },
                }}
              />
            </View>

            <TaskButton
              isTransferred={row.isTransferred}
              taskID={row.id}
              sessionStatus={row.sessionStatus}
              roomID={row.roomID}
              draftProgressNoteID={row.draftProgressNoteID}
              bookingID={(row as unknown as ReviewableLiveTask).bookingID}
              liveCallID={row.liveCallID}
              asyncSessionID={row.asyncSessionID}
            />
          </View>
        </TableRow>
      ))}
    </View>
  );

  const renderMobileView = () => (
    <>
      {tasks.map((row: UnsubmittedSessionTask, index) => (
        <TableRow
          to={() => handleRowPress(row)}
          key={row.id || (row as unknown as ReviewableLiveTask).bookingID.toString()}
          isHovering={hoveredRow === index.toString()}
        >
          <View style={{ marginTop: 5, marginRight: 10 }}>
            {getIcon({
              sessionModality: row.sessionModality,
              liveSessionType: row.liveSessionType,
            })}
          </View>
          <View flex={1}>
            <View row flex={1} justify="space-between">
              <View row>
                <View>
                  <DateOfSessionCell
                    startDate={row.serviceStartDate}
                    endDate={row.serviceEndDate}
                    shouldDisplayRange={row.sessionModality === 'messaging'}
                  />
                  <ModalityCell
                    sessionModality={row.sessionModality}
                    liveSessionType={row.liveSessionType}
                  />
                </View>
              </View>

              <TaskButton
                isTransferred={row.isTransferred}
                taskID={row.id}
                sessionStatus={row.sessionStatus}
                roomID={row.roomID}
                draftProgressNoteID={row.draftProgressNoteID}
                bookingID={(row as unknown as ReviewableLiveTask).bookingID}
                liveCallID={row.liveCallID}
                asyncSessionID={row.asyncSessionID}
              />
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <ClientCell
                isDischarged={row.isDischarged}
                isTransferred={row.isTransferred}
                fullName={[row.firstName, row.lastName].join(' ').trim()}
                nickname={row.clientNickname}
                preferredName={row.preferredName}
              />
              <AccountType>{row.accountType}</AccountType>
            </View>
            <View row flex={1} justify="space-between" onClick={(e) => e.preventDefault()}>
              <SessionStatusCell
                accountType={row.accountType}
                sessionModality={row.sessionModality}
                roomID={row.roomID}
                sessionStatus={row.sessionStatus}
                wordCount={row.accountType === 'bh' ? row.wordCount : null}
                wordDuration={row.accountType === 'bh' ? row.wordDuration : null}
                clientUserID={row.userID}
                maxSessionWords={row.maxSessionWords}
                minSessionWords={row.minSessionWords}
                isHovering={hoveredRow === index.toString()}
              />
              <TaskActions
                isHovering={hoveredRow === index.toString()}
                canReopen={row.canReopen}
                clientName={row.clientNickname || [row.firstName, row.lastName].join(' ').trim()}
                note={{
                  taskID: row.id,
                  clientUserID: row.userID,
                  roomID: row.roomID,
                  therapistID: row.therapistID,
                  task: {
                    sessionReportID: row.sessionReportID,
                    caseID: row.caseID,
                    draftProgressNoteID: row.draftProgressNoteID,
                    bookingID: (row as unknown as ReviewableLiveTask).bookingID,
                  },
                }}
              />
            </View>
          </View>
        </TableRow>
      ))}
    </>
  );

  const renderTabletView = () => (
    <>
      {tasks.map((row: UnsubmittedSessionTask, index) => (
        <TableRow
          key={row.id || (row as unknown as ReviewableLiveTask).bookingID.toString()}
          isHovering={hoveredRow === index.toString()}
          to={() => handleRowPress(row)}
        >
          <View row flex={1}>
            <View style={{ marginTop: 8, marginRight: 10 }}>
              {getIcon({
                sessionModality: row.sessionModality,
                liveSessionType: row.liveSessionType,
              })}
            </View>

            <View flex={1}>
              <View row flex={1} justify="space-between">
                <View row align="center">
                  <View style={{ width: 120 }}>
                    <DateOfSessionCell
                      startDate={row.serviceStartDate}
                      endDate={row.serviceEndDate}
                      shouldDisplayRange={row.sessionModality === 'messaging'}
                    />
                  </View>
                  <View onClick={(e) => e.preventDefault()}>
                    <SessionStatusCell
                      accountType={row.accountType}
                      sessionModality={row.sessionModality}
                      roomID={row.roomID}
                      sessionStatus={row.sessionStatus}
                      wordCount={row.accountType === 'bh' ? row.wordCount : null}
                      wordDuration={row.accountType === 'bh' ? row.wordDuration : null}
                      clientUserID={row.userID}
                      maxSessionWords={row.maxSessionWords}
                      minSessionWords={row.minSessionWords}
                      isHovering={false}
                    />
                  </View>
                </View>
                <View row style={{ gap: 10 }}>
                  <TaskButton
                    taskID={row.id}
                    sessionStatus={row.sessionStatus}
                    roomID={row.roomID}
                    draftProgressNoteID={row.draftProgressNoteID}
                    bookingID={(row as unknown as ReviewableLiveTask).bookingID}
                    liveCallID={row.liveCallID}
                    asyncSessionID={row.asyncSessionID}
                    isTransferred={row.isTransferred}
                  />
                  <View style={{ marginTop: -3 }}>
                    <TaskActions
                      isHovering={hoveredRow === index.toString()}
                      canReopen={row.canReopen}
                      clientName={
                        row.clientNickname || [row.firstName, row.lastName].join(' ').trim()
                      }
                      note={{
                        taskID: row.id,
                        clientUserID: row.userID,
                        roomID: row.roomID,
                        therapistID: row.therapistID,
                        task: {
                          sessionReportID: row.sessionReportID,
                          caseID: row.caseID,
                          draftProgressNoteID: row.draftProgressNoteID,
                          bookingID: (row as unknown as ReviewableLiveTask).bookingID,
                        },
                      }}
                    />
                  </View>
                </View>
              </View>
              <View style={{ marginTop: -5 }}>
                <ModalityCell
                  sessionModality={row.sessionModality}
                  liveSessionType={row.liveSessionType}
                />
                <View row>
                  <ClientCell
                    isDischarged={row.isDischarged}
                    fullName={[row.firstName, row.lastName].join(' ').trim()}
                    nickname={row.clientNickname}
                    preferredName={row.preferredName}
                    isTransferred={row.isTransferred}
                  />
                  <View style={{ margin: '0 5px' }}>·</View>
                  <AccountType>{row.accountType}</AccountType>
                </View>
              </View>
            </View>
          </View>
        </TableRow>
      ))}
    </>
  );

  if (isLoading) {
    return (
      <GreyBox row justify="center" isMobile={isMobile}>
        <Spinner />
      </GreyBox>
    );
  }

  if (isError) {
    return (
      <DashboardTablePlaceholder
        title="Oops, something went wrong."
        subtitle="Please check your internet connection and try again later"
      />
    );
  }

  if (tasks && !tasks.length) {
    return (
      <DashboardTablePlaceholder
        icon={<IconSubmitNotes />}
        title="You’re caught up on documentation!"
        subtitle="This is where you’ll see sessions that need a progress note."
      />
    );
  }

  const getContent = () => {
    if (isDesktop) {
      return (
        <>
          {renderHeader()}
          {renderRows()}
        </>
      );
    }
    if (isMobile) {
      return <>{renderMobileView()}</>;
    }

    return <>{renderTabletView()}</>;
  };

  return <TableContainer>{getContent()}</TableContainer>;
};

export default TasksTable;
