import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Select } from '@talkspace/react-toolkit';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { setCustomerInformation } from '../../../../../actions/CustomerInformationActions';
import {
  getAvailableTherapists,
  switchRoomTherapist,
} from '../../../../../actions/MatchingActions';
import './MatchingTherapist.css';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import AlertContainer from '../../../../Reusable/AlertContainer/AlertContainer';
import Button from '../../../../Reusable/Buttons/Button';
import Spinner from '../../../../Reusable/Spinner/Spinner';
import Footer from '../../../../Reusable/Footer/Footer';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';
import SwitchTherapistDropdown from './SwitchTherapistDropdown';

import '../../../../Account/Account.css';

class SwitchTherapistComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTherapist: undefined,
      switchInitiated: false,
    };
  }

  getEnumValueFromDisplayValue = (displayValue, options) => {
    const option = options.find((option) => option.value === displayValue);
    return option ? option.key : null;
  };

  getUpdateFunctionForComponent = (fieldName) => (event, data) => {
    let newValue = data ? data.value : null;
    if (fieldName === 'state' || fieldName === 'country')
      newValue = this.getEnumValueFromDisplayValue(data.value, data.options);
    if (this.props[fieldName] !== newValue) {
      this.props.setCustomerInformation(this.props.clientUserID, fieldName, newValue);
    }
  };

  setSelectedTherapist = (therapist = undefined) => {
    let nextState = { selectedTherapist: therapist };
    const reinitializeSwitch = therapist === undefined && this.props.isError;
    if (reinitializeSwitch) nextState = { ...nextState, switchInitiated: false };
    this.setState(nextState);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // TODO: delete this and use static getDerivedStateFromProps function
    this.redirectToDashboardIfSwitchSuccessful(nextProps.isSwitching, nextProps.isError);
    this.displaySpinnerIfSwitching(nextProps.isSwitching);
  }

  alertView = () => {
    const createHandleSelectChange = (field) => (selectObject) => {
      this.props.setCustomerInformation(this.props.clientUserID, {
        [field]: selectObject,
      });
    };
    const childComponents = (
      <>
        <AlertContainer
          title="Missing required fields"
          body="The following fields are missing and should be completed in order to proceed."
          childrenContainerStyle={{ flexDirection: 'column' }}
        >
          <Select
            value={this.props.country}
            options={getOptionsByField('country')}
            placeholder="Country"
            onChange={createHandleSelectChange('country')}
          />
          {this.isStateMissing() && (
            <Select
              value={this.props.state}
              options={getOptionsByField('state')}
              placeholder="State"
              onChange={createHandleSelectChange('state')}
            />
          )}
        </AlertContainer>
      </>
    );
    const footerComponent = <Footer key={1} childComponents={[this.cancelButton()]} />;
    return { childComponents, footerComponent };
  };

  cancelButton = () => (
    <Button clickHandler={this.props.history.goBack} title="Cancel" isSecondary />
  );

  displaySpinnerIfSwitching = (nextPropsIsSwitching) => {
    if (!this.props.isSwitching && nextPropsIsSwitching) this.setState({ switchInitiated: true });
  };

  isCountryMissing = () => !this.props.country;

  isNewTherapistUnavailable = () => {
    const therapist = this.state.selectedTherapist;
    return therapist && !therapist.isAvailable;
  };

  isStateMissing = () => (this.props.country || {}).value === 'US' && !this.props.state;

  redirectToDashboardIfSwitchSuccessful = (nextPropsIsSwitching, nextPropsIsError) => {
    const therapistWasSuccessfullySwitched =
      this.props.isSwitching && !this.props.isError && !nextPropsIsSwitching && !nextPropsIsError;
    if (therapistWasSuccessfullySwitched)
      setTimeout(() => {
        if (ReactFrameService.instance().isInFrame()) {
          ReactFrameService.instance().sendAsyncMessage('popToDashboard');
        } else {
          this.props.history.push('/rooms');
        }
      }, 3000);
  };

  switchButton = () => (
    <Button
      style={{ marginLeft: 13 }}
      clickHandler={this.switchTherapistHandler}
      isSecondary={!this.state.selectedTherapist}
      disabled={!this.state.selectedTherapist}
      title="Switch provider"
    />
  );

  switchSpinner = () => (
    <Spinner
      style={{ margin: 12 }}
      isUpdating={this.props.isSwitching}
      isError={this.props.isError}
      spinnerMessage="Provider successfully switched"
    />
  );

  switchTherapistHandler = () => {
    if (this.state.selectedTherapist)
      this.props.switchRoomTherapist(this.state.selectedTherapist.therapistID);
  };

  switchViewArray = () => {
    const footerStyle = {
      height: '166px',
      flexDirection: 'column-reverse',
      paddingTop: '3px',
    };
    const showAlert = !!this.isNewTherapistUnavailable();
    const childComponents = (
      <SwitchTherapistDropdown
        key={0}
        setSelectedTherapist={this.setSelectedTherapist}
        selectedTherapist={this.state.selectedTherapist}
      />
    );
    const footerComponent = (
      <Footer
        key={1}
        footerStyle={showAlert ? footerStyle : undefined}
        childComponents={
          showAlert
            ? [
                <AlertContainer
                  key={0}
                  title="Therapist Unavailable"
                  body="The therapist selected is not available at the moment. Are you sure you want to select this therapist?"
                  childComponents={[
                    <Button
                      key={1}
                      title="Select anyway"
                      isSecondary={false}
                      clickHandler={this.switchTherapistHandler}
                    />,
                    <Button
                      title="Cancel"
                      key={2}
                      isSecondary
                      clickHandler={() => {
                        this.setState({ selectedTherapist: undefined });
                      }}
                    />,
                  ]}
                  containerStyle={{
                    width: 355,
                    border: 'none',
                  }}
                />,
              ]
            : [
                this.state.switchInitiated ? null : this.cancelButton(),
                this.state.switchInitiated ? null : this.switchButton(),
                this.state.switchInitiated ? this.switchSpinner() : null,
              ]
        }
      />
    );
    return { childComponents, footerComponent };
  };

  getSubmenuComponents = () => {
    const components =
      this.isStateMissing() || this.isCountryMissing() ? this.alertView() : this.switchViewArray();

    return components;
  };

  render() {
    return (
      <Submenu
        childComponents={[this.getSubmenuComponents().childComponents]}
        footerComponent={this.getSubmenuComponents().footerComponent}
        title="Switch a provider"
        prevRoute="case-tab"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
    country: state.customerInformation.country,
    state: state.customerInformation.state,
    availableTherapists: state.matching.availableTherapists,
    isRequestingCustomer: state.customerInformation.isRequestingCustomer,
    isPatchingCustomer: state.customerInformation.isPatchingCustomer,
    clientUserID: state.customerInformation.clientUserID,
    customerList: state.customerList.list,
    disabledComponents: state.customerInformation.disabledComponents,
    isError: state.matching.isError,
    isSwitching: state.matching.isSwitching,
    invalidComponents: state.customerInformation.invalidComponents,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCustomerInformation,
      getAvailableTherapists,
      switchRoomTherapist,
    },
    dispatch
  );
const SwitchTherapist = connect(mapStateToProps, mapDispatchToProps)(SwitchTherapistComponent);

export default SwitchTherapist;
