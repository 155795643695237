import * as actionTypes from '../constants/ClinicalInformationConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';
import {
  transformAPIPayloadToReducerPayload,
  transformReducerPayloadToAPIPayload,
} from '../utils/optionsHelpers';

function userIsPrimary(userID, customerList) {
  return customerList[0] && customerList[0].userID === userID;
}

const handleAction = (action) => (payload) => {
  return {
    type: action,
    payload,
  };
};
const handleErrorAction = (action) => (error) => {
  return {
    type: action,
    error,
  };
};

const requestGetClinicalInformation = handleAction(actionTypes.REQUEST_GET_CLINICAL_INFORMATION);
const receiveGetClinicalInformation = handleAction(actionTypes.RECEIVE_GET_CLINICAL_INFORMATION);
const requestGetClinicalInformationError = handleErrorAction(
  actionTypes.REQUEST_GET_CLINICAL_INFORMATION_ERROR
);
const requestSetClinicalInformation = handleAction(actionTypes.REQUEST_SET_CLINICAL_INFORMATION);
const receiveSetClinicalInformation = handleAction(actionTypes.RECEIVE_SET_CLINICAL_INFORMATION);
const requestSetClinicalInformationError = handleErrorAction(
  actionTypes.REQUEST_SET_CLINICAL_INFORMATION_ERROR
);

export const getClinicalInformation = (clientUserID) => (dispatch) => {
  dispatch(requestGetClinicalInformation());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/clients/${clientUserID}/clinical-information`)
    .then((response) => {
      const payload = transformAPIPayloadToReducerPayload(response.data.data);
      return dispatch(receiveGetClinicalInformation(payload));
    })
    .catch((error) => dispatch(requestGetClinicalInformationError(error)));
};

export const setClinicalInformation = (clientUserID, payload) => (dispatch) => {
  dispatch(requestSetClinicalInformation());
  const data = transformReducerPayloadToAPIPayload(payload);
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/v3/clients/${clientUserID}/clinical-information`, { data })
    .then(() => dispatch(receiveSetClinicalInformation(payload)))
    .catch((error) => dispatch(requestSetClinicalInformationError(error)));
};

const receiveGetConditions = handleAction(actionTypes.RECEIVE_GET_CONDITIONS);
const requestGetConditions = handleAction(actionTypes.REQUEST_GET_CONDITIONS);
const requestGetConditionsError = handleErrorAction(actionTypes.REQUEST_GET_CONDITIONS_ERROR);
const requestPostConditions = handleAction(actionTypes.REQUEST_POST_CONDITIONS);
const receivePostConditions = handleAction(actionTypes.RECEIVE_POST_CONDITIONS);
const requestPostConditionsError = handleErrorAction(actionTypes.REQUEST_POST_CONDITIONS_ERROR);
const requestDeleteConditions = handleAction(actionTypes.REQUEST_DELETE_CONDITIONS);
const receiveDeleteConditions = handleAction(actionTypes.RECEIVE_DELETE_CONDITIONS);
const requestDeleteConditionsError = handleErrorAction(actionTypes.REQUEST_DELETE_CONDITIONS_ERROR);

export const getConditions = (userID) => (dispatch) => {
  dispatch(requestGetConditions());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/clients/${userID}/conditions`)
    .then((response) => {
      const conditions = response.data.data.map(
        ({ id, value, conditionID, isWorkingDiagnosis, isBillable }) => {
          return {
            id,
            value: conditionID,
            label: value,
            isWorkingDiagnosis,
            isBillable,
          };
        }
      );
      dispatch(receiveGetConditions({ conditions }));
    })
    .catch((error) => dispatch(requestGetConditionsError(error)));
};

export const postConditions =
  (userID, { value, label }, conditions) =>
  (dispatch, getState) => {
    dispatch(requestPostConditions());
    const { customerList } = getState();
    return apiWrapper
      .post(`${apiHelper().apiEndpoint}/api/v1/clients/${userID}/conditions`, { value: label })
      .then((res) => {
        // add the database instance id for this condition
        const { id } = res.data.data;
        const conditionsPayload = conditions.map((c) =>
          c.value === value ? { id, value: c.value, label: c.label } : c
        );
        return dispatch(receivePostConditions({ conditions: conditionsPayload }));
      })
      .then(() => {
        if (userIsPrimary(userID, customerList.list)) dispatch(getConditionSurvey());
      })
      .catch((error) => dispatch(requestPostConditionsError(error)));
  };

export const deleteConditions =
  (userID, { id }, conditions) =>
  (dispatch, getState) => {
    dispatch(requestDeleteConditions());
    const { customerList } = getState();
    return apiWrapper
      .delete(`${apiHelper().apiEndpoint}/api/v1/clients/${userID}/conditions/${id}`)
      .then(() => dispatch(receiveDeleteConditions({ conditions })))
      .then(() => {
        if (userIsPrimary(userID, customerList.list)) dispatch(getConditionSurvey());
      })
      .catch((error) => dispatch(requestDeleteConditionsError(error)));
  };

const receiveGetPhysicalConditions = handleAction(actionTypes.RECEIVE_GET_PHYSICAL_CONDITIONS);
const requestGetPhysicalConditions = handleAction(actionTypes.REQUEST_GET_PHYSICAL_CONDITIONS);
const requestGetPhysicalConditionsError = handleErrorAction(
  actionTypes.REQUEST_GET_PHYSICAL_CONDITIONS_ERROR
);
const requestPostPhysicalConditions = handleAction(actionTypes.REQUEST_POST_PHYSICAL_CONDITIONS);
const receivePostPhysicalConditions = handleAction(actionTypes.RECEIVE_POST_PHYSICAL_CONDITIONS);
const requestPostPhysicalConditionsError = handleErrorAction(
  actionTypes.REQUEST_POST_PHYSICAL_CONDITIONS_ERROR
);
const requestDeletePhysicalConditions = handleAction(
  actionTypes.REQUEST_DELETE_PHYSICAL_CONDITIONS
);
const receiveDeletePhysicalConditions = handleAction(
  actionTypes.RECEIVE_DELETE_PHYSICAL_CONDITIONS
);
const requestDeletePhysicalConditionsError = handleErrorAction(
  actionTypes.REQUEST_DELETE_PHYSICAL_CONDITIONS_ERROR
);

export const getPhysicalConditions = (userID) => (dispatch) => {
  dispatch(requestGetPhysicalConditions());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/clients/${userID}/physical-conditions`)
    .then((response) => {
      const physicalConditions = response.data.data.map(({ id, value, physicalConditionID }) => {
        return {
          id,
          value: physicalConditionID,
          label: value,
        };
      });
      dispatch(receiveGetPhysicalConditions({ physicalConditions }));
    })
    .catch((error) => dispatch(requestGetPhysicalConditionsError(error)));
};

export const postPhysicalConditions =
  (userID, { value, label }, physicalConditions) =>
  (dispatch) => {
    dispatch(requestPostPhysicalConditions());
    return apiWrapper
      .post(`${apiHelper().apiEndpoint}/api/v1/clients/${userID}/physical-conditions`, {
        value: label,
      })
      .then((res) => {
        // add the database instance id for this physical condition
        const { id } = res.data.data;
        const physicalConditionsPayload = physicalConditions.map((pc) =>
          pc.value === value ? { id, value: pc.value, label: pc.label } : pc
        );
        return dispatch(
          receivePostPhysicalConditions({ physicalConditions: physicalConditionsPayload })
        );
      })
      .catch((error) => dispatch(requestPostPhysicalConditionsError(error)));
  };

export const deletePhysicalConditions =
  (userID, { id }, physicalConditions) =>
  (dispatch) => {
    dispatch(requestDeletePhysicalConditions());
    return apiWrapper
      .delete(`${apiHelper().apiEndpoint}/api/v1/clients/${userID}/physical-conditions/${id}`)
      .then(() => dispatch(receiveDeletePhysicalConditions({ physicalConditions })))
      .catch((error) => dispatch(requestDeletePhysicalConditionsError(error)));
  };

const requestRoomPresentingProblem = handleAction(actionTypes.REQUEST_ROOM_PRESENTING_PROBLEM);
const receiveRoomPresentingProblem = handleAction(actionTypes.RECEIVE_ROOM_PRESENTING_PROBLEM);
const requestRoomPresentingProblemError = handleErrorAction(
  actionTypes.REQUEST_ROOM_PRESENTING_PROBLEM_ERROR
);
const requestPostRoomPresentingProblem = handleAction(
  actionTypes.REQUEST_POST_ROOM_PRESENTING_PROBLEM
);
const postRoomPresentingProblemSuccess = handleAction(
  actionTypes.POST_ROOM_PRESENTING_PROBLEM_SUCCESS
);
const postRoomPresentingProblemError = handleErrorAction(
  actionTypes.POST_ROOM_PRESENTING_PROBLEM_ERROR
);
const requestDeleteRoomPresentingProblem = handleAction(
  actionTypes.REQUEST_DELETE_ROOM_PRESENTING_PROBLEM
);
const deleteRoomPresentingProblemSuccess = handleAction(
  actionTypes.DELETE_ROOM_PRESENTING_PROBLEM_SUCCESS
);
const deleteRoomPresentingProblemError = handleErrorAction(
  actionTypes.DELETE_ROOM_PRESENTING_PROBLEM_ERROR
);

export const getPresentingProblems = (clientUserID) => (dispatch, getState) => {
  const { room } = getState();
  dispatch(requestRoomPresentingProblem());
  return apiWrapper
    .get(
      `${apiHelper().apiEndpoint}/api/v1/rooms/${
        room.roomID
      }/clients/${clientUserID}/presenting-problems`
    )
    .then((response) => {
      const presentingProblems = response.data.data.map(({ id, value, presentingProblemID }) => {
        return {
          id,
          value: presentingProblemID,
          label: value,
        };
      });
      return dispatch(receiveRoomPresentingProblem({ presentingProblems }));
    })
    .catch((error) => dispatch(requestRoomPresentingProblemError(error)));
};

export const postPresentingProblems =
  (clientUserID, { label, value }, presentingProblems) =>
  (dispatch, getState) => {
    dispatch(requestPostRoomPresentingProblem());
    const { room } = getState();
    return apiWrapper
      .post(`${apiHelper().apiEndpoint}/api/v1/rooms/${room.roomID}/presenting-problems`, {
        value: label,
        clientUserID,
      })
      .then((res) => {
        // add the database instance id for this presenting problem
        const { id } = res.data.data;
        const presentingProblemsPayload = presentingProblems.map((pp) =>
          pp.value === value ? { id, value: pp.value, label: pp.label } : pp
        );
        return dispatch(
          postRoomPresentingProblemSuccess({ presentingProblems: presentingProblemsPayload })
        );
      })
      .catch((error) => dispatch(postRoomPresentingProblemError(error)));
  };

export const deletePresentingProblems =
  (clientUserID, { id }, presentingProblems) =>
  (dispatch, getState) => {
    dispatch(requestDeleteRoomPresentingProblem());
    const { room } = getState();
    return apiWrapper
      .delete(`${apiHelper().apiEndpoint}/api/v1/rooms/${room.roomID}/presenting-problems/${id}`)
      .then(() => dispatch(deleteRoomPresentingProblemSuccess({ presentingProblems })))
      .catch((error) => dispatch(deleteRoomPresentingProblemError(error)));
  };

export function requestCurrentSurvey() {
  return {
    type: actionTypes.REQUEST_CURRENT_SURVEY,
  };
}

export function receiveCurrentSurvey(response) {
  return {
    type: actionTypes.RECEIVE_CURRENT_SURVEY,
    currentSurvey: response,
  };
}

export function requestCurrentSurveyError(error) {
  return {
    type: actionTypes.REQUEST_CURRENT_SURVEY_ERROR,
    error,
  };
}

export const getCurrentSurvey = (roomID) => (dispatch) => {
  dispatch(requestCurrentSurvey());
  return apiWrapper
    .get(`${apiHelper().surveyAPIEndpoint}/v2/room-survey/${roomID}`)
    .then((response) => dispatch(receiveCurrentSurvey(response.data)))
    .catch((error) => dispatch(requestCurrentSurveyError(error)));
};

export function requestConditionSurvey() {
  return {
    type: actionTypes.REQUEST_CONDITION_SURVEY,
  };
}

export function receiveConditionSurvey(response, surveyAlert) {
  return {
    type: actionTypes.RECEIVE_CONDITION_SURVEY,
    conditionSurvey: response,
    surveyAlert,
  };
}

export function requestConditionSurveyError(error) {
  return {
    type: actionTypes.REQUEST_CONDITION_SURVEY_ERROR,
    error,
  };
}

export function closeSurvey() {
  return {
    type: actionTypes.CLOSE_SURVEY_ALERT,
    surveyAlert: false,
  };
}

export const getConditionSurvey = () => (dispatch, getState) => {
  const { clinicalInformation } = getState();
  const conditionIDs = clinicalInformation.conditions.map((c) => c.value);
  if (conditionIDs.length === 0) {
    return dispatch(closeSurvey());
  }
  dispatch(requestConditionSurvey());
  return apiWrapper
    .get(`${apiHelper().surveyAPIEndpoint}/v2/condition-survey?conditions=[${conditionIDs}]`)
    .then((response) => {
      const currentSurvey =
        (clinicalInformation.currentSurvey && clinicalInformation.currentSurvey.surveyID) || null;
      const surveyAlert = !!currentSurvey && currentSurvey !== response.data.Survey.surveyID;
      return dispatch(receiveConditionSurvey(response.data, surveyAlert));
    })
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_CONDITION_SURVEY_ERROR,
        error,
      })
    );
};

function cancelSurvey(roomID, userRoomSurveyID) {
  return apiWrapper.put(`${apiHelper().surveyAPIEndpoint}/v2/room/${roomID}/survey`, {
    reasonCanceled: 'New survey selected by therapist',
    userRoomSurveyID,
  });
}

function createSurvey(clientUserID, roomID, therapistID, surveyID, conditionID) {
  return apiWrapper.post(`${apiHelper().surveyAPIEndpoint}/v2/room/${roomID}/survey`, {
    userID: clientUserID,
    roomID,
    therapistID,
    surveyID,
    conditionID,
  });
}

export function requestSwitchSurvey() {
  return {
    type: actionTypes.REQUEST_SWITCH_SURVEY,
  };
}

export function switchSurveySuccess() {
  return {
    type: actionTypes.SWITCH_SURVEY_SUCCESS,
  };
}

export function switchSurveyError(error) {
  return {
    type: actionTypes.SWITCH_SURVEY_ERROR,
    error,
  };
}

export const switchSurvey =
  (clientUserID, roomID, therapistID, surveyID, conditionID) => (dispatch, getState) => {
    const {
      outcomeMeasures: { outcomeMeasures },
      clinicalInformation: { currentSurvey },
    } = getState();
    dispatch(requestSwitchSurvey());
    const userRoomSurveyID =
      (currentSurvey &&
        Array.isArray(outcomeMeasures) &&
        outcomeMeasures.find(
          (om) => !om.completedAt && !om.canceledAt && om.label === currentSurvey.surveyLabel
        )?.id) ||
      0;
    cancelSurvey(roomID, userRoomSurveyID)
      .then(() => {
        createSurvey(clientUserID, roomID, therapistID, surveyID, conditionID)
          .then(() => {
            dispatch(switchSurveySuccess());
            dispatch(getCurrentSurvey(roomID));
          })
          .catch((e) => dispatch(switchSurveyError(e)));
      })
      .catch((e) => dispatch(switchSurveyError(e)));
  };

export const closeSurveyAlert = () => (dispatch) => dispatch(closeSurvey());
