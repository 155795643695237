import './StatusMobile.css';
import moment from 'moment';

const StatusMobile = ({ client }) => {
  let renewContent = '';
  let statusContent = '';

  if (client.renew_date) {
    renewContent = `Renew: ${moment(client.renew_date).format('MMM DD')}`;
  }

  if (client.plan_end) {
    renewContent = `Plan end: ${moment(client.plan_end).format('MMM DD')}`;
  }

  statusContent = `Status: ${client.room_status}`;

  return (
    <>
      <span>{statusContent}</span>
      <span className="renew-content-block-mobile">{renewContent}</span>
    </>
  );
};

export default StatusMobile;
