import './ItemList.css';

const buildItemList = (items, noDataMessage) => {
  if (items && items.length) return items;
  return <div className="item-list-no-data">{noDataMessage}</div>;
};

const ItemList = ({ items, titles, noDataMessage, cssClass, widths }) => (
  <div className={cssClass ? `item-list-container ${cssClass}` : 'item-list-container'}>
    {titles && titles.length && (
      <div className="item-list-head">
        {titles.map((title, key) => (
          <div style={{ width: (widths && widths[key]) || 100 }} key={key}>
            {title}
          </div>
        ))}
      </div>
    )}
    <div className="item-list">{buildItemList(items, noDataMessage)}</div>
  </div>
);

export default ItemList;
