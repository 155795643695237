import { Component } from 'react';
import PropTypes from 'prop-types';
import MultiSelectSearchView from './MultiSelectSearchView';

class MultiSelectSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholderState: props.selected.length ? 'up' : 'down',
      selected: props.selected || [],
      selection: !!props.selected.length,
      isMissing: !!props.isMissing,
      // create state for current selected
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.selected != nextState.selected || nextProps.selection != nextState.selection;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled) this.styleMultiSelectLabels(prevProps);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selected !== this.props.selected) {
      this.setState({
        placeholderState: this.state.selected.length || nextProps.selected.length ? 'up' : 'down',
        selected: nextProps.selected,
        selection: !!nextProps.selected.length,
      });
    }
    if (nextProps.isMissing !== this.props.isMissing) {
      this.setState({
        isMissing: !!this.props.isMissing,
      });
    }
  }

  handleBlur = (event, data) => {
    const phState = this.state.selected.length ? 'up' : 'down';
    this.setState({ placeholderState: phState });
  };

  handleChange = (event, data) => {
    if (this.props.minNumberOfItems > 0 && data.value.length < this.props.minNumberOfItems) return;
    this.setState(
      {
        selected: data.value,
      },
      () => {
        this.props.saveChange(event, data);
      }
    );
  };

  handleTouch = (event) => {
    if (this.state.placeholderState === 'down') this.setState({ placeholderState: 'up' });
  };

  styleMultiSelectLabels(props) {
    // TODO: This is enabled only on treatment preferences. its bad but we will replace semantic soon
    const multiSelectSearchChips = document.querySelectorAll(
      '.ts-Treatmentpreferences-dropdown .ui.label'
    );
    multiSelectSearchChips.forEach((chip) => {
      if (props.disabled.includes(+chip.getAttribute('value'))) {
        chip.classList.add('grayed');
      } else {
        chip.classList.remove('grayed');
      }
    });
  }

  render() {
    return (
      <MultiSelectSearchView
        placeholder={this.props.placeholder}
        placeholderState={this.state.placeholderState}
        handleBlur={this.handleBlur}
        handleTouch={this.handleTouch}
        handleChange={this.handleChange}
        options={this.props.options}
        selection={this.state.selection}
        selected={this.state.selected}
        isMissing={this.state.isMissing}
        placeholderDataQa={this.props.placeholderDataQa}
      />
    );
  }
}

MultiSelectSearch.propTypes = {
  placeholder: PropTypes.string,
  selected: PropTypes.array,
  options: PropTypes.array,
  placeholderDataQa: PropTypes.string,
};

export default MultiSelectSearch;
