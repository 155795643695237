import { FunctionComponent, useEffect } from 'react';
import { View, Standard } from '@talkspace/react-toolkit';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useParams } from '@/core/routerLib/routerLib';
import styled from '@/core/styled';
import CTAContainer from './CTAContainer';
import NotesList from './NotesList';
import CTAContainerV2 from './CTA/CTAContainerV2';
import CreateOtherNotes from './CreateOtherNotes';
import trackEvent from '../../../../modules/analytics/trackEvent';

const NotesTabWrapper = styled(View)({
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 20,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minWidth: 355,
});

const NonLastMessageSection = styled(View)({
  marginBottom: 15,
});

const Title = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 21,
    fontWeight: 400,
    color: colors.black,
    marginBottom: 20,
  };
});

const NotesTab: FunctionComponent = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLine } = useQueryBusinessLine(roomID, clientUserID);

  useEffect(() => {
    trackEvent(
      'viewNotesTab',
      {
        actionName: 'progressNotesInteraction',
      },
      {
        action: 'provider views notes tab in room',
        roomID,
      }
    );
  }, [roomID]);

  const renderNotesTabMessage = (): JSX.Element | null => {
    let message: JSX.Element | string = '';
    if (businessLine?.isDTE) {
      message = (
        <>
          <NonLastMessageSection>
            For DTE plans, messaging and live sessions have to be submitted upon completion for
            payment.
          </NonLastMessageSection>
          <View>Typically ongoing messaging documentation is submitted every 7 days.</View>
        </>
      );
    }
    if (!businessLine || (businessLine && !businessLine.isB2B)) {
      if (businessLine?.isPsychiatry) {
        message =
          'For Self-pay plans, live sessions have to be submitted upon completion for payment.';
      } else {
        message = (
          <>
            <NonLastMessageSection>
              For Self-pay plans, messaging and live sessions have to be submitted upon completion
              for payment.
            </NonLastMessageSection>
            <View>Typically ongoing messaging documentation is submitted every 7 days.</View>
          </>
        );
      }
    } else if (businessLine.isBH && businessLine.isPsychiatry) {
      message = 'For BH plans, live sessions have to be submitted upon completion for payment.';
    } else if (businessLine.isBH) {
      message =
        'For BH plans, messaging and live sessions have to be submitted upon completion for payment.';
    } else if (businessLine.isEAP) {
      message = (
        <>
          <NonLastMessageSection>
            For EAP plans, messaging sessions are auto-submitted as complete every 7 days. Live
            sessions have to be submitted upon completion.
          </NonLastMessageSection>
          <View>
            All clients have accepted the 7 day standard of billing. You’re not obligated to reopen
            sessions if no engagement occurred.
          </View>
        </>
      );
    }

    return (
      <View style={{ width: '100%', paddingLeft: 5 }}>
        <View style={{ fontSize: '21px', marginBottom: '10px' }}>Submit sessions</View>
        <View>{message}</View>
      </View>
    );
  };

  const getProgressNoteTitleV2 = () => {
    let title = 'Submit progress notes';

    if (businessLine) {
      const { isBH, isEAP, isPsychiatry, isB2C, isDTE } = businessLine;
      if (isBH) {
        title = 'Submit BH progress notes';
      }
      if (isEAP) {
        title = 'Submit EAP progress notes';
      }
      if (isPsychiatry) {
        title = 'Submit psychiatry progress notes';
      }
      if (isB2C) {
        title = 'Submit self-pay progress notes';
      }
      if (isDTE) {
        title = 'Submit DTE progress notes';
      }
    }

    return <Title>{title}</Title>;
  };

  return (
    <NotesTabWrapper style={{ alignItems: !businessLine?.isPsychiatry && 'center' }}>
      {!businessLine?.isPsychiatry ? (
        <>
          {getProgressNoteTitleV2()}
          <CTAContainerV2 />
        </>
      ) : (
        <>
          {renderNotesTabMessage()}
          <CTAContainer />
        </>
      )}
      <CreateOtherNotes />
      <NotesList />
    </NotesTabWrapper>
  );
};

export default NotesTab;
