import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from 'react-router-dom';
import moment from 'moment';
import {
  PanelHeader,
  PanelManager,
  ResponsiveLayoutWithHeader,
  useWindowWidth,
  View,
} from '@talkspace/react-toolkit';
import { getNavBarHeight } from 'components/Reusable/NavBar/NavBar';
import { ERoom } from 'ts-frontend/entities/Room';
import { VideoCallProvider } from 'chat/hooks/videoCallContext';
import ChatMessageContextProvider from 'chat/hooks/chatMessageContext';
import { InRoomSchedulingProvider } from 'inRoomScheduling';
import { PromiseMessageActionsContext } from 'ts-promise-message';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { getRoomProperties, resetInitialRoomState } from '../../actions/RoomActions';
import './Room.css';
import { getActiveCustomerName } from '../../utils/customerHelper';
import { getUserData } from '../../utils/token';
import { tokenIsValid } from '../../utils/tokenIsValid';
import {
  getCustomerInformation,
  resetInitialCustomerState,
} from '../../actions/CustomerInformationActions';
import { getAvailability } from '../../actions/AvailabilityActions';
import { getEmergencyContact } from '../../actions/EmergencyContactActions';
import { getCustomerList } from '../../actions/CustomerListActions';
import { getJourneyItems } from '../../actions/JourneyActions';
import { getPresentingProblems, getConditions } from '../../actions/ClinicalInformationActions';
import SwitchTherapist from './CRMContainer/CaseTab/Matching/SwitchTherapist';
import SuggestTherapist from './CRMContainer/CaseTab/Matching/SuggestTherapist';
import ChangeRoomType from './CRMContainer/CaseTab/Matching/ChangeRoomType';
import IntakeQuestionnaire from './CRMContainer/CaseTab/IntakeQuestionnaire/IntakeQuestionnaire';
import MatchingIntake from './CRMContainer/CaseTab/Matching/MatchingIntake';
import InformedConsent from './CRMContainer/CaseTab/InformedConsent/InformedConsent';
import EmergencyContact from './CRMContainer/CaseTab/EmergencyContact/EmergencyContact';
import VideoCredits from './CRMContainer/CaseTab/VideoCredits/VideoCredits';
import VideoCallHistory from './CRMContainer/CaseTab/VideoCallHistory/VideoCallHistory';
import RoomCreation from './CRMContainer/CaseTab/RoomCreation/RoomCreation';
import Invites from './CRMContainer/CaseTab/Invites/Invites';
import QuickMatchAnswers from './CRMContainer/CaseTab/QuickMatchAnswers/QuickMatchAnswers';
import EligibilityQuestionnaire from './CRMContainer/CaseTab/EligibilityQuestionnaire/EligibilityQuestionnaire';
import OutcomeMeasureDetails from './CRMContainer/ClinicalTab/OutcomeMeasures/OutcomeMeasureDetails';
import DoseSpotPatientForm from './CRMContainer/ClinicalTab/Psychiatry/DoseSpotPatientForm';
import CrmMenu from './CRMContainer/CrmMenu';
import MessageContainer from './MessageContainer/MessageContainer';
import OutcomeMeasureCancel from './CRMContainer/ClinicalTab/OutcomeMeasures/OutcomeMeasureCancel';
import OutcomeMeasureStart from './CRMContainer/ClinicalTab/OutcomeMeasures/OutcomeMeasureStart';
import OutcomeMeasureAnswers from './CRMContainer/ClinicalTab/OutcomeMeasures/OutcomeMeasureAnswers';
import EmergencyContactDetails from './CRMContainer/CaseTab/EmergencyContact/EmergencyContactDetails';
import TreatmentPlannerGoals from './CRMContainer/ClinicalTab/TreatmentPlanner/TreatmentPlannerGoals';
import ClientLogins from './CRMContainer/CaseTab/EmergencyContact/ClientLogins';
import TreatmentPlannerObjectives from './CRMContainer/ClinicalTab/TreatmentPlanner/TreatmentPlannerObjectives';
import TreatmentPlannerInterventions from './CRMContainer/ClinicalTab/TreatmentPlanner/TreatmentPlannerInterventions';
import CreateNewTreatmentPlan from './CRMContainer/ClinicalTab/TreatmentPlanner/CreateNewTreatmentPlan';
import ArchivedTreatmentPlans from './CRMContainer/ClinicalTab/TreatmentPlanner/ArchivedTreatmentPlans';
import ArchivedTreatmentPlan from './CRMContainer/ClinicalTab/TreatmentPlanner/ArchivedTreatmentPlan';
import SessionSummarization from './CRMContainer/JourneyTab/SessionSummarization/SessionSummarization';
import DiagnosticProfilePicker from './CRMContainer/ClinicalTab/DiagnosticProfile/DiagnosticProfilePicker';
import DiagnosticProfile from './CRMContainer/ClinicalTab/DiagnosticProfile/DiagnosticProfile';
import InRoomSchedulingWrapper from './CRMContainer/LVSTab/InRoomSchedulingWrapper/InRoomSchedulingWrapper';
import TransferClient from './CRMContainer/CaseTab/TransferClient/TransferClient';
import CRMChatManager from './CRMChatManager/CRMChatManager';
import NavBarLogo from '../Reusable/NavBarLogo/NavBarLogo';
import NavBarUserBadge from '../Reusable/NavBarUserBadge/NavBarUserBadge';
import NavBarAccountDropDownMenu from '../Reusable/NavBarAccountDropDownMenu/NavBarAccountDropDownMenu';
import CRMChatSwitcher from './CRMChatSwitcher/CRMChatSwitcher';
import StarredContainer from './StarredContainer/StarredContainer';
import SharedFilesContainer from './SharedFilesContainer/SharedFilesContainer';
import CrmNavBar from './CRMContainer/CrmNavBar/CrmNavBar';
import NoteTypeExplanation from './CRMContainer/NotesTab/NoteTypeExplanation';
import styled from '../../core/styled';
import ReopenEAPSessionDialog from './CRMContainer/NotesTab/ReopenEAPSessionDialog';
import ProgressNoteForm from './CRMContainer/NotesTab/ProgressNoteForm';
import DischargeNoteForm from './CRMContainer/NotesTab/DischargeNoteForm';
import DischargeNoteFormNavigator from './CRMContainer/NotesTab/DischargeNoteFormNavigator';
import DeleteDischargeNoteDialog from './CRMContainer/NotesTab/DeleteDischargeNoteDialog';
import PsychotherapyNoteForm from './CRMContainer/NotesTab/PsychotherapyNoteForm';
import PsychiatryReferralNoteForm from './CRMContainer/NotesTab/PsychiatryReferralNoteForm';
import TransferNoteForm from './CRMContainer/NotesTab/TransferNoteForm';
import CollateralNoteForm from './CRMContainer/NotesTab/CollateralNoteForm';
import DeleteCollateralNoteDialog from './CRMContainer/NotesTab/DeleteCollateralNoteDialog';
import DeletePsychotherapyNoteDialog from './CRMContainer/NotesTab/DeletePsychotherapyNoteDialog';
import PostLVSPrompt from './CRMContainer/NotesTab/PostLVSPrompt';
import DeleteCaseConsultationNoteDialog from './CRMContainer/NotesTab/DeleteCaseConsultationNoteDialog';
import CaseConsultationNoteForm from './CRMContainer/NotesTab/CaseConsultationNoteForm';
import SaveCollateralNoteDialog from './CRMContainer/NotesTab/SaveCollateralNoteDialog';
import SaveCaseConsultationNoteDialog from './CRMContainer/NotesTab/SaveCaseConsultationNoteDialog';
import SaveDischargeNoteDialog from './CRMContainer/NotesTab/SaveDischargeNoteDialog';
import RestartCare from './CRMContainer/CaseTab/RestartCare/RestartCare';
import ConfirmDischargeNote from './CRMContainer/NotesTab/ConfirmDischargeNote';
import IncompleteNotes from './CRMContainer/NotesTab/IncompleteNotes';
import trackEvent from '../../modules/analytics/trackEvent';
import AllUnsubmittedSessions from './CRMContainer/NotesTab/CTA/AllUnsubmittedSessions';
import { isIonic } from '@/auth/reactFrame/helpers';

const LeftPanelHeader = ({ roomID, nickname, description }) => {
  const { isMobile } = useWindowWidth();
  return (
    <PanelHeader
      renderLeft={() =>
        isMobile ? (
          <NavBarUserBadge roomID={roomID} nickname={nickname} description={description} />
        ) : (
          <NavBarLogo />
        )
      }
      style={isMobile ? {} : { paddingLeft: 8 }}
    />
  );
};

const MiddlePanelHeader = ({ roomID, nickname, description }) => {
  const { isMobile } = useWindowWidth();
  return (
    <PanelHeader
      renderLeft={() => (
        <NavBarUserBadge roomID={roomID} nickname={nickname} description={description} />
      )}
      renderRight={() => <NavBarAccountDropDownMenu hidden={isMobile} />}
    />
  );
};

const RoomPanel = ({ panelHeader, children, hideHeader = false }) => {
  const { isMobile } = useWindowWidth();
  return (
    <ResponsiveLayoutWithHeader
      shouldScroll
      headerHeight={hideHeader ? 0 : getNavBarHeight(isMobile)}
      renderHeader={() =>
        hideHeader ? null : (
          <View flex={1}>
            {panelHeader}
            <CRMChatSwitcher />
          </View>
        )
      }
    >
      {children}
    </ResponsiveLayoutWithHeader>
  );
};

const RoomRoute = styled(View)({
  flexDirection: 'row',
});

const RoomWrapper = styled(View)({
  width: '100%',
  backgroundColor: 'white',
  flex: 1,
  overflowY: 'overlay',
});

class Room extends Component {
  constructor(props) {
    super(props);
    if (!tokenIsValid()) {
      window.location.pathname = '/logout';
    }

    this.roomRef = null;
    this.therapistInfoRef = null;

    this.initializeComponent();
    this.getChildComponents = this.getChildComponents.bind(this);
    this.state = {
      view: 'crm',
    };
  }

  initializeComponent() {
    this.props
      .getRoomProperties(this.props.match.params.roomID)
      .then(() =>
        Promise.all([
          this.props.getCustomerList(),
          this.props.getAvailability(getUserData().id),
          this.props.getCustomerInformation(this.props.clientUserID),
        ])
      )
      .then(() => {
        if (this.props.roomID && this.props.roomType) {
          this.roomRef = new ERoom({
            expirationDate: new Date(),
            therapistID: getUserData().id,
            roomType: this.props.roomType,
            createdAt: this.props.createdAt,
            roomID: Number(this.props.roomID),
            isVideoOnly: this.props.isVideoOnly,
          });
          this.therapistInfoRef = {
            id: getUserData().id,
            firstName: getUserData().firstName,
          };
        }
        if (
          this.props.roomType === 'psychiatry_room' &&
          this.props.therapistType === 'psychiatrist'
        ) {
          this.props.getEmergencyContact(this.props.clientUserID);
        }
        this.props.getPresentingProblems(this.props.clientUserID);
        if (this.props.customerList.length > 0) {
          this.props
            .getConditions(this.props.customerList[0].userID)
            .then(() => this.props.getJourneyItems());
        }
      });
  }

  componentWillUnmount() {
    this.props.resetInitialCustomerState();
    this.props.resetInitialRoomState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.roomID !== prevProps.match.params.roomID && isIonic()) {
      this.initializeComponent();
    }
  }

  componentDidMount() {
    const { roomID } = this.props.match.params;
    if (roomID) {
      trackEvent('roomOpened', { actionName: 'providerRoomOpened' }, { roomID });
    }
  }

  getChildComponents() {
    const {
      match,
      roomType,
      customerList,
      therapistType,
      customerInformation,
      emergencyContactByClientUserID,
      roomID,
    } = this.props;

    const meetsPsychiatryRequirements =
      (roomType === 'psychiatry_room' &&
        therapistType === 'psychiatrist' &&
        emergencyContactByClientUserID[customerInformation.clientUserID]) ||
      roomType !== 'psychiatry_room' ||
      therapistType !== 'psychiatrist';
    const displayName = getActiveCustomerName(customerInformation, customerList);

    return roomID &&
      customerList &&
      customerList.length &&
      customerInformation &&
      customerInformation.clientUserID &&
      meetsPsychiatryRequirements ? (
      <RoomWrapper>
        <Route path={`${match.path}`} component={CrmMenu} />
        <Route path={`${match.path}/switch-provider`} component={SwitchTherapist} />
        <Route path={`${match.path}/suggest-provider`} component={SuggestTherapist} />
        <Route path={`${match.path}/change-room-type`} component={ChangeRoomType} />
        <Route path={`${match.path}/invites`} component={Invites} />
        <Route path={`${match.path}/create-room`} component={RoomCreation} />
        <Route
          path={`${match.path}/create-psychiatry-room`}
          render={(props) => <RoomCreation {...props} isPsychiatry />}
        />
        <Route path={`${match.path}/video-credits`} component={VideoCredits} />
        <Route path={`${match.path}/video-call-history`} component={VideoCallHistory} />
        <Route path={`${match.path}/informed-consent`} component={InformedConsent} />
        <Route path={`${match.path}/quick-match-answers`} component={QuickMatchAnswers} />
        <Route path={`${match.path}/outcome-measure`} component={OutcomeMeasureDetails} />
        <Route path={`${match.path}/outcome-measure-cancel`} component={OutcomeMeasureCancel} />
        <Route path={`${match.path}/outcome-measure-start`} component={OutcomeMeasureStart} />
        <Route path={`${match.path}/outcome-measure-answers`} component={OutcomeMeasureAnswers} />
        <Route path={`${match.path}/emergency-contact-login`} component={EmergencyContact} />
        <Route path={`${match.path}/client-logins`} component={ClientLogins} />
        <Route path={`${match.path}/intake-questionnaire`} component={IntakeQuestionnaire} />
        <Route
          path={`${match.path}/eligibility-questionnaire`}
          component={EligibilityQuestionnaire}
        />
        <Route path={`${match.path}/matching-intake`} component={MatchingIntake} />
        <Route
          path={`${match.path}/emergency-contact-details`}
          component={EmergencyContactDetails}
        />
        <Route path={`${match.path}/treatment-planner-goals`} component={TreatmentPlannerGoals} />
        <Route
          path={`${match.path}/treatment-planner-objectives`}
          component={TreatmentPlannerObjectives}
        />
        <Route
          path={`${match.path}/treatment-planner-interventions`}
          component={TreatmentPlannerInterventions}
        />
        <Route
          path={`${match.path}/create-new-treatment-plan`}
          component={CreateNewTreatmentPlan}
        />
        <Route path={`${match.path}/archived-treatment-plans`} component={ArchivedTreatmentPlans} />
        <Route
          path={`${match.path}/archived-treatment-plan/:id`}
          component={ArchivedTreatmentPlan}
        />
        <Route path={`${match.path}/dosespot-patient-form`} component={DoseSpotPatientForm} />
        <Route path={`${match.path}/session-summary`} component={SessionSummarization} />
        <Route path={`${match.path}/diagnostic-profiles`} component={DiagnosticProfilePicker} />
        <Route path={`${match.path}/diagnostic-profile`} component={DiagnosticProfile} />
        <Route path={`${match.path}/discharge-and-transfer`} component={TransferClient} />
        <Route
          path={`${match.path}/in-room-scheduling`}
          render={() => (
            <ChatMessageContextProvider>
              <InRoomSchedulingWrapper
                isTherapist
                room={this.roomRef}
                clientDisplayName={displayName}
                therapist={this.therapistInfoRef}
                clientUserID={customerInformation.clientUserID}
              />
            </ChatMessageContextProvider>
          )}
        />
        <Route
          path={`${match.path}/progress-notes/new`}
          render={() => <ProgressNoteForm mode="create" />}
        />
        <Route
          path={`${match.path}/progress-notes/:noteID/edit`}
          render={() => <ProgressNoteForm mode="edit" />}
        />
        <Route
          path={`${match.path}/progress-notes/:noteID/view`}
          render={() => <ProgressNoteForm mode="view" />}
        />
        <Route
          path={`${match.path}/discharge-notes/new`}
          render={() => <DischargeNoteFormNavigator mode="create" />}
        />
        <Route
          path={`${match.path}/discharge-notes/:noteID/edit`}
          render={() => <DischargeNoteFormNavigator mode="edit" />}
        />
        <Route
          path={`${match.path}/discharge-notes/:noteID/delete`}
          render={() => <DeleteDischargeNoteDialog />}
        />
        <Route
          path={`${match.path}/discharge-notes/:noteID/view`}
          render={() => <DischargeNoteForm mode="view" />}
        />
        <Route
          path={`${match.path}/discharge-notes/save-draft`}
          component={SaveDischargeNoteDialog}
        />
        <Route
          path={`${match.path}/discharge-notes/confirm-discharge`}
          component={ConfirmDischargeNote}
        />
        <Route path={`${match.path}/incomplete-notes`} component={IncompleteNotes} />
        <Route
          path={`${match.path}/case-consultation-notes/new`}
          render={() => <CaseConsultationNoteForm mode="create" />}
        />
        <Route
          path={`${match.path}/case-consultation-notes/:noteID/view`}
          render={() => <CaseConsultationNoteForm mode="view" />}
        />
        <Route
          path={`${match.path}/case-consultation-notes/:noteID/edit`}
          render={() => <CaseConsultationNoteForm mode="edit" />}
        />
        <Route
          path={`${match.path}/case-consultation-notes/:noteID/delete`}
          render={() => <DeleteCaseConsultationNoteDialog />}
        />
        <Route
          path={`${match.path}/case-consultation-notes/save-draft`}
          component={SaveCaseConsultationNoteDialog}
        />
        <Route
          path={`${match.path}/collateral-notes/new`}
          render={() => <CollateralNoteForm mode="create" />}
        />
        <Route
          path={`${match.path}/collateral-notes/:noteID/view`}
          render={() => <CollateralNoteForm mode="view" />}
        />
        <Route
          path={`${match.path}/collateral-notes/:noteID/edit`}
          render={() => <CollateralNoteForm mode="edit" />}
        />
        <Route
          path={`${match.path}/collateral-notes/:noteID/delete`}
          component={DeleteCollateralNoteDialog}
        />
        <Route
          path={`${match.path}/collateral-notes/save-draft`}
          component={SaveCollateralNoteDialog}
        />
        <Route
          path={`${match.path}/psychiatry-referral-notes/:noteID/view`}
          render={() => <PsychiatryReferralNoteForm mode="view" />}
        />
        <Route
          path={`${match.path}/psychotherapy-notes/new`}
          render={() => <PsychotherapyNoteForm mode="create" />}
        />
        <Route
          path={`${match.path}/psychotherapy-notes/:noteID/edit`}
          render={() => <PsychotherapyNoteForm mode="edit" />}
        />
        <Route
          path={`${match.path}/psychotherapy-notes/:noteID/delete`}
          component={DeletePsychotherapyNoteDialog}
        />
        <Route
          path={`${match.path}/transfer-notes/:noteID/view`}
          render={() => <TransferNoteForm mode="view" />}
        />
        <Route path={`${match.path}/note-types-explainer`} component={NoteTypeExplanation} />
        <Route path={`${match.path}/reopen-session`} component={ReopenEAPSessionDialog} />
        <Route path={`${match.path}/restart-care`} component={RestartCare} />
        <Route path={`${match.path}/all-unsubmitted-sessions`} component={AllUnsubmittedSessions} />
      </RoomWrapper>
    ) : null;
  }

  render() {
    const {
      presentingProblems,
      customerInformation,
      customerInformation: { gender, dateOfBirth },
      customerList,
    } = this.props;

    const isInFrame = ReactFrameService.instance().isInFrame();

    const descriptionItems = [];
    const userBadgeGender = gender && gender.label;
    const nickname = getActiveCustomerName(customerInformation, customerList);
    const diagnosis = presentingProblems[0] && presentingProblems[0].label;
    const age = dateOfBirth && moment.duration(moment().diff(moment(dateOfBirth))).years();
    if (userBadgeGender) descriptionItems.push(userBadgeGender);
    if (age) descriptionItems.push(age);
    if (diagnosis) descriptionItems.push(diagnosis);
    const description = descriptionItems.join(', ');

    const CRMPanel = (
      <RoomPanel
        hideHeader={isInFrame}
        panelHeader={
          <LeftPanelHeader
            roomID={this.props.roomID}
            nickname={nickname}
            description={description}
          />
        }
      >
        <Route
          path={`${this.props.match.path}/(agenda-tab|case-tab|notes-tab|clinical-tab|lvs-tab)`}
          render={() => <CrmNavBar {...this.props} />}
        />
        {this.getChildComponents()}
      </RoomPanel>
    );

    const ChatPanel = (
      <RoomPanel
        panelHeader={
          <MiddlePanelHeader
            roomID={this.props.roomID}
            nickname={nickname}
            description={description}
          />
        }
      >
        <MessageContainer roomID={this.props.roomID} />
      </RoomPanel>
    );

    return (
      <VideoCallProvider>
        <InRoomSchedulingProvider>
          <RoomRoute className="room-route">
            <CRMChatManager>
              {!isInFrame && (
                <PanelManager.LeftPanel style={{ overflowY: 'hidden' }}>
                  {CRMPanel}
                </PanelManager.LeftPanel>
              )}
              <PanelManager.MiddlePanel style={{ overflowY: 'hidden' }}>
                {isInFrame ? CRMPanel : ChatPanel}
              </PanelManager.MiddlePanel>
              <PanelManager.RightPanel>
                {this.props.isStarredMessagesOpen && <StarredContainer />}
                {this.props.isSharedFilesOpen && <SharedFilesContainer />}
                {this.props.isPostLVSPromptOpen && <PostLVSPrompt />}
              </PanelManager.RightPanel>
            </CRMChatManager>
          </RoomRoute>
        </InRoomSchedulingProvider>
      </VideoCallProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    journey: state.journey,
    roomID: state.room.roomID,
    roomType: state.room.roomType,
    createdAt: state.room.createdAt,
    isVideoOnly: state.room.isVideoOnly,
    clientUserID: state.room.clientUserID,
    conversionDate: state.room.conversionDate,
    customerInformation: state.customerInformation,
    conditions: state.clinicalInformation.conditions,
    customerList: state.customerList.list,
    clinicalInformation: state.clinicalInformation,
    emergencyContactByClientUserID: state.emergencyContact.emergencyContactByClientUserID,
    therapistType: state.availability.therapistType,
    isStarredMessagesOpen: state.room.isStarredMessagesOpen,
    isSharedFilesOpen: state.room.isSharedFilesOpen,
    isPostLVSPromptOpen: state.room.isPostLVSPromptOpen,
    presentingProblems: state.clinicalInformation.presentingProblems,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAvailability,
      getCustomerList,
      getCustomerInformation,
      getEmergencyContact,
      getRoomProperties,
      resetInitialCustomerState,
      resetInitialRoomState,
      getJourneyItems,
      getPresentingProblems,
      getConditions,
    },
    dispatch
  );

Room.contextType = PromiseMessageActionsContext;
const TherapistRoom = connect(mapStateToProps, mapDispatchToProps)(Room);

export default TherapistRoom;
