import { VoidFunctionComponent } from 'react';
import {
  Standard,
  View,
  BaseButton,
  TextAreaRounded,
  COLORS,
  Reminder,
} from '@talkspace/react-toolkit';
import { keyframes } from '@emotion/core';
import styled from '../../../../../core/styled';
import SubmitButton from '../../TaskActionButtons/SubmitButton';
import ssoHelper from '../../../../../modules/utils/sso';

interface ReopenDescriptionScreenProps {
  content?: JSX.Element;
  disableSubmit?: boolean;
  text: string;
  shouldDisplayReminder?: boolean;
  handleText: (userInput: string) => void;
  handleSubmit: () => void;
  handleCancel: () => void;
}

const fadeIn = keyframes`
 0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const CommentWrapper = styled(View)(() => {
  return {
    animation: `${fadeIn} 500ms cubic-bezier(.65,-0.01,.38,1) both`,
    paddingTop: 3,
  };
});

const Container = styled(View)(() => {
  return {
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 12,
  };
});

const ActionTypeButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    marginLeft: 15,
    '& > p': {
      fontWeight: 500,
      color: colors.slateGrey,
    },
  };
});

const getTextAreaContainerStyle = () => {
  return {
    height: 110,
    minHeight: 110,
    borderColor: COLORS.lightFordGrey,
    borderRadius: 5,
    padding: 11,
  };
};

const getTextAreaStyle = () => {
  return {
    margin: 0,
    fontSize: 14,
    padding: 0,
    caretColor: COLORS.permaTalkspaceDarkGreen,
    color: COLORS.black,
  };
};

const ReopenDescriptionScreen: VoidFunctionComponent<ReopenDescriptionScreenProps> = ({
  content,
  disableSubmit,
  text,
  shouldDisplayReminder = false,
  handleText,
  handleSubmit,
  handleCancel,
}) => {
  const textAreaContainerStyle = getTextAreaContainerStyle();
  const textAreaStyle = getTextAreaStyle();
  return (
    <CommentWrapper onClick={(e) => e.preventDefault()}>
      <Container>
        <View style={{ marginBottom: 20, display: 'inline-block' }}>{content}</View>
        <TextAreaRounded
          style={textAreaContainerStyle}
          textAreaStyle={textAreaStyle}
          value={text}
          onChangeText={handleText}
        />
        <View row justify="end" style={{ marginTop: 15 }}>
          <ActionTypeButton onPress={handleCancel}>
            <Standard>Cancel</Standard>
          </ActionTypeButton>
          <SubmitButton
            style={{ width: 134, marginLeft: 20, background: !disableSubmit && COLORS.permaEden }}
            onPress={handleSubmit}
            {...(disableSubmit && { disabled: true })}
          >
            <Standard variant="standardWhite">Reopen</Standard>
          </SubmitButton>
        </View>
      </Container>
      {shouldDisplayReminder && (
        <Reminder
          reminderText="Seven days of access equates to a billable session."
          linkText="Learn more"
          handleClickLink={() => ssoHelper.openZendesk('/hc/en-us/articles/8465130941979')}
          isReminderLabel
        />
      )}
    </CommentWrapper>
  );
};
export default ReopenDescriptionScreen;
