import { useState, useMemo } from 'react';
import {
  Button,
  Large,
  Modal,
  SelectRounded,
  SparklingCalendar,
  TextAreaRounded,
  View,
  shuffleArray,
} from '@talkspace/react-toolkit';
import { ProviderDeclineReasonEnum, ProviderDeclineReasonOption } from 'ts-frontend/types';
import useMutationDeclineBooking from 'inRoomScheduling/hooks/useMutationDeclineBooking';
import { useParams } from '@/core/routerLib';
import DeclineBookingSuccessModal from './DeclineBookingSuccessModal';

interface Props {
  selectedBookingID: string;
  onSuccess: () => void;
  closeModal: () => void;
  hasHadFirstSession?: boolean;
  bookingDatetimeString?: string;
}

const EXCLUDES_AFTER_FIRST_SESSION = [
  ProviderDeclineReasonEnum.CLIENT_NEED_HIGHER_LEVEL_OF_CARE,
  ProviderDeclineReasonEnum.CLIENT_NOT_RIGHT_FOR_PLATFORM,
];

const DeclineBookingModal = ({
  selectedBookingID,
  onSuccess,
  closeModal,
  bookingDatetimeString,
  hasHadFirstSession = true,
}: Props) => {
  const [declineReason, setDeclineReason] = useState<ProviderDeclineReasonEnum>();
  const [showTextInput, setShowTextInput] = useState<boolean>(false);
  const [otherReasonText, setOtherReasonText] = useState<string>();
  const { roomID } = useParams<{ roomID: string }>();

  const { mutate, isSuccess, isLoading, reset } = useMutationDeclineBooking();

  const handleDeclineBooking = () => {
    if (declineReason) {
      mutate(
        {
          roomID,
          bookingID: selectedBookingID,
          data: {
            reason: declineReason,
            ...(declineReason === ProviderDeclineReasonEnum.OTHER
              ? { metadata: { cancellationReason: otherReasonText } }
              : {}),
          },
        },
        {
          onSuccess,
        }
      );
    }
  };

  const selectOptions: ProviderDeclineReasonOption[] = useMemo(
    () => [
      ...shuffleArray([
        {
          value: ProviderDeclineReasonEnum.NOT_AVAILABLE,
          label: 'I’m not available',
        },
        {
          value: ProviderDeclineReasonEnum.AGREED_ON_RESCHEDULE_TIME,
          label: 'We’ve agreed on a reschedule time',
        },
        {
          value: ProviderDeclineReasonEnum.CLIENT_REQUESTED,
          label: 'Client request',
        },
        {
          value: ProviderDeclineReasonEnum.CLIENT_NEED_HIGHER_LEVEL_OF_CARE,
          label: 'The client needs a higher level of care',
        },
        {
          value: ProviderDeclineReasonEnum.CLIENT_NOT_RIGHT_FOR_PLATFORM,
          label: 'The client is not right for this platform',
        },
      ]),
      { value: ProviderDeclineReasonEnum.OTHER, label: 'Other' },
    ],
    []
  );

  if (isSuccess) {
    return declineReason ? (
      <DeclineBookingSuccessModal
        declineReason={declineReason}
        onClose={() => {
          reset();
          closeModal();
        }}
      />
    ) : null;
  }
  const handleOnSelectChange = ({ value }) => {
    setDeclineReason(value);
    if (value === ProviderDeclineReasonEnum.OTHER) {
      setShowTextInput(true);
    } else {
      setShowTextInput(false);
      setOtherReasonText('');
    }
  };

  return (
    <Modal isVisible onBackdropPress={closeModal} titleText="Decline booking">
      <Modal.Panel
        onBackdropPress={closeModal}
        contentViewStyle={{ paddingTop: 0, alignItems: 'center' }}
      >
        <View
          style={{
            marginTop: 9,
            marginBottom: 14,
            textAlign: 'center',
            width: 320,
            alignItems: 'center',
          }}
        >
          <SparklingCalendar />
          <Large inline style={{ marginTop: 30, marginBottom: 40, fontWeight: 600 }}>
            To decline your live session scheduled for
            {bookingDatetimeString}, please select a reason for declining.
          </Large>
          <SelectRounded
            options={
              hasHadFirstSession
                ? selectOptions.filter(
                    (option) => !EXCLUDES_AFTER_FIRST_SESSION.includes(option.value)
                  )
                : selectOptions
            }
            placeholder="Select one"
            onChange={handleOnSelectChange as (OptionType) => void}
          />
          {showTextInput && (
            <TextAreaRounded
              multiline
              placeholder="Please tell us more about your reason for declining"
              dataQa="textInputDeclineReason"
              value={otherReasonText}
              onChangeText={(value) => {
                setOtherReasonText(value);
              }}
              textAreaStyle={{ padding: '16px 0 0' }}
              style={{ marginTop: 8, width: '100%' }}
            />
          )}
        </View>
        <Button
          text="Decline booking"
          onPress={handleDeclineBooking}
          style={{ marginTop: 20, width: 320 }}
          disabled={isLoading || !declineReason || (showTextInput && !otherReasonText)}
        />
      </Modal.Panel>
    </Modal>
  );
};

export default DeclineBookingModal;
