export const COGNITO_2FA_LOGIN_SUCCESS = 'COGNITO_2FA_LOGIN_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const TWO_FACTOR_TOKEN_REQUEST = 'TWO_FACTOR_TOKEN_REQUEST';
export const RECEIVE_OTP_TOKEN = 'RECEIVE_OTP_TOKEN';
export const REQUEST_SESSION_TOKENS = 'REQUEST_SESSION_TOKENS';
export const VERIFY_ERROR = 'VERIFY_ERROR';
export const CLEAR_REDIRECT_TO_LOGIN = 'CLEAR_REDIRECT_TO_LOGIN';
export const REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN';
export const REQUEST_INITIATE_2FA = 'REQUEST_INITIATE_2FA';
export const RECEIVE_INITIATE_2FA = 'RECEIVE_INITIATE_2FA';
export const ERROR_INITIATE_2FA = 'ERROR_INITIATE_2FA';
export const REQUEST_VERIFY_2FA = 'REQUEST_VERIFY_2FA';
export const RECEIVE_VERIFY_2FA = 'RECEIVE_VERIFY_2FA';
export const ERROR_VERIFY_2FA = 'ERROR_VERIFY_2FA';
export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD';
export const RECEIVE_CHANGE_PASSWORD = 'RECIEVE_CHANGE_PASSWORD';
export const ERROR_CHANGE_PASSWORD = 'ERROR_CHANGE_PASSWORD';
export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const RECEIVE_FORGOT_PASSWORD = 'RECIEVE_FORGOT_PASSWORD';
export const ERROR_FORGOT_PASSWORD = 'ERROR_FORGOT_PASSWORD';
export const CLEAR_VERIFY_ERROR = 'CLEAR_VERIFY_ERROR';
export const REQUEST_RESEND_OTP = 'REQUEST_RESEND_OTP';
