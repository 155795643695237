import trackEvent from '../../../modules/analytics/trackEvent';

type ModalType =
  | 'Claim room success modal'
  | 'Already claimed modal'
  | 'Preferences modal'
  | 'General error modal'
  | 'Client information modal';

type ActionType = 'Hide' | 'Show';

type ButtonType = 'Add to caseload button' | 'Send a message button';

const Analytics = {
  trackClaimableRoomsListFetchedEvent: ({
    therapistID,
    numberOfClients,
    activeFiltersList,
  }: {
    therapistID: number;
    numberOfClients: number;
    activeFiltersList: string;
  }) => {
    trackEvent(
      'ClaimableRoomsListFetched',
      { actionName: 'growCaseloadInteraction' },
      {
        numberOfClients,
        therapistID,
        activeFiltersList,
      }
    );
  },

  trackRoomClaimedEvent: ({ therapistID, roomID }: { therapistID: number; roomID: number }) => {
    trackEvent(
      'RoomClaimed',
      { actionName: 'growCaseloadInteraction' },
      {
        therapistID,
        roomID,
      }
    );
  },

  trackClaimableClientInformationViewedEvent: ({
    roomID,
    therapistID,
    presentingProblems,
    clientGender,
    age,
    preference,
    state,
    sessions,
    isTreatmentIntakeCompleted,
  }: {
    roomID: number;
    therapistID: number;
    presentingProblems: string;
    clientGender: string;
    age: number;
    preference: string;
    state: string;
    sessions: string;
    isTreatmentIntakeCompleted: boolean;
  }) => {
    trackEvent(
      'ClaimableClientInformationViewed',
      { actionName: 'growCaseloadInteraction' },
      {
        roomID,
        therapistID,
        presentingProblems,
        clientGender,
        age,
        preference,
        state,
        sessions,
        isTreatmentIntakeCompleted,
      }
    );
  },

  trackModalButtonPressedEvent: ({
    modal,
    button,
    roomID,
    therapistID,
  }: {
    modal: ModalType;
    button: ButtonType;
    roomID?: number;
    therapistID: number;
  }) => {
    trackEvent(
      'ModalButtonPressed',
      { actionName: 'growCaseloadInteraction' },
      {
        modal,
        button,
        roomID,
        therapistID,
      }
    );
  },

  trackModalActionEvent: ({
    modal,
    action,
    roomID,
    therapistID,
  }: {
    modal: ModalType;
    action: ActionType;
    roomID?: number;
    therapistID: number;
  }) => {
    trackEvent(
      'ModalAction',
      { actionName: 'growCaseloadInteraction' },
      {
        modal,
        action,
        roomID,
        therapistID,
      }
    );
  },
};

export default Analytics;
