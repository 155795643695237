import { Mini, Small } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import moment from 'moment';
import { RoomRecord } from 'components/Dashboard/types';

interface StatusV2Props {
  client: RoomRecord;
}

const StatusV2: VoidFunctionComponent<StatusV2Props> = ({ client }) => {
  let renewContent = '';
  let statusContent = '';

  if (client.renewDate) {
    renewContent = `Renew: ${moment(client.renewDate).format('MMM DD')}`;
  }

  if (client.planEnd) {
    renewContent = `Plan end: ${moment(client.planEnd).format('MMM DD')}`;
  }

  statusContent = `${client.roomStatus}`;

  return (
    <>
      <Small variant="smallTSBlack">{statusContent}</Small>
      <Mini>{renewContent}</Mini>
    </>
  );
};

export default StatusV2;
