import { useEmotionTheme } from '@talkspace/react-toolkit';

import { Metric, MetricName, MetricLevelName } from '../types';
import { getMetricColor, getMetricData } from '../utils/metricsHelpers';

interface MetricDisplayParameters {
  title: string;
  subtitle: string;
  titleColor: string;
  valueColor: string;
  description: string;
  displayValue: string;
  levelTitle: string;
  tooltipTitle: string;
  tooltipDescription: string;
}
const useMetricsDisplayParameters = (
  metricName: MetricName,
  metric: Metric | undefined
): MetricDisplayParameters => {
  const { colors } = useEmotionTheme();
  const metricData = getMetricData(metricName, metric);
  const { title, subtitle } = metricData;

  const { metricValue, percentile } = metric || {};
  let displayValue: string;
  let level: MetricLevelName;

  let valueMinutes: number;
  let hours: number;
  let minutes: number;

  switch (metricName) {
    case 'responseTime':
      valueMinutes = metricValue || 0;
      hours = Math.floor(valueMinutes / 60);
      minutes = Math.floor(valueMinutes % 60);
      if (!valueMinutes) {
        level = 'neutral';
      } else if (valueMinutes >= 5 * 60) {
        level = 'bad';
      } else if (valueMinutes >= 3 * 60) {
        level = 'average';
      } else {
        level = 'good';
      }
      displayValue = !valueMinutes ? '-' : `${hours}h ${minutes}m`;
      break;
    case 'retention':
      if ((!percentile && percentile !== 0) || !metricValue) {
        level = 'neutral';
      } else if (percentile <= 50) {
        level = 'good';
      } else if (percentile <= 80) {
        level = 'average';
      } else {
        level = 'bad';
      }
      displayValue = !metricValue ? '-' : `${metricValue}%`;
      break;
    case 'progressReportsAvg':
      if (!metricValue) {
        level = 'neutral';
      } else if (metricValue >= 3) {
        level = 'good';
      } else if (metricValue >= 2) {
        level = 'average';
      } else {
        level = 'bad';
      }
      displayValue = !metricValue ? '-' : `${metricValue}`;
      break;
    case 'b2BSessions':
      if (!metricValue) {
        level = 'neutral';
      } else if (metricValue >= 3) {
        level = 'good';
      } else if (metricValue >= 2) {
        level = 'average';
      } else {
        level = 'bad';
      }
      displayValue = !metricValue ? '-' : `${metricValue}`;
      break;
    default:
      level = 'neutral';
      displayValue = '-';
  }
  const color = getMetricColor(level, colors);
  const titleColor = color;
  let valueColor = color;
  if (level === 'neutral') {
    valueColor = colors.gray;
  }
  const { levelTitle, description, tooltipTitle, tooltipDescription } = metricData.levels[level];
  return {
    title,
    subtitle,
    titleColor,
    valueColor,
    description,
    displayValue,
    levelTitle,
    tooltipTitle,
    tooltipDescription,
  };
};

export default useMetricsDisplayParameters;
