import './Overdue.css';
import moment from 'moment';

const meridianHours = (localDate) => {
  const hours = localDate.hours();

  const meridian = hours >= 12 ? 'PM' : 'AM';
  let newHours = hours % 12;
  newHours = newHours || 12;

  return {
    newHours,
    meridian,
  };
};

const formatReply = (localDate) => {
  const { newHours, meridian } = meridianHours(localDate);

  const endOfDay = moment().local().endOf('day');

  // if today show only hours
  if (endOfDay.isSameOrAfter(localDate)) return `${newHours}${meridian}`;
  // before end of tomorrow show 'tomorrow'
  if (endOfDay.add(1, 'day').isSameOrAfter(localDate)) {
    if (newHours === 12 && meridian === 'AM') return 'Midnight today';
    return `${newHours}${meridian} tomorrow`;
  }
  // if current week state day-name otherwise date
  const inCurrentWeek = moment().local().endOf('week').isSameOrAfter(localDate);
  return inCurrentWeek
    ? `${newHours}${meridian} ${localDate.format('dddd')}`
    : `${newHours}${meridian} ${localDate.format('MMM, Do')}`;
};

const getOrdinal = (num) => {
  if (!Number.isFinite(num)) return num;

  const absNum = Math.abs(num);
  const cent = absNum % 100;
  if (cent >= 10 && cent <= 20) return `${num}th`;
  const dec = absNum % 10;
  if (dec === 1) return `${num}st`;
  if (dec === 2) return `${num}nd`;
  if (dec === 3) return `${num}rd`;
  return `${num}th`;
};

const Overdue = (props) => {
  const client = props.user;
  const now = moment().utc();

  let timeContent = <></>;
  let textContent = <></>;

  if (
    (client.sla_status === 'waiting' || client.sla_status === 'resolved') &&
    !client.sla_misses_current_week
  ) {
    textContent = <p>Keep it up, you&apos;ve replied on time this week</p>;
  }

  if (client.sla_reply_by) {
    if (
      moment(client.sla_original_reply_by).isSameOrAfter(now) &&
      client.sla_status === 'waiting'
    ) {
      const time_left_difference = moment.duration(moment(client.sla_reply_by).diff(now));

      const replyByDate = formatReply(moment(client.sla_reply_by).local());

      timeContent =
        time_left_difference.asHours() < 1 ? (
          <span className="sla-reply-warning">
            {' '}
            Reply within {time_left_difference.minutes()} minutes
          </span>
        ) : (
          <span className="sla-reply-normal"> Reply by {replyByDate} </span>
        );
    } else {
      const time_overdue_difference = moment.duration(
        now.diff(moment(client.sla_original_reply_by))
      );

      const overdueMessage = () => {
        if (time_overdue_difference.asHours() < 1) {
          return `${Math.trunc(time_overdue_difference.asMinutes())} min`;
        }
        const days = time_overdue_difference.days();
        const hours = time_overdue_difference.hours();
        const minutes = time_overdue_difference.minutes();
        return days > 0 ? `${days} day(s) ${hours}h ${minutes}min` : `${hours}h ${minutes}min`;
      };

      const replyByDate = formatReply(moment(client.sla_reply_by).local());

      switch (client.sla_status) {
        case 'waiting':
          timeContent = (
            <span className={props.isMobile ? 'sla-overdue-heading-mobile' : 'sla-overdue-heading'}>
              <>
                {overdueMessage()} overdue{' '}
                {!!client.sla_extended_count &&
                  (client.sla_extended_count === 1
                    ? ` - Extension until ${replyByDate}`
                    : ` - ${getOrdinal(client.sla_extended_count)} extension until ${replyByDate}`)}
              </>
            </span>
          );
          break;
        case 'missed':
          timeContent = (
            <span className={props.isMobile ? 'sla-overdue-heading-mobile' : 'sla-overdue-heading'}>
              <>
                {overdueMessage()} overdue{' '}
                {!!client.sla_extended_count &&
                  (client.sla_extended_count === 1
                    ? ' - Extension missed'
                    : ` - ${getOrdinal(client.sla_extended_count)} extension missed`)}
              </>
            </span>
          );
          break;
        default:
          break;
      }
    }
  }

  let missedReplyMessage = '';

  switch (client.sla_misses_current_week) {
    case 1:
      missedReplyMessage = `
      Reply on time to meet your client's expectations.
      `;
      break;
    case 2:
      missedReplyMessage =
        ' To prevent negatively impacting your payout, please reply on time the rest of this week.';
      break;
    default:
      missedReplyMessage =
        ' Client provided with one week credit option, negatively impacting your payout.';
  }

  if (client.sla_misses_current_week >= 1) {
    textContent = (
      <p className={props.isMobile ? 'sla-overdue-normal-mobile' : 'sla-overdue-normal'}>
        <img
          alt="talkspace"
          src={require('../../../Icons/circle-exclamation.svg')}
          className={props.isMobile ? 'sla-overdue-alert-mobile' : 'sla-overdue-alert'}
        />
        <p className="sla-overdue-text">
          {client.sla_misses_current_week}/2 <span className="sla-overdue-bold">Reply By</span>
          &nbsp;misses this week.{missedReplyMessage}
        </p>
      </p>
    );
  }

  return (
    <>
      {timeContent}
      {textContent}
    </>
  );
};

export default Overdue;
