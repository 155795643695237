import * as actionTypes from '../constants/VideoCreditsConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

export function requestVideoCredits(roomID) {
  return {
    type: actionTypes.REQUEST_VIDEO_CREDITS,
    roomID,
  };
}

export function receiveVideoCredits(videoCredits) {
  return {
    type: actionTypes.RECEIVE_VIDEO_CREDITS,
    videoCredits: videoCredits.data,
  };
}

export function requestVideoCreditsError(error) {
  return {
    type: actionTypes.REQUEST_VIDEO_CREDITS_ERROR,
    error,
  };
}

export const getVideoCredits = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  dispatch(requestVideoCredits(roomID));
  apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/video-credits`)
    .then((response) => {
      dispatch(receiveVideoCredits(response.data));
    })
    .catch((error) => {
      dispatch(requestVideoCreditsError(error));
    });
};
