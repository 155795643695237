import { FunctionComponent } from 'react';
import { View, styled, ExtraBig, Tooltip, COLORS } from '@talkspace/react-toolkit';
import SingleObjective from './SingleObjective';
import { Objective } from './types';
import { progressIcons } from './utils';

interface ObjectivesProps {
  objectives: Objective[];
  // eslint-disable-next-line react/no-unused-prop-types
  treatmentPlannerID: string;
}

const ObjectiveIconWrapper = styled(View)({
  width: '15%',
});

const ObjectiveTextWrapper = styled(View)({
  width: '80%',
});

const ObjectiveWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontFamily: 'Roboto',
    color: colors.black,
    marginTop: 10,
  };
});

const ObjectivesWrapper = styled(View)({
  marginTop: 25,
});

const Objectives: FunctionComponent<ObjectivesProps> = ({ objectives }) => (
  <ObjectivesWrapper>
    {objectives.length > 0 && <ExtraBig>Objectives</ExtraBig>}
    {objectives.map((objective) => {
      const progressIcon =
        progressIcons[objective.overallProgress ? objective.overallProgress : 'no_progress'];
      return (
        <ObjectiveWrapper key={objective.id}>
          <ObjectiveIconWrapper>
            <Tooltip
              messageStyle={{
                backgroundColor: `${COLORS.white} !important`,
                color: `${COLORS.veryDarkGray} !important`,
                border: `1px solid ${COLORS.veryLightGray}`,
                boxShadow: `-3px 3px 3px ${COLORS.transparentLightGray}`,
                paddingLeft: '20px',
              }}
              tip={progressIcon.iconTooltipText}
            >
              {progressIcon.icon}
            </Tooltip>
          </ObjectiveIconWrapper>
          <ObjectiveTextWrapper>
            <SingleObjective objective={objective} />
          </ObjectiveTextWrapper>
        </ObjectiveWrapper>
      );
    })}
  </ObjectivesWrapper>
);
export default Objectives;
