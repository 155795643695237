import { useCallback, useEffect, useState } from 'react';
import {
  Tiny,
  View,
  BaseButton,
  ExtraHuge,
  Input,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { keyframes } from '@emotion/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createCookie } from 'ts-frontend/utils';
import { clearIonicStorage } from 'ts-ionic/plugins/secureStorage';
import { Link as RouterLink } from '../../core/routerLib';
import { login } from '../../reducers/auth';
import { handleSSOAfterLogin } from '../../utils/ssoHelper';
import styled from '../../core/styled';
import './Login.css';
import { removeLogoutLastActivity } from '../../utils/token';
import storage from '../../core/storage';
import trackEvent from '../../modules/analytics/trackEvent';
import { isIonic } from '@/auth/reactFrame/helpers';
import Navbar from './Navbar';

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

const TextButton = styled(Tiny)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'left !important',
  };
});

const Label = styled(Tiny)(({ theme: { colors } }) => {
  return {
    color: colors.grey950,
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left !important',
    marginBottom: 4,
  };
});

export const ErrorBanner = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    borderRadius: 8,
    padding: 12,
    background: colors.softPink,
    fontSize: 12,
    textAlign: 'left',
    color: colors.permaFuchsia,
  };
});

export const Spinner = styled(View)(({ theme: { colors }, color }) => {
  return {
    width: 24,
    height: 24,
    animation: `${spin} 1s linear infinite`,
    borderRadius: '50%',
    border: `2px solid ${color || colors.white}`,
    borderTop: '2px solid transparent',
    justifySelf: 'center',
  };
});

const Wrapper = styled(View)({
  textAlign: 'center',
  marginTop: 83,
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledButton = styled(BaseButton)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? '100%' : 430,
      background: colors.accessibilityGreenDark,
      borderRadius: 10,
      color: colors.white,
      padding: '14px 24px',
      fontSize: 16,
      fontWeight: 600,
      laterSpacing: '0.16px',
      marginTop: 24,
      display: 'grid',
    };
  }
);

const StyledInput = styled(Input)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? '100%' : 430,
      backgroundColor: colors.permaPhantomWhite,
      borderRadius: 8,
      padding: 12,
      fontSize: 16,
      border: `1px solid ${colors.periwinkleGrey}`,
      height: 48,
    };
  }
);

const TherapistLogin = ({ login, history }) => {
  const [loginError, setLoginError] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { colors } = useEmotionTheme();

  useEffect(() => {
    clearIonicStorage();
    removeLogoutLastActivity();
  }, []);

  const onLoginError = (err) => {
    const errorString = err.toString().replace('Error: ', '');
    if (errorString.includes('401')) {
      setLoginError('Incorrect email or password');
    } else if (errorString.includes('429')) {
      const errorMessageWithoutCode = errorString.replace('429', '');
      setLoginError(errorMessageWithoutCode);
    } else if (errorString.includes('500')) {
      setLoginError('Internal server error, please contact support');
    } else {
      setLoginError(errorString);
    }
  };

  const onLogin = useCallback(
    async (event) => {
      event?.preventDefault();
      setIsLoading(true);
      if (!password?.length || !email?.length) {
        setIsLoading(false);
        setLoginError('Please fill in your email and password');
        return;
      }

      try {
        const didBypassTwoFactor = await login(email, password);

        if (didBypassTwoFactor) {
          handleSSOAfterLogin();
          const redirectUrl = storage.getItem('redirectUrl');
          storage.removeItem('redirectUrl');
          createCookie('_tsIsProvider', true);

          trackEvent('login', { actionName: 'activeExperiments' });

          if (redirectUrl) {
            history.push(redirectUrl);
          } else if (isIonic()) {
            history.push('/passcode');
          } else {
            history.push('/rooms');
          }
        } else {
          history.push(`/two-factor${window.location.search}`);
        }
      } catch (err) {
        setIsLoading(false);
        onLoginError(err);
      }
    },
    [email, history, login, password]
  );

  return (
    <>
      <Navbar />
      <Wrapper>
        <View>
          <ExtraHuge>Log in to your account</ExtraHuge>
          <View style={{ marginTop: 40 }}>
            <Label>Email</Label>
            <StyledInput
              placeholder="Email"
              type="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              dataQa="loginEmailInput"
            />
          </View>
          <View style={{ marginTop: 16 }}>
            <Label>Password</Label>
            <StyledInput
              style={{ fontSize: password && 22 }}
              placeholder="Password"
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              dataQa="loginPasswordInput"
            />
          </View>

          {loginError && (
            <ErrorBanner style={{ marginTop: 24 }} data-qa="loginErrorBanner">
              <Tiny style={{ fontSize: 12, color: colors.permaFuchsia }}>{loginError}</Tiny>
              <RouterLink to="/forgot-password">
                <TextButton
                  style={{ fontSize: 12, textDecoration: 'underline' }}
                  dataQa="forgotPasswordButton"
                >
                  Forgot password?
                </TextButton>
              </RouterLink>
            </ErrorBanner>
          )}
          {!loginError && (
            <RouterLink to="/forgot-password" style={{ marginTop: 10 }}>
              <TextButton dataQa="forgotPasswordButton">Forgot password?</TextButton>
            </RouterLink>
          )}
          <StyledButton
            type="submit"
            onClick={onLogin}
            style={{ background: isLoading && colors.paleBluGray }}
            dataQa="loginButton"
          >
            {isLoading ? <Spinner /> : 'Log in'}
          </StyledButton>
        </View>
      </Wrapper>
    </>
  );
};

const mapDispatchToProps = { login };
const Login = connect(null, mapDispatchToProps)(TherapistLogin);

export default withRouter(Login);
