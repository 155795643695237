import { EmotionTheme } from '@talkspace/react-toolkit';

import {
  MetricName,
  MetricLevelName,
  ResponseTimeMetric,
  RetentionMetric,
  ProgressReportsMetric,
  B2BSessionsMetric,
} from '../types';

export const getMetricColor = (level: MetricLevelName, colors: EmotionTheme['colors']) =>
  ({
    bad: colors.jellybeanRed,
    average: colors.yellowSea,
    good: colors.limaGreen,
    neutral: colors.black,
  }[level]);

export const tooltipEmptyState: { tooltipTitle: string; tooltipDescription: string } = {
  tooltipTitle: 'Metrics are not available yet',
  tooltipDescription:
    'Metrics are available after working with a minimum of 1 client for at least 30 days.',
};

export const getRoundedPercentileText = (
  percentile?: number | null,
  percentileCutoff: number = 50
) => {
  if (percentile === 0) return 'Top 1% of providers';
  if (percentile === 100) return 'Bottom 10% of providers';

  const roundedPercentile = percentile ? Math.ceil(percentile / 10) * 10 : 0;
  const flippedPercentile = percentile ? Math.ceil((100 - percentile) / 10) * 10 : 0;
  const title =
    percentile && percentile <= percentileCutoff
      ? `Top ${roundedPercentile}% of providers`
      : `Bottom ${flippedPercentile}% of providers`;
  return title;
};

const goodTitle = 'Exceeds expectations';
const averageTitle = 'Meets expectations';
const badTitle = 'Below expectations';

type MetricLevelValue = {
  [key in MetricLevelName]: {
    levelTitle: string;
    description: string;
    tooltipTitle: string;
    tooltipDescription: string;
  };
};
interface MetricDataMap {
  title: string;
  subtitle: string;
  levels: MetricLevelValue;
}
const getResponseTimeData = (metric: ResponseTimeMetric): MetricDataMap => {
  return {
    title: 'Average response time to new clients',
    subtitle: 'Last 30 days',
    levels: {
      good: {
        description:
          'The time between a new client matching with you and your first message to them is under 3 hrs. This metric takes into account time off and working days.',
        levelTitle: goodTitle,
        tooltipTitle: goodTitle,
        tooltipDescription:
          'The time between a new client matching with you and your first message to them is under 3 hrs.',
      },
      average: {
        description:
          'The time between a new client matching with you and your first message to them is between 3 -5 hrs. Response times matter when building rapport in online therapy. Try to respond to users within 3 hours of their first message. This metric takes into account time off and working days.',
        levelTitle: averageTitle,
        tooltipTitle: averageTitle,
        tooltipDescription:
          'The time between a new client matching with you and your first message to them is between 3 - 5 hrs.',
      },
      bad: {
        description:
          'The time between a new client matching with you and your first message to them is over 5 hrs. Response times matter when building rapport in online therapy. Try to respond to users within 3 hours of their first message. This metric takes into account time off and working days.',
        levelTitle: badTitle,
        tooltipTitle: badTitle,
        tooltipDescription:
          'The time between a new client matching with you and your first message to them is over 5 hrs.',
      },
      neutral: {
        description:
          'The time between a new client matching with you and your first message to them.',
        levelTitle: '-',
        ...tooltipEmptyState,
      },
    },
  };
};

const getRetentionData = (metric: RetentionMetric): MetricDataMap => {
  const { percentile, additionalData } = metric || {};
  const { churnedClients, totalClients } = additionalData || {};
  const title = getRoundedPercentileText(percentile, 80);
  const description = `Over the last 35 days, ${churnedClients || 0} of your ${
    totalClients || 0
  } clients on consumer plans switched to another provider or ended their subscription within 35 days of being matched to you.`;
  return {
    title: 'New consumer retention rate',
    subtitle: 'Last 35 days',
    levels: {
      good: {
        description,
        levelTitle: title,
        tooltipTitle: title,
        tooltipDescription: description,
      },
      average: {
        description,
        levelTitle: title,
        tooltipTitle: title,
        tooltipDescription: description,
      },
      bad: {
        description,
        levelTitle: title,
        tooltipTitle: title,
        tooltipDescription: description,
      },
      neutral: {
        description:
          'Number of clients on consumer plans that switched to another provider or ended their subscription within 35 days of being matched to you',
        levelTitle: '-',
        ...tooltipEmptyState,
      },
    },
  };
};

const getProgressReportsData = (metric: ProgressReportsMetric): MetricDataMap => {
  const description =
    'Progress notes should be submitted after each live session or after 7 days of messaging therapy. Aim for 4 progress notes per client per month.';
  return {
    title: 'Consumer progress notes completion per client',
    subtitle: 'Last 30 days',
    levels: {
      good: {
        description,
        levelTitle: goodTitle,
        tooltipTitle: goodTitle,
        tooltipDescription: description,
      },
      average: {
        description,
        levelTitle: averageTitle,
        tooltipTitle: averageTitle,
        tooltipDescription: description,
      },
      bad: {
        description,
        levelTitle: badTitle,
        tooltipTitle: badTitle,
        tooltipDescription: description,
      },
      neutral: {
        description,
        levelTitle: '-',
        ...tooltipEmptyState,
      },
    },
  };
};

const getB2BSessionsData = (metric: B2BSessionsMetric): MetricDataMap => {
  const description =
    'Session summary notes should be submitted after each live session or after 7 days of messaging therapy. Aim for 4 session summary notes per client per month.';
  return {
    title: 'BH summary notes submitted per client',
    subtitle: 'Last 30 days',
    levels: {
      good: {
        description,
        levelTitle: goodTitle,
        tooltipTitle: goodTitle,
        tooltipDescription: description,
      },
      average: {
        description,
        levelTitle: averageTitle,
        tooltipTitle: averageTitle,
        tooltipDescription: description,
      },
      bad: {
        description,
        levelTitle: badTitle,
        tooltipTitle: badTitle,
        tooltipDescription: description,
      },
      neutral: {
        description,
        levelTitle: '-',
        ...tooltipEmptyState,
      },
    },
  };
};

export const getMetricData = (metricName: MetricName, metric): MetricDataMap => {
  switch (metricName) {
    case 'responseTime':
      return getResponseTimeData(metric);
    case 'retention':
      return getRetentionData(metric);
    case 'progressReportsAvg':
      return getProgressReportsData(metric);
    case 'b2BSessions':
      return getB2BSessionsData(metric);
    default:
      return {
        title: '',
        subtitle: '',
        levels: Object.fromEntries(
          ['bad', 'average', 'good', 'neutral'].map((level) => [
            level,
            { description: '', tooltipTitle: '', tooltipDescription: '' },
          ])
        ) as MetricLevelValue,
      };
  }
};
