import { FormControl, FormGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './FreeFormInput.css';

const FreeFormInputView = (props) => (
  <div className={`ts-component-free-form ${props.disabled ? 'disabled' : ''} `}>
    <div className={`faux-placeholder ${props.placeholderState}`}>{props.placeholder}</div>
    <FormGroup controlId={props.id}>
      <FormControl
        disabled={props.disabled}
        className="input-element"
        type="text"
        value={props.inputValue}
        onChange={props.changeHandler}
        onBlur={props.blurHandler}
        onFocus={props.focusHandler}
      />
      <div className={`error-icon ${props.valid ? 'hidden' : ''}`} />
      <FormControl.Feedback />
    </FormGroup>
  </div>
);

FreeFormInputView.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  changeHandler: PropTypes.func,
  inputValue: PropTypes.string,
};

export default FreeFormInputView;
