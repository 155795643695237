export const REQUEST_EMERGENCY_CONTACT = 'REQUEST_EMERGENCY_CONTACT';
export const REQUEST_EMERGENCY_CONTACT_ERROR = 'REQUEST_EMERGENCY_CONTACT_ERROR';
export const RECEIVE_EMERGENCY_CONTACT = 'RECEIVE_EMERGENCY_CONTACT';

export const REQUEST_SET_EMERGENCY_CONTACT = 'REQUEST_SET_EMERGENCY_CONTACT';
export const REQUEST_SET_EMERGENCY_CONTACT_ERROR = 'REQUEST_SET_EMERGENCY_CONTACT_ERROR';
export const RECEIVE_SET_EMERGENCY_CONTACT = 'RECEIVE_SET_EMERGENCY_CONTACT';

export const REQUEST_EMERGENCY_CONTACT_MESSAGE = 'REQUEST_EMERGENCY_CONTACT_MESSAGE';
export const REQUEST_EMERGENCY_CONTACT_MESSAGE_ERROR = 'REQUEST_EMERGENCY_CONTACT_MESSAGE_ERROR';
export const RECEIVE_EMERGENCY_CONTACT_MESSAGE = 'RECEIVE_EMERGENCY_CONTACT_MESSAGE';

export const REQUEST_CLIENT_LOGINS = 'REQUEST_CLIENT_LOGINS';
export const REQUEST_CLIENT_LOGINS_ERROR = 'REQUEST_CLIENT_LOGINS_ERROR';
export const RECEIVE_CLIENT_LOGINS = 'RECEIVE_CLIENT_LOGINS';
