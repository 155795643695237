import { PanelManager } from '@talkspace/react-toolkit';
import TherapistWebPanelManager from 'components/TherapistWebPanelManager';
import { VoidFunctionComponent } from 'react';
import { connect } from 'react-redux';
import { isTherapistCT, TherapistType } from 'ts-frontend/entities/Therapist';
import DashboardCT from './DashboardCT';

interface TherapistDashboardProps {
  therapistType: TherapistType;
}

const TherapistDashboard: VoidFunctionComponent<TherapistDashboardProps> = ({ therapistType }) => {
  const isCT = isTherapistCT(therapistType);

  return isCT ? (
    <DashboardCT />
  ) : (
    <PanelManager>
      <TherapistWebPanelManager />
    </PanelManager>
  );
};

const mapStateToProps = (state) => {
  return {
    therapistType: state.availability.therapistType,
  };
};

const Dashboard = connect(mapStateToProps)(TherapistDashboard);

export default Dashboard;
