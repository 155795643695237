import './Status.css';
import moment from 'moment';

const Status = ({ client }) => {
  let renewContent = '';
  let statusContent = '';

  if (client.renew_date) {
    renewContent = `Renew: ${moment(client.renew_date).format('MMM DD')}`;
  }

  if (client.plan_end) {
    renewContent = `Plan end: ${moment(client.plan_end).format('MMM DD')}`;
  }

  statusContent = `${client.room_status}`;

  return (
    <>
      <span>{statusContent}</span>
      <span className="renew-content-block">{renewContent}</span>
    </>
  );
};

export default Status;
