import { Fragment, Component } from 'react';
import '../../Dashboard.css';
import moment from 'moment';
import {
  ButtonGroup,
  Button,
  FormControl,
  FormGroup,
  Pagination,
  MenuItem,
  Dropdown,
  InputGroup,
  DropdownButton,
} from 'react-bootstrap';
import isEqual from 'lodash/isEqual';
import {
  SingleDatePickerWithInput,
  View,
  useWindowWidthState,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import styled from '../../../../core/styled';
import Overdue from '../../Cells/Overdue/Overdue';
import Status from '../../Cells/Status/Status';
import StatusMobile from '../../Cells/StatusMobile/StatusMobile';

import { DashboardCell, DashboardRow, DashboardHeader } from '../../DashboardTable';
import DashboardCellRoomDetail from '../../Cells/DashboardCellRoomDetail/DashboardCellRoomDetail';
import DashboardTablePlaceholder from '../../Placeholders/DashboardTablePlaceholder';
import IconGrow from '../../Placeholders/IconGrow';

const AllClientsContentWrapper = styled(View)({
  paddingBottom: 20,
});

const AllClientsTitleWrapper = styled(View)({
  marginBottom: 0,
  paddingTop: 14,
  minHeight: 48,
});

const ColGroupForView = ({ view }) => {
  const { isDesktop } = useWindowWidthState();
  if (!isDesktop) {
    return null;
  }
  if (view === 'follow_up') {
    return (
      <colgroup>
        <col span="1" style={{ width: '10%' }} />
        <col span="1" style={{ width: '10%' }} />
        <col span="1" style={{ width: '20%' }} />
        <col span="1" style={{ width: '10%' }} />
        <col span="1" style={{ width: '10%' }} />
        <col span="1" style={{ width: '20%' }} />
        <col span="1" style={{ width: '10%' }} />
        <col span="1" />
      </colgroup>
    );
  }
  if (view === 'paying') {
    return (
      <colgroup>
        <col span="1" style={{ width: '25%' }} />
        <col span="1" style={{ width: '12%' }} />
        <col span="1" style={{ width: '12%' }} />
        <col span="1" style={{ width: '15%' }} />
        <col span="1" style={{ width: '12%' }} />
        <col span="1" />
      </colgroup>
    );
  }
  // 'all' or 'consultation'
  return (
    <colgroup>
      <col span="1" style={{ width: '10%' }} />
      <col span="1" style={{ width: '9%' }} />
      <col span="1" style={{ width: '15%' }} />
      <col span="1" style={{ width: '9%' }} />
      <col span="1" style={{ width: '9%' }} />
      <col span="1" style={{ width: '18%' }} />
      <col span="1" style={{ width: '9%' }} />
      <col span="1" style={{ width: '9%' }} />
      <col span="1" />
    </colgroup>
  );
};

const FollowUpHeaderRow = (props) => {
  const { followUpGroupOpen, followUpsArray } = props;
  const { isDesktop } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const cellProps = isDesktop ? { colSpan: 8 } : { style: { width: '100%' } };
  return (
    <DashboardRow style={{ backgroundColor: colors.green }}>
      <DashboardCell className="unstackable" {...cellProps}>
        <i
          className={
            followUpGroupOpen
              ? 'fa fa-chevron-down fa-1x pull-left'
              : 'fa fa-chevron-right fa-1x pull-left'
          }
          style={{ padding: '4px' }}
        />
        {followUpsArray}
      </DashboardCell>
    </DashboardRow>
  );
};

class AllClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      activePage: 1,
      numPerPage: 25,
      currentRooms: this.props.allRoomsData.length,
      followUpsArray: [],
      searchType: 'Nickname',
      searchTypeOpen: false,
      caseLoadOpen: false,
      followUpGroupOpen: {},
    };
  }

  onGroupHeaderClick = (event) => {
    const { followUpGroupOpen } = this.state;
    followUpGroupOpen[event.currentTarget.dataset.key] =
      !followUpGroupOpen[event.currentTarget.dataset.key];
    this.setState({
      followUpGroupOpen,
    });
  };

  getSortIcon = (columnName) => {
    const { columnName: sortedColumnName, ascending: isAscending } = this.props.sort;
    const isSorted = columnName === sortedColumnName;
    let text = '▲▼';
    if (isSorted) text = isAscending ? '▼' : '▲';
    return (
      <span
        style={{ marginLeft: 2, color: isSorted ? 'rgba(0,0,0,.87)' : 'lightGrey' }}
        data-column-name={columnName}
      >
        {text}
      </span>
    );
  };

  getViewDisplayName = (view) => {
    const displayNameMapping = {
      all: 'All',
      paying: 'Paying',
      follow_up: 'Follow Up',
      consultation: 'Consultation',
    };

    return displayNameMapping[view] || '';
  };

  setFollowUpsArray = ({ maintainClosed = false } = {}) => {
    const sortOrder = {
      'Traffic Control: Good': 1,
      'Traffic Control: No reply': 2,
      'Traffic Control: Freebie': 3,
      'Paying rooms with no conditions defined': 4,
      'Missing acuity': 5,
      'Discharge details missing': 6,
    };

    const followUpsArray = [];
    const followUpGroupOpen = {};

    this.props.allRoomsData.forEach((room) => {
      if (!followUpsArray.some((k) => k.key === room.follow_up) && room.follow_up !== null) {
        followUpsArray.push({ key: room.follow_up, value: room.followUpTitle });
        const oldOpen = this.state.followUpGroupOpen[room.follow_up];
        followUpGroupOpen[room.follow_up] = maintainClosed
          ? oldOpen !== undefined
            ? oldOpen
            : false
          : false;
      }
    });
    const sortedArr = followUpsArray.sort(({ value: aValue }, { value: bValue }) => {
      if (sortOrder[aValue] || sortOrder[bValue]) {
        return (
          (sortOrder[aValue] || Number.MAX_SAFE_INTEGER) -
          (sortOrder[bValue] || Number.MAX_SAFE_INTEGER)
        );
      }
      return aValue > bValue ? 1 : -1;
    });
    this.setState({ followUpsArray: sortedArr, followUpGroupOpen });
  };

  setSLAMarginLeft(sentClient) {
    const client = sentClient;
    const now = moment().utc();

    let styleSpecificTd = 'unstackable row-within-rows nickname';
    if (
      client.sla_original_reply_by &&
      this.props.slaFeatureActive &&
      ['waiting', 'missed'].indexOf(client.sla_status) != -1
    ) {
      if (moment(client.sla_original_reply_by).isSameOrAfter(now)) {
        const time_left_difference = moment.duration(
          moment(client.sla_original_reply_by).diff(now)
        );
        styleSpecificTd +=
          time_left_difference.asHours() < 1
            ? ' sla-reply-warning-border'
            : ' sla-reply-normal-border';
      } else {
        styleSpecificTd += ' sla-overdue-alert-border';
      }
    }

    return styleSpecificTd;
  }

  setSearchType = (event) => {
    this.setState({
      searchType: event.target.name,
      searchString: '',
      searchTypeOpen: false,
    });
  };

  closeAllDropDown = () => {
    this.setState({
      searchTypeOpen: false,
      caseLoadOpen: false,
    });
  };

  toggleSearchTypeOpen = (event) => {
    this.setState({
      searchTypeOpen: !this.state.searchTypeOpen,
    });
  };

  toggleCaseLoadOpen = (event) => {
    this.setState({
      caseLoadOpen: !this.state.caseLoadOpen,
    });
  };

  changeNumPerPage = (event) => {
    this.setState({
      numPerPage: parseInt(event.target.value, 10),
    });
  };

  matchesSearchString = (client) => {
    switch (this.state.searchType) {
      case 'Nickname':
        const nameString = client.nick_name || '';
        return nameString.toLowerCase().includes(this.state.searchString);
      case 'User':
        const userId = client.client_user_id !== null ? String(client.client_user_id) : '';
        return userId.includes(this.state.searchString);
      case 'Room':
        const roomId = client.private_talk_id !== null ? String(client.private_talk_id) : '';
        return roomId.includes(this.state.searchString);
      default:
        return false;
    }
  };

  filterData = (data) =>
    data
      .filter((client) => this.matchesSearchString(client))
      .reverse()
      .slice(
        (this.state.activePage - 1) * this.state.numPerPage,
        (this.state.activePage - 1) * this.state.numPerPage + this.state.numPerPage
      );

  handleSearch = (event) => {
    const searchTerm = event.target.value;
    const activePage = searchTerm && searchTerm.length > 0 ? 1 : this.state.activePage;
    this.setState({ searchString: searchTerm.toLowerCase(), activePage });
  };

  handleSelect = (eventKey) => {
    this.setState({
      activePage: eventKey,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.view === 'follow_up' &&
      !isEqual(prevProps.allRoomsData, this.props.allRoomsData)
    ) {
      this.setFollowUpsArray({ maintainClosed: true });
    }

    if (prevProps.shouldCloseDropDown === false && this.props.shouldCloseDropDown) {
      this.closeAllDropDown();
    }
  }

  render() {
    const timeFormatter = (cell, row) => {
      if (cell === null) {
        return '';
      }
      const lastActivityTime = moment(cell).fromNow();
      return lastActivityTime;
    };

    const timeAgoFormatter = (cell, row) => {
      if (cell === null) {
        return '';
      }
      const lastActivityTime = `${moment(cell).diff(moment.now(), 'days')} days`;
      return lastActivityTime;
    };

    const monthFormatter = (cell, row) => {
      if (cell === null) {
        return '';
      }
      const lastActivityTime = moment(cell).format('L');
      return lastActivityTime;
    };

    const isEngaged = (cell) => {
      if (cell === 0) {
        return 'tdCircleRed';
      }
      if (cell === 1) {
        return 'tdCircleYellow';
      }
      return 'tdCircleGreen';
    };

    const highlightRow = (client) => {
      const now = moment().utc();

      if (
        this.props.view === 'all' ||
        this.props.view === 'consultation' ||
        this.props.view === 'follow_up'
      ) {
        return client.room_status === 'Free (good)'
          ? 'all-room-row funky-yellow'
          : 'unstackable all-room-row';
      }

      if (this.props.view === 'paying') {
        let rowClass = 'unstackable all-room-row';

        if (this.props.slaFeatureActive) {
          rowClass += ' all-room-row-extend';
        }

        if (
          client.sla_original_reply_by &&
          !moment(client.sla_original_reply_by).isSameOrAfter(now)
        ) {
          rowClass += ' white-row';
        }

        return rowClass;
      }

      return 'unstackable all-room-row';
    };

    const fromNoworNow = (inputTime) => {
      if (Math.abs(moment().diff(inputTime) < 1000)) {
        // 1000 milliseconds
        return 'just now';
      }
      return moment(inputTime).fromNow(true);
    };

    const CustomerWaitingFormatter = (cell, row) => {
      if (cell === null) {
        return '';
      }
      return (
        <div className="unstackable">
          <div className="unstackable customer-waiting">{fromNoworNow(cell)}</div>
        </div>
      );
    };

    const footerHiddenClass = this.props.view === 'follow_up' ? 'hidden' : '';
    const footerClass = `unstackable table-footer ${footerHiddenClass}`;

    const followUpRows = [];

    if (this.props.view === 'follow_up') {
      let followUpSection = [];
      for (let i = 0; i < this.state.followUpsArray.length; i++) {
        const { key, value } = this.state.followUpsArray[i];

        followUpRows.push(
          <tbody
            key={value}
            onClick={this.onGroupHeaderClick}
            data-key={key}
            className="unstackable follow-up-group-header"
          >
            <FollowUpHeaderRow
              followUpGroupOpen={this.state.followUpGroupOpen[key]}
              followUpsArray={this.state.followUpsArray[i].value}
            />
          </tbody>
        );

        for (let j = 0; j < this.props.allRoomsData.length; j++) {
          const client = this.props.allRoomsData[j];
          if (client.follow_up === key && this.matchesSearchString(client)) {
            followUpSection.push(
              <DashboardRow key={(i + 1) * (100000 + j)} className={highlightRow(client)}>
                <DashboardCell className="unstackable row-within-rows last-activity">
                  {this.props.checkRoomPath(client, moment(client.last_activity).format('L'))}
                  <i className="unstackable fa fa-angle-right fa-2x pull-right room-arrow" />
                  {client.room_reminder_count > 0 ? (
                    <div className="room-reminder-all-clients-container">
                      <div className="unstackable room-reminder-text">
                        {this.props.checkRoomPath(client, 'Reminders ')}
                        <span className="unstackable rrCircleRed room-reminder-count-icon">
                          {this.props.checkRoomPath(
                            client,
                            client.room_reminder_count < 100 ? client.room_reminder_count : '99+',
                            'room-reminder-count-text'
                          )}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </DashboardCell>
                <DashboardCell className="unstackable row-within-rows display-time">
                  {this.props.checkRoomPath(
                    client,
                    CustomerWaitingFormatter(client.customer_waiting, client)
                  )}
                </DashboardCell>
                <DashboardCell className="unstackable row-within-rows nickname">
                  <div className="unstackable tdCircleRed engagement-circle" />
                  {this.props.checkRoomPath(
                    client,
                    <DashboardCellRoomDetail
                      pseudonym={client.nick_name}
                      roomID={client.private_talk_id}
                      userID={client.client_user_id}
                    />
                  )}
                </DashboardCell>
                <DashboardCell className="unstackable row-within-rows room-status">
                  {this.props.checkRoomPath(client, client.room_status)}
                </DashboardCell>
                <DashboardCell className="unstackable row-within-rows client-source">
                  <div className="unstackable client-source-container">
                    <div className="unstackable client-source-title">Source:&nbsp;</div>
                    {this.props.checkRoomPath(client, client.Source)}
                  </div>
                </DashboardCell>
                <DashboardCell className="unstackable row-within-rows plan-name">
                  <div className="unstackable client-plan-name">
                    {this.props.checkRoomPath(client, client.plan_name)}
                  </div>
                  <div className="unstackable selectedDaysOfWeek">
                    <div className="unstackable client-plan-weekdays">Weekdays:&nbsp;</div>
                    {client.weekdays ? this.props.checkRoomPath(client, client.weekdays) : ''}
                  </div>
                </DashboardCell>
                <DashboardCell className="unstackable row-within-rows client-renew-date">
                  <div>
                    <div className="unstackable client-renews-date-mobile">Renews At:&nbsp;</div>
                    {this.props.checkRoomPath(client, monthFormatter(client.renew_date))}
                  </div>
                </DashboardCell>
                <DashboardCell className="unstackable row-within-rows client-plan-end">
                  {this.props.checkRoomPath(client, timeFormatter(client.plan_end))}
                </DashboardCell>
              </DashboardRow>
            );
          }
        }
        followUpRows.push(
          <tbody key={key} className="unstackable" hidden={!this.state.followUpGroupOpen[key]}>
            {followUpSection}
          </tbody>
        );
        followUpSection = [];
      }
    }

    const filteredData = this.filterData(this.props.allRoomsData);
    const hasAnyClients = Number(this.props.counters.all_counter) > 0;
    return (
      <div
        className={this.props.isHidden ? 'ibox table-hidden unstackable' : 'ibox unstackable'}
        style={{ backgroundColor: 'white', paddingTop: 20 }}
      >
        <AllClientsTitleWrapper className="unstackable">
          <h4>Your caseload</h4>
        </AllClientsTitleWrapper>
        {(this.props.isLoading || !hasAnyClients) && (
          <DashboardTablePlaceholder
            title="You have no client caseload yet!"
            subtitle="This is where you’ll provide ongoing treatment. To grow your clientele, "
            icon={<IconGrow />}
            linkPath="/my-account?scrollTo=capacity"
            linkText="manage your caseload."
            isLoading={this.props.isLoading}
          />
        )}
        {!this.props.isLoading && hasAnyClients && (
          <AllClientsContentWrapper>
            <div className="all-clients-head">
              <div className="unstackable col-xs-12 col-sm-12 col-md-9 col-lg-9 button-rows">
                <ButtonGroup
                  className="clients-options hidden-xs hidden-sm"
                  style={{ width: '100%' }}
                >
                  <Button
                    active={this.props.view === 'all'}
                    onClick={() => {
                      this.setState({ activePage: 1 });
                      this.props.getRoomsData('all');
                    }}
                    style={{ width: '13%' }}
                  >
                    All
                    <span className="unstackable number-indication">
                      {this.props.counters.all_counter}
                    </span>
                  </Button>
                  <Button
                    active={this.props.view === 'consultation'}
                    onClick={() => {
                      this.setState({ activePage: 1 });
                      this.props.getRoomsData('consultation');
                    }}
                    style={{ width: '24%' }}
                  >
                    Consultation{' '}
                    <span className="unstackable number-indication">
                      {this.props.counters.consultation_counter}
                    </span>
                  </Button>
                  <Button
                    active={this.props.view === 'paying'}
                    onClick={() => {
                      this.setState({ activePage: 1 });
                      this.props.getRoomsData('paying');
                    }}
                    style={{ width: '18%' }}
                  >
                    Paying{' '}
                    <span className="unstackable number-indication">
                      {this.props.counters.paying_counter}
                    </span>
                  </Button>
                  {!['primary', 'psychiatrist'].includes(this.props.therapistType) && (
                    <Button
                      active={this.props.view === 'follow_up'}
                      onClick={() => this.props.getRoomsData('follow_up', this.setFollowUpsArray)}
                      style={{ width: '20%' }}
                    >
                      Follow-up{' '}
                      <span className="unstackable number-indication">
                        {this.props.counters.follow_up_counter}
                      </span>
                    </Button>
                  )}
                </ButtonGroup>
                <Dropdown
                  id="viewDropdown"
                  className="mobile-view-dropdown"
                  open={this.state.caseLoadOpen}
                >
                  <Dropdown.Toggle onClick={this.toggleCaseLoadOpen}>
                    {this.getViewDisplayName(this.props.view)}{' '}
                    <span className="unstackable number-indication">
                      {this.props.counters[`${this.props.view}_counter`]}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu title={this.props.view}>
                    <MenuItem
                      onClick={() => {
                        this.setState({ activePage: 1, caseLoadOpen: false });
                        this.props.getRoomsData('all');
                      }}
                    >
                      All
                      <span className="unstackable number-indication">
                        {this.props.counters.all_counter}
                      </span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.setState({ activePage: 1, caseLoadOpen: false });
                        this.props.getRoomsData('consultation');
                      }}
                    >
                      Consultation
                      <span className="unstackable number-indication">
                        {this.props.counters.consultation_counter}
                      </span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.setState({ activePage: 1, caseLoadOpen: false });
                        this.props.getRoomsData('paying');
                      }}
                    >
                      Paying
                      <span className="unstackable number-indication">
                        {this.props.counters.paying_counter}
                      </span>
                    </MenuItem>
                    {!['primary', 'psychiatrist'].includes(this.props.therapistType) && (
                      <MenuItem
                        onClick={() => {
                          this.setState({ caseLoadOpen: false });
                          this.props.getRoomsData('follow_up', this.setFollowUpsArray);
                        }}
                      >
                        Follow Up
                        <span className="unstackable number-indication">
                          {this.props.counters.follow_up_counter}
                        </span>
                      </MenuItem>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="unstackable room-search col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <FormGroup>
                  <InputGroup>
                    <FormControl
                      onChange={this.handleSearch}
                      type="text"
                      placeholder="Search"
                      value={this.state.searchString}
                    />
                    <DropdownButton
                      componentClass={InputGroup.Button}
                      id="search-type-dropdown"
                      bsStyle="Default"
                      title={this.state.searchType}
                      noCaret={false}
                      onClick={this.toggleSearchTypeOpen}
                      open={this.state.searchTypeOpen}
                    >
                      <MenuItem eventKey="1" onClick={this.setSearchType} name="Nickname">
                        Nickname
                      </MenuItem>
                      <MenuItem eventKey="2" onClick={this.setSearchType} name="User">
                        User
                      </MenuItem>
                      <MenuItem eventKey="3" onClick={this.setSearchType} name="Room">
                        Room
                      </MenuItem>
                    </DropdownButton>
                  </InputGroup>
                </FormGroup>
              </div>
              <div className="button-rows hidden-xs hidden-sm col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="date-filter-label">From: </div>
                <SingleDatePickerWithInput
                  date={this.props.filterFromDate}
                  onDateChange={this.props.updateFilterFromDate}
                  focused={this.state.startDateFocused}
                  isOutsideRange={(day) => day.isAfter(this.props.filterToDate)}
                  onFocusChange={({ focused }) => this.setState({ startDateFocused: focused })}
                />
                <div className="date-filter-label">To: </div>
                <SingleDatePickerWithInput
                  date={this.props.filterToDate}
                  onDateChange={this.props.updateFilterToDate}
                  focused={this.state.endDateFocused}
                  isOutsideRange={(day) => day.isBefore(this.props.filterFromDate)}
                  onFocusChange={({ focused }) => this.setState({ endDateFocused: focused })}
                />
              </div>
            </div>
            <div className="unstackable ibox-content">
              <div className="unstackable grid" role="grid">
                <table className="unstackable ui fixed table">
                  <ColGroupForView view={this.props.view} />
                  <thead>
                    <DashboardRow className="unstackable all-clients-row">
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        data-column-name="last_activity"
                        hidden={this.props.view === 'paying'}
                        className="unstackable all-clients-heading"
                      >
                        Last Activity{this.getSortIcon('last_activity')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        hidden={this.props.view === 'paying'}
                        data-column-name="customer_waiting"
                        className="unstackable all-clients-heading"
                      >
                        Client Waiting{this.getSortIcon('customer_waiting')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        hidden={this.props.view === 'paying'}
                        data-column-name="nick_name"
                        className="unstackable all-clients-heading"
                      >
                        ID{this.getSortIcon('nick_name')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        hidden={this.props.view === 'paying'}
                        data-column-name="room_status"
                        className="unstackable all-clients-heading"
                      >
                        Status{this.getSortIcon('room_status')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        hidden={this.props.view === 'paying'}
                        data-column-name="Source"
                        className="unstackable all-clients-heading"
                      >
                        Source{this.getSortIcon('Source')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        data-column-name="plan_name"
                        hidden={this.props.view === 'paying'}
                        className="unstackable all-clients-heading"
                      >
                        Plan Name{this.getSortIcon('plan_name')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        hidden={this.props.view === 'paying'}
                        data-column-name="renew_date"
                        className="unstackable all-clients-heading"
                      >
                        Renew Date{this.getSortIcon('renew_date')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        hidden={this.props.view === 'follow_up' || this.props.view === 'paying'}
                        data-column-name="offer_date"
                        className="unstackable all-clients-heading"
                      >
                        Offer Date{this.getSortIcon('offer_date')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        hidden={this.props.view === 'paying'}
                        data-column-name="plan_end"
                        className="unstackable all-clients-heading"
                      >
                        Plan End{this.getSortIcon('plan_end')}
                      </DashboardHeader>

                      <DashboardHeader
                        onClick={this.props.handleSort}
                        hidden={this.props.view !== 'paying'}
                        data-column-name="nick_name"
                        className="unstackable all-clients-heading"
                      >
                        ID{this.getSortIcon('nick_name')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        data-column-name="last_activity"
                        hidden={this.props.view !== 'paying'}
                        className="unstackable all-clients-heading"
                      >
                        Last activity{this.getSortIcon('last_activity')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        hidden={this.props.view !== 'paying'}
                        data-column-name="customer_waiting"
                        className="unstackable all-clients-heading"
                      >
                        Waiting{this.getSortIcon('customer_waiting')}
                      </DashboardHeader>
                      <DashboardHeader
                        colSpan="2"
                        data-column-name="sla_original_reply_by"
                        onClick={this.props.handleSort}
                        hidden={!this.props.slaFeatureActive || this.props.view !== 'paying'}
                        className="unstackable all-clients-heading"
                      >
                        Reply by{this.getSortIcon('sla_original_reply_by')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        hidden={this.props.view !== 'paying'}
                        data-column-name="Source"
                        className="unstackable all-clients-heading"
                      >
                        Source{this.getSortIcon('Source')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        hidden={this.props.view !== 'paying'}
                        data-column-name="renew_date"
                        className="unstackable all-clients-heading"
                      >
                        Status {this.getSortIcon('renew_date')}
                      </DashboardHeader>
                      <DashboardHeader
                        onClick={this.props.handleSort}
                        data-column-name="plan_name"
                        hidden={this.props.view !== 'paying'}
                        className="unstackable all-clients-heading"
                      >
                        Plan Name{this.getSortIcon('plan_name')}
                      </DashboardHeader>
                    </DashboardRow>
                  </thead>
                  <tbody hidden={this.props.view === 'follow_up'}>
                    {filteredData.map((client, index) => (
                      <DashboardRow key={index} className={highlightRow(client)}>
                        <DashboardCell
                          hidden={this.props.view === 'paying'}
                          className="unstackable row-within-rows last-activity"
                        >
                          {this.props.checkRoomPath(client, moment(client.last_activity).fromNow())}
                          <i className="unstackable fa fa-angle-right fa-2x pull-right room-arrow" />
                          {client.room_reminder_count > 0 ? (
                            <div className="room-reminder-all-clients-container">
                              <div className="unstackable room-reminder-text">
                                {this.props.checkRoomPath(client, 'Reminders ')}
                                <span className="unstackable rrCircleRed room-reminder-count-icon">
                                  {this.props.checkRoomPath(
                                    client,
                                    client.room_reminder_count < 100
                                      ? client.room_reminder_count
                                      : '99+',
                                    'room-reminder-count-text'
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view === 'paying'}
                          className="unstackable row-within-rows display-time"
                        >
                          {this.props.checkRoomPath(
                            client,
                            CustomerWaitingFormatter(client.customer_waiting, client)
                          )}
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view === 'paying'}
                          className="unstackable row-within-rows nickname"
                        >
                          {this.props.checkRoomPath(
                            client,
                            <DashboardCellRoomDetail
                              pseudonym={client.nick_name}
                              roomID={client.private_talk_id}
                              userID={client.client_user_id}
                            />
                          )}
                          {this.props.view === 'paying' && (
                            <div
                              className={`unstackable ${isEngaged(
                                client.engagement
                              )} engagement-circle`}
                            />
                          )}
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view === 'paying'}
                          className="unstackable row-within-rows room-status"
                        >
                          {this.props.checkRoomPath(client, client.room_status)}
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view === 'paying' || this.props.view !== 'follow_up'}
                          className="unstackable row-within-rows client-vertical"
                        >
                          {this.props.checkRoomPath(client, client.vertical)}
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view === 'paying'}
                          className="unstackable row-within-rows client-source"
                        >
                          <div className="unstackable client-source-container">
                            <div className="unstackable client-source-title">Source:&nbsp;</div>
                            {this.props.checkRoomPath(client, client.Source)}
                          </div>
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view === 'paying'}
                          className="unstackable row-within-rows plan-name"
                        >
                          <div className="unstackable client-plan-name">
                            {this.props.checkRoomPath(client, client.plan_name)}
                          </div>
                          <div className="unstackable selectedDaysOfWeek">
                            <div className="unstackable client-plan-weekdays">Weekdays:&nbsp;</div>
                            {this.props.checkRoomPath(
                              client,
                              client.weekdays ? client.weekdays : ''
                            )}
                          </div>
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view === 'paying'}
                          className="unstackable row-within-rows client-renew-date"
                        >
                          <div>
                            <div className="unstackable client-renews-date-mobile">
                              Renews At:&nbsp;
                            </div>
                            {this.props.checkRoomPath(client, monthFormatter(client.renew_date))}
                          </div>
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view === 'follow_up' || this.props.view === 'paying'}
                          className="unstackable row-within-rows client-offer-date hidden-xs hidden-sm"
                        >
                          {this.props.checkRoomPath(client, monthFormatter(client.offer_date))}
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view === 'paying'}
                          className="unstackable row-within-rows client-plan-end hidden-xs hidden-sm"
                        >
                          {this.props.checkRoomPath(client, timeFormatter(client.plan_end))}
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view !== 'paying'}
                          className={this.setSLAMarginLeft(client)}
                        >
                          {this.props.checkRoomPath(
                            client,
                            <DashboardCellRoomDetail
                              pseudonym={client.nick_name}
                              roomID={client.private_talk_id}
                              userID={client.client_user_id}
                            />
                          )}
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view !== 'paying'}
                          className="unstackable row-within-rows last-activity"
                        >
                          {this.props.checkRoomPath(
                            client,
                            client.last_activity ? moment(client.last_activity).fromNow() : ''
                          )}
                          <i className="unstackable fa fa-angle-right fa-2x pull-right room-arrow" />
                          {client.room_reminder_count > 0 ? (
                            <div className="room-reminder-all-clients-container">
                              <div className="unstackable room-reminder-text">
                                {this.props.checkRoomPath(client, 'Reminders ')}
                                <span className="unstackable rrCircleRed room-reminder-count-icon">
                                  {this.props.checkRoomPath(
                                    client,
                                    client.room_reminder_count < 100
                                      ? client.room_reminder_count
                                      : '99+',
                                    'room-reminder-count-text'
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view !== 'paying'}
                          className="unstackable row-within-rows display-time"
                        >
                          {this.props.checkRoomPath(
                            client,
                            CustomerWaitingFormatter(client.customer_waiting, client)
                          )}
                        </DashboardCell>
                        <DashboardCell
                          colSpan="2"
                          hidden={!this.props.slaFeatureActive || this.props.view !== 'paying'}
                          className="unstackable row-within-rows"
                        >
                          <div className="client-overdue">
                            {this.props.checkRoomPath(
                              client,
                              <Overdue user={client} isMobile={false} />
                            )}
                          </div>
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view !== 'paying'}
                          className="unstackable row-within-rows client-source"
                        >
                          {client.room_reminder_count > 0 ? (
                            <div className="room-reminder-all-clients-container-mobile-only">
                              <div className="unstackable room-reminder-text">
                                {this.props.checkRoomPath(client, 'Reminders ')}
                                <span className="unstackable rrCircleRed room-reminder-count-icon">
                                  {this.props.checkRoomPath(
                                    client,
                                    client.room_reminder_count < 100
                                      ? client.room_reminder_count
                                      : '99+',
                                    'room-reminder-count-text'
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : null}
                          <div className="unstackable client-source-container">
                            <div className="unstackable client-source-title">Source:&nbsp;</div>
                            {this.props.checkRoomPath(client, client.Source)}
                          </div>
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view !== 'paying'}
                          className="unstackable row-within-rows client-renew-date"
                        >
                          <div>
                            <div className="unstackable client-renews-date-mobile">
                              {this.props.checkRoomPath(client, <StatusMobile client={client} />)}
                              <div
                                hidden={!this.props.slaFeatureActive}
                                className="unstackable client-overdue-mobile"
                              >
                                <Overdue user={client} isMobile />
                              </div>
                            </div>
                            <div className="unstackable client-renews-date">
                              {this.props.checkRoomPath(client, <Status client={client} />)}
                            </div>
                          </div>
                        </DashboardCell>
                        <DashboardCell
                          hidden={this.props.view !== 'paying'}
                          className="unstackable row-within-rows plan-name"
                        >
                          <div className="unstackable client-plan-name">
                            <span className="bold-sla-plan">
                              {this.props.checkRoomPath(client, client.plan_name)}
                            </span>
                          </div>
                        </DashboardCell>
                      </DashboardRow>
                    ))}
                  </tbody>
                  {this.props.view === 'follow_up' ? followUpRows : null}
                </table>
                <div className={footerClass}>
                  <Pagination
                    prev
                    next
                    first
                    last
                    ellipsis
                    boundaryLinks
                    items={Math.ceil(
                      this.props.allRoomsData.filter((client) => {
                        const nameString = client.nick_name !== null ? client.nick_name : '';
                        return nameString.toLowerCase().includes(this.state.searchString);
                      }).length / this.state.numPerPage
                    )}
                    maxButtons={5}
                    activePage={this.state.activePage}
                    onSelect={this.handleSelect}
                  />
                  <ButtonGroup style={{ paddingTop: '20px', float: 'right' }}>
                    <Button
                      value={10}
                      active={this.state.numPerPage === 10}
                      onClick={this.changeNumPerPage}
                    >
                      10
                    </Button>
                    <Button
                      value={25}
                      active={this.state.numPerPage === 25}
                      onClick={this.changeNumPerPage}
                    >
                      25
                    </Button>
                    <Button
                      value={50}
                      active={this.state.numPerPage === 50}
                      onClick={this.changeNumPerPage}
                    >
                      50
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </AllClientsContentWrapper>
        )}
      </div>
    );
  }
}

export default AllClients;
