import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getAvailableTherapists } from '../../../../../actions/MatchingActions';

// base64 image generated from: talkspace-web/apps/therapist-web/src/components/Dashboard/NavBar/profile_default.svg
const defaultProfileImageBase64 =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxjaXJjbGUgaWQ9ImEiIGN4PSIxNy41IiBjeT0iMTcuNSIgcj0iMTcuNSIvPjwvZGVmcz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PC9tYXNrPjx1c2UgZmlsbD0iI0VGRjRGNiIgeGxpbms6aHJlZj0iI2EiLz48cGF0aCBkPSJNMjMuMTAyIDIzLjQ3NkgxMS44OThjLS42MDMgMC0xLjA0OC0uNTk1LS44Mi0xLjE0MyAxLjA1NS0yLjUzIDMuNTM0LTMuOTggNi40MjItMy45OCAyLjg4OSAwIDUuMzY4IDEuNDUgNi40MjMgMy45OC4yMjcuNTQ4LS4yMTggMS4xNDMtLjgyIDEuMTQzem0tOS4wODgtMTAuMjQ0YzAtMS44ODMgMS41NjQtMy40MTUgMy40ODYtMy40MTUgMS45MjIgMCAzLjQ4NiAxLjUzMiAzLjQ4NiAzLjQxNXMtMS41NjQgMy40MTQtMy40ODYgMy40MTRjLTEuOTIyIDAtMy40ODYtMS41MzEtMy40ODYtMy40MTR6IiBmaWxsPSIjMDA5QjhEIiBtYXNrPSJ1cmwoI2IpIi8+PC9nPjwvc3ZnPg==';

class SwitchTherapistDropdownComponent extends Component {
  constructor(props) {
    super(props);

    this.props.getAvailableTherapists();

    this.state = {
      value: this.props.value || undefined,
      placeholderState: this.props.value ? 'up' : 'down',
      isFocused: false,
      searchTerm: '',
      field: this.props.field,
      disabled: false,
      filteredTherapists: this.props.availableTherapists,
    };

    this.changeFocus = this.changeFocus.bind(this);
    this.focusSearchInput = this.focusSearchInput.bind(this);
    this.getTherapistList = this.getTherapistList.bind(this);
    this.getTherapistListItem = this.getTherapistListItem.bind(this);
    this.getPlaceholder = this.getPlaceholder.bind(this);
    this.getInput = this.getInput.bind(this);
    this.handleSearchTerm = this.handleSearchTerm.bind(this);
    this.getSelectTherapistHandler = this.getSelectTherapistHandler.bind(this);
    this.unSelectTherapist = this.unSelectTherapist.bind(this);
    this.getSelectedTherapistToken = this.getSelectedTherapistToken.bind(this);
  }

  getInput() {
    return (
      <input
        ref={(input) => (this.searchInput = input)}
        className={`available-therapists-select ${this.state.isFocused ? 'focused' : ''}`}
        value={this.state.searchTerm}
        onFocus={this.changeFocus}
        onBlur={this.changeFocus}
        onChange={this.handleSearchTerm}
      />
    );
  }

  getPlaceholder() {
    return (
      <div
        className={`available-therapists-placeholder ${
          this.state.searchTerm.length > 0 || this.props.selectedTherapist ? 'up' : 'down'
        }`}
        onClick={this.focusSearchInput}
      >
        Select a provider
      </div>
    );
  }

  getSelectTherapistHandler(therapist, nameAndTitle) {
    return () => {
      this.setState({ searchTerm: nameAndTitle || '' });
      this.props.setSelectedTherapist(therapist);
    };
  }

  getSelectedTherapistToken() {
    const { title, firstName, lastName, imageURL } = this.props.selectedTherapist;
    const fullName = `${firstName} ${lastName}`;
    const nameAndTitle = title ? `${title} ${fullName}` : fullName;

    return (
      <div className="selected-therapist-token">
        <img
          alt="profile"
          className="profile-image"
          src={imageURL}
          onError={(e) => {
            // eslint-disable-next-line no-param-reassign
            e.target.onerror = null;
            // eslint-disable-next-line no-param-reassign
            e.target.src = defaultProfileImageBase64;
          }}
        />
        <div className="full-name">{nameAndTitle}</div>
        <div onClick={this.unSelectTherapist} className="de-select" />
      </div>
    );
  }

  getTherapistList() {
    return this.state.filteredTherapists.map((availableTherapist, index) =>
      this.getTherapistListItem(availableTherapist, index)
    );
  }

  getTherapistListItem(therapist, key) {
    const { title, firstName, lastName, imageURL, therapistID } = therapist;
    const fullName = `${firstName} ${lastName}`;
    const nameAndTitle = title ? `${title} ${fullName}` : fullName;

    return (
      <div
        className="available-therapists-list-item"
        key={key}
        onMouseDown={this.getSelectTherapistHandler(therapist, nameAndTitle)}
      >
        <img
          alt="profile"
          className="profile-image"
          src={imageURL}
          onError={(e) => {
            // eslint-disable-next-line no-param-reassign
            e.target.onerror = null;
            // eslint-disable-next-line no-param-reassign
            e.target.src = defaultProfileImageBase64;
          }}
          onMouseDown={this.getSelectTherapistHandler(therapist, nameAndTitle)}
        />
        <div
          className="full-name"
          onMouseDown={this.getSelectTherapistHandler(therapist, nameAndTitle)}
        >
          {nameAndTitle}
        </div>
      </div>
    );
  }

  // static getDerivedStateFromProps(nextProps) {
  //   return nextProps.availableTherapists ? { filteredTherapists: nextProps.availableTherapists } : null;
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // TODO: delete this and use static getDerivedStateFromProps function
    this.setState({ filteredTherapists: nextProps.availableTherapists, searchTerm: '' });
  }

  changeFocus(event, data) {
    this.setState({
      isFocused: !this.state.isFocused,
      placeholderState: data && data.value !== undefined ? 'up' : 'down',
    });
  }

  focusSearchInput() {
    if (!this.state.isFocused) this.searchInput.focus();
  }

  handleSearchTerm(event) {
    event.preventDefault();
    const filteredTherapists = this.props.availableTherapists.filter(
      ({ title, firstName, lastName }) => {
        const fullName = `${firstName} ${lastName}`;
        const nameAndTitle = title ? `${title} ${fullName}` : fullName;
        return nameAndTitle.toLowerCase().includes(event.target.value.toLowerCase());
      }
    );
    this.setState({ searchTerm: event.target.value, filteredTherapists });
  }

  unSelectTherapist() {
    this.props.setSelectedTherapist();
    this.setState({
      searchTerm: '',
      selectedTherapist: undefined,
      filteredTherapists: this.props.availableTherapists,
    });
  }

  render() {
    return (
      <div className="available-therapists">
        <div>
          {this.getPlaceholder()}
          {this.getInput()}
          {this.props.selectedTherapist ? this.getSelectedTherapistToken() : null}
        </div>
        <div className="available-therapists-list" hidden={!this.state.isFocused}>
          {this.getTherapistList()}
        </div>
      </div>
    );
  }
}

SwitchTherapistDropdownComponent.propTypes = {
  availableTherapists: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    availableTherapists: state.matching.availableTherapists,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({ getAvailableTherapists }, dispatch);
const SwitchTherapistDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchTherapistDropdownComponent);

export default SwitchTherapistDropdown;
