import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { getClientLogins } from '../../../../../actions/EmergencyContactActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import ListItem from '../../../../Reusable/ListItems/ListItem';
import ItemList from '../../../../Reusable/ItemList/ItemList';
import Button from '../../../../Reusable/Buttons/Button';

const extractPlatformFromLoginType = (str) => {
  if (str.includes('WEB')) return 'Web';
  if (str.includes('IOS')) return 'iOS';
  if (str.includes('ANDROID')) return 'Android';
  if (str.includes('MOBILE')) return 'Mobile';
  return 'Unknown';
};

const extractPlatformFromPlatform = (str) => {
  if (str.includes('web')) return 'Web';
  if (str.includes('ios')) return 'iOS';
  if (str.includes('android')) return 'Android';
  return 'Unknown';
};

class ClientLogin extends Component {
  constructor(props) {
    super(props);
    this.processItems = this.processItems.bind(this);
    this.requestMoreItems = this.requestMoreItems.bind(this);
    this.renderClientLogins = this.renderClientLogins.bind(this);
  }

  componentDidMount() {
    const { clientUserID } = this.props.location.state;
    this.props.getClientLogins(clientUserID, { limit: 20 });
  }

  processItems(items) {
    const formatItem = ({ createdAt, loginType, ipAddress, platform }) => {
      return {
        createdAt: moment(createdAt).format('LL'),
        loginType: platform
          ? extractPlatformFromPlatform(platform)
          : extractPlatformFromLoginType(loginType),
        ipAddress,
      };
    };
    return items.map((item, i) => (
      <ListItem
        key={i}
        itemDataOrder={['createdAt', 'ipAddress', 'loginType']}
        itemDisplayData={formatItem(item)}
        item={formatItem(item)}
        widths={[125, 105, 105]}
      />
    ));
  }

  requestMoreItems() {
    const { clientUserID } = this.props.location.state;
    this.props.getClientLogins(clientUserID, {
      isRefetch: true,
      limit: 20,
    });
  }

  renderClientLogins() {
    const { canRequestMoreClientLogins, isRequesting } = this.props;
    return [
      <ItemList
        key="item-list"
        items={this.processItems(this.props.clientLogins)}
        titles={['Date', 'IP', 'Platform']}
        noDataMessage="No recorded sessions."
        widths={[125, 105, 105]}
      />,
      canRequestMoreClientLogins && !isRequesting ? (
        <Button key="button" clickHandler={this.requestMoreItems} title="Load more" />
      ) : null,
    ];
  }

  render() {
    return (
      <Submenu
        childComponents={this.renderClientLogins()}
        title="Client logins"
        bodyStyle={{ paddingLeft: 15 }}
        prevRoute="emergency-contact-login"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientLogins: state.emergencyContact.clientLogins,
    canRequestMoreClientLogins: state.emergencyContact.canRequestMoreClientLogins,
    isRequesting: state.emergencyContact.isRequesting,
    clientList: state.customerList.list,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({ getClientLogins }, dispatch);
const ClientLoginContainer = connect(mapStateToProps, mapDispatchToProps)(ClientLogin);

export default ClientLoginContainer;
