import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OptionType, Select, useWindowWidth, View } from '@talkspace/react-toolkit';
import { withRouter } from 'react-router';
import { OptionsType } from 'react-select';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';
import { checkIfShouldBeSearchable, yesNoOptions } from '../../../../../utils/optionsHelpers';
import { getMarketing, updateMarketing } from '../../../../../actions/MarketingActions';
import AccordionContainer from '../../../../Reusable/AccordionContainer/AccordionContainer';
import UserBadge from '../../../../Reusable/UserBadge/UserBadge';

interface MarketingProps {
  clientUserID: number;
  customerInformation: any;
  firstTimeInTherapy: OptionType<string>;
  // eslint-disable-next-line react/no-unused-prop-types
  isRequestingMarketing: boolean;
  likelyToBuy: OptionType<string>;
  marketingCustomerList: CustomerList[];
  reasonNoPurchase: OptionType<string>;
  // eslint-disable-next-line react/no-unused-prop-types
  requestMarketingError: boolean;
  trafficControl: OptionType<string>;
  patchMarketing: (clientUserID: number, value: {}) => void;
  retrieveMarketing: (clientUserID: number) => void;
}

interface CustomerList {
  displayName: string;
  role: string;
  timezone: string;
  userID: number;
}

const Marketing = ({
  clientUserID,
  customerInformation,
  marketingCustomerList,
  firstTimeInTherapy,
  likelyToBuy,
  reasonNoPurchase,
  trafficControl,
  patchMarketing,
  retrieveMarketing,
}: MarketingProps) => {
  const trafficControlOptions = getOptionsByField('trafficControl');

  useEffect(() => {
    if (customerInformation) retrieveMarketing(customerInformation.clientUserID);
  }, [retrieveMarketing, customerInformation]);

  useEffect(() => {
    if (trafficControl) {
      const exists = trafficControlOptions.find((option) => option.value === trafficControl.value);
      if (!exists) {
        // eslint-disable-next-line no-param-reassign
        trafficControl.label = trafficControl.value;
        trafficControlOptions.push(trafficControl);
      }
    }
  }, [trafficControl, trafficControlOptions]);

  const getUpdateFunctionForComponent = (fieldName) => (value) => {
    patchMarketing(clientUserID, { [fieldName]: value });
  };

  const createUserButtons = (customerList: CustomerList[]) => (
    <View>
      {customerList.map((customer) => (
        <UserBadge
          key={customer.userID}
          onClick={() => {
            if (customer.userID !== clientUserID) retrieveMarketing(customer.userID);
          }}
          preferredName={customer.displayName}
          active={customer.userID === clientUserID}
        />
      ))}
    </View>
  );

  const { isMobile } = useWindowWidth();
  const showUserButtons =
    !!marketingCustomerList && !!marketingCustomerList.length && marketingCustomerList.length > 1;

  return (
    <AccordionContainer title="Marketing Information">
      {showUserButtons && createUserButtons(marketingCustomerList)}
      <Select
        placeholder="Traffic control"
        options={trafficControlOptions}
        onChange={getUpdateFunctionForComponent('trafficControl')}
        value={trafficControl}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('trafficControl')}
      />
      <Select
        placeholder="Why hasn't the customer purchased?"
        options={getOptionsByField('reasonNoPurchase')}
        onChange={getUpdateFunctionForComponent('reasonNoPurchase')}
        value={reasonNoPurchase}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('reasonNoPurchase')}
      />
      <Select
        placeholder="Likely to commit to treatment?"
        options={getOptionsByField('likelyToBuy')}
        onChange={getUpdateFunctionForComponent('likelyToBuy')}
        value={likelyToBuy}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('likelyToBuy')}
      />
      <Select
        placeholder="First time in therapy?"
        options={yesNoOptions as OptionsType<any>}
        onChange={getUpdateFunctionForComponent('firstTimeInTherapy')}
        value={firstTimeInTherapy}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('firstTimeInTherapy')}
      />
    </AccordionContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    trafficControl: state.marketing.trafficControl,
    reasonNoPurchase: state.marketing.reasonNoPurchase,
    firstTimeInTherapy: state.marketing.firstTimeInTherapy,
    likelyToBuy: state.marketing.likelyToBuy,
    isRequestingMarketing: state.marketing.isRequestingMarketing,
    requestMarketingError: state.marketing.requestMarketingError,
    clientUserID: state.marketing.clientUserID,
    disabledComponents: state.marketing.disabledComponents,
    invalidComponents: state.customerInformation.invalidComponents,
    marketingCustomerList: state.customerList.list,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchMarketing: updateMarketing,
      retrieveMarketing: getMarketing,
    },
    dispatch
  );
const MarketingContainer = connect(mapStateToProps, mapDispatchToProps)(Marketing);

export default withRouter(MarketingContainer);
