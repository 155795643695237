import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactFrameService from '../../../../../modules/auth/reactFrame/ReactFrameService';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import { sendEligibilityMessage } from '../../../../../actions/MatchingActions';
import { getActiveCustomerName } from '../../../../../utils/customerHelper';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';

function TSEligibilityQuestionnaire(props) {
  const {
    isError,
    isUpdating,
    dispatchSendEligibilityMessage,
    customerList,
    customerInformation,
    match: { roomID },
  } = props;
  const [showSpinner, setIsShowSpinner] = useState(false);
  const buttons = [
    {
      title: 'Send eligibility questionnaire',
      clickHandler: () => {
        setIsShowSpinner(true);
        setTimeout(() => {
          setIsShowSpinner(false);

          if (ReactFrameService.instance().isInFrame()) {
            ReactFrameService.instance().closePopup({
              navigateTo: 'room',
              metadata: { roomID },
            });
          }
        }, 3000);
        dispatchSendEligibilityMessage();
      },
    },
  ];

  const cardText =
    'The questionnaire is designed to gather information about the client to verify their coverage provided by insurance, an employer, or an educational organization. It will take them through QuickMatch, where they will select a therapist and checkout with their benefit applied.';
  return (
    <Submenu
      title="Eligibility questionnaire"
      childComponents={[
        <UserActionCard
          key={1}
          preferredName={getActiveCustomerName(customerInformation, customerList)}
          content={cardText}
          buttons={buttons}
          isUpdating={isUpdating}
          isError={isError}
          showSpinner={showSpinner}
        />,
      ]}
      prevRoute="case-tab"
    />
  );
}

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
    isError: state.matching.isError,
    isUpdating: state.matching.isUpdating,
    customerList: state.customerList.list,
    customerInformation: state.customerInformation,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ dispatchSendEligibilityMessage: sendEligibilityMessage }, dispatch);

const EligibilityQuestionnaire = connect(
  mapStateToProps,
  mapDispatchToProps
)(TSEligibilityQuestionnaire);

export default EligibilityQuestionnaire;
