import {
  REQUEST_VIDEO_CALL_HISTORY,
  REQUEST_VIDEO_CALL_HISTORY_ERROR,
  RECEIVE_VIDEO_CALL_HISTORY,
} from '../constants/VideoCallHistoryConstants';

const initialState = {
  data: [],
  isRequesting: false,
  requestError: false,
};

export default function caseTab(state = initialState, action) {
  switch (action.type) {
    case REQUEST_VIDEO_CALL_HISTORY:
      return {
        ...state,
        isRequesting: true,
        requestError: false,
      };
    case REQUEST_VIDEO_CALL_HISTORY_ERROR:
      return {
        ...state,
        isRequesting: false,
        requestError: true,
      };
    case RECEIVE_VIDEO_CALL_HISTORY:
      return {
        ...state,
        data: action.videoCallHistory,
        isRequesting: false,
        requestError: false,
      };
    default:
      return state;
  }
}
