import * as types from '../constants/CustomerListConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

export function requestCustomerList(roomID) {
  return {
    type: types.REQUEST_GET_CUSTOMER_INFORMATION_LIST,
    roomID,
  };
}

export function requestCustomerListError(error) {
  return {
    type: types.REQUEST_GET_CUSTOMER_INFORMATION_LIST_ERROR,
    error,
  };
}

export function receiveCustomerList(responseBody) {
  return {
    type: types.RECEIVE_GET_CUSTOMER_INFORMATION_LIST,
    responseBody,
  };
}

export const getCustomerList = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestCustomerList(roomID));
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/participants`)
    .then((response) => dispatch(receiveCustomerList(response.data)))
    .catch((error) => dispatch(requestCustomerListError(error)));
};
