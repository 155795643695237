import { VoidFunctionComponent } from 'react';
import { Standard, View, TouchableView, Small, Tiny, Reminder } from '@talkspace/react-toolkit';
import styled from '../../../../../core/styled';
import ssoHelper from '../../../../../modules/utils/sso';

interface ReopenOptionsScreenProps {
  menuItems: Map<string, any>;
  handleClick: (menuKey: string) => void;
}

const MenuItemWrapper = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    width: '100%',
    borderRadius: 5,
    padding: `8px 0 8px 27px`,
    '& > p': {
      color: colors.permaRiverBed,
    },
    '&:hover': {
      backgroundColor: colors.a11yLinkWaterGrey,
    },
  };
});

const ReopenOptionsScreen: VoidFunctionComponent<ReopenOptionsScreenProps> = ({
  menuItems,
  handleClick,
}) => (
  <View onClick={(e) => e.preventDefault()}>
    <Standard style={{ marginBottom: 12, fontSize: 13, padding: '0 20px' }}>
      What’s your reason for reopening this EAP session?
    </Standard>
    {[...menuItems.entries()].map(([key, value]) => (
      <MenuItemWrapper key={key} onPress={() => handleClick(key)}>
        <Small variant="smallDarkGrey">{value.label}</Small>
        {value.subLabel && <Tiny style={{ color: '#536481', fontSize: 12 }}>{value.subLabel}</Tiny>}
      </MenuItemWrapper>
    ))}
    <Reminder
      reminderText="Seven days of access equates to a billable session."
      linkText="Learn more"
      handleClickLink={() => ssoHelper.openZendesk('/hc/en-us/articles/8465130941979')}
      isReminderLabel
    />
  </View>
);

export default ReopenOptionsScreen;
