import { Spinner, View, UserIcon } from '@talkspace/react-toolkit';
import { Container, Title, Subtitle } from './styles';
import { useProgressNoteFormState } from '../context/TherapistProgressNoteFormContext';
import { useRouteMatch } from '@/core/routerLib';
import { UnsubmittedSessionTask } from '../../../../../../hooks/dashboard/useQueryTaskListV3';
import useQueryPresentingProblems from '../../../../../../hooks/useQueryPresentingProblems';

const TransferredClientSection = () => {
  const match = useRouteMatch<{ roomID: string; noteID?: string }>();
  const { unsubmittedSession } = useProgressNoteFormState();
  const unsubmittedSessionTask = unsubmittedSession as UnsubmittedSessionTask;
  const {
    firstName,
    lastName,
    clientNickname,
    age,
    gender,
    accountType,
    userID: clientUserID,
  } = unsubmittedSessionTask;
  const { data: presentingProblems, isLoading } = useQueryPresentingProblems(
    match.params.roomID,
    clientUserID
  );

  if (isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <View row>
        <View style={{ marginRight: 11 }}>
          <UserIcon />
        </View>
        <View>
          <Title>{`${firstName} ${lastName}`}</Title>
          <Subtitle>
            {gender}, {age}, {presentingProblems?.map((item) => item.label)}
          </Subtitle>
          <Subtitle>Nickname: {clientNickname}</Subtitle>
          <Subtitle>User: {clientUserID}</Subtitle>
          <Subtitle>Plan type: {accountType.toUpperCase()}</Subtitle>
        </View>
      </View>
    </Container>
  );
};

export default TransferredClientSection;
