import {
  REQUEST_VIDEO_CREDITS,
  REQUEST_VIDEO_CREDITS_ERROR,
  RECEIVE_VIDEO_CREDITS,
} from '../constants/VideoCreditsConstants';

const initialState = {
  data: [],
  isRequesting: false,
  requestError: false,
};

export default function caseTab(state = initialState, action) {
  switch (action.type) {
    case REQUEST_VIDEO_CREDITS:
      return {
        ...state,
        isRequesting: true,
        requestError: false,
      };
    case REQUEST_VIDEO_CREDITS_ERROR:
      return {
        ...state,
        isRequesting: false,
        requestError: true,
      };
    case RECEIVE_VIDEO_CREDITS:
      return {
        ...state,
        data: action.videoCredits,
        isRequesting: false,
        requestError: false,
      };
    default:
      return state;
  }
}
