import { ThunkAction } from 'redux-thunk';
import {
  DiagnosticProfileState,
  ClientDiagnosticProfile,
  ClientDiagnosticProfiles,
  DiagnosticProfileActionTypes,
  SET_CURRENT_DIAGNOSTIC_PROFILE_CLIENT,
  RECEIVE_ALL_CLIENTS_DIAGNOSTIC_PROFILES,
  REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES,
  REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES_ERROR,
} from '../constants/DiagnosticProfileConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

type ActionsThunk = ThunkAction<
  Promise<any> | void,
  any, // TODO: Remove any once we use typescript on the whole store
  undefined,
  DiagnosticProfileActionTypes
>;

interface DiagnosticProfileAPIResponse {
  status: number;
  data: { data: ClientDiagnosticProfile[] };
}

function requestDiagnosticProfiles(): DiagnosticProfileActionTypes {
  return { type: REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES };
}

function receivedAllDiagnosticProfiles(
  payload: Pick<DiagnosticProfileState, 'diagnosticProfiles'>
): DiagnosticProfileActionTypes {
  return { type: RECEIVE_ALL_CLIENTS_DIAGNOSTIC_PROFILES, payload };
}

function requestDiagnosticProfilesError(error): DiagnosticProfileActionTypes {
  return { type: REQUEST_ALL_CLIENTS_DIAGNOSTIC_PROFILES_ERROR, error };
}

export const getDiagnosticProfiles = (): ActionsThunk => (dispatch, getState) => {
  const {
    room: { roomID },
    customerList: { list },
  } = getState();
  dispatch(requestDiagnosticProfiles);

  const getProfile = (userID: string) =>
    apiWrapper
      .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/client-diagnostic-profiles/${userID}`)
      .catch((err) => err)
      .then((res) => {
        return {
          ...(res as DiagnosticProfileAPIResponse),
          userID,
        };
      });

  const userIDs = (list as { userID: string }[]).map(({ userID }) => userID);
  const promises = userIDs.map(getProfile);

  return Promise.all(promises).then((responses) => {
    // Ignore 404s
    const successfulResponses = responses.filter((res) => res.data && res.status !== 404);
    const unsuccessfulResponses = responses.filter((res) => !res.data && res.status !== 404);
    const diagnosticProfiles = successfulResponses.reduce<ClientDiagnosticProfiles>(
      (acc, { userID, data }) => {
        acc[userID] = data.data;
        return acc;
      },
      {}
    );
    dispatch(receivedAllDiagnosticProfiles({ diagnosticProfiles }));
    if (unsuccessfulResponses.length > 0) {
      unsuccessfulResponses.forEach((error) => {
        dispatch(requestDiagnosticProfilesError(error));
      });
    }
  });
};

export const setCurrentDiagnosticProfileClient =
  (clientUserID: string): ActionsThunk =>
  (dispatch) => {
    dispatch({ type: SET_CURRENT_DIAGNOSTIC_PROFILE_CLIENT, clientUserID });
  };
