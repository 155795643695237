import { Component } from 'react';
import './MatchingTherapist.css';
import { Select, useWindowWidthState } from '@talkspace/react-toolkit';

import { getOptionsByField } from '../../../../../utils/tsConfigsValues';

const genderOptionsWithNoPreference = getOptionsByField('therapistGenderPreference');
genderOptionsWithNoPreference.unshift({
  value: null,
  label: 'No Preference',
});

const therapistEthnicityOptionsWithNoPreference = getOptionsByField('therapistEthnicityPreference');
therapistEthnicityOptionsWithNoPreference.unshift({
  value: null,
  label: 'No Preference',
});
const SuggestTherapistOptionsView = ({
  inPlatformMatchingOptions: { yearsOfExperienceOptions },
  selectedOptions: {
    selectedSpecialties,
    selectedTreatmentPreferences,
    disabledTreatmentPreferences,
    selectedClientFocus,
    selectedLanguages,
    selectedTherapistGender,
    selectedTherapistEthnicity,
    selectedAgeGroups,
    selectedYearsOfExperience,
  },
  createSaveFieldHandler,
}) => {
  const { isMobile } = useWindowWidthState();

  return (
    <div className="in-platform-options">
      <div className="title">Field of expertise</div>
      <Select
        isMulti
        placeholder="Specialties"
        options={getOptionsByField('issues')}
        value={selectedSpecialties}
        onChange={createSaveFieldHandler('selectedSpecialties', true)}
      />
      <Select
        isMulti
        placeholder="Treatment preferences"
        options={getOptionsByField('treatmentPreferences')}
        value={selectedTreatmentPreferences}
        disabled={disabledTreatmentPreferences}
        onChange={createSaveFieldHandler('selectedTreatmentPreferences', true)}
      />
      <Select
        isMulti
        placeholder="Client focus"
        options={getOptionsByField('clientFocus')}
        value={selectedClientFocus}
        onChange={createSaveFieldHandler('selectedClientFocus', true)}
      />
      <Select
        isMulti
        placeholder="Languages"
        options={getOptionsByField('languages')}
        value={selectedLanguages}
        onChange={createSaveFieldHandler('selectedLanguages', true)}
      />
      <Select
        placeholder="Gender"
        options={genderOptionsWithNoPreference}
        value={selectedTherapistGender}
        onChange={createSaveFieldHandler('selectedTherapistGender')}
        isValid
        showIndicatorBeforeClick={isMobile}
      />
      <Select
        placeholder="Ethnicity"
        options={therapistEthnicityOptionsWithNoPreference}
        value={selectedTherapistEthnicity}
        onChange={createSaveFieldHandler('selectedTherapistEthnicity')}
        isValid
        showIndicatorBeforeClick={isMobile}
      />
      <Select
        isMulti
        placeholder="Age groups"
        options={getOptionsByField('therapistAgeGroups')}
        value={selectedAgeGroups}
        onChange={createSaveFieldHandler('selectedAgeGroups', true)}
      />
      <Select
        placeholder="Years of experience"
        options={yearsOfExperienceOptions}
        value={selectedYearsOfExperience}
        onChange={createSaveFieldHandler('selectedYearsOfExperience')}
        isValid
        showIndicatorBeforeClick={isMobile}
      />
    </div>
  );
};

export default SuggestTherapistOptionsView;
