import { useState } from 'react';
import {
  BaseButton,
  BookingIconWithX,
  Button,
  COLORS,
  Huge,
  Large,
  Modal,
  SelectRounded,
  SparklingCalendar,
  Standard,
  TextAreaRounded,
  View,
  shuffleArray,
} from '@talkspace/react-toolkit';
import {
  ProviderCancellationReasonEnum,
  ProviderCancellationReasonOption,
} from 'ts-frontend/types';
import { FetchState } from '../types';

const CancelBookingModal = ({
  closeModal,
  cancelBooking,
  bookingDatetimeString,
  fetchState,
}: {
  closeModal: () => void;
  cancelBooking: (cancelReason: string, otherReasonText?: string) => void;
  bookingDatetimeString?: string;
  fetchState: FetchState;
}) => {
  const hasCancelSucceeded = fetchState === 'fetchComplete';
  const [cancelReason, setCancelReason] = useState<ProviderCancellationReasonEnum>();
  const [showTextInput, setShowTextInput] = useState<boolean>(false);
  const [otherReasonText, setOtherReasonText] = useState<string>();

  const selectOptions: ProviderCancellationReasonOption[] = [
    ...shuffleArray([
      {
        value: ProviderCancellationReasonEnum.PROVIDER_NOT_AVAILABLE,
        label: 'I’m not available',
      },
      {
        value: ProviderCancellationReasonEnum.CLIENT_REQUESTED,
        label: 'The client requested it',
      },
      {
        value: ProviderCancellationReasonEnum.AGREED_TO_RESCHEDULE,
        label: 'We’ve agreed on a reschedule time',
      },
    ]),
    { value: ProviderCancellationReasonEnum.OTHER, label: 'Other' },
  ];

  if (hasCancelSucceeded) {
    const cancelConfirmedText =
      cancelReason === ProviderCancellationReasonEnum.CLIENT_REQUESTED
        ? 'We’ve let your client know that you’ve cancelled this session at their request.'
        : 'We’ve let your client know. Please message them to arrange an alternative time for your session.';
    return (
      <Modal isVisible onBackdropPress={closeModal} titleText="Booking cancelled">
        <Modal.Panel
          onBackdropPress={closeModal}
          contentViewStyle={{ paddingTop: 0, alignItems: 'center' }}
        >
          <View
            style={{
              marginTop: 9,
              marginBottom: 14,
              textAlign: 'center',
              width: 320,
              alignItems: 'center',
            }}
          >
            <BookingIconWithX color={COLORS.purple} />
            <Huge style={{ marginTop: 30, marginBottom: 20 }}>Booking cancelled</Huge>
            <Large>{cancelConfirmedText}</Large>
          </View>
          <BaseButton style={{ marginTop: 50 }} onPress={closeModal}>
            <Standard variant="standardDarkGrey" style={{ color: COLORS.darkGreen }}>
              Close
            </Standard>
          </BaseButton>
        </Modal.Panel>
      </Modal>
    );
  }
  const handleOnSelectChange = ({ value }) => {
    setCancelReason(value);
    if (value === ProviderCancellationReasonEnum.OTHER) {
      setShowTextInput(true);
    } else {
      setShowTextInput(false);
      setOtherReasonText('');
    }
  };

  return (
    <Modal isVisible onBackdropPress={closeModal} titleText="Cancel booking">
      <Modal.Panel
        onBackdropPress={closeModal}
        contentViewStyle={{ paddingTop: 0, alignItems: 'center' }}
      >
        <View
          style={{
            marginTop: 9,
            marginBottom: 14,
            textAlign: 'center',
            width: 320,
            alignItems: 'center',
          }}
        >
          <SparklingCalendar />
          <Large inline style={{ marginTop: 30, marginBottom: 40, fontWeight: 600 }}>
            To cancel your live session scheduled for
            {bookingDatetimeString}, select a reason for cancelling.
          </Large>
          <SelectRounded
            options={selectOptions}
            placeholder="Select one"
            onChange={handleOnSelectChange as (OptionType) => void}
          />
          {showTextInput && (
            <TextAreaRounded
              multiline
              placeholder="Please tell us more about your reason for cancelling"
              dataQa="textInputCancellationReason"
              value={otherReasonText}
              onChangeText={(value) => {
                setOtherReasonText(value);
              }}
              textAreaStyle={{ padding: '16px 0 0' }}
              style={{ marginTop: 8, width: '100%' }}
            />
          )}
        </View>
        <Button
          text="Cancel booking"
          onPress={() => cancelReason && cancelBooking(cancelReason, otherReasonText)}
          style={{ marginTop: 20, width: 320 }}
          disabled={
            fetchState === 'fetching' || !cancelReason || (showTextInput && !otherReasonText)
          }
        />
      </Modal.Panel>
    </Modal>
  );
};

export default CancelBookingModal;
