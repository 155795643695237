import { EmotionStyle } from '@talkspace/react-toolkit';
import Svg, { Circle, G } from 'svgs';

interface UpcomingLVSIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const UpcomingLVSIcon = ({
  color = '#4CD964',
  width = 6,
  height = 6,
  ...otherProps
}: UpcomingLVSIconProps) => (
  <Svg width={width} height={height} viewBox="0 0 6 6" {...otherProps}>
    <G fill="none" stroke="none" strokeWidth={1} fillRule="evenodd">
      <Circle fill={color} cx={3} cy={3} r={3} />
    </G>
  </Svg>
);

export default UpcomingLVSIcon;
