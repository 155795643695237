import * as actionTypes from '../constants/MatchingConstants';

const initialState = {
  inPlatformMatchingOptions: {
    specialties: [],
    treatmentPreferences: [],
    clientFocus: [],
    languages: [],
  },
  availableTherapists: [],
  isUpdating: false,
  isSwitching: false,
  isSuggesting: false,
  isError: false,
  noTherapistsFound: false,
  treatmentIntakeStatus: undefined,
  matchingIntakeStatus: undefined,
  isChangingRoomType: false,
};

export default function matching(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_AVAILABLE_THERAPISTS:
    case actionTypes.REQUEST_SEND_INTAKE_MESSAGE:
    case actionTypes.REQUEST_INTAKE_SURVEY_STATUS:
    case actionTypes.REQUEST_IN_PLATFORM_MATCHING_OPTIONS:
    case actionTypes.REQUEST_SEND_ELIGIBILITY_MESSAGE:
      return {
        ...state,
        isUpdating: true,
        isError: false,
      };
    case actionTypes.REQUEST_AVAILABLE_THERAPISTS_ERROR:
    case actionTypes.REQUEST_SEND_INTAKE_MESSAGE_ERROR:
    case actionTypes.REQUEST_INTAKE_SURVEY_STATUS_ERROR:
    case actionTypes.REQUEST_IN_PLATFORM_MATCHING_OPTIONS_ERROR:
    case actionTypes.REQUEST_SEND_ELIGIBILITY_MESSAGE_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
      };
    case actionTypes.RECEIVE_SEND_ELIGIBILITY_MESSAGE:
      return {
        ...state,
        isUpdating: false,
        isError: false,
      };
    case actionTypes.RECEIVE_AVAILABLE_THERAPISTS:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        availableTherapists: action.availableTherapists,
      };
    case actionTypes.RECEIVE_SEND_TREATMENT_INTAKE_MESSAGE:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        treatmentIntakeStatus: 'requested',
      };
    case actionTypes.RECEIVE_SEND_MATCHING_INTAKE_MESSAGE:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        matchingIntakeStatus: 'requested',
      };
    case actionTypes.RECEIVE_TREATMENT_INTAKE_SURVEY_STATUS:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        treatmentIntakeStatus: action.treatmentIntakeStatus,
      };
    case actionTypes.RECEIVE_MATCHING_INTAKE_SURVEY_STATUS:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        matchingIntakeStatus: action.matchingIntakeStatus,
      };
    case actionTypes.RECEIVE_IN_PLATFORM_MATCHING_OPTIONS:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        ...action.payload,
      };
    case actionTypes.SWITCH_THERAPIST:
      return {
        ...state,
        isSwitching: true,
        isError: false,
      };
    case actionTypes.SWITCH_THERAPIST_SUCCESS:
      return {
        ...state,
        isSwitching: false,
        isError: false,
      };
    case actionTypes.SWITCH_THERAPIST_ERROR:
      return {
        ...state,
        isSwitching: false,
        isError: true,
      };
    case actionTypes.SUGGESTING_THERAPISTS:
      return {
        ...state,
        isSuggesting: true,
        isError: false,
        noTherapistsFound: false,
      };
    case actionTypes.SUGGEST_THERAPISTS_SUCCESS:
      return {
        ...state,
        isSuggesting: false,
        isError: false,
        noTherapistsFound: false,
      };
    case actionTypes.SUGGEST_THERAPISTS_ERROR:
      return {
        ...state,
        isSuggesting: false,
        isError: true,
        noTherapistsFound: false,
      };
    case actionTypes.NO_SUGGESTED_THERAPISTS_FOUND:
      return {
        ...state,
        isSuggesting: false,
        isError: false,
        noTherapistsFound: true,
      };
    case actionTypes.CHANGING_ROOM_TYPE:
      return {
        ...state,
        isChangingRoomType: true,
        isError: false,
      };
    case actionTypes.CHANGE_ROOM_TYPE_SUCCESS:
      return {
        ...state,
        isChangingRoomType: false,
        isError: false,
      };
    case actionTypes.CHANGE_ROOM_TYPE_ERROR:
      return {
        ...state,
        isChangingRoomType: false,
        isError: true,
      };
    default:
      return state;
  }
}
