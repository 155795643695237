import { TouchableView, View } from '@talkspace/react-toolkit';
import styled from '../../../core/styled';

const Button = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 15,
  };
});

export const ViewButton = styled(Button)(({ theme: { window, colors } }) => {
  return {
    lineHeight: '30px',
    width: window.isMobile ? 91 : 123,
    backgroundColor: colors.permaBlueStoneNew,
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 700,
    color: colors.white,
    textAlign: 'center',
    alignItems: 'center',
  };
});

const ButtonWrapper = styled(View)({
  width: '100%',
  display: 'flex',
  justifyContent: 'right',
  flexDirection: 'row',
});

const ViewClientButton = ({ onButtonPress }: { onButtonPress: () => void }) => (
  <ButtonWrapper>
    <ViewButton onPress={onButtonPress}>View</ViewButton>
  </ButtonWrapper>
);

export default ViewClientButton;
