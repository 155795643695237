/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import { useEffect, useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import moment, { Moment } from 'moment';
import { PanelManager, useObjectState, View, useWindowWidthState } from '@talkspace/react-toolkit';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import { useQueryClient } from 'react-query';
import dashboardKeys from 'hooks/dashboard/queryKeys';
import useQueryAdminConfigValue from 'hooks/useQueryUsersAdminConfigValue';
import useQueryRooms, { RoomsResponse } from 'hooks/dashboard/useQueryRooms';
import useQueryClientUnavailabilityPeriod from 'hooks/dashboard/useQueryCapacity';
import useQueryMetrics from 'hooks/dashboard/useQueryMetrics';
import useQueryRoomsByID from 'hooks/dashboard/useQueryRoomsByID';
import useMutationUpdateClinicalInfo from 'hooks/useMutationUpdateClinicalInfo';
import useQueryAccountInformationStatus from 'hooks/dashboard/useQueryAccountInformationStatus';
import useMutationUpdateClientCapacity from 'hooks/availability/useMutationUpdateClientCapacity';
import useQueryClientCapacity from 'hooks/availability/useQueryClientCapacity';
import AccessInitiativeModal from './Modals/AccessInitiativeModal';
import DailyCaseLoadModal from './Modals/DailyCaseLoadModal';
import Footer from '../Footer/Footer';
import { getUserData } from '../../utils/token';
import SocketService from '../../utils/socket/SocketService';
import LiveSessions from './LiveSessions/LiveSessions';
import AllClients from './Clients/NonCT/AllClients';
import NewClients from './Clients/NonCT/NewClients';
import NewClientAvailabilityBar from './NewClientAvailabilityBar/NewClientAvailabilityBar';
import './Dashboard.css';
import storage from '../../core/storage';
import {
  CaseLoadView,
  CheckRoomPath,
  Counters,
  CurrentResponsiveTab,
  NewRoomsRecord,
  RoomRecord,
  localStorageTableNames,
} from './types';
import styled from '../../core/styled';

interface TherapistDashboardState {
  counters: Counters;
  newRoomsData: NewRoomsRecord[];
  allRoomsData: RoomRecord[];
  slaFeatureActive: boolean;
  currentView: CaseLoadView;
  showMissingInfoModal: boolean;
  showAccessInitiativeModal: boolean;
  showDailyCaseLoadModal: boolean;
  modalValues: string[];
  filterFromDate: string;
  filterToDate: string;
  token: string | null;
  currentResponsiveTab: CurrentResponsiveTab;
  isLoading: boolean;
  isAvailabilityBarOpen: boolean;
  shouldShowAnimatedCheck: boolean;
  roomID: number;
  numberOfBookings?: number;
}

interface TherapistDashboardProps {
  isNPP?: boolean;
  therapistType?: TherapistType;
  isError: boolean;
  checkRoomPath: CheckRoomPath;
}

const getLocalStorageState = () => {
  let overrideState: Partial<
    Pick<TherapistDashboardState, 'currentView' | 'filterFromDate' | 'filterToDate'>
  > = {};
  const caseLoadTable = storage.getItem(localStorageTableNames.caseLoad);

  if (caseLoadTable) {
    const parseData = JSON.parse(caseLoadTable);
    overrideState = {
      ...(parseData.view && { currentView: parseData.view }),
      ...(parseData.filterFromDate && { filterFromDate: parseData.filterFromDate }),
      ...(parseData.filterToDate && { filterToDate: parseData.filterToDate }),
    };
  }
  return overrideState;
};

const getSearchParamDefaults = (
  therapistType?: TherapistType
): Pick<TherapistDashboardState, 'filterFromDate' | 'filterToDate' | 'currentView'> => {
  return {
    currentView: therapistType === 'primary' ? 'paying' : 'all',
    filterFromDate: moment().subtract(7, 'days').startOf('day').toISOString(),
    filterToDate: moment().endOf('day').toISOString(),
  };
};

const updateAllRoomsStateOneRoom = (roomID, roomsInState, roomsFromResponse) => {
  const updatedRoom: RoomRecord | null =
    roomsFromResponse?.length > 0 ? roomsFromResponse[0] : null;
  // filter out room if it is already in our data
  const updatedAllRoomsData: RoomRecord[] = roomsInState.filter((room) => room.roomID !== roomID);
  // include new room
  if (updatedRoom) {
    updatedAllRoomsData.push(updatedRoom);
  }
  return updatedAllRoomsData;
};

const updateNewRoomsStateOneRoom = (roomID, roomsInState, roomsFromResponse) => {
  // update new rooms from response
  const [newRoom] = roomsFromResponse;
  const isNewRoomInState = !!roomsInState.find((room) => room.roomID === roomID);
  if (!newRoom && isNewRoomInState) {
    // Remove new room from list
    return roomsInState.filter((room) => room.roomID !== roomID);
  }
  if (newRoom && isNewRoomInState) {
    // Update new room in list
    return roomsInState.map((room) => (room.roomID === roomID ? newRoom : room));
  }
  if (newRoom) {
    // Add room to state
    return [newRoom, ...roomsInState];
  }
  // No change
  return roomsInState;
};

const Wrapper = styled(View)(() => {
  return {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: `calc(100vh - 68px)`,
  };
});

const TherapistDashboard = ({
  isNPP,
  therapistType,
  isError,
  checkRoomPath,
}: TherapistDashboardProps) => {
  const queryClient = useQueryClient();

  const overrideState = getLocalStorageState();

  const cachedRooms: RoomsResponse | undefined = queryClient.getQueryData(
    dashboardKeys.roomsFilter({
      include: ['newRooms', therapistType === 'primary' ? 'paying' : 'all'],
      filterFromDate: moment().subtract(7, 'days').startOf('day').toISOString(),
      filterToDate: moment().startOf('day').toISOString(),
      ...(overrideState?.currentView && { include: ['newRooms', overrideState.currentView] }),
      ...(overrideState?.filterFromDate && {
        filterFromDate: moment(overrideState.filterFromDate).startOf('day').toISOString(),
      }),
      ...(overrideState?.filterToDate && {
        filterToDate: moment(overrideState.filterToDate).endOf('day').toISOString(),
      }),
    })
  );

  const [state, setState] = useObjectState<TherapistDashboardState>({
    ...getSearchParamDefaults(therapistType),
    counters: cachedRooms?.counts || { all: 0, consultation: 0, paying: 0 },
    newRoomsData: cachedRooms?.newRooms || [],
    allRoomsData: cachedRooms?.rooms || [],
    slaFeatureActive: false,
    showMissingInfoModal: false,
    showAccessInitiativeModal: false,
    showDailyCaseLoadModal: false,
    modalValues: [],
    token: storage.getItem('id_token'),
    currentResponsiveTab: 'live-sessions',
    isLoading: !cachedRooms,
    isAvailabilityBarOpen: false,
    shouldShowAnimatedCheck: false,

    // live sessions
    numberOfBookings: undefined,
    roomID: -1,
    ...getLocalStorageState(),
  });

  const memorizedRoomQueryKeys = useMemo(() => {
    return {
      include: ['newRooms', state.currentView],
      filterFromDate: state.filterFromDate,
      filterToDate: state.filterToDate,
    };
  }, [state.currentView, state.filterFromDate, state.filterToDate]);

  const { isMobile } = useWindowWidthState();
  const [shouldCloseDropDown, setShouldCloseDropDown] = useState<boolean>(false);

  const therapistID: number = getUserData().id;

  const history = useHistory();

  const { data: dailyCaseLoadOptions, isLoading: isDailyCaseLoadOptionsLoading } =
    useQueryAdminConfigValue<Record<string, number>>({
      key: 'provider_daily_caseload_options',
      json: true,
    });

  const {
    data: rooms,
    isLoading: isRoomsLoading,
    isFetching: isRoomsFetching,
  } = useQueryRooms(memorizedRoomQueryKeys);

  const { data: roomsByID, isFetching: isRoomFetching } = useQueryRoomsByID({
    roomID: state.roomID,
    ...memorizedRoomQueryKeys,
  });

  const { data: metrics } = useQueryMetrics(therapistID);
  const { data: unavailabilityPeriod, isLoading: isUnavailabilityPeriodLoading } =
    useQueryClientUnavailabilityPeriod(therapistID);
  const { data: accountInformationStatus, isLoading: isAccountInfoStatusLoading } =
    useQueryAccountInformationStatus(therapistID);
  const { data: clientCapacity, isLoading: isClientCapacityLoading } =
    useQueryClientCapacity(therapistID);

  const { mutate: updateClinicalInfo } = useMutationUpdateClinicalInfo();
  const { mutate: updateClientCapacity, isLoading: isClientCapacityUpdating } =
    useMutationUpdateClientCapacity();

  useEffect(() => {
    if (!isRoomsFetching && rooms) {
      setState({
        counters: rooms.counts,
        allRoomsData: rooms.rooms,
      });
    }
  }, [isRoomsFetching, rooms, rooms?.counts, rooms?.rooms, setState]);

  useEffect(() => {
    if (!isRoomFetching && roomsByID?.rooms && roomsByID?.newRooms) {
      const updatedAllRoomsData = updateAllRoomsStateOneRoom(
        state.roomID,
        state.allRoomsData,
        roomsByID?.rooms
      );

      const updatedNewRooms = updateNewRoomsStateOneRoom(
        state.roomID,
        state.newRoomsData,
        roomsByID?.newRooms
      );

      queryClient.invalidateQueries(dashboardKeys.rooms(), { refetchInactive: true });

      setState({
        allRoomsData: updatedAllRoomsData,
        counters: roomsByID.counts,
        newRoomsData: updatedNewRooms,
        roomID: -1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoomFetching, roomsByID, setState, state.roomID]);

  useEffect(() => {
    if (!isAccountInfoStatusLoading) {
      if (accountInformationStatus?.missingInformation) {
        setState({
          showMissingInfoModal: true,
          modalValues: accountInformationStatus?.fields || [],
        });
      }
      const shouldShowAccessInitiativeModal: boolean =
        accountInformationStatus?.fields.includes('Access Initiative Participation') || false;
      if (shouldShowAccessInitiativeModal) {
        setState({
          showAccessInitiativeModal: true,
        });
      }
    }
  }, [
    accountInformationStatus?.fields,
    accountInformationStatus?.missingInformation,
    isAccountInfoStatusLoading,
    setState,
  ]);

  const getRoomsData = useCallback(
    (roomView: CaseLoadView = state.currentView) => {
      setState({
        currentView: roomView,
      });
    },
    [setState, state.currentView]
  );

  const handleToggleTab = useCallback(
    (event) => {
      setState({
        currentResponsiveTab: (event.currentTarget as HTMLButtonElement).dataset
          .view as CurrentResponsiveTab,
      });
    },
    [setState]
  );

  const updateFilterFromDate = useCallback(
    (filterFromDate: Moment) => {
      setState({
        filterFromDate: filterFromDate.startOf('day').toISOString(),
      });
    },
    [setState]
  );

  const updateFilterToDate = useCallback(
    (filterToDate: Moment) => {
      setState({
        filterToDate: filterToDate.endOf('day').toISOString(),
      });
    },
    [setState]
  );

  const updateNumberOfBookings = useCallback(
    (numberOfBookings: number) => setState({ numberOfBookings }),
    [setState]
  );

  const visitMyAccount = useCallback(() => {
    history.push('/my-account');
  }, [history]);

  const getShouldShowDailyCaseLoadModal = useCallback(
    (
      providerInUnavailabilityPeriod: boolean,
      capacityModalSeenAt: string | null,
      oneTimeCapacityModalSeenAt?: string | null
    ) => {
      const showForUnavailabilityPeriod: boolean =
        providerInUnavailabilityPeriod &&
        !oneTimeCapacityModalSeenAt &&
        clientCapacity?.dailyCaseLoad === 'none';
      const showForOneWeekReminder =
        !capacityModalSeenAt &&
        !oneTimeCapacityModalSeenAt &&
        (moment().subtract(1, 'weeks').isAfter(moment(clientCapacity?.updatedAt)) ||
          !clientCapacity?.updatedAt) &&
        clientCapacity?.dailyCaseLoad === 'none';

      return showForUnavailabilityPeriod || showForOneWeekReminder;
    },
    [clientCapacity?.dailyCaseLoad, clientCapacity?.updatedAt]
  );

  const getCapacity = useCallback(() => {
    const capacityModalSeenAt = storage.getItem('capacityModalSeenAt');
    const oneTimeCapacityModalSeenAt = storage.getItem('oneTimeCapacityModalSeenAt');

    if (isUnavailabilityPeriodLoading) return null;

    const providerInUnavailabilityPeriod =
      unavailabilityPeriod?.state === 1 &&
      moment().isBetween(
        moment(unavailabilityPeriod.startDate),
        moment(unavailabilityPeriod.endDate)
      );

    if (
      getShouldShowDailyCaseLoadModal(
        providerInUnavailabilityPeriod,
        capacityModalSeenAt,
        oneTimeCapacityModalSeenAt
      )
    ) {
      setState({ showDailyCaseLoadModal: true });
    }

    return true;
  }, [
    getShouldShowDailyCaseLoadModal,
    isUnavailabilityPeriodLoading,
    setState,
    unavailabilityPeriod?.endDate,
    unavailabilityPeriod?.startDate,
    unavailabilityPeriod?.state,
  ]);

  const invalidateSingleRoom = useCallback(
    () =>
      queryClient.invalidateQueries(
        dashboardKeys.roomFilter(state.roomID, {
          ...memorizedRoomQueryKeys,
        })
      ),
    [queryClient, memorizedRoomQueryKeys, state.roomID]
  );

  useEffect(() => {
    const handleNewMessage = (data) => {
      setState({
        roomID: data.roomId,
      });
      invalidateSingleRoom();
    };

    const handleRoomChange = (data) => {
      setState({
        roomID: data.message.private_talk_id,
      });
      invalidateSingleRoom();
    };

    SocketService.instance().on('newMessageHeader', handleNewMessage);
    SocketService.instance().on('room_change', handleRoomChange);
    return () => {
      SocketService.instance().off('newMessageHeader', handleNewMessage);
      SocketService.instance().off('room_change', handleRoomChange);
    };
  }, [
    invalidateSingleRoom,
    queryClient,
    setState,
    state.currentView,
    state.filterFromDate,
    state.filterToDate,
  ]);

  useEffect(() => {
    if (!isRoomsLoading && rooms && therapistType) {
      setState({
        isLoading: false,
        allRoomsData: rooms.rooms,
        newRoomsData: rooms.newRooms,
        counters: rooms.counts,
      });
    }
  }, [isRoomsLoading, rooms, setState, therapistType]);

  useEffect(() => {
    getCapacity();
  }, [getCapacity]);

  const closeDropDown = useCallback(
    (e) => {
      if (document.querySelector('.dropdown.open') && !e.target.closest('.dropdown.open')) {
        setShouldCloseDropDown(true);
      } else if (shouldCloseDropDown) {
        setShouldCloseDropDown(false);
      }
    },
    [shouldCloseDropDown]
  );

  const handleAccessInitiativePress = useCallback(
    (userInput: boolean) => {
      updateClinicalInfo({ accessInitiativeParticipation: userInput, therapistID });

      const updatedModalValues =
        accountInformationStatus?.fields?.filter(
          (v) => !v.includes('Access Initiative Participation')
        ) || [];

      setState({
        showAccessInitiativeModal: false,
        showMissingInfoModal: updatedModalValues.length > 0,
        modalValues: updatedModalValues,
      });
    },
    [accountInformationStatus?.fields, setState, therapistID, updateClinicalInfo]
  );

  const handleOpenAvailabilityBar = useCallback(
    (value: boolean) => {
      setState({
        isAvailabilityBarOpen: value,
      });
    },
    [setState]
  );

  const handleShowAnimatedCheck = useCallback(
    (value: boolean) => {
      setState({ shouldShowAnimatedCheck: value });
    },
    [setState]
  );

  const handleAvailabilityPress = useCallback(
    async (caseLoad: string) => {
      updateClientCapacity({ therapistID, dailyCaseLoad: caseLoad });

      setState({
        showDailyCaseLoadModal: false,
        shouldShowAnimatedCheck: true,
      });
    },
    [setState, therapistID, updateClientCapacity]
  );

  const handlePreviousSettingPress = useCallback(
    async (caseLoad: string) => {
      updateClientCapacity({ therapistID, dailyCaseLoad: caseLoad });

      setState({
        showDailyCaseLoadModal: false,
      });
    },
    [setState, therapistID, updateClientCapacity]
  );

  const isPrimary = useMemo(() => therapistType === 'primary', [therapistType]);

  const shouldShowAccessInitiative = useMemo(
    () => state.showAccessInitiativeModal && isPrimary,
    [isPrimary, state.showAccessInitiativeModal]
  );

  return (
    <div onClick={closeDropDown}>
      <PanelManager>
        {!state.isLoading && (
          <>
            <Modal
              show={state.showMissingInfoModal && !shouldShowAccessInitiative}
              onHide={() => setState({ showMissingInfoModal: false })}
              container={this}
              aria-labelledby="contained-modal-title"
            >
              <div className="modal-content-wrapper" style={{ color: '#E8006B' }}>
                <span className="fa-stack fa-3x exclamation">
                  <i className="fa fa-circle-thin fa-stack-2x" />
                  <i className="fa fa-exclamation fa-stack-1x" />
                </span>
              </div>
              <h2 className="center">Missing Information</h2>
              <p className="center paddedlr-30">
                You will not receive referrals until all of your Account information is complete.
                The following information is missing:
              </p>
              <div>
                <ul className="list-unstyled" style={{ width: '300px', margin: '0 auto' }}>
                  {state.modalValues
                    .filter(
                      (value) =>
                        !(
                          value === 'Access Initiative Participation' && therapistType !== 'primary'
                        )
                    )
                    .map((key, index) => (
                      <li
                        key={key}
                        className="text-center"
                        style={{ fontSize: '16px', color: '#E8006B' }}
                      >
                        <strong>- {key} </strong>
                      </li>
                    ))}
                </ul>
              </div>
              <div onClick={visitMyAccount} className="modal-content-wrapper modal-btn-wrapper">
                <button type="button" className="btn btn-lg btn-talkspace-primary">
                  Go to my account
                </button>
              </div>
            </Modal>
            {shouldShowAccessInitiative && (
              <AccessInitiativeModal
                showModal={state.showAccessInitiativeModal}
                onPartipicateButtonPress={() => handleAccessInitiativePress(true)}
                onNotInterestedButtonPress={() => handleAccessInitiativePress(false)}
              />
            )}
            {state.showDailyCaseLoadModal &&
              !shouldShowAccessInitiative &&
              !state.showMissingInfoModal &&
              therapistType &&
              clientCapacity?.dailyCaseLoad &&
              dailyCaseLoadOptions && (
                <DailyCaseLoadModal
                  therapistType={therapistType}
                  showModal={state.showDailyCaseLoadModal}
                  onAvailabilityPress={handleAvailabilityPress}
                  onKeepSettingPress={handlePreviousSettingPress}
                  dailyCaseLoad={clientCapacity.dailyCaseLoad}
                  dailyCaseLoadOptions={dailyCaseLoadOptions}
                />
              )}
          </>
        )}
        <Wrapper>
          <div className="client-wrapper-padding">
            <div
              style={{ padding: 0, marginTop: 25, marginBottom: 25 }}
              className="col-xs-12 col-lg-10 col-lg-push-1"
            >
              {clientCapacity?.dailyCaseLoad && dailyCaseLoadOptions && therapistType && (
                <NewClientAvailabilityBar
                  dailyCaseLoadOptions={dailyCaseLoadOptions}
                  providerCapacity={
                    dailyCaseLoadOptions && dailyCaseLoadOptions[clientCapacity.dailyCaseLoad]
                  }
                  onSelectionPress={(value) => {
                    updateClientCapacity({ therapistID, dailyCaseLoad: value });
                  }}
                  isCapacityUpdating={isClientCapacityUpdating}
                  onSuccess={() => {}}
                  isError={isError}
                  handleOpen={handleOpenAvailabilityBar}
                  isOpen={state.isAvailabilityBarOpen}
                  shouldShowAnimatedCheck={state.shouldShowAnimatedCheck}
                  handleShowAnimatedCheck={handleShowAnimatedCheck}
                  therapistType={therapistType}
                  isLoading={isDailyCaseLoadOptionsLoading || isClientCapacityLoading}
                />
              )}
            </div>
            {isMobile && (
              <div className="col-xs-12 col-lg-10 col-lg-push-1 table-tab-container">
                <div
                  onClick={handleToggleTab}
                  className={
                    state.currentResponsiveTab === 'live-sessions'
                      ? 'table-tab tab-selected'
                      : 'table-tab'
                  }
                  data-view="live-sessions"
                >
                  Upcoming live sessions{' '}
                  {state.numberOfBookings !== undefined && (
                    <span className="number-indication">{state.numberOfBookings}</span>
                  )}
                </div>
                <div
                  onClick={handleToggleTab}
                  className={
                    state.currentResponsiveTab === 'new-clients'
                      ? 'table-tab tab-selected'
                      : 'table-tab'
                  }
                  data-view="new-clients"
                >
                  Send first message{' '}
                  <span className="number-indication">{state.newRoomsData.length}</span>
                </div>
                <div
                  onClick={handleToggleTab}
                  className={
                    state.currentResponsiveTab === 'all-clients'
                      ? 'table-tab tab-selected'
                      : 'table-tab'
                  }
                  data-view="all-clients"
                >
                  Your caseload <span className="number-indication">{state.counters.all}</span>
                </div>
              </div>
            )}
            <div style={{ padding: 0 }} className="col-xs-12 col-lg-10 col-lg-push-1">
              <LiveSessions
                isHidden={isMobile && state.currentResponsiveTab !== 'live-sessions'}
                updateNumberOfBookings={updateNumberOfBookings}
              />
              <NewClients
                isLoading={state.isLoading}
                newRoomsData={state.newRoomsData}
                isHidden={isMobile && state.currentResponsiveTab !== 'new-clients'}
                checkRoomPath={checkRoomPath}
                handleOpenAvailabilityBar={handleOpenAvailabilityBar}
              />
              <AllClients
                isLoading={state.isLoading}
                slaFeatureActive={state.slaFeatureActive}
                allRoomsData={state.allRoomsData}
                counters={state.counters}
                getRoomsData={getRoomsData}
                view={state.currentView}
                isHidden={isMobile && state.currentResponsiveTab !== 'all-clients'}
                checkRoomPath={checkRoomPath}
                filterFromDate={moment(state.filterFromDate)}
                filterToDate={moment(state.filterToDate)}
                updateFilterFromDate={updateFilterFromDate}
                updateFilterToDate={updateFilterToDate}
                shouldCloseDropDown={shouldCloseDropDown}
                metrics={metrics}
                isNPP={isNPP}
                handleOpenAvailabilityBar={handleOpenAvailabilityBar}
              />
              <Footer hidden={false} />
            </div>
          </div>
        </Wrapper>
      </PanelManager>
    </div>
  );
};

export default TherapistDashboard;
