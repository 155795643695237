import { Component } from 'react';
import { Select, useWindowWidthState } from '@talkspace/react-toolkit';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';
import AlertContainer from '../../../../Reusable/AlertContainer/AlertContainer';
import Button from '../../../../Reusable/Buttons/Button';
import Spinner from '../../../../Reusable/Spinner/Spinner';
import Footer from '../../../../Reusable/Footer/Footer';
import SuggestTherapistOptionsView from './SuggestTherapistOptionsView';
import './MatchingTherapist.css';

const continueButton = (continueButtonClickHandler) => (
  <Button
    key={1}
    style={{ marginTop: 34, marginBottom: 2, paddingRight: 0, float: 'none' }}
    clickHandler={continueButtonClickHandler}
    title="Continue"
  />
);

const cancelButton = (history) => (
  <Button key={0} clickHandler={history.goBack} title="Cancel" isSecondary />
);

const suggestButton = (callSuggestTherapistsRPC) => (
  <Button
    key={1}
    isSecondary={false}
    clickHandler={callSuggestTherapistsRPC}
    title="Suggest providers"
  />
);

const suggestSpinner = (isSuggesting, isError, noTherapistsFound) => (
  <Spinner
    style={{ margin: 12 }}
    key={3}
    isUpdating={isSuggesting}
    isError={isError || noTherapistsFound}
    errorMessage="No providers were found"
    spinnerMessage="Successfully posted in room"
  />
);

const AlertView = ({
  history,
  isStateMissing,
  isCountryMissing,
  state,
  country,
  continueButtonClickHandler,
  clientUserID,
  setCustomerInformation,
}) => {
  const { isMobile } = useWindowWidthState();
  const createHandleSelectChange = (field) => (selectObject) => {
    setCustomerInformation(clientUserID, {
      [field]: selectObject,
    });
  };
  const childComponents = (
    <>
      <AlertContainer
        title="Missing required fields"
        body="The following fields are missing and should be completed in order to proceed."
        childrenContainerStyle={{ flexDirection: 'column' }}
      >
        <Select
          value={country}
          options={getOptionsByField('country')}
          placeholder="Country"
          onChange={createHandleSelectChange('country')}
          showIndicatorBeforeClick={isMobile}
        />
        {isStateMissing && (
          <Select
            value={state}
            options={getOptionsByField('state')}
            placeholder="State"
            onChange={createHandleSelectChange('state')}
            showIndicatorBeforeClick={isMobile}
          />
        )}
      </AlertContainer>
      {isStateMissing || isCountryMissing ? null : continueButton(continueButtonClickHandler())}
    </>
  );
  const footerComponent = <Footer key={1} childComponents={[cancelButton(history)]} />;
  return { childComponents, footerComponent };
};

const suggestViewArray = ({
  history,
  isError,
  suggestInitiated,
  isSuggesting,
  noTherapistsFound,
  callSuggestTherapistsRPC,
  inPlatformMatchingOptions,
  selectedOptions,
  createSaveFieldHandler,
}) => {
  const childComponents = [
    <SuggestTherapistOptionsView
      key={0}
      inPlatformMatchingOptions={inPlatformMatchingOptions}
      selectedOptions={selectedOptions}
      createSaveFieldHandler={createSaveFieldHandler}
    />,
  ];
  const footerComponent = (
    <Footer
      key={1}
      childComponents={[
        suggestInitiated ? null : cancelButton(history),
        suggestInitiated ? null : suggestButton(callSuggestTherapistsRPC),
        suggestInitiated ? suggestSpinner(isSuggesting, isError, noTherapistsFound) : null,
      ]}
    />
  );

  return { childComponents, footerComponent };
};

const matchingView = ({
  displayAlert,
  isStateMissing,
  isCountryMissing,
  history,
  country,
  state,
  isError,
  isRequestingCustomer,
  getUpdateFunctionForComponent,
  continueButtonClickHandler,
  clientUserID,
  invalidComponents,
  disabledComponents,
  setCustomerInformation,
  isSuggestATherapist,
  isSuggesting,
  callSuggestTherapistsRPC,
  suggestInitiated,
  noTherapistsFound,
  inPlatformMatchingOptions,
  selectedOptions,
  createSaveFieldHandler,
}) =>
  displayAlert
    ? AlertView({
        history,
        isStateMissing,
        isCountryMissing,
        state,
        country,
        continueButtonClickHandler,
        setCustomerInformation,
        clientUserID,
        disabledComponents,
        invalidComponents,
        getUpdateFunctionForComponent,
        isRequestingCustomer,
      })
    : suggestViewArray({
        displayAlert,
        isStateMissing,
        isCountryMissing,
        history,
        country,
        state,
        isError,
        isRequestingCustomer,
        suggestInitiated,
        continueButtonClickHandler,
        clientUserID,
        setCustomerInformation,
        disabledComponents,
        invalidComponents,
        isSuggestATherapist,
        isSuggesting,
        callSuggestTherapistsRPC,
        noTherapistsFound,
        inPlatformMatchingOptions,
        selectedOptions,
        createSaveFieldHandler,
      });

const MatchingView = {};
MatchingView.ChildComponents = (props) => matchingView(props).childComponents;

MatchingView.FooterComponent = (props) => matchingView(props).footerComponent;

export default MatchingView;
