import { useEffect, useState, useRef } from 'react';
import { Grid, Row } from 'react-bootstrap';
import { useLocation } from 'react-router';
import {
  View,
  PanelHeader,
  useWindowWidth,
  useObjectState,
  ResponsiveLayoutWithHeader,
} from '@talkspace/react-toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Footer from '../Footer/Footer';
import { getAvailability } from '../../actions/AvailabilityActions';
import { getUserData } from '../../utils/token';
import { useUpcomingBookings } from '../../modules/upcomingBookings';
import Profile from './Profile/Profile';
import Security from './Security/Security';
import PrivateInformation from './PrivateInformation/PrivateInformation';
import W9Information from './W9Information/W9Information';
import PaymentInformation from './PaymentInformation/PaymentInformation';
import Availability from './Availability/Availability';
import ClinicalFocus from './ClinicalFocus/ClinicalFocus';
import ClinicalInfo from './ClinicalInfo/ClinicalInfo';
import TreatmentApproach from './TreatmentApproach/TreatmentApproach';
import ClientFocus from './ClientFocus/ClientFocus';
import TimeOff from './TimeOff/TimeOff';
import MultipleTimeOffs from './MultipleTimeOffs/MultipleTimeOffs';
import NavBar, { getNavBarHeight } from '../Reusable/NavBar/NavBar';
import './Account.css';
import styled from '../../core/styled';
import ReactFrameService from '../../modules/auth/reactFrame/ReactFrameService';
import useScrollIntoView from '../../hooks/useScrollIntoView';

const timeOffInPlaceOrPlannedStates = [1, 3];

const USE_W9_ADDRESS_ONLY = true;

const AccountWrapper = styled(View)({
  height: 'calc(100vh - 67px)',
  overflowY: 'scroll',
  overflowX: 'hidden',
});

const Header = ({ children, mobileView = false }) => {
  const { isMobile } = useWindowWidth();
  if (mobileView) {
    if (ReactFrameService.instance().isInFrame()) {
      return (
        <ResponsiveLayoutWithHeader
          headerHeight={getNavBarHeight()}
          renderHeader={() => (
            <PanelHeader
              title="My Account"
              isMobile={isMobile}
              onRightPress={() => ReactFrameService.instance().closePopup()}
            />
          )}
        >
          {children}
        </ResponsiveLayoutWithHeader>
      );
    }
    return <div>{children}</div>;
  }
  return (
    <div>
      <NavBar />
      {children}
    </div>
  );
};

const Account = ({ therapistType, initTherapistInfo, isW2 }) => {
  const [isSectionLoaded, setIsSectionLoaded] = useObjectState({
    profile: false,
    availability: false,
    bookings: false,
    clinicalInfo: false,
    multipleTimeOffs: false,
    clinicalFocus: false,
    clientFocus: false,
    privateInformation: false,
    paymentInformation: false,
    w9Information: false,
  });

  const user = getUserData();
  const mobileView = window.location.search === '?hideHeaderFooter';
  const [
    { timeOff, timeOffs, cancelBookings, isLoading, isMultipleTimeOffs },
    {
      dispatchGetTimeOff,
      dispatchGetMultipleTimeOffs,
      dispatchGetBookings,
      dispatchGetMultipleBookings,
      dispatchGetMultipleTimeOffsFeatureFlag,
    },
  ] = useUpcomingBookings();

  const [timeOffPeriods, setTimeOffPeriods] = useState([]);

  const allSectionsLoaded = Object.values(isSectionLoaded).every(Boolean);

  useEffect(() => {
    dispatchGetMultipleTimeOffsFeatureFlag();
  }, [dispatchGetMultipleTimeOffsFeatureFlag]);

  useEffect(() => {
    if (isMultipleTimeOffs) dispatchGetMultipleTimeOffs();
    else if (isMultipleTimeOffs === false) dispatchGetTimeOff();
  }, [dispatchGetTimeOff, dispatchGetMultipleTimeOffs, isMultipleTimeOffs]);

  const timeOffHeaderRef = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scrollTo = searchParams.get('scrollTo');

  useScrollIntoView({
    ref: timeOffHeaderRef,
    trigger: scrollTo === 'timeOff',
    options: { behavior: 'smooth' },
    allSectionsLoaded,
  });

  useEffect(() => {
    if (
      isMultipleTimeOffs === false &&
      timeOff &&
      timeOff.startDate &&
      timeOff.endDate &&
      timeOffInPlaceOrPlannedStates.includes(timeOff.state)
    ) {
      setTimeOffPeriods([{ startDate: timeOff.startDate, endDate: timeOff.endDate }]);
    } else if (isMultipleTimeOffs && timeOffs)
      setTimeOffPeriods(
        timeOffs.map(({ startDate, endDate }) => {
          return { startDate, endDate };
        })
      );
  }, [setTimeOffPeriods, isMultipleTimeOffs, timeOff, timeOffs]);

  useEffect(() => {
    if (mobileView) {
      initTherapistInfo(getUserData().id);
    }
  }, [mobileView, initTherapistInfo]);

  useEffect(() => {
    setIsSectionLoaded({ bookings: !isLoading });
  }, [setIsSectionLoaded, isLoading]);

  return (
    <Header mobileView={mobileView}>
      <AccountWrapper>
        <Grid className="account-grid">
          {!mobileView && <p className="ts-title-green">My Account</p>}
          <Row>
            <Profile setIsSectionLoaded={setIsSectionLoaded} isNPP={!!isW2} />
            <Availability
              user={user}
              timeOffPeriods={timeOffPeriods}
              setIsSectionLoaded={setIsSectionLoaded}
              allSectionsLoaded={allSectionsLoaded}
            />
            {isMultipleTimeOffs === false && (
              <TimeOff
                headerRef={timeOffHeaderRef}
                user={user}
                timeOff={timeOff}
                getTimeOff={dispatchGetTimeOff}
                getBookings={dispatchGetBookings}
                bookings={cancelBookings}
                isLoadingBookings={isLoading}
              />
            )}
            {isMultipleTimeOffs === true && (
              <MultipleTimeOffs
                user={user}
                headerRef={timeOffHeaderRef}
                timeOffs={timeOffs}
                getTimeOffs={dispatchGetMultipleTimeOffs}
                getBookings={dispatchGetMultipleBookings}
                bookings={cancelBookings}
                isLoadingBookings={isLoading}
                setIsSectionLoaded={setIsSectionLoaded}
              />
            )}
            <ClinicalInfo
              setIsSectionLoaded={setIsSectionLoaded}
              allSectionsLoaded={allSectionsLoaded}
              therapistType={therapistType}
            />
            <ClinicalFocus setIsSectionLoaded={setIsSectionLoaded} />
            {therapistType !== 'psychiatrist' && <TreatmentApproach />}
            <ClientFocus setIsSectionLoaded={setIsSectionLoaded} />
            <Security />
            <PrivateInformation setIsSectionLoaded={setIsSectionLoaded} />
            <PaymentInformation
              setIsSectionLoaded={setIsSectionLoaded}
              hideAddressFields={USE_W9_ADDRESS_ONLY}
            />
            <W9Information
              setIsSectionLoaded={setIsSectionLoaded}
              forceAllowAddressEdit={USE_W9_ADDRESS_ONLY}
            />
          </Row>
        </Grid>
        <Footer hidden={mobileView} />
      </AccountWrapper>
    </Header>
  );
};

const mapStateToProps = (state) => {
  return {
    therapistType: state.availability.therapistType,
    isW2: state.availability.isW2,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      initTherapistInfo: getAvailability,
    },
    dispatch
  );

const TherapistAccount = connect(mapStateToProps, mapDispatchToProps)(Account);

export default TherapistAccount;
