import configureAWSAmplify from '@talkspace/auth/configureAWSAmplify';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { initReactI18next, i18nInit, STATIC_PATH as I18N_STATIC_PATH } from '@talkspace/i18n';
import { initTracker } from '@/utils/analytics/eventTracker';
import apiWrapper from '@/core/api/apiWrapper';
import store from './store';
import Routes from './routes';
import './assets/fonts/Roboto/roboto-font.css';

configureAWSAmplify();

initTracker(apiWrapper);
i18nInit(initReactI18next, I18N_STATIC_PATH);

render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root')
);
