import { FunctionComponent, useState } from 'react';
import {
  View,
  Tiny,
  Small,
  Standard,
  Tooltip,
  CircledQuestionMark,
  Huge,
  COLORS,
  BaseButton,
  Large,
  Big,
  TooltipV2,
  Link,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import AccordionListItem from './AccordionListItem';
import { TransformedPaymentReportWithFormattedTextRow } from './types';
import CollapseAllIcon from './Icons/CollapseAllIcon';
import ExpandAllIcon from './Icons/ExpandAllIcon';
import TimeIcon from './Icons/TimeIcon';
import styled from '../../core/styled';
import ssoHelper from '../../modules/utils/sso';

interface PaymentReportsTableProps {
  isMobile: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  isMedium: boolean;
  isLarge: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  isDesktop: boolean;
  currentWindowWidth: number;
  paymentReports: TransformedPaymentReportWithFormattedTextRow[];
  activeRooms?: number;
  handleAccordionClick: (roomID: number) => void;
  therapistEarnings?: string;
  openItems: { [key: number]: boolean };
  grandTotal?: string;
  isPsych?: boolean;
  payoutDate?: Date;
  isSecondHalf?: boolean;
  isExpanded?: boolean;
  handleCollapseAllClick: () => void;
  handleExpandAllClick: () => void;
  isLongTermBonusDisabled: boolean;
  provisionalPeriods?: Date[];
  statementPeriod?: string;
  yearStatement?: string;
  zendeskLink: string;
}

const EmptyReportWrapper = styled(View)<{ isMobile: boolean }>(
  ({ theme: { colors }, isMobile }) => {
    return {
      overflowX: 'scroll',
      paddingBottom: 8,
      paddingLeft: 0,
      marginLeft: isMobile ? 11 : 21,
      marginRight: 21,
      borderBottom: '1px solid #D8D8D8',
    };
  }
);

const PayoutHeaderWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 30,
    paddingBottom: 8,
    borderBottom: '1px solid #D8D8D8',
    marginLeft: 6,
    marginRight: 6,
    width: 'fit-content',
  };
});

const PayoutFooterWrapper = styled(View)<{ isMobile: boolean }>(
  ({ theme: { colors }, isMobile }) => {
    return {
      marginTop: isMobile ? 18 : 43,
      marginLeft: 'auto',
      marginRight: 6,
      display: 'flex',
      justifyContent: 'flex-end',
    };
  }
);

const getStyles = (width: number) => {
  switch (true) {
    case width <= 750:
      return 'isMobile';
    case width > 750 && width <= 1024:
      return 'isLarge';
    case width > 1024:
      return 'isDesktop';
    default:
      return 'isDesktop';
  }
};

const getClientColumnStyles = (width: number) => {
  switch (getStyles(width)) {
    case 'isMobile':
      return { width: 150, paddingLeft: 10 };
    case 'isLarge':
      return { width: 200, paddingLeft: 10 };
    case 'isDesktop':
      return { width: 250, paddingLeft: 10 };
    default:
      return undefined as never;
  }
};

const getClientWordcountColumnStyles = (width: number) => {
  switch (getStyles(width)) {
    case 'isMobile':
      return { width: 108, paddingRight: 12 };
    case 'isLarge':
      return { width: 107, paddingRight: 12 };
    case 'isDesktop':
      return { width: 159 };
    default:
      return undefined as never;
  }
};
const getProviderWordcountColumnStyles = (width: number) => {
  switch (getStyles(width)) {
    case 'isMobile':
      return { width: 102, paddingRight: 12 };
    case 'isLarge':
      return { width: 123, paddingRight: 12 };
    case 'isDesktop':
      return { width: 182 };
    default:
      return undefined as never;
  }
};
const getValueBasedBonusColumnStyles = (width: number) => {
  switch (getStyles(width)) {
    case 'isMobile':
      return { width: 108, paddingRight: 12 };
    case 'isLarge':
      return { width: 113, paddingRight: 12 };
    case 'isDesktop':
      return { width: 201 };
    default:
      return undefined as never;
  }
};
const getAmountEarnedColumnStyles = (width) => {
  switch (getStyles(width)) {
    case 'isMobile':
      return { width: 106, paddingRight: 12 };
    case 'isLarge':
      return { width: 130 };
    case 'isDesktop':
      return { width: 150 };
    default:
      return undefined as never;
  }
};

const PaymentReportsTable: FunctionComponent<PaymentReportsTableProps> = ({
  isMobile,
  isLarge,
  currentWindowWidth,
  paymentReports,
  therapistEarnings,
  activeRooms,
  handleAccordionClick,
  openItems,
  grandTotal,
  isPsych,
  payoutDate,
  isSecondHalf,
  isExpanded,
  handleCollapseAllClick,
  handleExpandAllClick,
  isLongTermBonusDisabled,
  provisionalPeriods,
  statementPeriod,
  yearStatement,
  zendeskLink,
}) => {
  const [isPayPeriodTooltipVisible, setIsPayPeriodTooltipVisible] = useState<boolean>(false);

  const toolTipText = (
    <View style={{ fontFamily: 'roboto' }}>
      <View style={{ fontWeight: 700, fontSize: 15, lineHeight: '30px' }}>What is this total?</View>
      <Standard style={{ display: 'inline-block', fontWeight: 400, fontSize: 14 }}>
        This total reflects your accumulated earnings for this pay period so far, not including
        bonus pay. This total updates daily. Payment occurs on a bi-weekly basis.{' '}
        <Link
          onClick={() => ssoHelper.openZendesk(zendeskLink)}
          style={{
            fontWeight: 700,
            fontSize: 14,
            color: '#005C65',
            textDecoration: 'underline',
            marginLeft: 0,
          }}
        >
          Learn more
        </Link>
      </Standard>
    </View>
  );

  const headerTextStyles = { fontWeight: 'bold', color: '#222F2D', marginRight: 3 };
  const clientColumnStyles = getClientColumnStyles(currentWindowWidth);
  const clientWordcountColumnStyles = getClientWordcountColumnStyles(currentWindowWidth);
  const providerWordcountColumnStyles = getProviderWordcountColumnStyles(currentWindowWidth);
  const valueBasedBonusColumnStyles = getValueBasedBonusColumnStyles(currentWindowWidth);
  const amountEarnedColumnStyles = getAmountEarnedColumnStyles(currentWindowWidth);
  const { width: clientColumnWidth } = clientColumnStyles;
  const { width: providerWordcountColumnWidth } = providerWordcountColumnStyles;
  const { width: valueBasedBonusColumnWidth } = valueBasedBonusColumnStyles;
  const { width: clientWordcountColumnWidth } = clientWordcountColumnStyles;
  let amountEarnedPadding = isMobile ? 40 : 54;
  if (isLarge) amountEarnedPadding = 33;
  const TextComponent = isMobile ? Tiny : Small;
  const isMonthEmpty = !!(paymentReports?.length === 0);

  let isProvisional = false;
  if (provisionalPeriods) {
    const payoutPeriod = isSecondHalf
      ? moment.utc(payoutDate).date(16)
      : moment.utc(payoutDate).startOf('month');

    provisionalPeriods.forEach((p) => {
      if (moment.utc(p).isSame(payoutPeriod)) {
        isProvisional = true;
      }
    });
  }
  const now = moment.utc().startOf('month');
  const samePeriod = moment.utc(payoutDate).startOf('month').isSame(now);

  if (isMonthEmpty) {
    const message = (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <TimeIcon style={{ marginTop: 3, marginRight: 5 }} />
        {samePeriod
          ? 'Your earnings for this period will appear soon'
          : 'No earnings for this period'}
      </View>
    );
    return (
      <>
        <EmptyReportWrapper isMobile={isMobile}>
          <View row={!isMobile} justify="space-between">
            {isMobile ? (
              <Small style={{ color: '#546074', fontWeight: 700, marginLeft: 20 }}>
                {statementPeriod}, {yearStatement}
              </Small>
            ) : (
              <Big style={{ color: '#546074', marginLeft: 20 }}>
                {statementPeriod}, {yearStatement}
              </Big>
            )}
          </View>
        </EmptyReportWrapper>
        <View row style={{ marginTop: 20, alignItems: 'center' }}>
          {isMobile ? (
            <Small style={{ color: '#546074', fontWeight: 700, marginLeft: 10 }}>{message}</Small>
          ) : (
            <Standard style={{ color: '#546074', marginLeft: 40 }}>{message}</Standard>
          )}
        </View>
      </>
    );
  }
  return (
    <>
      <View
        style={{
          overflowX: 'scroll',
        }}
      >
        <View row justify="space-between">
          {isMobile ? (
            <Small style={{ color: '#546074', fontWeight: 700, marginLeft: 20 }}>
              {statementPeriod}, {yearStatement}
            </Small>
          ) : (
            <Big style={{ color: '#546074', marginLeft: 20 }}>
              {statementPeriod}, {yearStatement}
            </Big>
          )}
          <BaseButton
            onClick={isExpanded ? handleCollapseAllClick : handleExpandAllClick}
            style={{ marginRight: 6, marginLeft: isMobile ? 20 : 0 }}
          >
            <View row>
              {isExpanded ? <CollapseAllIcon align="center" /> : <ExpandAllIcon align="center" />}
              <Large variant="largeBoldWideGreen" style={{ marginLeft: 6, marginTop: 2 }}>
                {isExpanded ? 'Collapse all' : 'Expand all'}
              </Large>
            </View>
          </BaseButton>
        </View>
        <PayoutHeaderWrapper row>
          <View row align="center" style={{ ...clientColumnStyles }}>
            <TextComponent style={headerTextStyles}>Client</TextComponent>
          </View>
          <View row align="center" style={clientWordcountColumnStyles}>
            {!isPsych && (
              <TextComponent inline style={headerTextStyles}>
                {'Client word count '}
                <Tooltip
                  buttonStyle={{ height: 16, verticalAlign: 'middle' }}
                  tip="Word count is displayed for engagement plans only. Non-engagement based plans do not include word count."
                >
                  <CircledQuestionMark color="#8D9DB7" width={15} height={15} />
                </Tooltip>
              </TextComponent>
            )}
          </View>
          <View row align="center" style={{ ...providerWordcountColumnStyles }}>
            {!isPsych && (
              <TextComponent inline style={headerTextStyles}>
                {'Provider word count '}
                <Tooltip
                  buttonStyle={{ height: 16, verticalAlign: 'middle' }}
                  tip="Word count is displayed for engagement plans only. Non-engagement based plans do not include word count."
                >
                  <CircledQuestionMark color="#8D9DB7" width={15} height={15} />
                </Tooltip>
              </TextComponent>
            )}
          </View>
          {!isPsych && !isLongTermBonusDisabled && (
            <View row align="center" style={{ ...valueBasedBonusColumnStyles }}>
              <TextComponent inline style={headerTextStyles}>
                {'Value based bonus '}
                <Tooltip
                  buttonStyle={{ height: 16, verticalAlign: 'middle' }}
                  tip="Value based bonus rewards you for long term active clients, defined as B2C active clients with engagement plans that remain with you for at least 60 days or B2B attestation clients (BH/EAP) for whom you have completed at least a third session attestation for the specific billing month."
                >
                  <CircledQuestionMark color="#8D9DB7" width={15} height={15} />
                </Tooltip>
              </TextComponent>
            </View>
          )}
          <View row align="center" style={{ ...amountEarnedColumnStyles }}>
            <TextComponent inline style={headerTextStyles}>
              {'Amount earned '}
              <Tooltip
                buttonStyle={{ height: 16, verticalAlign: 'middle' }}
                tip="Amount shown is the cumulative payment for each client. There are no payouts for B2B clients that did not have a session report submitted by the last day of the month by 11:59:59pm UTC."
              >
                <CircledQuestionMark color="#8D9DB7" width={15} height={15} />
              </Tooltip>
            </TextComponent>
          </View>
        </PayoutHeaderWrapper>
        {paymentReports &&
          paymentReports.map((row, index) => (
            <AccordionListItem
              isLongTermBonusDisabled={isLongTermBonusDisabled}
              roomID={row.roomID}
              userNickname={row.userNickname}
              longTerm={row.longTerm}
              netTherapistEarning={row.netTherapistEarning}
              clientWordsCapped={row.clientWordsCapped}
              therapistWordsCapped={row.therapistWordsCapped}
              onClick={() => handleAccordionClick(row.roomID)}
              isExpanded={!!openItems[row.roomID]}
              detailRow={row.rows}
              // TODO: @cnharrison Is this is necessary?
              // eslint-disable-next-line react/no-array-index-key
              key={`${row.roomID}-${index}`}
              clientWordcountColumnStyles={clientWordcountColumnStyles}
              providerWordcountColumnStyles={providerWordcountColumnStyles}
              valueBasedBonusColumnStyles={valueBasedBonusColumnStyles}
              amountEarnedColumnStyles={amountEarnedColumnStyles}
              clientColumnStyles={clientColumnStyles}
              amountEarnedPadding={amountEarnedPadding}
              isMobile={isMobile}
              isLarge={isLarge}
              isPsych={isPsych}
            />
          ))}
        {activeRooms ? (
          <View
            row
            style={{
              paddingBottom: 22,
              paddingTop: 19,
              borderBottom: '1px solid #D8D8D8',
              marginLeft: 6,
              marginRight: 6,
            }}
          >
            <View
              style={{
                ...clientColumnStyles,
                width:
                  clientWordcountColumnWidth + clientColumnWidth + providerWordcountColumnWidth,
                justifyContent: 'flex-end',
              }}
            >
              <Standard>Value added bonus</Standard>
            </View>
            <View
              style={{
                width: valueBasedBonusColumnWidth,
                color: COLORS.black,
              }}
              align="start"
            >
              <TextComponent
                style={{
                  marginTop: 10,
                  paddingLeft: 5,
                  fontWeight: 'bold',
                  color: COLORS.black,
                }}
              >{`${activeRooms} clients`}</TextComponent>
            </View>
            <View style={{ width: amountEarnedColumnStyles.width }}>
              <TextComponent
                style={{
                  marginTop: 10,
                  paddingRight: amountEarnedPadding,
                  fontWeight: 'bold',
                  color: COLORS.black,
                }}
              >
                {therapistEarnings}
              </TextComponent>
            </View>
          </View>
        ) : null}
      </View>
      <PayoutFooterWrapper align="end" row={!isMobile} isMobile={isMobile}>
        <Standard
          style={{
            marginRight: isMobile ? 0 : 16,
            fontWeight: 'bold',
            marginBottom: 3,
            color: '#546074',
          }}
        >
          {isProvisional ? (
            <View row align="center">
              {statementPeriod} | Pay period total to date
              <TooltipV2
                toolTipText={toolTipText}
                isTooltipOpen={isPayPeriodTooltipVisible}
                containerStyle={{
                  right: isMobile ? -40 : -100,
                  width: isMobile ? '85vw' : 360,
                  top: 21,
                }}
                handleCloseButton={() => {
                  setIsPayPeriodTooltipVisible(false);
                }}
                circleStyle={{ width: 17, height: 17, marginTop: 2, marginLeft: 3 }}
                circleColor="#5F7A9B"
                hoverColor="#005C65"
              />
            </View>
          ) : (
            <View>{statementPeriod} | Pay period total</View>
          )}
        </Standard>
        <Huge style={{ fontWeight: 'bold' }}>{`${grandTotal}`}</Huge>
      </PayoutFooterWrapper>
    </>
  );
};

export default PaymentReportsTable;
