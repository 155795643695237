import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { PanelManager } from '@talkspace/react-toolkit';
import { routePromise } from 'ts-frontend/helpers/routePromise';
import { isTherapistCT } from 'ts-frontend/entities/Therapist';
import Footer from '../Footer/Footer';
import apiWrapper from '../../utils/apiWrapper';
import { getUserData, removeTokenFromLocalStore } from '../../utils/token';
import { tokenIsValid } from '../../utils/tokenIsValid';
import apiHelper from '../../utils/api';
import SocketService from '../../utils/socket/SocketService';
import AllClients from './Clients/CT/AllClients';
import NewClients from './Clients/CT/NewClients';
import NavBar from '../Reusable/NavBar/NavBar';
import './Dashboard.css';
import storage from '../../core/storage';

const DEFAULT_START_DATE = '2012-01-01'; // Year Talkspace started

class TherapistDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counters: {},
      newRoomsData: [],
      roomUrl: '',
      allRoomsData: [],
      roomJourneyFeatureActive: false,
      slaFeatureActive: false,
      currentView: 'all',
      limit: 1000,
      showMissingInfoModal: false,
      modalValues: [],
      offset: 0,
      filter_name: '',
      filter_days: 7,
      filterFromDate: moment().subtract(7, 'days'),
      filterToDate: moment(),
      user: {},
      token: storage.getItem('id_token'),
      currentResponsiveTab: 'new-clients',
      sort: {
        columnName: this.props.therapistType === 'primary' ? 'time_to_reply' : 'customer_waiting',
        ascending: true,
      },
      isLoading: true,

      // live sessions
      numberOfBookings: undefined,
      shouldCloseDropDown: false,
    };

    this.getRoomsData = this.getRoomsData.bind(this);
    this.loadRoom = this.loadRoom.bind(this);
    this.handleNewMessage = this.handleNewMessage.bind(this);
    this.handleRoomChange = this.handleRoomChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleToggleTab = this.handleToggleTab.bind(this);
    this.checkRoomPath = this.checkRoomPath.bind(this);
    this.visitMyAccount = this.visitMyAccount.bind(this);
    this.handleAllClientsSort = this.handleAllClientsSort.bind(this);
    this.triggerInterval = this.triggerInterval.bind(this);
    this.checkAuthBeforeRedirect = this.checkAuthBeforeRedirect.bind(this);
    this.updateFilterFromDate = this.updateFilterFromDate.bind(this);
    this.updateFilterToDate = this.updateFilterToDate.bind(this);
    this.getInitialData = this.getInitialData.bind(this);
    this.getQueryString = this.getQueryString.bind(this);
    this.closeDropDown = this.closeDropDown.bind(this);
  }

  componentDidMount() {
    this.setState({ intervalID: this.triggerInterval() });
    SocketService.instance().on('newMessageHeader', this.handleNewMessage);
    SocketService.instance().on('room_change', this.handleRoomChange);
  }

  componentWillUnmount() {
    SocketService.instance().off('newMessageHeader', this.handleNewMessage);
    SocketService.instance().off('room_change', this.handleRoomChange);
    clearInterval(this.state.intervalID);
  }

  getInitialData() {
    this.setState({ isLoading: true });
    return apiWrapper
      .get(`${apiHelper().apiEndpoint}/v2/rooms?${this.getQueryString()}`)
      .then((response) => {
        storage.setItem('roomUrl', response.data.roomUrl);
        this.setState({
          isLoading: false,
          allRoomsData: response.data.data.reverse(),
          newRoomsData: response.data.newRoomsData,
          roomUrl: response.data.roomUrl,
          roomJourneyFeatureActive: response.data.roomJourneyFeatureActive,
          slaFeatureActive: response.data.slaFeatureActive,
          counters: response.data.counters[0],
        });
      })
      .catch((err) => {});
  }

  getQueryString(roomView = this.state.currentView, excludeView = false) {
    const viewString = excludeView ? '' : `&view=${roomView}`;
    const limitString = `&limit=${this.state.limit}`;
    const offsetString = `&offset=${this.state.offset}`;
    const filterNameString = `&filter_name=${this.state.filter_name}`;
    const filterDaysString = `&filter_days=${this.state.filter_days}`;
    const filterDatesString = `&filter_from_date=${new Date(
      this.state.filterFromDate || DEFAULT_START_DATE
    )}&filter_to_date=${moment(this.state.filterToDate || undefined).toDate()}`;
    return `detail_level=dashboard${viewString}${limitString}${offsetString}${filterNameString}${filterDaysString}${filterDatesString}`;
  }

  getRoomsData(roomView = this.state.currentView, cb = null) {
    return apiWrapper
      .get(`${apiHelper().apiEndpoint}/v2/rooms?${this.getQueryString(roomView)}`)
      .then((response) => {
        this.setState(
          {
            counters: response.data.counters[0],
            currentView: roomView,
            allRoomsData: response.data.data.reverse(),
          },
          () => {
            this.handleAllClientsSort(roomView);
            if (roomView === 'follow_up' && cb) cb();
          }
        );
      })
      .catch((err) => {});
  }

  UNSAFE_componentWillMount() {
    if (this.props.therapistType) this.getInitialData();
    apiWrapper
      .get(
        `${apiHelper().apiEndpoint}/api/v1/therapist/${getUserData().id}/accountInformationStatus`
      )
      .then((response) => {
        if (response.data.missingInformation) {
          this.setState({
            showMissingInfoModal: true,
            modalValues: response.data.fields,
          });
        }
      })
      .catch((err) => {});
  }

  UNSAFE_componentWillUpdate({ therapistType }) {
    if (this.props.therapistType !== therapistType)
      this.setState({ currentView: 'all' }, this.getInitialData);
  }

  checkAuthBeforeRedirect(event) {
    if (!tokenIsValid()) {
      event.preventDefault();
      removeTokenFromLocalStore();
      routePromise('/login');
    }
  }

  checkRoomPath(room, linkText, className = 'dashboard-link') {
    return (
      <Link
        to={{
          pathname: `/room/${room.private_talk_id}`,
        }}
        onMouseEnter={(event) => this.checkAuthBeforeRedirect(event)}
        className={className}
      >
        {linkText}
      </Link>
    );
  }

  handleAllClientsSort(tabName) {
    const tabSortMapping = {
      paying: ['customer_waiting', 'last_activity'],
      all: ['customer_waiting', 'last_activity'],
      consultation: ['customer_waiting', 'last_activity'],
    };
    if (tabSortMapping[tabName] === undefined) return;
    const { allRoomsData } = this.state;

    allRoomsData.sort((roomA, roomB) => {
      const sortColumns = tabSortMapping[tabName];

      return sortColumns.reduce((compareValue, columnName) => {
        if (compareValue !== 0) return compareValue;
        let firstValue = roomA[columnName] || '';
        let secondValue = roomB[columnName] || '';
        if (typeof firstValue === 'number') firstValue = firstValue.toString();
        if (typeof secondValue === 'number') secondValue = secondValue.toString();
        if (columnName === 'time_to_reply' && firstValue.length !== 0 && secondValue.length !== 0) {
          return moment(firstValue).isAfter(moment(secondValue)) ? -1 : 1;
        }
        if (firstValue.localeCompare(secondValue) > 0) return 1;
        if (firstValue.localeCompare(secondValue) < 0) return -1;
        return 0;
      }, 0);
    });

    this.setState({
      allRoomsData,
      sort: { columnName: tabSortMapping[tabName][0], ascending: true },
    });
  }

  handleNewMessage(data) {
    this.loadRoom(data.roomId);
  }

  handleRoomChange(data) {
    this.loadRoom(data.message.private_talk_id);
  }

  handleSort(event) {
    const { columnName } = event.target.dataset;
    const { allRoomsData } = this.state;
    const ascending =
      this.state.sort.columnName === columnName ? !this.state.sort.ascending : false;

    allRoomsData.sort((a, b) => {
      const firstValue = (a[columnName] || '').toString();
      const secondValue = (b[columnName] || '').toString();

      if (ascending) {
        return firstValue.localeCompare(secondValue);
      }
      return secondValue.localeCompare(firstValue);
    });

    this.setState({ allRoomsData, sort: { columnName, ascending } });
  }

  handleToggleTab(event) {
    this.setState({ currentResponsiveTab: event.currentTarget.dataset.view });
  }

  loadRoom(id) {
    const endpoint = `${
      apiHelper().apiEndpoint
    }/v2/rooms/${id.toString()}?${this.getQueryString()}`;

    return apiWrapper.get(endpoint).then((response) => {
      const updated = response.data.data.length > 0 ? response.data.data[0] : null;
      // filter out room if it is already in our data
      const updatedAllRoomsData = this.state.allRoomsData.filter(
        (room) => parseInt(room.private_talk_id, 10) !== parseInt(id, 10)
      );
      // update new rooms from response
      const updatedNewRoomsData = response.data.newRoomsData;

      if (updated) {
        updatedAllRoomsData.push(updated);
      }
      const counters =
        response.data.counters && response.data.counters.length
          ? { counters: response.data.counters[0] }
          : {};

      this.setState(
        { allRoomsData: updatedAllRoomsData, newRoomsData: updatedNewRoomsData, ...counters },
        () => {
          this.handleAllClientsSort(this.state.currentView);
        }
      );
    });
  }

  triggerInterval() {
    const fiveMinuteInMilliseconds = 1000 * 60 * 5;
    const intervalID = setInterval(() => {
      this.getRoomsData(this.state.currentView);
    }, fiveMinuteInMilliseconds);

    return intervalID;
  }

  updateFilterFromDate(filterFromDate) {
    this.setState({ filterFromDate }, this.getRoomsData);
  }

  updateFilterToDate(filterToDate) {
    this.setState({ filterToDate }, this.getRoomsData);
  }

  updateNumberOfBookings = (numberOfBookings) => this.setState({ numberOfBookings });

  visitMyAccount() {
    this.props.history.push('/my-account');
  }

  updateResponsiveTab(type) {
    this.setState({ currentResponsiveTab: type });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.therapistType !== this.props.therapistType) {
      const isCT = isTherapistCT(this.props.therapistType);
      if (isCT) {
        this.updateResponsiveTab('new-clients');
      }
    }
  }

  closeDropDown(e) {
    if (document.querySelector('.dropdown.open') && !e.target.closest('.dropdown.open')) {
      this.setState({ shouldCloseDropDown: true });
    } else {
      this.setState({ shouldCloseDropDown: false });
    }
  }

  render() {
    const close = () => this.setState({ showMissingInfoModal: false });
    const { therapistType } = this.props;

    return (
      <div onClick={this.closeDropDown}>
        <PanelManager>
          {!this.state.isLoading && (
            <>
              <Modal
                show={this.state.showMissingInfoModal}
                onHide={close}
                container={this}
                aria-labelledby="contained-modal-title"
              >
                <div className="modal-content-wrapper" style={{ color: '#E8006B' }}>
                  <span className="fa-stack fa-3x exclamation">
                    <i className="fa fa-circle-thin fa-stack-2x" />
                    <i className="fa fa-exclamation fa-stack-1x" />
                  </span>
                </div>
                <h2 className="center">Missing Information</h2>
                <p className="center paddedlr-30">
                  You will not receive referrals until all of your Account information is complete.
                  The following information is missing:
                </p>
                <div>
                  <ul className="list-unstyled" style={{ width: '300px', margin: '0 auto' }}>
                    {this.state.modalValues
                      .filter(
                        (value) =>
                          !(
                            value === 'Access Initiative Participation' &&
                            therapistType !== 'primary'
                          )
                      )
                      .map((key, index) => (
                        <li
                          key={index}
                          className="text-center"
                          style={{ fontSize: '16px', color: '#E8006B' }}
                        >
                          <strong>- {key} </strong>
                        </li>
                      ))}
                  </ul>
                </div>
                <div
                  onClick={this.visitMyAccount}
                  className="modal-content-wrapper modal-btn-wrapper"
                >
                  <button type="button" className="btn btn-lg btn-talkspace-primary">
                    Go to my account
                  </button>
                </div>
              </Modal>
            </>
          )}

          <div>
            <NavBar />
            <div className="client-wrapper">
              <div className="client-wrapper-padding">
                <div className="col-xs-12 col-lg-10 col-lg-push-1 hidden-md hidden-lg table-tab-container">
                  <div
                    onClick={this.handleToggleTab}
                    className={
                      this.state.currentResponsiveTab === 'new-clients'
                        ? 'table-tab tab-selected'
                        : 'table-tab'
                    }
                    data-view="new-clients"
                  >
                    Send first message{' '}
                    <span className="number-indication">{this.state.newRoomsData.length}</span>
                  </div>
                  <div
                    onClick={this.handleToggleTab}
                    className={
                      this.state.currentResponsiveTab === 'all-clients'
                        ? 'table-tab tab-selected'
                        : 'table-tab'
                    }
                    data-view="all-clients"
                  >
                    Your caseload{' '}
                    <span className="number-indication">{this.state.counters.all_counter}</span>
                  </div>
                </div>
                <div style={{ padding: 0 }} className="col-xs-12 col-lg-10 col-lg-push-1">
                  <NewClients
                    isLoading={this.state.isLoading}
                    newRoomsData={this.state.newRoomsData}
                    roomJourneyFeatureActive={this.state.roomJourneyFeatureActive}
                    isHidden={this.state.currentResponsiveTab !== 'new-clients'}
                    checkRoomPath={this.checkRoomPath}
                  />
                  <AllClients
                    isLoading={this.state.isLoading}
                    slaFeatureActive={this.state.slaFeatureActive}
                    allRoomsData={this.state.allRoomsData}
                    counters={this.state.counters}
                    currentView={this.state.currentView}
                    roomUrl={this.state.roomUrl}
                    roomJourneyFeatureActive={this.state.roomJourneyFeatureActive}
                    getRoomsData={this.getRoomsData}
                    view={this.state.currentView}
                    isHidden={this.state.currentResponsiveTab !== 'all-clients'}
                    handleSort={this.handleSort}
                    sort={this.state.sort}
                    checkRoomPath={this.checkRoomPath}
                    filterFromDate={this.state.filterFromDate}
                    filterToDate={this.state.filterToDate}
                    updateFilterFromDate={this.updateFilterFromDate}
                    updateFilterToDate={this.updateFilterToDate}
                    therapistType={this.props.therapistType}
                    shouldCloseDropDown={this.state.shouldCloseDropDown}
                  />
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </PanelManager>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    therapistType: state.availability.therapistType,
  };
};

const mapDispatchToProps = { getUserData };
const Dashboard = connect(mapStateToProps, mapDispatchToProps)(TherapistDashboard);

export default withRouter(Dashboard);
