import * as actionTypes from '../constants/PaymentReportsConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

export function requestPaymentReport(therapistID) {
  return {
    type: actionTypes.REQUEST_PAYMENT_REPORT,
    therapistID,
  };
}

export function receivePaymentReport(PaymentReports) {
  return {
    type: actionTypes.RECEIVE_PAYMENT_REPORT,
    PaymentReports: PaymentReports.data,
  };
}

export function requestPaymentReportError(error) {
  return {
    type: actionTypes.REQUEST_PAYMENT_REPORT_ERROR,
    error,
  };
}

export const getPaymentReports = (therapistID, billingStart) => (dispatch) => {
  dispatch(requestPaymentReport(therapistID));
  apiWrapper
    .get(
      `${
        apiHelper().apiEndpoint
      }/v3/therapists/${therapistID}/payment-report?billingStart=${billingStart}`
    )
    .then((response) => {
      dispatch(receivePaymentReport(response.data));
    })
    .catch((error) => {
      dispatch(requestPaymentReportError(error));
    });
};

export function requestPaymentReportDates(therapistID) {
  return {
    type: actionTypes.REQUEST_PAYMENT_REPORT_DATES,
    therapistID,
  };
}

export function receivePaymentReportDates(PaymentReportDates) {
  return {
    type: actionTypes.RECEIVE_PAYMENT_REPORT_DATES,
    PaymentReportDates: PaymentReportDates.data,
  };
}

export function requestPaymentReportDatesError(error) {
  return {
    type: actionTypes.REQUEST_PAYMENT_REPORT_DATES_ERROR,
    error,
  };
}

export const getPaymentReportDates = (therapistID) => (dispatch) => {
  dispatch(requestPaymentReportDates(therapistID));
  apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/therapists/${therapistID}/payment-report-dates`)
    .then((response) => {
      dispatch(receivePaymentReportDates(response.data));
    })
    .catch((error) => {
      dispatch(requestPaymentReportDatesError(error));
    });
};
