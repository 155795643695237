import { memo } from 'react';
import { View, useEmotionTheme } from '@talkspace/react-toolkit';
import '../../Dashboard.css';
import styled from '../../../../core/styled';

import DashboardTablePlaceholder from '../../Placeholders/DashboardTablePlaceholder';
import IconNewClient from '../../Placeholders/IconNewClient';
import { dashboardGetHeaderProps } from '../../DashboardTable';

import { NewRoomsRecord } from '../../types';
import ClientTable from './ClientTable';

const NewClientsTitleWrapper = styled(View)({
  marginBottom: 0,
  paddingTop: 20,
  paddingRight: 15,
  paddingBottom: 10,
  minHeight: 48,
});

const getTitle = (newRoomCount) => {
  let clientCopy = '';
  if (newRoomCount === 0) {
    clientCopy = '(no new clients)';
  }
  if (newRoomCount === 1) {
    clientCopy = '(1 new client)';
  }
  if (newRoomCount > 1) {
    clientCopy = `(${newRoomCount} new clients)`;
  }
  return `Send first message ${clientCopy}`;
};

interface NewClientsProps {
  newRoomsData: NewRoomsRecord[];
  handleOpenAvailabilityBar: (value?: boolean) => void;
  isHidden: boolean;
  isLoading: boolean;
}

const NewClientsV4 = ({
  isHidden,
  newRoomsData,
  isLoading,
  handleOpenAvailabilityBar,
}: NewClientsProps) => {
  const { colors } = useEmotionTheme();

  if (isHidden) {
    return null;
  }

  return (
    <View>
      <NewClientsTitleWrapper className="unstackable">
        <h4>{getTitle(newRoomsData.length)}</h4>
      </NewClientsTitleWrapper>
      <div className="unstackable ibox-content">
        <div className="unstackable grid" role="grid">
          <ClientTable
            tableType="new-client"
            data={newRoomsData}
            tableStyle={{ marginTop: 0 }}
            getHeaderProps={dashboardGetHeaderProps(colors)}
            initialState={{ sortBy: [{ id: 'clientWaiting', desc: false }] }}
            Empty={
              <DashboardTablePlaceholder
                title="You have no new clients!"
                subtitle="This is where you’ll greet your new clients. To receive clients, "
                icon={<IconNewClient />}
                linkText="update your new client availability."
                handleOnPress={handleOpenAvailabilityBar}
              />
            }
            isLoading={isLoading}
          />
        </div>
      </div>
    </View>
  );
};

export default memo(NewClientsV4);
