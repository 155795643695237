import { useEffect, useState, VoidFunctionComponent } from 'react';
import {
  BaseButton,
  Button,
  Checkbox,
  CheckV2,
  ExtraHuge,
  Large,
  Standard,
  Tiny,
  useEmotionTheme,
  VerificationCodeInput,
  View,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import appConfig from '../../utils/configs';
import { Spinner } from '../Login/Login';

interface TwoFactorAuthTextProps {
  userPhone: string;
  verifyError: boolean;
  redirectToLogin: boolean;
}

interface TwoFactorProps {
  handleOtpKeyInput: (otpKey: string) => void;
  handleVerifyLogin: () => void;
  handleResendOTPKey: () => void;
  handleBypassTwoFactor: () => void;
  redirectToLogin: boolean;
  rememberBrowser: boolean;
  userPhone: string;
  verifyError: boolean;
  isDisabled: boolean;
  clearVerifyError: () => void;
  isResendLoading: boolean;
}

const TwoFactorViewWrapper = styled(View)({
  maxWidth: 335,
  alignSelf: 'center',
  marginTop: 30,
  textAlign: 'center',
});

const ErrorText = styled(Standard)(({ theme: { colors } }) => {
  return {
    marginTop: 10,
    color: colors.permaFuchsia,
    fontWeight: 400,
    fontSize: 16,
  };
});

const TwoFactorAuthText: VoidFunctionComponent<TwoFactorAuthTextProps> = ({
  userPhone,
  verifyError,
  redirectToLogin,
}) => {
  if (redirectToLogin) {
    return (
      <ErrorText style={{ marginTop: 10 }}>
        You have reached the maximum number of entry attempts. Please log in and try again.
      </ErrorText>
    );
  }

  if (verifyError) {
    return (
      <ErrorText>
        Incorrect verification code.
        <br />
        Please try again.
      </ErrorText>
    );
  }

  return (
    <Large variant="largeDarkGrey" style={{ marginTop: 10 }}>
      Enter the 6-digit verification code sent to your phone number ending in{' '}
      <strong>{userPhone}</strong>.
    </Large>
  );
};

const TwoFactorView: VoidFunctionComponent<TwoFactorProps> = ({
  handleOtpKeyInput,
  handleVerifyLogin,
  handleResendOTPKey,
  handleBypassTwoFactor,
  rememberBrowser,
  userPhone,
  verifyError,
  redirectToLogin,
  isDisabled,
  clearVerifyError,
  isResendLoading,
}) => {
  const { colors } = useEmotionTheme();
  const [resendCodeSuccess, setResendCodeSuccess] = useState<boolean>(false);
  const [resendCodePressed, setResendCodePressed] = useState<boolean>(false);

  useEffect(() => {
    if (resendCodePressed && !isResendLoading) {
      setResendCodeSuccess(true);
    }
  }, [isResendLoading, resendCodePressed]);

  useEffect(() => {
    if (resendCodeSuccess) {
      setTimeout(() => {
        setResendCodeSuccess(false);
      }, 3000);
    }
  }, [resendCodeSuccess]);

  const handleOnChange = (otpKey: string) => {
    handleOtpKeyInput(otpKey);
  };

  const renderResendButton = () => {
    if (resendCodeSuccess) {
      return (
        <View row align="center">
          <CheckV2 color={colors.softGray} style={{ marginRight: 6 }} />{' '}
          <Standard style={{ color: colors.softGray }}>Code sent</Standard>
        </View>
      );
    }
    if (isResendLoading) {
      return <Spinner color={colors.softGray} />;
    }
    return 'Resend code';
  };
  return (
    <View>
      <TwoFactorViewWrapper justify="center">
        <ExtraHuge>Two-factor authentication</ExtraHuge>
        <TwoFactorAuthText
          userPhone={userPhone}
          verifyError={verifyError}
          redirectToLogin={redirectToLogin}
        />
        <VerificationCodeInput
          clearVerificationCode={verifyError}
          clearVerificationCodeError={clearVerifyError}
          isError={verifyError}
          style={{ marginTop: 40 }}
          onChange={handleOnChange}
        />
        <Button
          type="submit"
          dataQa="therapistLogin2faNextButton"
          disabled={isDisabled}
          text="Next"
          onPress={handleVerifyLogin}
          style={{
            marginTop: 40,
            width: '100%',
            height: 55,
            fontSize: 19,
            fontWeight: 700,
            background: isDisabled && colors.paleBluGray,
          }}
          roundedFocusStyle
          primaryColor={colors.accessibilityGreenDark}
        />
        {!appConfig.featureFlags.cognitoLogin && (
          <Checkbox
            primaryColor={colors.accessibilityGreenDark}
            label={<Tiny>Don't ask for codes on this browser for 2 weeks</Tiny>}
            isChecked={rememberBrowser}
            setIsChecked={handleBypassTwoFactor}
            containerStyle={{ marginTop: 24, alignItems: 'start' }}
            isLabelOnRight
            roundedFocusStyle
            checkComponent={<CheckV2 />}
          />
        )}
        <BaseButton
          data-qa="therapistLogin2faResendCodeButton"
          onPress={() => {
            setResendCodePressed(true);
            handleResendOTPKey();
          }}
          style={{ marginTop: 20, borderRadius: 5 }}
          primaryColor={colors.accessibilityGreenDark}
          disabled={resendCodeSuccess}
          roundedFocusStyle
        >
          <Large variant="largeDarkGrey">Didn’t receive a verification code?</Large>

          <Standard
            style={{ padding: 6, display: 'flex', justifyContent: 'center' }}
            variant="standardBoldAccessibilityGreen"
          >
            {renderResendButton()}
          </Standard>
        </BaseButton>
      </TwoFactorViewWrapper>
    </View>
  );
};

export default TwoFactorView;
