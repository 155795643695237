import { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { TouchableView, View, Text } from '@talkspace/react-toolkit';
import {
  getDiagnosticProfiles,
  setCurrentDiagnosticProfileClient,
} from '../../../../../actions/DiagnosticProfileActions';
import { DiagnosticProfileState } from '../../../../../constants/DiagnosticProfileConstants';
import AccordionContainer from '../../../../Reusable/AccordionContainer/AccordionContainer';
import DiagnosticProfileIcon from './DiagnosticProfileIcon';

const mapStateToProps = (state) => {
  return {
    diagnosticProfiles: state.diagnosticProfile.diagnosticProfiles,
    errorMessage: state.diagnosticProfile.errorMessage,
    clientUserID: state.diagnosticProfile.clientUserID,
    isLoading: state.diagnosticProfile.isLoading,
    isError: state.diagnosticProfile.isError,
    customerList: state.customerList.list,
    roomID: state.room.roomID,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDiagnosticProfiles,
      setCurrentDiagnosticProfileClient,
    },
    dispatch
  );

interface DiagnosticProfileCardProps
  extends ReturnType<typeof mapDispatchToProps>,
    RouteComponentProps {
  diagnosticProfiles: DiagnosticProfileState['diagnosticProfiles'];
  errorMessage: DiagnosticProfileState['errorMessage'];
  clientUserID: DiagnosticProfileState['clientUserID'];
  isLoading: DiagnosticProfileState['isLoading'];
  isError: DiagnosticProfileState['isError'];
  customerList: { userID: string }[];
  roomID: string;
}

const DiagnosticProfileCard: FunctionComponent<DiagnosticProfileCardProps> = ({
  roomID,
  history,
  customerList,
  clientUserID,
  diagnosticProfiles,
  ...otherProps
}) => {
  const isCouple = customerList.length > 1;
  const hasDiagnosticProfile = Object.keys(diagnosticProfiles).length > 0;
  useEffect(() => {
    if (!clientUserID) otherProps.setCurrentDiagnosticProfileClient(customerList[0].userID);
    otherProps.getDiagnosticProfiles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const showProfile = () => {
    if (isCouple) {
      history.push(`/room/${roomID}/diagnostic-profiles`);
    } else {
      history.push(`/room/${roomID}/diagnostic-profile`);
    }
  };
  return (
    <AccordionContainer
      title={`Diagnostic Profile${isCouple ? 's' : ''}`}
      dataQa="diagnosticProfileAccordion"
    >
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <DiagnosticProfileIcon style={{ flex: 1 }} />
        <View flex={4} align="stretch" style={{ flexDirection: 'column', padding: 5 }}>
          <Text style={{ fontSize: 15, fontWeight: 'normal', color: '#ccc' }}>
            {hasDiagnosticProfile
              ? // eslint-disable-next-line quotes
                "Your client's diagnostic profile is ready"
              : 'Not enough data to generate diagnostic profile'}
          </Text>
          {hasDiagnosticProfile && (
            <TouchableView
              onPress={showProfile}
              style={{
                flex: 1,
                marginTop: 25,
                outline: 'none',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Text style={{ fontSize: 14, color: '#09B198', fontWeight: 500, textAlign: 'right' }}>
                Show profile
              </Text>
            </TouchableView>
          )}
        </View>
      </View>
    </AccordionContainer>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiagnosticProfileCard));
