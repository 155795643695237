import { FunctionComponent, useEffect, useMemo } from 'react';
import { View, Button, Small, Standard, Large } from '@talkspace/react-toolkit';
import Submenu from 'components/Reusable/Submenu';
import PinkAlert from 'components/Icons/PinkAlert';
import useQueryNotes, { NoteItem } from 'hooks/notes/useQueryNotes';
import useMutationReopenSession from 'hooks/notes/useMutationReopenSession';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useQueryProgressNoteHelperData from 'hooks/notes/useQueryProgressNoteHelperData';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { Nullable } from 'ts-frontend/types';
import { useHistory, useLocation, useParams } from '@/core/routerLib';
import styled from '@/core/styled';
import { getSubmenuTitleAndSubtitle } from './utils';

const MainContainer = styled(View)(({ theme: { colors } }) => {
  return {
    alignSelf: 'center',
    height: 282,
    width: 306,
    borderColor: colors.pink,
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 5,
    marginTop: 8,
    paddingTop: 22,
    paddingRight: 20,
    paddingLeft: 20,
  };
});

const SmallText = styled(Small)({
  marginBottom: 12,
});

const TitleContainer = styled(View)({
  flexDirection: 'row',
  marginBottom: 10,
});

const BoldButton = styled(Button)({
  fontWeight: 'bold',
  margin: 5,
});

const ReopenEAPSessionDialog: FunctionComponent = () => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();
  const location = useLocation<{ noteItem: NoteItem & { title: string } }>();

  const memoizedSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const sessionReportID = Number(memoizedSearch.get('sessionReportID') as Nullable<string>);

  const { data: noteData } = useQueryNotes(roomID);
  const noteDataToUse = noteData?.items?.filter(
    (note) => note?.sessionReportData?.id === sessionReportID
  )[0];
  const noteItem = location.state?.noteItem || noteDataToUse;

  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  useQueryProgressNote(clientUserID, noteItem?.noteID ? noteItem.noteID.toString() : undefined);
  useQueryProgressNoteHelperData(roomID);

  const { isLoading, mutate: reopenSession } = useMutationReopenSession();

  useEffect(() => {
    if ((!noteItem && !sessionReportID) || (noteItem && !noteItem?.canReopen)) {
      history.push(`/room/${roomID}/notes-tab`);
    }
  }, [history, noteItem, roomID, sessionReportID]);

  const handleYesPress = () => {
    if (noteItem?.sessionReportData) {
      reopenSession(
        {
          roomID,
          clientUserID,
          sessionReportID: noteItem.sessionReportData.id,
          noteID: noteItem.noteID,
          caseID: noteItem.sessionReportData.caseID,
          currentSessionReportID: noteData?.currentSessionReport?.id,
        },
        {
          onSettled: () => {
            history.push(`/room/${roomID}/notes-tab`);
          },
        }
      );
    }
  };

  const { title: submenuTitle, subtitle: submenuSubtitle } = getSubmenuTitleAndSubtitle({
    noteType: 'progress',
    businessLine: {
      isEAP: true,
      isB2B: true,
      isBH: false,
      isPsychiatry: false,
      isVideoOnly: false,
      isB2BTeen: false,
    },
    sessionReportPosition: noteItem?.sessionReportData?.position,
    serviceStartDate: new Date(noteItem?.startDate),
    serviceEndDate: noteItem?.endDate ? new Date(noteItem?.endDate) : null,
    formMode: 'edit',
  });

  const handleBackButtonPress = () => {
    history.push(`/room/${roomID}/notes-tab`);
  };

  return (
    <Submenu
      childComponents={[
        <MainContainer>
          <TitleContainer>
            <PinkAlert style={{ marginRight: 10 }} />
            <Standard variant="standardBoldTSBlack">Reopen this session?</Standard>
          </TitleContainer>
          <SmallText variant="smallDarkGrey">
            This will require you to manually re-submit, once you feel the session has been
            completed.
          </SmallText>
          <SmallText variant="smallDarkGrey">
            Payments cannot be processed until you re-submit the session as completed. Your next
            session will begin after the submission date.
          </SmallText>
          <SmallText variant="smallDarkGrey">Continue?</SmallText>
          <View style={{ flexDirection: 'row-reverse' }}>
            <BoldButton isSecondary size="small" removeBorder onPress={() => history.goBack()}>
              No
            </BoldButton>
            <BoldButton
              isSecondary
              size="small"
              removeBorder
              onPress={handleYesPress}
              isLoading={isLoading}
            >
              Yes
            </BoldButton>
          </View>
        </MainContainer>,
      ]}
      titleComponent={
        <View>
          <Large variant="largeBoldWide">{submenuTitle}</Large>
          <Small variant="smallBoldGrey">{submenuSubtitle}</Small>
        </View>
      }
      onBackAlt={handleBackButtonPress}
    />
  );
};

export default ReopenEAPSessionDialog;
