import { View, useWindowWidthState } from '@talkspace/react-toolkit';

import styled from '@/core/styled';
import MetricCell from './MetricCell';
import { MetricsRecord } from '../types';

const Container = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      margin: isMobile ? '0 10px' : '0 20px',
      padding: '0 20px',
      border: `1px solid ${colors.extraLightGrey}`,
    };
  }
);

const Separator = styled(View)(({ theme: { colors } }) => {
  return {
    borderTop: `1px solid ${colors.extraLightGrey}`,
  };
});

interface Props {
  metrics: MetricsRecord;
}
const MetricsDashboard = ({ metrics }: Props) => {
  const { isMobile } = useWindowWidthState();
  return (
    <Container>
      <MetricCell metricName="responseTime" metric={metrics.responseTime} canFlex />
      <Separator />
      <View row={!isMobile} style={{ flexWrap: 'wrap' }}>
        <MetricCell metricName="retention" metric={metrics.retention} />
        <Separator />
        <MetricCell metricName="progressReportsAvg" metric={metrics.progressReportsAvg} />
        <Separator />
        <MetricCell metricName="b2BSessions" metric={metrics.b2BSessions} />
      </View>
    </Container>
  );
};

export default MetricsDashboard;
