import { useQuery } from 'react-query';
import { ClaimableRoom } from 'components/Dashboard/GrowCaseload/types';
import Analytics from 'components/Dashboard/GrowCaseload/Analytics';
import { WaitingClientsFilter } from 'components/Dashboard/GrowCaseload/Modals/PreferencesModal';

import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';

export type ClaimableRoomsParams = {
  therapistID: number;
  preferences: Array<WaitingClientsFilter>;
  onSuccess: () => void;
};

const fetchClaimableRooms = (params: ClaimableRoomsParams) => async () => {
  const { therapistID, preferences } = params;

  const { data } = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/therapists/${therapistID}/get-claimable-rooms`,
    { preferences }
  );

  Analytics.trackClaimableRoomsListFetchedEvent({
    therapistID,
    numberOfClients: data.data.length,
    activeFiltersList: preferences.toString(),
  });
  return data.data;
};

const useQueryClaimableRooms = (params: ClaimableRoomsParams) =>
  useQuery<ClaimableRoom[], Error>({
    queryKey: ['claimableRooms'],
    queryFn: fetchClaimableRooms(params),
    onSuccess: () => params.onSuccess(),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

export default useQueryClaimableRooms;
