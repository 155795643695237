import { useState, useMemo, useCallback } from 'react';
import {
  Standard,
  FloatingMenu,
  useWindowWidthState,
  useEmotionTheme,
  EmotionStyle,
  AnimatedSwipeDownModal,
} from '@talkspace/react-toolkit';
import { reopenTaskReasons } from '@talkspace/configs';
import useMutationReopenSession from 'hooks/notes/useMutationReopenSession';
import useQueryNotes, { NoteItem } from 'hooks/notes/useQueryNotes';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useLocation } from '@/core/routerLib';
import ReopenOptionsScreen from './ReopenOptionsScreen';
import ReopenAreYouSureScreen from './ReopenAreYouSureScreen';
import trackEvent from '../../../../../modules/analytics/trackEvent';
import ReopenDescriptionScreen from './ReopenDescriptionScreen';

export interface TaskNoteProps {
  taskID: number | string;
  roomID: number;
  clientUserID: number;
  therapistID: number;
  task: {
    sessionReportID: number | null;
    caseID: number | null;
    draftProgressNoteID?: number;
    bookingID?: number;
  };
}
interface TaskActionReopenMenuProps {
  note: TaskNoteProps;
  isHidden: boolean;
  handleCloseMenu: () => void;
  modalStyles?: EmotionStyle;
}

const getFloatingMenuStyle = (isMobile: boolean) => {
  return {
    top: 27,
    left: 'auto',
    right: isMobile ? 0 : -10,
    width: 306,
    height: 'fit-content',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 17,
    paddingBottom: 8,
    overflowY: 'auto',
  };
};

const TaskActionReopenContainer = ({
  note,
  isHidden,
  handleCloseMenu,
  modalStyles,
}: TaskActionReopenMenuProps) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [reopenReason, setReopenReason] = useState<string>('');
  const [providerReasonDescription, setProvideReasonDescription] = useState<string>('');
  const { mutate: reopenSession } = useMutationReopenSession();
  const location = useLocation<{ noteItem?: NoteItem & { title: string } }>();
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const {
    taskID,
    roomID,
    clientUserID,
    therapistID,
    task: { sessionReportID, caseID, draftProgressNoteID },
  } = note;

  const { accessUnsubmittedSessions: { dynamicProgressNoteTasks } = {} } = useFlags();

  const { data: noteData } = useQueryNotes(roomID.toString(), !dynamicProgressNoteTasks);

  const noteItem = dynamicProgressNoteTasks
    ? null
    : location.state?.noteItem ||
      noteData?.items?.filter((item) => item?.sessionReportData?.id === sessionReportID)[0];

  const mappedMenuItems = useMemo(
    () =>
      new Map([
        [
          reopenTaskReasons.technical_issue,
          {
            label: reopenTaskReasons.technical_issue,
            content: (
              <>
                <Standard inline>Describe the technical issue you’re experiencing</Standard>{' '}
                <Standard inline style={{ color: colors.slateGrey, fontWeight: 400 }}>
                  (optional)
                </Standard>
              </>
            ),
          },
        ],
        [reopenTaskReasons.session_postponed, { label: reopenTaskReasons.session_postponed }],
        [
          reopenTaskReasons.i_and_or_client_unavailable,
          {
            label: reopenTaskReasons.i_and_or_client_unavailable,
            subLabel: 'Sickness, emergency, vacation, etc',
          },
        ],
        [
          reopenTaskReasons.i_and_or_client_not_realize_session_began,
          { label: reopenTaskReasons.i_and_or_client_not_realize_session_began },
        ],
        [
          reopenTaskReasons.other,
          {
            label: reopenTaskReasons.other,
            content: <Standard>Describe your reason for reopening</Standard>,
            disableSubmit: providerReasonDescription.length === 0,
          },
        ],
      ]),
    [colors.slateGrey, providerReasonDescription.length]
  );

  const handleClick = (menuKey: string) => {
    setReopenReason(menuKey);
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleReset = useCallback(() => {
    handleCloseMenu();
    setActiveStep(0);
    setReopenReason('');
    setProvideReasonDescription('');
  }, [handleCloseMenu]);

  const handleSubmitReopen = useCallback(() => {
    const sessionReportIDValue = noteItem?.sessionReportData?.id || sessionReportID;

    const noteIDValue = noteItem?.noteID || draftProgressNoteID;

    const caseIDValue = noteItem?.sessionReportData?.caseID || caseID;

    if (sessionReportIDValue && caseIDValue) {
      const baseBody: { eventDate: string; type: string; taskID: number } = {
        eventDate: new Date().toISOString(),
        type: 'progressNote',
        taskID: Number(taskID),
      };
      reopenSession({
        reason: reopenReason,
        reasonDescription: providerReasonDescription,
        roomID: roomID.toString(),
        clientUserID,
        therapistID,
        sessionReportID: sessionReportIDValue,
        noteID: noteIDValue,
        caseID: caseIDValue,
        ...(!dynamicProgressNoteTasks && {
          currentSessionReportID: noteData?.currentSessionReport?.id,
        }),
      });
      handleReset();
      trackEvent(
        'taskClick',
        { actionName: 'providerTaskInteraction' },
        {
          ...baseBody,
          ...(reopenReason && { reason: reopenReason }),
          action: 'reopen',
        }
      );
    }
  }, [
    noteItem?.sessionReportData?.id,
    noteItem?.sessionReportData?.caseID,
    noteItem?.noteID,
    sessionReportID,
    draftProgressNoteID,
    caseID,
    taskID,
    reopenSession,
    reopenReason,
    providerReasonDescription,
    roomID,
    clientUserID,
    therapistID,
    dynamicProgressNoteTasks,
    noteData?.currentSessionReport?.id,
    handleReset,
  ]);

  const handleConfirm = useCallback(() => {
    if ([reopenTaskReasons.technical_issue, reopenTaskReasons.other].includes(reopenReason)) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      handleSubmitReopen();
    }
  }, [handleSubmitReopen, reopenReason]);

  const getStepContent = useCallback(
    (step: number) => {
      switch (step) {
        case 0:
          return <ReopenOptionsScreen menuItems={mappedMenuItems} handleClick={handleClick} />;
        case 1: {
          return <ReopenAreYouSureScreen handleSubmit={handleConfirm} handleCancel={handleReset} />;
        }
        case 2: {
          const menuItem = mappedMenuItems.get(reopenReason);
          return (
            menuItem && (
              <ReopenDescriptionScreen
                content={menuItem.content}
                disableSubmit={menuItem.disableSubmit}
                text={providerReasonDescription}
                handleText={setProvideReasonDescription}
                handleSubmit={handleSubmitReopen}
                handleCancel={handleReset}
                shouldDisplayReminder={reopenReason === reopenTaskReasons.other}
              />
            )
          );
        }
        default:
          return null;
      }
    },
    [
      mappedMenuItems,
      handleConfirm,
      handleReset,
      reopenReason,
      providerReasonDescription,
      handleSubmitReopen,
    ]
  );

  const floatingMenuStyle = getFloatingMenuStyle(isMobile);

  return (
    <>
      {isMobile ? (
        <AnimatedSwipeDownModal isOpen={!isHidden} onClose={handleReset}>
          {getStepContent(activeStep)}
        </AnimatedSwipeDownModal>
      ) : (
        <FloatingMenu
          style={{ ...floatingMenuStyle, ...modalStyles }}
          hide={isHidden}
          onBackdropPress={handleReset}
        >
          {getStepContent(activeStep)}
        </FloatingMenu>
      )}
    </>
  );
};

export default TaskActionReopenContainer;
