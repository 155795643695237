import {
  View,
  ReopenIcon,
  useWindowWidth,
  useEmotionTheme,
  HoverInfoBox,
  TouchableView,
} from '@talkspace/react-toolkit';
import { useState } from 'react';
import TaskActionReopenContainer, {
  TaskNoteProps,
} from '../../Cells/TaskActionReopenMenu/screens/TaskActionReopenContainer';

interface ReopenButtonProps {
  note: TaskNoteProps;
}

const getModalStyles = () => {
  return {
    right: 130,
    top: 40,
    overflow: 'hidden',
  };
};

const ReopenButton = ({ note }: ReopenButtonProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isMenuHidden, setHideMenu] = useState<boolean>(true);
  const { isDesktop } = useWindowWidth();
  const { colors } = useEmotionTheme();

  const modalStyles = getModalStyles();

  return (
    <TouchableView
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onPress={() => setHideMenu(false)}
    >
      <ReopenIcon
        isHovered={isHovering && isDesktop}
        color={isHovering && !isDesktop ? colors.white : colors.accessibilityGreenDark}
      />
      {!isDesktop && <View style={{ marginLeft: 8, fontWeight: 500 }}>Reopen session</View>}
      <TaskActionReopenContainer
        modalStyles={modalStyles}
        isHidden={isMenuHidden}
        handleCloseMenu={() => {
          setIsHovering(false);
          setHideMenu(true);
        }}
        note={note}
      />
      {isDesktop && (
        <HoverInfoBox
          toolTipText="Reopen session"
          isTooltipOpen={isHovering}
          styles={{ left: -92 }}
        />
      )}
    </TouchableView>
  );
};

export default ReopenButton;
