import { VoidFunctionComponent } from 'react';
import { Standard, View, TouchableView, Small } from '@talkspace/react-toolkit';
import styled from '../../../../../core/styled';

interface SnoozeOptionsScreennProps {
  menuItems: Map<string, any>;
  handleClick: (menuKey: string) => void;
}

const MenuItemWrapper = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    width: '100%',
    borderRadius: 5,
    padding: `8px 0 8px 27px`,
    '& > p': {
      color: colors.permaRiverBed,
    },
    '&:hover': {
      backgroundColor: colors.a11yLinkWaterGrey,
    },
  };
});

const SnoozeOptionsScreen: VoidFunctionComponent<SnoozeOptionsScreennProps> = ({
  menuItems,
  handleClick,
}) => (
  <View>
    <Standard style={{ marginBottom: 12, marginLeft: 8, marginRight: 8 }}>
      What’s your reason for snoozing this task?
    </Standard>
    {[...menuItems.entries()].map(([key, value]) => (
      <MenuItemWrapper key={key} onPress={() => handleClick(key)}>
        <Small>{value.label}</Small>
      </MenuItemWrapper>
    ))}
  </View>
);

export default SnoozeOptionsScreen;
