import { Component } from 'react';
import './EmergencyContact.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getEmergencyContact,
  postEmergencyContactMessage,
} from '../../../../../actions/EmergencyContactActions';
import SocketService from '../../../../../utils/socket/SocketService';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import Submenu from '../../../../Reusable/Submenu/Submenu';

class EmergencyContact extends Component {
  constructor(props) {
    super(props);
    this.state = { showSpinnerByClientUserID: {} };
    this.requestEmergencyContactMessage = this.requestEmergencyContactMessage.bind(this);
    this.renderEmergencyContactCards = this.renderEmergencyContactCards.bind(this);
    this.renderClientLoginCards = this.renderClientLoginCards.bind(this);
  }

  componentDidMount() {
    const clientUserIDs = this.props.clientList.map((client) => {
      this.props.getEmergencyContact(client.userID);
      return Number(client.userID);
    });
    SocketService.instance().on('emergency_contact', (data) => {
      if (clientUserIDs.includes(Number(data.userID))) this.props.getEmergencyContact(data.userID);
    });
  }

  componentWillUnmount() {
    SocketService.instance().off('emergency_contact');
  }

  requestEmergencyContactMessage(client) {
    this.setState({
      showSpinnerByClientUserID: { ...this.state.showSpinnerByClientUserID, [client.userID]: true },
    });
    setTimeout(() => {
      this.setState({
        showSpinnerByClientUserID: {
          ...this.state.showSpinnerByClientUserID,
          [client.userID]: false,
        },
      });
    }, 3000);
    this.props.postEmergencyContactMessage(client.userID);
  }

  renderClientLoginCards() {
    const { clientList, history } = this.props;
    return clientList.map((client) => {
      const buttons = [
        {
          title: 'View client login information',
          clickHandler: () => history.push('client-logins', { clientUserID: client.userID }),
        },
      ];
      return (
        <UserActionCard
          key={client.userID}
          preferredName={client.displayName || 'Name Missing'}
          content={
            // eslint-disable-next-line quotes
            "When using Talkspace's IP locational tools, please always prioritize and respect Client privacy. The locational tool was built into the emergency contact system to aide you in guiding local authorities during an emergent crisis. The use of this tool should be restricted to only those situations."
          }
          buttons={buttons}
        />
      );
    });
  }

  renderEmergencyContactCards() {
    const {
      emergencyContactByClientUserID,
      clientList,
      history,
      isRequestingByClientUserID,
      requestErrorByClientUserID,
      clientUserIDsRequested,
    } = this.props;
    return clientList.map((client) => {
      const emergencyContact = emergencyContactByClientUserID[client.userID] || {};
      const isCompleted = !!emergencyContact.firstName;
      const viewEmergencyContactDetailsButton = {
        title: 'View emergency contact information',
        clickHandler: () =>
          history.push('emergency-contact-details', { clientUserID: client.userID }),
      };
      const buttons = [
        {
          title: clientUserIDsRequested.includes(client.userID)
            ? 'Request information again'
            : 'Request emergency information',
          isSecondary: isCompleted,
          clickHandler: () => this.requestEmergencyContactMessage(client),
        },
      ];
      let cardText = clientUserIDsRequested.includes(client.userID)
        ? 'You have already sent a request for contact information, but the client hasn’t provided it yet. Please reach out to your client to discuss any questions or concerns they may have. You may also send the emergency contact link again if needed.'
        : 'The emergency contact link will automatically send when part two of the informed consent is complete. You can manually send the emergency contact by clicking on the “Request emergency information” button here.';
      if (isCompleted) {
        cardText =
          'The client has shared their contact information with you. You can view the information by clicking below. You may also request updated information if necessary.';
        buttons.push(viewEmergencyContactDetailsButton);
      }
      return (
        <UserActionCard
          key={client.userID}
          preferredName={client.displayName || 'Name Missing'}
          content={cardText}
          buttons={buttons}
          isUpdating={isRequestingByClientUserID[client.userID]}
          isError={requestErrorByClientUserID[client.userID]}
          showSpinner={this.state.showSpinnerByClientUserID[client.userID]}
        />
      );
    });
  }

  render() {
    return (
      <Submenu
        childComponents={this.renderClientLoginCards()}
        title="Emergency contact login"
        prevRoute="case-tab"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emergencyContactByClientUserID: state.emergencyContact.emergencyContactByClientUserID,
    isRequestingByClientUserID: state.emergencyContact.isRequestingByClientUserID,
    requestErrorByClientUserID: state.emergencyContact.requestErrorByClientUserID,
    clientList: state.customerList.list,
    clientUserIDsRequested: state.emergencyContact.clientUserIDsRequested,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getEmergencyContact, postEmergencyContactMessage }, dispatch);
const EmergencyContactContainer = connect(mapStateToProps, mapDispatchToProps)(EmergencyContact);

export default EmergencyContactContainer;
