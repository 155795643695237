import * as actionTypes from '../constants/BookingConstants';

const moment = require('moment-timezone');

const initialState = {
  bookingStartTime: null,
  bookingEndTime: null,
  bookingTherapistTimezone: moment.tz.guess(),
  bookingState: 'BOOKING_SERVER_ISSUE',
  spinnerMessage: ' ',
  isUpdating: true,
  isError: false,
};

export default function activity(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_PATCH_BOOKING:
      return {
        ...state,
        spinnerMessage: 'Loading activity data',
        isUpdating: true,
        isError: false,
      };
    case actionTypes.REQUEST_PATCH_BOOKING_ERROR:
      return {
        bookingState: 'BOOKING_SERVER_ISSUE',
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };
    case actionTypes.RECEIVE_PATCH_BOOKING:
      return {
        bookingState: action.data.state,
        bookingStartTime: action.data.start,
        bookingEndTime: action.data.end,
        bookingTherapistTimezone: action.data.therapistTimezone,
        hasBreakAfterSession: action.data.hasBreakAfterSession,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };
    default:
      return state;
  }
}
