import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { cancelOutcomeMeasures } from '../../../../../actions/OutcomeMeasureActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import SimpleSingleSelect from '../../../../Reusable/SingleSelect/SimpleSingleSelect';
import TextAreaComponent from '../../../../Reusable/TextArea/TextArea';
import Footer from '../../../../Reusable/Footer/Footer';
import Button from '../../../../Reusable/Buttons/Button';
import './OutcomeMeasures.css';

class OutcomeMeasureCancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: null,
      showOther: false,
      showSpinner: false,
    };
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleOtherChange = this.handleOtherChange.bind(this);
    this.handleStopScheduling = this.handleStopScheduling.bind(this);
  }

  getContent() {
    return (
      <div style={{ padding: 20 }} className="om-cancel">
        <div className="om-cancel-msg">
          If your client would like to stop receiving assessments you can cancel the service. Please
          provide a reason below.
        </div>
        <SimpleSingleSelect
          options={this.getReasons()}
          className="single-select"
          callback={this.handleDropdownChange}
          placeholder="Reason for stopping"
          isValid
          field="reason"
          value={this.state.showOther ? 'Other' : this.state.reason}
        />
        {this.state.showOther && (
          <TextAreaComponent
            placeholder="Describe reason for stopping assessment scheduling"
            onChange={this.handleOtherChange}
            style={{
              fontSize: 14,
              color: '#333',
              width: '100%',
            }}
          />
        )}
      </div>
    );
  }

  getReasons() {
    return [
      {},
      {
        key: 0,
        value: 'Wrong diagnosis selected for the client',
        text: 'Wrong diagnosis selected for the client',
      },
      {
        key: 1,
        value: 'Client is having technical difficulties with the survey',
        text: 'Client is having technical difficulties with the survey',
      },
      {
        key: 2,
        value: 'Too soon, I want to discuss with client first',
        text: 'Too soon, I want to discuss with client first',
      },
      {
        key: 3,
        value: 'Client is ignoring reminders for taking the survey',
        text: 'Client is ignoring reminders for taking the survey',
      },
      {
        key: 4,
        value: 'Not a good time due for the client',
        text: 'Not a good time due for the client',
      },
      {
        key: 5,
        value: 'Client requested to stop receiving the assessments',
        text: 'Client requested to stop receiving the assessments',
      },
      {
        key: 6,
        value: 'Survey has been pending too long',
        text: 'Survey has been pending too long',
      },
      {
        key: 7,
        value: 'Other',
        text: 'Other',
      },
    ];
  }

  handleDropdownChange(_event, { value }) {
    const showOther = value === 'Other';
    this.setState({ reason: value, showOther, showSpinner: false });
  }

  handleOtherChange(_event, { value }) {
    this.setState({ reason: value });
  }

  handleStopScheduling() {
    const roomID = this.props.match.params.roomID;
    const cancelData = {
      reasonCanceled: this.state.reason,
      userRoomSurveyID: this.props.location.state?.outcomeMeasure?.id,
    };
    this.setState({ showSpinner: true });
    this.props.cancelOutcomeMeasures(roomID, cancelData).then((res) => {
      setTimeout(this.props.history.goBack, 1000);
    });
  }

  renderFooter() {
    const { isUpdating, isError, spinnerMessage } = this.props;
    return (
      <Footer
        showSpinner={this.state.showSpinner}
        isUpdating={isUpdating}
        isError={isError}
        spinnerMessage={spinnerMessage}
        childComponents={[
          <Button
            title="Cancel"
            isSecondary
            key={1}
            clickHandler={() => this.props.history.goBack()}
          />,
          <Button title="Stop scheduling" key={2} clickHandler={this.handleStopScheduling} />,
        ]}
      />
    );
  }

  render() {
    return (
      <Submenu
        childComponents={[this.getContent()]}
        title="Stop assessment scheduling"
        key={2}
        footerComponent={this.renderFooter()}
        prevRoute="clinical-tab"
      />
    );
  }
}

OutcomeMeasureCancel.propTypes = {};

const mapStateToProps = (state) => {
  return {
    ...state.outcomeMeasures,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cancelOutcomeMeasures,
    },
    dispatch
  );
const OutcomeMeasureCancelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OutcomeMeasureCancel);

export default withRouter(OutcomeMeasureCancelContainer);
