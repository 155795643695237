import { Component } from 'react';
import moment from 'moment';
import { View, useWindowWidthState } from '@talkspace/react-toolkit';
import '../../Dashboard.css';
import { withRouter } from 'react-router-dom';
import styled from '../../../../core/styled';

import { DashboardCell, DashboardRow, DashboardHeader } from '../../DashboardTable';
import DashboardCellRoomDetail from '../../Cells/DashboardCellRoomDetail/DashboardCellRoomDetail';
import DashboardTablePlaceholder from '../../Placeholders/DashboardTablePlaceholder';
import IconNewClient from '../../Placeholders/IconNewClient';

const NewClientsContentWrapper = styled(View)({
  padding: 0,
});

const NewClientsTitleWrapper = styled(View)({
  marginBottom: 0,
  paddingTop: 20,
  paddingRight: 15,
  paddingBottom: 10,
  minHeight: 48,
});

const getTitle = (newRoomCount) => {
  let clientCopy = '';
  if (newRoomCount === 0) {
    clientCopy = '(no new clients)';
  }
  if (newRoomCount === 1) {
    clientCopy = '(1 new client)';
  }
  if (newRoomCount > 1) {
    clientCopy = `(${newRoomCount} new clients)`;
  }
  return `Send first message ${clientCopy}`;
};

const ColgroupResponsive = () => {
  const { isDesktop } = useWindowWidthState();
  return isDesktop ? (
    <colgroup>
      <col span="1" style={{ width: '11%' }} />
      <col span="1" style={{ width: '11%' }} />
      <col span="1" style={{ width: '11%' }} />
      <col span="1" style={{ width: '24%' }} />
      <col span="1" style={{ width: '11%' }} />
      <col span="1" style={{ width: '11%' }} />
      <col span="1" />
    </colgroup>
  ) : null;
};

class NewClients extends Component {
  render() {
    const { isLoading, isHidden, newRoomsData, checkRoomPath } = this.props;
    const isHiddenClass = isHidden ? 'ibox table-hidden' : 'ibox';

    return (
      <div className={isHiddenClass}>
        <NewClientsTitleWrapper className="unstackable">
          <h4>{getTitle(newRoomsData.length)}</h4>
        </NewClientsTitleWrapper>
        {(isLoading || !newRoomsData.length) && (
          <DashboardTablePlaceholder
            title="You have no new clients!"
            subtitle="This is where you’ll greet your new clients. To receive clients, "
            icon={<IconNewClient />}
            linkPath="/my-account?scrollTo=capacity"
            linkText="manage your caseload."
            isLoading={isLoading}
          />
        )}
        {!isLoading && !!newRoomsData.length && (
          <NewClientsContentWrapper className="unstackable">
            <div className="unstackable grid" role="grid">
              <table className="unstackable ui fixed table">
                <ColgroupResponsive />
                <thead>
                  <DashboardRow>
                    <DashboardHeader>Joined</DashboardHeader>
                    <DashboardHeader>Last Activity</DashboardHeader>
                    <DashboardHeader>Waiting</DashboardHeader>
                    <DashboardHeader>ID</DashboardHeader>
                    <DashboardHeader>Status</DashboardHeader>
                    <DashboardHeader>Source</DashboardHeader>
                    <DashboardHeader>Plan Name</DashboardHeader>
                  </DashboardRow>
                </thead>
                <tbody>
                  {newRoomsData.map((client, index) => (
                    <DashboardRow key={index} className="unstackable new-room-row">
                      <DashboardCell className="unstackable row-within-rows created-at">
                        {checkRoomPath(
                          client,
                          client.customer_waiting
                            ? moment(client.customer_waiting).from(moment())
                            : 'a few seconds ago'
                        )}
                      </DashboardCell>
                      <DashboardCell className="unstackable row-within-rows last-activity-new-clients">
                        {checkRoomPath(client, moment(client.last_activity).fromNow())}
                        <i className="unstackable fa fa-angle-right fa-2x pull-right room-arrow" />
                        {client.room_reminder_count > 0 ? (
                          <div className="room-reminder-container">
                            <div className="unstackable room-reminder-text">
                              {checkRoomPath(client, 'Reminders ')}
                              <span className="unstackable rrCircleRed room-reminder-count-icon">
                                {checkRoomPath(
                                  client,
                                  client.room_reminder_count < 100
                                    ? client.room_reminder_count
                                    : '99+',
                                  'room-reminder-count-text'
                                )}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </DashboardCell>
                      <DashboardCell className="unstackable row-within-rows display-time">
                        <div className="unstackable">
                          <div className="unstackable customer-waiting">
                            {checkRoomPath(
                              client, // passing 'true' - omits the 'ago' string
                              client.customer_waiting
                                ? moment(client.customer_waiting).from(moment(), true)
                                : 'a few seconds ago'
                            )}
                          </div>
                        </div>
                      </DashboardCell>
                      <DashboardCell className="unstackable row-within-rows nickname">
                        {checkRoomPath(
                          client,
                          <DashboardCellRoomDetail
                            pseudonym={client.nick_name}
                            userID={client.client_user_id}
                            roomID={client.private_talk_id}
                          />
                        )}
                      </DashboardCell>
                      <DashboardCell className="unstackable row-within-rows room-status">
                        {checkRoomPath(client.private_talk_id, client.room_status)}
                      </DashboardCell>
                      <DashboardCell className="unstackable row-within-rows client-source">
                        <div className="unstackable client-source-container">
                          <div className="unstackable client-source-title">Source:&nbsp;</div>
                          {checkRoomPath(client, client.Source)}
                        </div>
                      </DashboardCell>
                      <DashboardCell className="unstackable row-within-rows plan-name">
                        <div className="unstackable client-plan-name">
                          {checkRoomPath(client, client.plan_name)}
                        </div>
                        <div className="unstackable selectedDaysOfWeek">
                          <div className="unstackable client-plan-weekdays">Weekdays:&nbsp;</div>
                          {checkRoomPath(client, client.weekdays ? client.weekdays : '')}
                        </div>
                      </DashboardCell>
                    </DashboardRow>
                  ))}
                </tbody>
              </table>
            </div>
          </NewClientsContentWrapper>
        )}
      </div>
    );
  }
}

export default withRouter(NewClients);
