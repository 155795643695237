import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import './SingleSelect.css';

const SingleSelectView = (props) => {
  const inputText = (
    <div>
      <span className="value-text">{props.value}</span>
      <span
        className={`delete-value-icon ${props.value && props.isFocused ? '' : 'hidden'}`}
        onClick={(event, data) => props.handleDelete(event, data)}
      />
    </div>
  );
  return (
    <div className={`ts-component-single-select ${props.placeholderState}`} style={props.styles}>
      <div className="faux-placeholder">{props.placeholder}</div>
      <Dropdown
        trigger={inputText}
        value={props.value}
        disabled={props.disabled}
        options={props.options}
        onOpen={(event, data) => props.changeFocus(true, event, data)}
        onClose={(event, data) => props.changeFocus(false, event, data)}
        onChange={(event, data) => props.handleChange(event, data)}
        onBlur={(event, data) => props.handleClose(event, data)}
        selectOnBlur={false}
      />
      <div className={`error-icon ${props.isValid ? 'hidden' : ''}`} />
    </div>
  );
};

SingleSelectView.propTypes = {
  changeFocus: PropTypes.func,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  isValid: PropTypes.bool,
  isFocused: PropTypes.bool,
  placeholder: PropTypes.string,
  placeholderState: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

export default SingleSelectView;
