import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getVideoCallHistory } from '../../../../../actions/VideoCallHistoryActions';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import ListItem from '../../../../Reusable/ListItems/ListItem';
import ItemList from '../../../../Reusable/ItemList/ItemList';
import { formatDateTimeShort } from '../../../../../utils/date';
import { getActiveCustomerName } from '../../../../../utils/customerHelper';

class VideoCallHistory extends Component {
  constructor(props) {
    super(props);

    this.getItemList = this.getItemList.bind(this);
    this.getAllVideoCallHistory = this.getAllVideoCallHistory.bind(this);
    this.getContent = this.getContent.bind(this);

    this.props.getVideoCallHistory();
  }

  getAllVideoCallHistory() {
    const getItemContent = (videoCallHistoryItem, key) => {
      const itemData = {
        date: formatDateTimeShort(videoCallHistoryItem.date),
        duration: `${Math.max(Math.round(videoCallHistoryItem.duration / 60), 1)} min`,
        type:
          videoCallHistoryItem.type.charAt(0).toUpperCase() + videoCallHistoryItem.type.slice(1),
      };

      return (
        <ListItem
          key={videoCallHistoryItem.callID}
          itemDataOrder={['date', 'duration', 'type']}
          itemDisplayData={itemData}
          widths={[140, 85, 60]}
        />
      );
    };

    return this.props.videoCallHistory.data.map(getItemContent);
  }

  getContent() {
    return (
      <UserActionCard
        preferredName={getActiveCustomerName(
          this.props.customerInformation,
          this.props.customerList
        )}
        content={this.getItemList()}
        buttons={[]}
        isError={this.props.videoCallHistory.requestError}
        showSpinner={this.props.videoCallHistory.isRequesting}
        spinnerMessage="Loading live session history"
      />
    );
  }

  // TODO: This is supposed to be a static method, so not sure how this works
  // eslint-disable-next-line react/no-typos
  getDerivedStateFromProps(nextProps) {
    if (nextProps.videoCallHistory !== this.props.videoCallHistory) {
      return {
        videoCallHistory: nextProps.videoCallHistory,
      };
    }
    return undefined;
  }

  getItemList() {
    return (
      <ItemList
        items={this.getAllVideoCallHistory()}
        titles={['Date', 'Duration', 'Type']}
        noDataMessage="No live sessions found"
        widths={[140, 85, 60]}
      />
    );
  }

  render() {
    return (
      <Submenu
        childComponents={[this.getContent()]}
        title="Live session history"
        prevRoute="video-credits"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    customerList: state.customerList.list,
    videoCallHistory: state.videoCallHistory,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getVideoCallHistory }, dispatch);

const VideoCallHistoryContainer = connect(mapStateToProps, mapDispatchToProps)(VideoCallHistory);

export default VideoCallHistoryContainer;
