import { useState, useEffect, FunctionComponent, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { Room, RoomStatus } from 'ts-frontend/entities/Room';
import { isTherapistCT, TherapistType } from 'ts-frontend/entities/Therapist';
import { View, usePanelManagerActions } from '@talkspace/react-toolkit';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryProgressNoteHelperData from 'hooks/notes/useQueryProgressNoteHelperData';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { PromiseMessageTypeNames } from 'ts-promise-message';
import { useFlags } from 'launchDarkly/FlagsProvider';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import getAdminConfigValue from '../../../utils/adminConfig';
import CaseTab from './CaseTab/CaseTab';
import ClinicalTab from './ClinicalTab/ClinicalTab';
import JourneyTab from './JourneyTab/JourneyTab';
import NotesTab from './NotesTab/NotesTab';
import LVSTab from './LVSTab/LVSTab';
import styled from '../../../core/styled';
import './CrmMenu.css';
import useQueryNotes from '../../../hooks/notes/useQueryNotes';

const CrmRoom = styled(View)(
  ({
    theme: {
      window: { isMobile },
      colors,
    },
  }) => {
    return {
      padding: 0,
      height: 'auto',
      backgroundColor: colors.white,
      alignItems: 'center',
      flex: 1,
      flexDirection: isMobile ? 'column-reverse' : 'column',
    };
  }
);

interface CrmMenuProps extends RouteComponentProps {
  isEAP: boolean;
  isQuickmatch: boolean;
  therapistType: TherapistType;
  room: Room;
  dispatch: Dispatch<any>;
}

const ROOM_STATUS_ACTIVE_LIST = [
  RoomStatus.ACTIVE,
  RoomStatus.PENDING_INACTIVE,
  RoomStatus.NOT_RENEWING,
  RoomStatus.CC_SUCCESS,
  RoomStatus.PAST_DUE,
  RoomStatus.FROZEN,
  RoomStatus.FREE_TRIAL_CHATBOT,
];

const CrmMenu: FunctionComponent<CrmMenuProps> = (props) => {
  const { match, location, isEAP, isQuickmatch, therapistType, room, dispatch } = props;
  const [isProviderScheduleBookingFlagOn, setProviderScheduleBookingFlag] = useState(false);
  const { setIsLeftPanelOnTop, setIsLargeRightPanel, setIsRightPanelOpen } =
    usePanelManagerActions();
  const { data: { clientUserID, hasHadFirstSession } = {} } = useQueryRoomDetails(
    room.roomID.toString()
  );
  const { data: businessLineData } = useQueryBusinessLine(room.roomID.toString(), clientUserID);

  const { refetch } = useQueryProgressNoteHelperData(room.roomID.toString(), false);

  const { data: notesData } = useQueryNotes(room?.roomID?.toString());

  const { nycTeensIntake } = useFlags();

  const openStarredMessages = () => {
    if (ReactFrameService.instance().isInFrame()) {
      ReactFrameService.instance().sendAsyncMessage(PromiseMessageTypeNames.loadStarredMessagesVC, {
        roomID: room.roomID,
      });
      return;
    }
    dispatch({ type: 'CLOSE_SHARED_FILES' });
    dispatch({ type: 'OPEN_STARRED_MESSAGES' });
    setIsLeftPanelOnTop(false);
    setIsRightPanelOpen(true);
    setIsLargeRightPanel(true);
  };

  const openSharedFiles = () => {
    if (ReactFrameService.instance().isInFrame()) {
      ReactFrameService.instance().sendAsyncMessage(PromiseMessageTypeNames.loadSharedFilesVC, {
        roomID: room.roomID,
      });
      return;
    }
    dispatch({ type: 'CLOSE_STARRED_MESSAGES' });
    dispatch({ type: 'OPEN_SHARED_FILES' });
    setIsLeftPanelOnTop(false);
    setIsRightPanelOpen(true);
    setIsLargeRightPanel(true);
  };

  useEffect(() => {
    const handleLiveSessionStarted = () => {
      refetch();
    };

    document.addEventListener('liveSessionStarted', handleLiveSessionStarted);

    return () => {
      document.removeEventListener('liveSessionStarted', handleLiveSessionStarted);
    };
  }, [refetch]);

  useEffect(() => {
    const openPostLVSPrompt = (videoCallID: string) => {
      dispatch({ type: 'CLOSE_STARRED_MESSAGES' });
      dispatch({ type: 'CLOSE_SHARED_FILES' });
      dispatch({ type: 'OPEN_POST_LVS_PROMPT', completedVideoCallID: videoCallID });
      setIsLeftPanelOnTop(false);
      setIsRightPanelOpen(true);
      setIsLargeRightPanel(true);
    };

    const handleVideoCallEnded = (e: any) => {
      if (e.detail.shouldOpenPostLVSPrompt) {
        openPostLVSPrompt(e.detail.videoCallID);
      }
    };

    document.addEventListener('videoCallEnded', handleVideoCallEnded);

    return () => {
      document.removeEventListener('videoCallEnded', handleVideoCallEnded);
    };
  }, [
    businessLineData,
    dispatch,
    room.roomID,
    setIsLargeRightPanel,
    setIsLeftPanelOnTop,
    setIsRightPanelOpen,
  ]);

  useEffect(() => {
    let mounted = true;
    getAdminConfigValue('therapist_schedule_lvs')
      .then((flagValue) => {
        if (mounted) setProviderScheduleBookingFlag(!!flagValue);
      })
      .catch();
    return () => {
      mounted = false;
    };
  }, []);

  const isCT = isTherapistCT(therapistType);
  const shouldAllowBookings =
    !isCT &&
    (ROOM_STATUS_ACTIVE_LIST.includes(room.status) ||
      room.isVideoOnly ||
      room.roomType === 'psychiatry_room');

  const { pathname } = location;
  // if the pathname and match.url are both room/:roomID forward to agenda tab
  if (pathname === match.url) {
    const isProviderPsych = therapistType === 'psychiatrist';
    const seperator = pathname.slice(-1) === '/' ? '' : '/';
    if (isProviderPsych) return <Redirect to={`${pathname}${seperator}clinical-tab`} />;
    if (isCT) return <Redirect to={`${pathname}${seperator}case-tab`} />;
    return <Redirect to={`${pathname}${seperator}agenda-tab`} />;
    // remove trailing slashes
  }
  if (pathname.substr(pathname.length - 1, pathname.length) === '/') {
    return <Redirect to={`${pathname.substr(0, pathname.length - 1)}`} />;
  }

  return (
    <CrmRoom>
      <Route path={`${match.path}/agenda-tab`} component={JourneyTab} />
      <Route
        path={`${match.path}/case-tab`}
        render={() => (
          <CaseTab
            isEAP={isEAP}
            isQuickmatch={isQuickmatch}
            therapistType={therapistType}
            openStarredMessagesHandler={openStarredMessages}
            openSharedFilesHandler={openSharedFiles}
            roomStatus
            isClientDischarged={notesData?.isClientDischarged}
          />
        )}
      />
      <Route
        path={`${match.path}/clinical-tab`}
        render={() => <ClinicalTab nycTeensIntake={nycTeensIntake} />}
      />
      <Route path={`${match.path}/notes-tab`} component={NotesTab} />
      <Route
        path={`${match.path}/lvs-tab`}
        render={() => (
          <LVSTab
            isProviderScheduleBookingFlagOn={isProviderScheduleBookingFlagOn}
            shouldAllowBookings={shouldAllowBookings}
            hasHadFirstSession={Boolean(hasHadFirstSession)}
          />
        )}
      />
    </CrmRoom>
  );
};

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
    isEAP: state.room.isEAP,
    isQuickmatch: state.room.isQuickmatch,
    therapistType: state.availability.therapistType,
    room: state.room,
  };
};

const TsCrmMenu = connect(mapStateToProps)(CrmMenu);

export default TsCrmMenu;
