import capitalize from 'lodash/capitalize';
import { VoidFunctionComponent, useState, useMemo } from 'react';
import { Standard, View, Button, BaseButton, useWindowWidthState } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { TasksResponse } from 'hooks/dashboard/useQueryTaskList';
import { useHistory } from 'react-router-dom';
import { ProgressNoteModalityType } from 'ts-frontend/types';
import { taskActionEnum, SubmitPress, ReviewPress, ProgressNoteAction } from '../../types';
import styled, { EmotionStyle } from '../../../../core/styled';
import { Status } from '../TaskStatus/TaskStatus';
import TaskActionReopenContainer, {
  TaskNoteProps,
} from '../TaskActionReopenMenu/screens/TaskActionReopenContainer';
import TaskActionSnoozeContainer from '../TaskActionSnoozeMenu/TaskActionSnoozeContainer';
import ReviewNoShowModal from '../../Tasks/ReviewNoShowModal';

interface TaskActionButtonsProps {
  note: TasksResponse;
  onSubmitPress: (value: SubmitPress | ReviewPress) => void;
  onReviewPress: (value: ReviewPress) => void;
}

export const SubmitButton = styled(Button)<{ disabled: boolean }>(
  ({ theme: { colors, window }, disabled }) => {
    const { isMobile, isLarge } = window;

    const mobileStyle: EmotionStyle = isMobile ? { width: 95, marginLeft: 15 } : {};
    const tabletStyle: EmotionStyle = isLarge ? { width: 95 } : {};

    return {
      width: 135,
      minHeight: 30,
      height: 30,
      borderRadius: 5,
      margin: 0,
      background: disabled && colors.extraLightGrey,
      ...tabletStyle,
      ...mobileStyle,
    };
  }
);

const ActionTypeButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    marginLeft: 15,
    '& > p': {
      fontWeight: 500,
      color: colors.slateGrey,
    },
  };
});

const Wrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  const mobileStyle: EmotionStyle = isMobile
    ? { flexDirection: 'row-reverse', marginTop: 15, marginBottom: 5 }
    : {};
  return {
    flexDirection: 'row',
    position: 'relative',
    ...mobileStyle,
  };
});

const TaskActionButtons: VoidFunctionComponent<TaskActionButtonsProps> = ({
  note,
  onSubmitPress,
  onReviewPress,
}) => {
  const [hideMenu, setHideMenu] = useState<boolean>(true);
  const [reviewHideMenu, setReviewHideMenu] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { height } = useWindowWidthState();
  const { reopenTaskPopup, accessUnsubmittedSessions: { dynamicProgressNoteTasks } = {} } =
    useFlags();
  const history = useHistory();

  const {
    taskID,
    roomID,
    therapistID,
    progressNoteID,
    task: {
      isEAP,
      reopenedAt,
      canReopenUntil,
      lockedAt,
      progressNoteModality,
      videoCallID,
      sessionReportID,
    },
    id: unsubmittedSessionID,
  } = note;

  const actionButtonText = useMemo(() => {
    const canReopen =
      canReopenUntil &&
      new Date() < new Date(canReopenUntil) &&
      progressNoteModality !== ProgressNoteModalityType.liveSession;

    const shouldDisplayReopen = isEAP && reopenedAt === null && lockedAt === null && canReopen;
    const shouldDisplaySnooze =
      !dynamicProgressNoteTasks &&
      (!isEAP || (isEAP && (reopenedAt !== null || lockedAt !== null || !canReopen)));

    if (note?.task?.canReopen || shouldDisplayReopen) {
      return capitalize(taskActionEnum.REOPEN);
    }

    if (shouldDisplaySnooze) {
      return capitalize(taskActionEnum.SNOOZE);
    }
    return '';
  }, [
    canReopenUntil,
    progressNoteModality,
    isEAP,
    reopenedAt,
    lockedAt,
    dynamicProgressNoteTasks,
    note?.task?.canReopen,
  ]);

  const handleCloseMenu = () => {
    setHideMenu(true);
  };

  const handleActionPress = (type: ProgressNoteAction) => {
    if (!reopenTaskPopup) {
      if (type === taskActionEnum.SNOOZE) {
        setHideMenu(false);
      } else {
        history.push(`/room/${roomID}/reopen-session?sessionReportID=${sessionReportID}`);
      }
    } else {
      setHideMenu(false);
    }
  };

  const isReviewAction =
    note.type === Status.CLIENT_NO_SHOW || note.type === Status.CLIENT_LATE_CANCELLATION;

  const actionButtonDataQA = isReviewAction
    ? `reviewTask-roomID-${roomID}-liveCallID-${videoCallID}`
    : `submitTask-roomID-${roomID}-liveCallID-${videoCallID}`;

  return (
    <Wrapper>
      <SubmitButton
        disabled={isDisabled}
        dataQa={actionButtonDataQA}
        onPress={() => {
          const taskData = {
            roomID,
            taskID,
            therapistID,
            type: note.type,
          };
          if (['reviewClientNoShow', 'reviewClientLateCancellation'].includes(note.type)) {
            setReviewHideMenu(false);
            onReviewPress({
              ...taskData,
              bookingID: note.task.bookingID,
            });
          } else {
            onSubmitPress({
              ...taskData,
              progressNoteID,
              progressNoteModality,
              videoCallID,
              isEAP,
              sessionReportID,
              unsubmittedSessionID,
            });
          }
        }}
      >
        <Standard variant="standardWhite" style={{ fontWeight: 700 }}>
          {isReviewAction ? 'Review' : 'Submit'}
        </Standard>
      </SubmitButton>
      <ReviewNoShowModal
        handleDisable={(value: boolean) => setIsDisabled(value)}
        roomID={roomID}
        therapistID={therapistID}
        taskID={taskID}
        bookingID={note.task.bookingID}
        type={note.type}
        onBackdropPress={() => {
          setReviewHideMenu(true);
        }}
        hide={reviewHideMenu}
      />
      {actionButtonText &&
        note.type !== Status.CLIENT_NO_SHOW &&
        note.type !== Status.CLIENT_LATE_CANCELLATION && (
          <ActionTypeButton
            onPress={(event) => {
              // this will auto scroll to the menu to prevent cutoff due to behavior of floating menu
              if (height - 250 < event.clientY) {
                event.currentTarget.scrollIntoView();
              }

              handleActionPress(
                actionButtonText.toLowerCase() === taskActionEnum.SNOOZE
                  ? taskActionEnum.SNOOZE
                  : taskActionEnum.REOPEN
              );
            }}
          >
            <Standard>{actionButtonText}</Standard>
          </ActionTypeButton>
        )}
      {actionButtonText.toLowerCase() === taskActionEnum.SNOOZE ? (
        <TaskActionSnoozeContainer
          note={note}
          isHidden={hideMenu}
          handleCloseMenu={handleCloseMenu}
        />
      ) : (
        <>
          {reopenTaskPopup && actionButtonText.toLowerCase() === taskActionEnum.REOPEN && (
            <TaskActionReopenContainer
              note={note as TaskNoteProps}
              isHidden={hideMenu}
              handleCloseMenu={handleCloseMenu}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default TaskActionButtons;
