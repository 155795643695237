import * as actionTypes from '../constants/EmergencyContactConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';
import {
  transformReducerPayloadToAPIPayload,
  transformAPIPayloadToReducerPayload,
} from '../utils/optionsHelpers';

export function requestEmergencyContact(clientUserID) {
  return {
    type: actionTypes.REQUEST_EMERGENCY_CONTACT,
    clientUserID,
  };
}

export function requestEmergencyContactError(clientUserID, error) {
  return {
    type: actionTypes.REQUEST_EMERGENCY_CONTACT_ERROR,
    clientUserID,
    error,
  };
}

export function receiveEmergencyContact(payload, clientUserID) {
  return {
    type: actionTypes.RECEIVE_EMERGENCY_CONTACT,
    payload,
    clientUserID,
  };
}

export function requestSetEmergencyContact(clientUserID) {
  return {
    type: actionTypes.REQUEST_SET_EMERGENCY_CONTACT,
    clientUserID,
  };
}

export function requestSetEmergencyContactError(clientUserID, error) {
  return {
    type: actionTypes.REQUEST_SET_EMERGENCY_CONTACT_ERROR,
    clientUserID,
    error,
  };
}

export function receiveSetEmergencyContact(clientUserID, payload) {
  return {
    type: actionTypes.RECEIVE_SET_EMERGENCY_CONTACT,
    payload,
    clientUserID,
  };
}

export function requestEmergencyContactMessage(clientUserID) {
  return {
    type: actionTypes.REQUEST_EMERGENCY_CONTACT_MESSAGE,
    clientUserID,
  };
}

export function requestEmergencyContactMessageError(clientUserID, error) {
  return {
    type: actionTypes.REQUEST_EMERGENCY_CONTACT_MESSAGE_ERROR,
    clientUserID,
    error,
  };
}

export function receiveEmergencyContactMessage(clientUserID) {
  return {
    type: actionTypes.RECEIVE_EMERGENCY_CONTACT_MESSAGE,
    clientUserID,
  };
}

export function requestClientLogins(clientUserID) {
  return {
    type: actionTypes.REQUEST_CLIENT_LOGINS,
    clientUserID,
  };
}

export function requestClientLoginsError(clientUserID, error) {
  return {
    type: actionTypes.REQUEST_CLIENT_LOGINS_ERROR,
    clientUserID,
    error,
  };
}

export function receiveClientLogins(responseBody, { offset, limit, isRefetch }) {
  const canRequestMoreClientLogins = responseBody.data.length === limit;
  return {
    type: actionTypes.RECEIVE_CLIENT_LOGINS,
    ...responseBody,
    isRefetch,
    clientLoginsOffset: offset,
    canRequestMoreClientLogins,
  };
}

export const getEmergencyContact = (clientUserID) => (dispatch) => {
  dispatch(requestEmergencyContact(clientUserID));
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/clients/${clientUserID}/emergency-contact`)
    .then(({ data: { data } }) => {
      const payload = transformAPIPayloadToReducerPayload(data);
      dispatch(receiveEmergencyContact(payload, clientUserID));
    })
    .catch((error) => dispatch(requestEmergencyContactError(clientUserID, error)));
};

export const setEmergencyContact = (clientUserID, payload) => (dispatch) => {
  dispatch(requestSetEmergencyContact(clientUserID));
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/api/v1/clients/${clientUserID}/emergency-contact`, {
      data: transformReducerPayloadToAPIPayload(payload),
    })
    .then(() => dispatch(receiveSetEmergencyContact(clientUserID, payload)))
    .catch((error) => dispatch(requestSetEmergencyContactError(clientUserID, error)));
};

export const postEmergencyContactMessage = (clientUserID) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestEmergencyContactMessage(clientUserID));
  const requestBody = {
    clientUserID,
    messageType: 'emergencyContact',
  };
  return apiWrapper
    .post(
      `${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/messages/send-canned-message`,
      requestBody
    )
    .then(() => dispatch(receiveEmergencyContactMessage(clientUserID)))
    .catch((error) => dispatch(requestEmergencyContactMessageError(clientUserID, error)));
};

export const getClientLogins =
  (clientUserID, options = {}) =>
  (dispatch, getState) => {
    dispatch(requestClientLogins(clientUserID));
    const { limit = 20, isRefetch = false } = options;
    const {
      emergencyContact: { clientLoginsOffset },
    } = getState();
    const offset = isRefetch ? clientLoginsOffset : 0;
    return apiWrapper
      .get(
        `${
          apiHelper().apiEndpoint
        }/api/v1/clients/${clientUserID}/logins?offset=${offset}&limit=${limit}`
      )
      .then((response) => {
        const newOffset = clientLoginsOffset + limit;
        return dispatch(
          receiveClientLogins(response.data, { offset: newOffset, limit, isRefetch })
        );
      })
      .catch((error) => dispatch(requestClientLoginsError(clientUserID, error)));
  };
