import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getQuickMatchAnswers, deleteLiveSession } from '../../../../../actions/QuickMatchActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import MultiRowListItem from '../../../../Reusable/ListItems/MultiRowListItem';
import ItemList from '../../../../Reusable/ItemList/ItemList';

import './QuickMatchAnswers.css';

class QuickMatchAnswers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      answers: [],
    };
    this.processItems = this.processItems.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getQuickMatchAnswers();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.answers !== this.props.answers)
      this.setState({
        answers: nextProps.answers,
      });
  }

  processItems() {
    return this.props.answers.map((item, key) => {
      const displayData = {
        ...item,
        index: key + 1,
        dataOrder: ['question', 'answer'],
      };
      return (
        <MultiRowListItem
          key={key}
          item={item}
          displayData={displayData}
          cssClass="multirow-list-item"
        />
      );
    });
  }

  render() {
    const itemList = (
      <ItemList
        items={this.processItems(this.props.answers)}
        noDataMessage="No answers available."
        cssClass="quickmatch-answers"
      />
    );

    return <Submenu childComponents={[itemList]} title="QuickMatch Answers" prevRoute="case-tab" />;
  }
}

const mapStateToProps = (state) => {
  return {
    answers: state.quickMatch.answers,
    showSpinner: state.quickMatch.showSpinner,
    isUpdating: state.quickMatch.isUpdating,
    spinnerMessage: state.quickMatch.spinnerMessage,
    isError: state.quickMatch.isError,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getQuickMatchAnswers }, dispatch);

const QuickMatchAnswersContainer = connect(mapStateToProps, mapDispatchToProps)(QuickMatchAnswers);

export default QuickMatchAnswersContainer;
