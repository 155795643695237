import { TouchableView, View } from '@talkspace/react-toolkit';

import HourglassIcon from 'components/Icons/HourglassIcon';
import styled from '../../../../core/styled';
import ModalContainer from './ModalContainer';

const Button = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 15,
  };
});

const Title = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 14,
    fontSize: 21,
    lineHeight: '28px',
    fontWeight: 500,
    color: colors.permaOuterSpaceNew,
    textAlign: 'center',
    width: 288,
  };
});

const Subtitle = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 15,
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'center',
    color: colors.permaRiverBed,
    lineHeight: '18px',
  };
});

const CloseButton = styled(Button)(({ theme: { colors } }) => {
  return {
    marginTop: 50,
    fontSize: 17,
    backgroundColor: colors.white,
    color: colors.permaBlueStoneNew,
    letterSpacing: '0.5px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const ContentWrapper = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: 61,
  paddingRight: 60,
  paddingBottom: 50,
});

const AlreadyClaimedModal = ({
  onBackdropPress,
  hide,
}: {
  onBackdropPress: () => void;
  hide: boolean;
}) => (
  <ModalContainer onBackdropPress={onBackdropPress} hide={hide}>
    <ContentWrapper>
      <HourglassIcon />
      <Title>Oops, this client was matched with another therapist</Title>
      <Subtitle>
        Don't worry, our waitlist is continually updated. Refresh now and explore more potential
        clients.
      </Subtitle>
      <CloseButton onPress={onBackdropPress}>Refresh waitlist</CloseButton>
    </ContentWrapper>
  </ModalContainer>
);

export default AlreadyClaimedModal;
