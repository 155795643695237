import signIn from '@talkspace/auth/signIn';
import confirmSignIn from '@talkspace/auth/confirmSignIn';
import axios from 'axios';
import Base64 from 'ts-frontend/utils/Base64';
import appConfig from '../utils/configs';
import apiHelper from '../utils/api';
import * as actionTypes from '../constants/AuthConstants';
import SocketService from '../utils/socket/SocketService';
import { getBypassTwoFactorToken, storeBypassTwoFactorToken, receivedTokens } from '../utils/token';
import { tokenIsValid } from '../utils/tokenIsValid';
import storage from '../core/storage';
import { forgetDevice } from '../modules/auth/auth';
import { setTrackerUserID } from '../modules/utils/analytics/eventTracker';

// reducer
const initialState = {
  isFetching: false,
  isAuthenticated: !!storage.getItem('id_token'),
  user: {},
  error: {},
  otpToken: '',
  verifyError: false,
  cognitoUser: undefined,
  username: '',
  password: '',
  userPhone: '',
  isResendLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COGNITO_2FA_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        cognitoUser: action.cognitoUser,
        username: action.username,
        password: action.password,
        userPhone: action.userPhone,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        user: action.user,
      };
    case actionTypes.TWO_FACTOR_TOKEN_REQUEST:
      return {
        ...state,
        isFetching: true,
        redirectToLogin: false,
      };
    case actionTypes.RECEIVE_OTP_TOKEN:
      return {
        ...state,
        otpToken: action.otpToken,
        userPhone: action.userPhone,
        isFetching: false,
        verifyError: false,
        isResendLoading: false,
      };
    case actionTypes.VERIFY_ERROR:
      return {
        ...state,
        isFetching: false,
        verifyError: true,
      };
    case actionTypes.CLEAR_VERIFY_ERROR:
      return {
        ...state,
        isFetching: false,
        verifyError: false,
      };
    case actionTypes.REQUEST_SESSION_TOKENS:
      return {
        ...state,
        isFetching: true,
        verifyError: false,
      };
    case actionTypes.REDIRECT_TO_LOGIN:
      return {
        ...state,
        isFetching: false,
        verifyError: false,
        redirectToLogin: true,
      };
    case actionTypes.REQUEST_RESEND_OTP:
      return {
        ...state,
        isResendLoading: true,
      };
    case actionTypes.CLEAR_REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirectToLogin: false,
      };
    case actionTypes.REQUEST_INITIATE_2FA:
      return {
        ...state,
        userID: action.payload.userID,
        isFetching: true,
      };
    case actionTypes.RECEIVE_INITIATE_2FA:
      return {
        ...state,
        userPhone: action.payload.userPhone,
        otpToken: action.payload.otpToken,
        error: {},
        isFetching: false,
      };
    case actionTypes.ERROR_INITIATE_2FA:
      return {
        ...state,
        error: { type: 'fatalServerError', message: '' },
        isFetching: false,
      };
    case actionTypes.REQUEST_VERIFY_2FA:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.RECEIVE_VERIFY_2FA:
      return {
        ...state,
        changePasswordToken: action.payload.changePasswordToken,
        isVerified: action.payload.isVerified,
        error: {},
        isFetching: false,
      };
    case actionTypes.ERROR_VERIFY_2FA:
      return {
        ...state,
        error: { type: action.payload.errorType, message: 'Error in 2fa verify process.' },
        isFetching: false,
      };
    case actionTypes.REQUEST_CHANGE_PASSWORD:
      return {
        ...state,
        error: {},
        isFetching: true,
      };
    case actionTypes.RECEIVE_CHANGE_PASSWORD:
      return {
        ...state,
        passwordChangeSuccessfully: action.payload.passwordChangeSuccessfully,
        error: {},
        isFetching: false,
      };
    case actionTypes.ERROR_CHANGE_PASSWORD:
      return {
        ...state,
        error: { type: 'fatalServerError', message: 'Error in changing password process.' },
        fatalServerError: false,
        isFetching: false,
      };
    case actionTypes.REQUEST_FORGOT_PASSWORD:
      return {
        ...state,
        error: {},
        isFetching: true,
      };
    case actionTypes.RECEIVE_FORGOT_PASSWORD:
      return {
        ...state,
        isSuccessful: action.payload.isSuccessful,
        error: {},
        isFetching: false,
      };
    case actionTypes.ERROR_FORGOT_PASSWORD:
      return {
        ...state,
        error: { type: 'fatalServerError', message: 'Error in sending forgot password email.' },
        isFetching: false,
      };
    default:
      return state;
  }
};

if (tokenIsValid()) {
  SocketService.instance().addStateListener((state) => {
    if (state) socketRegisterUser();
  });
}

const socketRegisterUser = () => {
  const token = storage.getItem('id_token');
  if (token)
    SocketService.instance()
      .emit('registerUser', { token })
      .catch(() => undefined);
};

const requestSessionTokens = (otpKey, otpToken) => {
  return {
    type: actionTypes.REQUEST_SESSION_TOKENS,
    otpKey,
    otpToken,
  };
};

const requestTwoFactorLoginToken = () => {
  return {
    type: actionTypes.TWO_FACTOR_TOKEN_REQUEST,
  };
};

const receiveOTPToken = (otpToken, userPhone) => {
  return {
    type: actionTypes.RECEIVE_OTP_TOKEN,
    otpToken,
    userPhone,
  };
};

const loginSuccess = (id_token, user) => {
  SocketService.instance().addStateListener((state) => {
    if (state) socketRegisterUser();
  });
  return {
    type: actionTypes.LOGIN_SUCCESS,
    id_token,
    user,
  };
};

const cognito2faLoginSuccess = (cognitoUser, username, password, userPhone) => {
  return {
    type: actionTypes.COGNITO_2FA_LOGIN_SUCCESS,
    cognitoUser,
    username,
    password,
    userPhone,
  };
};

const verifyError = () => {
  return {
    type: actionTypes.VERIFY_ERROR,
  };
};

export const clearVerifyError = () => {
  return {
    type: actionTypes.CLEAR_VERIFY_ERROR,
  };
};

const redirectToLogin = () => {
  return {
    type: actionTypes.REDIRECT_TO_LOGIN,
  };
};

const clearRedirectToLogin = () => {
  return {
    type: actionTypes.CLEAR_REDIRECT_TO_LOGIN,
  };
};

const requestProfileInfo = (accessToken, userID) => {
  const xhr = axios.create();
  return xhr.request({
    baseURL: apiHelper().apiEndpoint,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    url: `/api/v1/therapist/${userID}/profile-info`,
    method: 'get',
  });
};

export const login = (username, password) => async (dispatch) => {
  dispatch(requestTwoFactorLoginToken());
  const bypassTwoFactorToken = getBypassTwoFactorToken();
  const bypassTwoFactorTokenRequestObject = bypassTwoFactorToken ? { bypassTwoFactorToken } : {};

  const xhr = axios.create();
  xhr.interceptors.response.use(
    (data) => data,
    (error) => {
      if (error.response && error.response.status === 429) {
        return error.response;
      }
      return Promise.reject(error);
    }
  );

  try {
    const base64 = Base64.btoa(encodeURIComponent(`${username}:${password}`));
    const authResponse = await xhr.request({
      baseURL: apiHelper().authAPIEndpoint,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${base64}`,
      },
      data: {
        userType: 'THERAPIST',
        ...bypassTwoFactorTokenRequestObject,
        platform: appConfig.platform,
        version: appConfig.appVersion,
      },
      url: '/v3/2fa/auth',
      method: 'post',
    });

    if (authResponse.status === 429) {
      const minutesToUnlock = Math.ceil(authResponse.data.data.secondsToUnlocked / 60);
      throw Error(
        `429 Your account is temporarily locked. Try again in ${minutesToUnlock} minutes`
      );
    }

    const didBypassTwoFactor = !!authResponse.data.data.access;
    if (didBypassTwoFactor) {
      const { accessToken, userID } = receivedTokens(authResponse.data.data);

      const profileInfo = await requestProfileInfo(accessToken, userID);

      const userData = {
        firstName: profileInfo.data.data.firstName,
        lastName: profileInfo.data.data.lastName,
        id: Number(userID),
      };

      storage.setItem('user', JSON.stringify(userData));
      setTrackerUserID(userID);
      dispatch(loginSuccess(accessToken, userData));
    } else {
      const { otpToken, userPhone } = authResponse.data.data;
      dispatch(receiveOTPToken(otpToken, userPhone));
    }

    return didBypassTwoFactor;
  } catch (err) {
    dispatch(verifyError());
    if (err.message.includes('505')) {
      window.location.reload(true);
      throw Error('version not supported, please reload page');
    }

    if (getBypassTwoFactorToken()) {
      await forgetDevice();
    }

    throw err;
  }
};

export const loginCognito = (username, password) => async (dispatch) => {
  const xhr = axios.create();
  xhr.interceptors.response.use(
    (data) => data,
    (error) => {
      if (error.response && error.response.status === 429) {
        return error.response;
      }
      return Promise.reject(error);
    }
  );

  try {
    dispatch(clearRedirectToLogin());

    const cognitoUser = await signIn({
      email: username,
      password,
    });

    if (cognitoUser.result === 'no-match') {
      throw new Error('invalid email address or password (error code 401)');
    }

    if (cognitoUser.result === 'network-error') {
      throw new Error('Network Error');
    }

    if (cognitoUser.is2FA && cognitoUser.userObject) {
      const lastTwoPhoneDigits = cognitoUser.phoneNumber.slice(-2);
      dispatch(
        cognito2faLoginSuccess(cognitoUser.userObject, username, password, lastTwoPhoneDigits)
      );
      return false;
    }

    const authResponse = (
      await xhr.request({
        baseURL: apiHelper().authAPIEndpoint,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Cognito ${cognitoUser.idToken}`,
        },
        data: {
          userType: 'THERAPIST',
          platform: appConfig.platform,
          version: appConfig.appVersion,
        },
        url: '/v1/cognito/login',
        method: 'post',
      })
    ).data.data;

    const { accessToken, userID } = receivedTokens(authResponse);

    const profileInfo = await requestProfileInfo(accessToken, userID);

    const userData = {
      firstName: profileInfo.data.data.firstName,
      lastName: profileInfo.data.data.lastName,
      id: Number(userID),
    };

    storage.setItem('user', JSON.stringify(userData));
    setTrackerUserID(userID);
    dispatch(loginSuccess(accessToken, userData));

    return true;
  } catch (err) {
    if (err.message.includes('505')) {
      window.location.reload(true);
      throw Error('version not supported, please reload page');
    }

    if (getBypassTwoFactorToken()) {
      await forgetDevice();
    }

    throw err;
  }
};

export const verifyCognitoLogin = (otpKey) => async (dispatch, getState) => {
  const {
    auth: { isFetching, cognitoUser },
  } = getState();
  if (isFetching) return;

  try {
    const loggedInCognitoUser = await confirmSignIn({
      cognitoUser,
      code: otpKey,
      mfaType: 'SMS_MFA',
    });

    if (loggedInCognitoUser.result === 'code-mismatch') {
      dispatch(verifyError());
      return;
    }

    if (loggedInCognitoUser.result !== 'success' || !loggedInCognitoUser.idToken) {
      dispatch(redirectToLogin());
      return;
    }

    const xhr = axios.create();
    const authResponse = (
      await xhr.request({
        baseURL: apiHelper().authAPIEndpoint,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Cognito ${loggedInCognitoUser.idToken}`,
        },
        data: {
          userType: 'THERAPIST',
          platform: appConfig.platform,
          version: appConfig.appVersion,
        },
        url: '/v1/cognito/login',
        method: 'post',
      })
    ).data.data;

    const { accessToken, userID } = receivedTokens(authResponse);
    const profileInfo = await requestProfileInfo(accessToken, userID);

    const userData = {
      firstName: profileInfo.data.data.firstName,
      lastName: profileInfo.data.data.lastName,
      id: Number(userID),
    };

    storage.setItem('user', JSON.stringify(userData));
    setTrackerUserID(userID);
    dispatch(loginSuccess(accessToken, userData));
    return;
  } catch {
    dispatch(redirectToLogin());
  }
};

export const verifyLogin = (otpKey, rememberBrowser) => (dispatch, getState) => {
  const {
    auth: { otpToken, userPhone, isFetching },
  } = getState();
  if (isFetching) return undefined;
  dispatch(requestSessionTokens(otpKey, otpToken));
  const xhr = axios.create();
  let userID;
  let accessToken;
  let bypassTwoFactorToken;
  return xhr
    .request({
      baseURL: apiHelper().authAPIEndpoint,
      headers: { 'Content-Type': 'application/json' },
      data: { otpToken, otpKey: parseInt(otpKey, 10), rememberDevice: rememberBrowser },
      url: '/v3/2fa/verify',
      method: 'post',
    })
    .then((response) => {
      if (response.status === 429) {
        const minutesToUnlock = Math.ceil(response.data.data.secondsToUnlocked / 60);
        throw Error(
          `429 Your account is temporarily locked. Try again in ${minutesToUnlock} minutes`
        );
      }

      ({ bypassTwoFactorToken } = response.data.data);
      ({ accessToken, userID } = receivedTokens(response.data.data));
      if (rememberBrowser) storeBypassTwoFactorToken(bypassTwoFactorToken);
      return requestProfileInfo(accessToken, userID);
    })
    .then((response) => {
      const userData = {
        firstName: response.data.data.firstName,
        lastName: response.data.data.lastName,
        id: Number(userID),
      };
      storage.setItem('user', JSON.stringify(userData));
      setTrackerUserID(userID);
      dispatch(loginSuccess(accessToken, userData));
      return true;
    })
    .catch(({ response: { status } }) => {
      if (status === 401) dispatch(verifyError());
      else dispatch(redirectToLogin());
    });
};

export const resendOTPKey = () => (dispatch, getState) => {
  const {
    auth: { otpToken },
  } = getState();
  const xhr = axios.create();
  let userID;
  let accessToken;
  xhr.interceptors.response.use(
    (data) => data,
    (error) => {
      if (error.response && error.response.status === 429) {
        return error.response;
      }
      return Promise.reject(error);
    }
  );
  dispatch({ type: actionTypes.REQUEST_RESEND_OTP });
  return xhr
    .request({
      baseURL: apiHelper().authAPIEndpoint,
      headers: { 'Content-Type': 'application/json' },
      data: { otpToken },
      url: '/v3/2fa/resend',
      method: 'post',
    })
    .then((response) => {
      if (response.status === 429) {
        const minutesToUnlock = Math.ceil(response.data.data.secondsToUnlocked / 60);
        throw Error(
          `429 Your account is temporarily locked. Try again in ${minutesToUnlock} minutes`
        );
      }

      const { otpToken: token, userPhone } = response.data.data;
      dispatch(receiveOTPToken(token, userPhone));
    })
    .catch((err) => {
      dispatch(redirectToLogin());
    });
};

export const resendCognitoCode = () => async (dispatch, getState) => {
  const {
    auth: { username, password },
  } = getState();

  try {
    dispatch(clearRedirectToLogin());

    const cognitoUser = await signIn({ email: username, password });

    if (cognitoUser.result === 'no-match') {
      throw new Error('invalid email address or password (error code 401)');
    }

    if (cognitoUser.result === 'network-error') {
      throw new Error('Network Error');
    }

    if (cognitoUser.is2FA && cognitoUser.userObject) {
      const lastTwoPhoneDigits = cognitoUser.phoneNumber.slice(-2);
      dispatch(
        cognito2faLoginSuccess(cognitoUser.userObject, username, password, lastTwoPhoneDigits)
      );
    } else {
      dispatch(redirectToLogin());
    }
  } catch {
    dispatch(redirectToLogin());
  }
};

export const revokeOTPToken = () => (dispatch, getState) => {
  const {
    auth: { otpToken },
  } = getState();
  const xhr = axios.create();
  return xhr.request({
    baseURL: apiHelper().authAPIEndpoint,
    headers: { 'Content-Type': 'application/json' },
    data: { otpToken },
    url: '/v3/2fa/revoke',
    method: 'post',
  });
};

export const initiate2fa = (token, userID) => (dispatch) => {
  dispatch({ type: actionTypes.REQUEST_INITIATE_2FA, payload: { userID } });
  const xhr = axios.create();
  return xhr
    .request({
      baseURL: apiHelper().authAPIEndpoint,
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      url: `/v3/2fa/therapist/${userID}/reset-password`,
      method: 'post',
    })
    .then((response) => {
      if (response.status === 429) {
        const minutesToUnlock = Math.ceil(response.data.data.secondsToUnlocked / 60);
        throw Error(
          `429 Your account is temporarily locked. Try again in ${minutesToUnlock} minutes`
        );
      }
      const { otpToken, userPhone } = response.data.data;
      dispatch({ type: actionTypes.RECEIVE_INITIATE_2FA, payload: { otpToken, userPhone } });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.ERROR_INITIATE_2FA });
    });
};

export const verify2FA = (otpToken, otpKey) => (dispatch, getState) => {
  const {
    auth: { otpToken: token },
  } = getState();
  dispatch({ type: actionTypes.REQUEST_VERIFY_2FA });
  const xhr = axios.create();
  return xhr
    .request({
      baseURL: apiHelper().authAPIEndpoint,
      headers: { 'Content-Type': 'application/json' },
      data: {
        otpToken: token,
        otpKey,
      },
      url: '/v3/2fa/reset-password/verify',
      method: 'post',
    })
    .then((response) => {
      if (response.status === 429) {
        const minutesToUnlock = Math.ceil(response.data.data.secondsToUnlocked / 60);
        throw Error(
          `429 Your account is temporarily locked. Try again in ${minutesToUnlock} minutes`
        );
      }
      const { changePasswordToken } = response.data.data;
      const isVerified = true;
      dispatch({
        type: actionTypes.RECEIVE_VERIFY_2FA,
        payload: { changePasswordToken, isVerified },
      });
    })
    .catch(({ response: { status } }) => {
      let errorType = 'verify2FAError';
      if (status !== 401) errorType = 'redirectToLogin';
      dispatch({ type: actionTypes.ERROR_VERIFY_2FA, payload: { errorType } });
    });
};

export const changePassword = (password, confirmedPassword) => (dispatch, getState) => {
  dispatch({ type: actionTypes.REQUEST_CHANGE_PASSWORD });
  const {
    auth: { changePasswordToken, userID },
  } = getState();
  const xhr = axios.create();
  return xhr
    .request({
      baseURL: apiHelper().apiEndpoint,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${changePasswordToken}`,
      },
      data: {
        password,
        confirmedPassword,
      },
      url: `/v2/users/${userID}/basic-details`,
      method: 'patch',
    })
    .then((response) => {
      if (response.status === 422) {
        throw Error(`422 Validation error: ${response.message}`);
      }
      if (response.status === 401) {
        throw Error(`401 Authorization error: ${response.message}`);
      }
      if (response.status !== 200) {
        throw Error(`500 server error: ${response}`);
      }
      const passwordChangeSuccessfully = true;
      dispatch({
        type: actionTypes.RECEIVE_CHANGE_PASSWORD,
        payload: { passwordChangeSuccessfully },
      });
    })
    .catch(() => {
      dispatch({ type: actionTypes.ERROR_CHANGE_PASSWORD });
    });
};

export const forgotPassword = (email) => (dispatch, getState) => {
  dispatch({ type: actionTypes.REQUEST_FORGOT_PASSWORD });
  const xhr = axios.create();
  return xhr
    .request({
      baseURL: apiHelper().authAPIEndpoint,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email,
      },
      url: '/v2/auth/forgot-password',
      method: 'post',
    })
    .then((response) => {
      if (response.status !== 204) {
        throw Error(`500 server error: ${response}`);
      }
      const isSuccessful = true;
      dispatch({
        type: actionTypes.RECEIVE_FORGOT_PASSWORD,
        payload: { isSuccessful },
      });
    })
    .catch(() => {
      dispatch({ type: actionTypes.ERROR_FORGOT_PASSWORD });
    });
};

export default reducer;
