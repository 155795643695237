import { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View } from '@talkspace/react-toolkit';
import { login } from '../../../../reducers/auth';
import { deleteConditions, postConditions } from '../../../../actions/ClinicalInformationActions';
import { getUserData } from '../../../../utils/token';
import {
  getJourneyItems,
  patchJourneyItem,
  updateJourneyItemFeedback,
  createJourneyItemFeedback,
  prepareSessionHighlights,
} from '../../../../actions/JourneyActions';

import TipsAndTools from './TipsAndTools/TipsAndTools';
import './JourneyTab.css';
import AgendaEmptyState from './AgendaEmptyState';

const { reminderTypeAndTargetMapping } = require('../../../../utils/RemindersConfig');

const actionButtonStyle = {
  marginTop: '0',
  padding: '0px 18px 5px 0px',
  fontSize: '14px',
  letterSpacing: '0.2px',
  lineHeight: '19px',
  color: '#09B198',
  textAlign: 'right',
  display: 'inline-block',
  cursor: 'pointer',
  fontWeight: '700',
};

class JourneyTabComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
    };
  }

  getReminderActionsByReminderTypeID(reminderTypeID) {
    const reminderActionsMapping = {
      missing_conditions_day_7: [
        {
          style: { ...actionButtonStyle },
          text: 'Choose diagnosis',
          handler: this.conditionNavigationHandler,
        },
      ],
      missing_conditions_day_10: [
        {
          style: { ...actionButtonStyle },
          text: 'Choose diagnosis',
          handler: this.conditionNavigationHandler,
        },
      ],
      missing_conditions_day_14_blocking: [
        {
          style: { ...actionButtonStyle, color: '#B8B8B8' },
          text: 'I agree',
          handler: this.conditionUpdateHandler,
        },
        {
          style: { ...actionButtonStyle },
          text: 'Choose another',
          handler: this.conditionNavigationHandler,
        },
      ],
      missing_first_message_hour_8: [],
      missing_first_media_message_hour_24: [],
      missing_response_to_client_message_hour_8: [],
      inadequate_word_count_day_2: [],
      inadequate_word_count_day_5: [],
      summarization_session: [
        {
          style: { ...actionButtonStyle },
          text: 'Show more',
          handler: this.sessionSummaryNavigationHandler,
        },
      ],
      intro_missing: [],
      expectation_setting_missing: [],
      diagnostic_profile: [
        {
          style: { ...actionButtonStyle },
          text: 'Show profile',
          handler: this.diagnosticProfileNavigationHandler,
        },
      ],
      working_diagnosis: [
        {
          style: { ...actionButtonStyle, color: '#B8B8B8', paddingRight: 12 },
          text: 'I agree',
          handler: this.handleUpdateJourneyItems,
        },
        {
          style: { ...actionButtonStyle },
          text: 'Premature to diagnose',
          handler: this.removeWorkingDiagnosisHandler,
        },
      ],
      outcome_measure_completed: [
        {
          style: { ...actionButtonStyle, color: '#005C65' },
          text: 'Review assessment',
          handler: this.outcomeMessureNavigationHandler,
        },
      ],
      si_risk_flag: [
        {
          style: { ...actionButtonStyle, color: '#005C65' },
          text: 'Review clinical tab',
          handler: this.clinicalInformationNavigationHandler,
        },
      ],
    };
    return reminderActionsMapping[reminderTypeAndTargetMapping[reminderTypeID]] || [];
  }

  getSuggestedConditionForPresentingProblem = (presentingProblemID) => {
    const { presentingProblemsConditionsMapping } = this.props;
    const condition = presentingProblemsConditionsMapping[presentingProblemID];
    return {
      conditionID: condition.conditionID,
      conditionText: condition.conditionText,
    };
  };

  getSuggestionsByReminderTypeID = (reminderTypeID) => {
    const reminderSuggestionsMapping = {
      missing_conditions_day_7: null,
      missing_conditions_day_10: null,
      missing_conditions_day_14_blocking: () => {
        const {
          props: { presentingProblems },
        } = this;
        const presentingProblemID = presentingProblems[0] ? presentingProblems[0].value : 17;
        const condition = this.getSuggestedConditionForPresentingProblem(presentingProblemID);
        return `Based on the information currently available a condition of "${condition.conditionText}" is suggested`;
      },
      missing_first_message_hour_8: null,
      missing_first_media_message_hour_24: null,
      missing_response_to_client_message_hour_8: null,
      inadequate_word_count_day_2: null,
      inadequate_word_count_day_5: null,
    };

    return reminderSuggestionsMapping[reminderTypeAndTargetMapping[reminderTypeID]]
      ? reminderSuggestionsMapping[reminderTypeAndTargetMapping[reminderTypeID]]
      : () => undefined;
  };

  UNSAFE_componentWillMount() {
    this.setState({ user: getUserData() });
  }

  conditionNavigationHandler = () => {
    const path = this.props.location.pathname.split('/');
    this.props.history.push(`/${path[1]}/${path[2]}/clinical-tab`);
  };

  conditionUpdateHandler = () => {
    const {
      props: { presentingProblems, customerInformation, postConditions },
    } = this;
    const clientID = customerInformation.clientUserID;
    const presentingProblemID = presentingProblems[0] ? presentingProblems[0].value : 17;
    const { conditionText, conditionID } =
      this.getSuggestedConditionForPresentingProblem(presentingProblemID);
    const condition = { value: conditionID, label: conditionText };
    postConditions(clientID, condition, [condition]);
  };

  removeWorkingDiagnosisHandler = () => {
    const {
      props: {
        customerInformation,
        clinicalInformation: { conditions },
        deleteConditions,
      },
    } = this;
    const workingDiagnosisCondition = conditions.find((condition) => condition.isWorkingDiagnosis);
    if (workingDiagnosisCondition) {
      // if there are no working diagnosis conditions, this card will not display
      const clientID = customerInformation.clientUserID;
      const otherConditions = conditions.filter((condition) => !condition.isWorkingDiagnosis);
      deleteConditions(clientID, { id: workingDiagnosisCondition.id }, otherConditions);
    }
  };

  diagnosticProfileNavigationHandler = (e, item, journeyItemThis) => {
    const { customerList, history, location } = this.props;
    // This card gets dismissed on usage
    this.handleUpdateJourneyItems(e, item, journeyItemThis);
    const path = location.pathname.split('/');
    const isCouple = customerList.list.length > 1;
    setTimeout(() => {
      if (isCouple) {
        history.push(`/${path[1]}/${path[2]}/diagnostic-profiles`, { title: 'Diagnostic profile' });
      } else {
        history.push(`/${path[1]}/${path[2]}/diagnostic-profile`, { title: 'Diagnostic profile' });
      }
      this.props.getJourneyItems();
    }, 500);
  };

  outcomeMessureNavigationHandler = (e, item, journeyItemThis) => {
    // This card gets dismissed on usage
    this.handleUpdateJourneyItems(e, item, journeyItemThis);
    this.handleNavigationToClinicalTab('outcome-measures');
  };

  clinicalInformationNavigationHandler = (e, item, journeyItemThis) => {
    // This card gets dismissed on usage
    this.handleUpdateJourneyItems(e, item, journeyItemThis);
    this.handleNavigationToClinicalTab('clinical-information');
  };

  handleNavigationToClinicalTab = (query) => {
    const { history, location } = this.props;
    const path = location.pathname.split('/');
    const queryParams = `?container=${query}`;
    setTimeout(() => {
      history.push(`/${path[1]}/${path[2]}/clinical-tab${queryParams}`);
      this.props.getJourneyItems();
    }, 500);
  };

  handleUpdateJourneyItems = (e, item, journeyItemThis) => {
    const { roomJourneyInteraction } = item;
    this.props.patchJourneyItem({ roomJourneyInteraction });
    journeyItemThis.setState({ height: e.target.parentElement.parentElement.clientHeight }, () => {
      setTimeout(
        () =>
          journeyItemThis.setState({ dismissing: true }, () =>
            setTimeout(() => {
              journeyItemThis.setState({ dismissed: true });
              this.props.getJourneyItems();
            }, 500)
          ),
        0
      );
    });
  };

  journeyItemFeedbackHandler = (feedback, itemID) => (value) =>
    feedback
      ? this.props.updateJourneyItemFeedback(itemID, { feedback: value })
      : this.props.createJourneyItemFeedback(itemID, { feedback: value });

  processJourneyItems = (journeyItems) => {
    const processedItems = journeyItems?.map((item) => {
      const isReminder = !!item.reminderTypeID;
      let processedItem;

      const defaultValues = {
        ...item,
        feedbackActions: {
          like: {
            handler: this.journeyItemFeedbackHandler(item.feedback, item.id),
          },
          dislike: {
            handler: this.journeyItemFeedbackHandler(item.feedback, item.id),
          },
        },
      };

      if (isReminder) {
        processedItem = {
          ...defaultValues,
          actions: this.getReminderActionsByReminderTypeID(item.reminderTypeID),
          suggestion: this.getSuggestionsByReminderTypeID(item.reminderTypeID),
        };
        if (!['alert', 'to-do'].includes(item.category)) {
          processedItem.actions.unshift({
            style: { ...actionButtonStyle, color: '#B8B8B8' },
            text: 'Dismiss',
            handler: this.handleUpdateJourneyItems,
          });
        }
      } else {
        processedItem = {
          ...defaultValues,
          actions: [
            {
              style: { ...actionButtonStyle, color: '#B8B8B8' },
              text: 'Dismiss',
              handler: this.handleUpdateJourneyItems,
            },
          ],
          suggestion: null,
        };
      }
      return processedItem;
    });
    return processedItems;
  };

  sessionSummaryNavigationHandler = () => {
    this.props.prepareSessionHighlights();
    const path = this.props.location.pathname.split('/');
    this.props.history.push(`/${path[1]}/${path[2]}/session-summary`);
  };

  render() {
    const processedJourneyItems = this.processJourneyItems(this.props.journeyItems);
    return this.props.journeyItems !== undefined ? (
      <View
        flex={1}
        align="start"
        style={{ overflowX: 'hidden', maxWidth: 374, alignItems: 'center' }}
      >
        {processedJourneyItems?.length > 0 ? (
          <View className="room-journey-tab">
            <TipsAndTools roomID={this.props.roomID} journeyItems={processedJourneyItems} />
          </View>
        ) : (
          <AgendaEmptyState />
        )}

        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} hideProgressBar />
      </View>
    ) : null;
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      createJourneyItemFeedback,
      updateJourneyItemFeedback,
      getJourneyItems,
      postConditions,
      deleteConditions,
      patchJourneyItem,
      prepareSessionHighlights,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
    journeyItems: state.journey.journeyItems,
    journeyIsUpdating: state.journey.isUpdating,
    therapistType: state.availability.therapistType,
    presentingProblemsConditionsMapping: state.journey.presentingProblemsConditionsMapping,
    presentingProblems: state.clinicalInformation.presentingProblems,
    customerInformation: state.customerInformation,
    room: state.room,
    customerList: state.customerList,
    clinicalInformation: state.clinicalInformation,
  };
};

const JourneyTab = connect(mapStateToProps, mapDispatchToProps)(JourneyTabComponent);

export default JourneyTab;
