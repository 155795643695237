import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import {
  AccordionContainer,
  Select,
  TextInput,
  WindowWidthStateContext,
  Tiny,
  Standard,
  styled,
  View,
} from '@talkspace/react-toolkit';
import _ from 'lodash';
import { checkIfShouldBeSearchable, yesNoOptions } from 'utils/optionsHelpers';
import { withBusinessLine } from 'ts-frontend/hoc';
import { suicideIdeationOrPlanning, homicidalIdeationOrPlanning } from '@talkspace/configs';
import {
  getClinicalInformation,
  setClinicalInformation,
  getPresentingProblems,
  postPresentingProblems,
  deletePresentingProblems,
  getConditions,
  postConditions,
  deleteConditions,
  getPhysicalConditions,
  postPhysicalConditions,
  deletePhysicalConditions,
  getCurrentSurvey,
} from '../../../../../actions/ClinicalInformationActions';
import { getCustomerInformation } from '../../../../../actions/CustomerInformationActions';
import UserBadge from '../../../../Reusable/UserBadge/UserBadge';
import './ClinicalInformation.css';
import SocketService from '../../../../../utils/socket/SocketService';
import configs, { getOptionsByField } from '../../../../../utils/tsConfigsValues';
import { journeyItemsHaveToDo } from '../../../../../utils/journeyHelper';
import RiskContainer from './RiskContainer';
import RiskAssessmentLink from '../../../../Reusable/RiskAssessmentLink';

const { conditionsNotBillableIDs, conditionsBillable } = configs;

function diffArrayOfObjects(newArr, oldArr) {
  const diff = newArr.length - oldArr.length;
  const valueArr = _.differenceWith(
    diff > 0 ? newArr : oldArr,
    diff > 0 ? oldArr : newArr,
    _.isEqual
  );
  return { diff, value: valueArr.length ? valueArr[0] : null };
}

const StyledText = styled(Standard)(({ theme: { colors } }) => {
  return {
    color: colors.permaRiverBed,
    marginTop: 9,
    marginBottom: 15,
    fontSize: 14,
  };
});

class ClinicalInformation extends Component {
  constructor(props) {
    super(props);
    this.viewRef = React.createRef();
    const params = new URLSearchParams(window.location.search);
    this.scrollToElem = params.get('container') === 'clinical-information';
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.scrollToElem && this.viewRef.current) {
        this.viewRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 600);
  }

  componentDidUpdate() {
    this.focusComponentInLocationState();
  }

  UNSAFE_componentWillMount() {
    if (this.props.clientUserID && this.props.roomID) {
      this.reloadCustomerInformation(this.props.clientUserID);
      this.props.getPresentingProblems(this.props.clientUserID);
      this.props.getCurrentSurvey(this.props.roomID);
      SocketService.instance().on('submittedIntakeSurvey', this.handleSubmittedIntakeSurveyEvent);
    }
  }

  componentWillUnmount() {
    SocketService.instance().off('submittedIntakeSurvey', this.handleSubmittedIntakeSurveyEvent);
  }

  changeSelectedClient = (clientUserID) => {
    if (clientUserID !== this.props.clientUserID) {
      this.props.getCustomerInformation(clientUserID);
      this.reloadCustomerInformation(clientUserID);
      this.props.getPresentingProblems(clientUserID);
      this.props.getCurrentSurvey(this.props.roomID);
    }
  };

  createHandleSelectChange =
    (field, isMulti = false) =>
    (value) => {
      const { clientUserID, setClinicalInformation } = this.props;
      setClinicalInformation(clientUserID, { [field]: isMulti ? value || [] : value });
    };

  createHandleTextInputOnBlur = (field) => (e) => {
    const { clientUserID, setClinicalInformation } = this.props;
    setClinicalInformation(clientUserID, { [field]: e.target.value });
  };

  createUserButtons = (customerList) => (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {customerList.map((customer, index) => (
        <UserBadge
          key={`user-badge-${index}`}
          onClick={() => this.changeSelectedClient(customer.userID)}
          preferredName={customer.displayName}
          active={customer.userID === this.props.clientUserID}
        />
      ))}
    </div>
  );

  focusComponentInLocationState = () => {
    const { location, history } = this.props;
    if (location.state) {
      const { focusElement, selectedClientID, ...otherState } = location.state;
      if (selectedClientID) {
        this.changeSelectedClient(selectedClientID);
        history.replace({ pathname: location.pathname, state: { focusElement, ...otherState } });
      }
      setTimeout(() => {
        const ref = this[`${focusElement}Ref`];
        if (ref && ref.focus) {
          ref.focus();
          history.replace({ pathname: location.pathname, state: { ...otherState } });
        }
      }, (selectedClientID ? 1000 : 0) + 1000);
    }
  };

  handleConditionChange = (options) => {
    const {
      conditions: oldConditions,
      postConditions,
      deleteConditions,
      clientUserID,
    } = this.props;
    const newConditions = options || [];
    const hasBillable = newConditions?.some(({ value }) => conditionsBillable[value]);
    const { diff, value } = diffArrayOfObjects(newConditions, oldConditions);
    if (!hasBillable && newConditions.length) {
      deleteConditions(clientUserID, value, []);
      newConditions.forEach((condition) => deleteConditions(clientUserID, condition, []));
    } else if (diff > 0) {
      postConditions(clientUserID, value, newConditions);
    } else if (diff < 0) {
      deleteConditions(clientUserID, value, newConditions);
    }
  };

  handlePhysicalConditionChange = (options) => {
    const {
      physicalConditions: oldPhysicalConditions,
      postPhysicalConditions,
      deletePhysicalConditions,
      clientUserID,
    } = this.props;
    const newPhysicalConditions = options || [];
    const { diff, value } = diffArrayOfObjects(newPhysicalConditions, oldPhysicalConditions);
    if (diff > 0) {
      postPhysicalConditions(clientUserID, value, newPhysicalConditions);
    } else if (diff < 0) {
      deletePhysicalConditions(clientUserID, value, newPhysicalConditions);
    }
  };

  handlePresentingProblemChange = (options) => {
    const {
      presentingProblems: oldPresentingProblems,
      postPresentingProblems,
      deletePresentingProblems,
      clientUserID,
    } = this.props;
    const newPresentingProblems = options || [];
    const { diff, value } = diffArrayOfObjects(newPresentingProblems, oldPresentingProblems);
    if (value) {
      if (diff > 0) {
        postPresentingProblems(clientUserID, value, newPresentingProblems);
      } else if (diff < 0) {
        deletePresentingProblems(clientUserID, value, newPresentingProblems);
      }
    }
  };

  handleSubmittedIntakeSurveyEvent = (data) => {
    const { clientUserID, getPresentingProblems, roomID } = this.props;

    if (Number(data.roomID) !== Number(roomID)) {
      return;
    }

    this.reloadCustomerInformation(clientUserID);
    getPresentingProblems(clientUserID);
  };

  reloadCustomerInformation = (userID) => {
    this.props.getClinicalInformation(userID);
    this.props.getConditions(userID);
    this.props.getPhysicalConditions(userID);
  };

  render() {
    const {
      customerList,
      presentingProblems,
      treatmentGoal,
      conditions,
      physicalConditions,
      stageOfChange,
      acuity,
      sleepQuality,
      sleepAidUse,
      socialSupportSystem,
      experiencingSchoolCommunity,
      experiencingSocialMedia,
      hospitalizationHistory,
      traumaticExperience,
      traumaticFlashbacks,
      guilt,
      isolation,
      suicidePlanning,
      suicideIdeation,
      homicidalIdeation,
      homicidalPlanning,
      angerAggression,
      previousRelativesMentalHealthIssues,
      previousMentalHealthIssues,
      previousMentalHealthTreatment,
      journeyItems,
      collectedAt,
      nycTeensIntake,
    } = this.props;

    const hasMissingConditionsAlert = journeyItemsHaveToDo(journeyItems);
    const isConditionsMissing = hasMissingConditionsAlert && conditions.length === 0;
    const showUserButtons = !!customerList && !!customerList.length && customerList.length > 1;
    const showIndicatorBeforeClick = this.context.isMobile;
    const hasSelectedBillable = conditions.some(({ value }) => conditionsBillable[value]);
    const disabledValues = hasSelectedBillable ? null : conditionsNotBillableIDs;

    return (
      <AccordionContainer
        title="Clinical Information"
        open={this.scrollToElem}
        dataQa="clinicalInformationAccordion"
      >
        {showUserButtons && this.createUserButtons(customerList)}
        <Select
          placeholder="Presenting problems"
          options={getOptionsByField('presentingProblems')}
          value={presentingProblems}
          refCallback={(ref) => (this.presentingProblemsRef = ref)}
          onChange={this.handlePresentingProblemChange}
          isMulti
        />
        <TextInput
          value={treatmentGoal}
          placeholder="Treatment goals"
          multiline
          disabled
          disabledNumberOfLines={6}
        />
        <Select
          key={JSON.stringify({ disabledValues })}
          placeholder="Diagnosis"
          options={getOptionsByField('conditions')}
          value={conditions}
          refCallback={(ref) => (this.diagnosisRef = ref)}
          onChange={this.handleConditionChange}
          isValid={!isConditionsMissing}
          isOptionDisabled={(option) => {
            if (!disabledValues?.length) return false;
            return option.value ? disabledValues.includes(`${option.value}`) : false;
          }}
          isSearchable={checkIfShouldBeSearchable('conditions')}
          isMulti
        />
        {!hasSelectedBillable &&
          this.props?.businessLineData &&
          !this.props.businessLineData.isEAP && (
            <Tiny>Non-billable diagnoses are disabled until a billable diagnosis is selected.</Tiny>
          )}
        {this.props?.businessLineData?.isEAP && (
          <Tiny>Diagnoses are optional for clients on EAP plans.</Tiny>
        )}
        <Select
          placeholder="Acuity"
          options={getOptionsByField('acuity')}
          value={acuity}
          refCallback={(ref) => (this.acuityRef = ref)}
          onChange={this.createHandleSelectChange('acuity')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={checkIfShouldBeSearchable('acuity')}
        />
        <Select
          placeholder="Stage of change"
          options={getOptionsByField('stageOfChange')}
          value={stageOfChange}
          refCallback={(ref) => (this.stageOfChangeRef = ref)}
          onChange={this.createHandleSelectChange('stageOfChange')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={checkIfShouldBeSearchable('stageOfChange')}
        />
        <Select
          placeholder="Physical conditions"
          options={getOptionsByField('physicalConditions')}
          value={physicalConditions}
          refCallback={(ref) => (this.physicalConditionsRef = ref)}
          onChange={this.handlePhysicalConditionChange}
          isSearchable={checkIfShouldBeSearchable('physicalConditions')}
          isMulti
        />
        <Select
          placeholder="Quality of sleep"
          options={getOptionsByField('sleepQuality')}
          value={sleepQuality}
          refCallback={(ref) => (this.sleepQualityRef = ref)}
          onChange={this.createHandleSelectChange('sleepQuality')}
          isSearchable={checkIfShouldBeSearchable('sleepQuality')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
        />
        <Select
          placeholder="Use of sleeping aids"
          options={getOptionsByField('sleepAidUse')}
          value={sleepAidUse}
          refCallback={(ref) => (this.sleepAidUseRef = ref)}
          onChange={this.createHandleSelectChange('sleepAidUse')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={checkIfShouldBeSearchable('sleepAidUse')}
        />
        {nycTeensIntake && (
          <>
            <Select
              placeholder="Social support system"
              options={getOptionsByField('socialSupportSystem')}
              value={socialSupportSystem}
              refCallback={(ref) => (this.socialSupportSystemRef = ref)}
              onChange={this.createHandleSelectChange('socialSupportSystem')}
              showIndicatorBeforeClick={showIndicatorBeforeClick}
              isSearchable={checkIfShouldBeSearchable('socialSupportSystem')}
            />
            <Select
              placeholder="Experience at school or community"
              options={getOptionsByField('experiencingSchoolCommunity')}
              value={experiencingSchoolCommunity}
              refCallback={(ref) => (this.experiencingSchoolCommunityRef = ref)}
              onChange={this.createHandleSelectChange('experiencingSchoolCommunity', true)}
              isSearchable={checkIfShouldBeSearchable('experiencingSchoolCommunity')}
              isMulti
            />
          </>
        )}
        <Select
          placeholder="Experience with social media"
          options={getOptionsByField('experiencingSocialMedia')}
          value={experiencingSocialMedia}
          refCallback={(ref) => (this.experiencingSocialMediaRef = ref)}
          onChange={this.createHandleSelectChange('experiencingSocialMedia', true)}
          isSearchable={checkIfShouldBeSearchable('experiencingSocialMedia')}
          isMulti
        />
        <Select
          placeholder="Previous mental health experience"
          options={getOptionsByField('previousMentalHealthTreatment')}
          value={previousMentalHealthTreatment}
          refCallback={(ref) => (this.previousMentalHealthTreatmentRef = ref)}
          onChange={this.createHandleSelectChange('previousMentalHealthTreatment')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={checkIfShouldBeSearchable('previousMentalHealthTreatment')}
        />
        <Select
          placeholder="Previously diagnosed mental health conditions"
          options={getOptionsByField('previousMentalHealthIssues')}
          value={previousMentalHealthIssues}
          refCallback={(ref) => (this.previousMentalHealthIssuesRef = ref)}
          onChange={this.createHandleSelectChange('previousMentalHealthIssues', true)}
          isSearchable={checkIfShouldBeSearchable('previousMentalHealthIssues')}
          placeholderIsTwoLines
          isMulti
        />
        <Select
          placeholder="Previously hospitalized (last 3 years)"
          options={getOptionsByField('hospitalizationHistory')}
          value={hospitalizationHistory}
          refCallback={(ref) => (this.hospitalizationHistoryRef = ref)}
          onChange={this.createHandleSelectChange('hospitalizationHistory')}
          isSearchable={checkIfShouldBeSearchable('hospitalizationHistory')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
        />
        <Select
          placeholder="Immediate relatives diagnosed with mental health conditions"
          options={getOptionsByField('previousRelativesMentalHealthIssues')}
          value={previousRelativesMentalHealthIssues}
          refCallback={(ref) => (this.previousRelativesMentalHealthIssuesRef = ref)}
          onChange={this.createHandleSelectChange('previousRelativesMentalHealthIssues', true)}
          isSearchable={checkIfShouldBeSearchable('previousRelativesMentalHealthIssues')}
          placeholderIsTwoLines
          isMulti
        />
        <Select
          placeholder="Experience with traumatic event"
          options={yesNoOptions}
          value={traumaticExperience}
          refCallback={(ref) => (this.traumaticExperienceRef = ref)}
          onChange={this.createHandleSelectChange('traumaticExperience')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={false}
        />
        <Select
          placeholder="Struggle with situation, reminder, dreams or thoughts"
          options={yesNoOptions}
          value={traumaticFlashbacks}
          refCallback={(ref) => (this.traumaticFlashbacksRef = ref)}
          onChange={this.createHandleSelectChange('traumaticFlashbacks')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={false}
          placeholderIsTwoLines
        />
        <Select
          placeholder="Guilt or self-blame"
          options={yesNoOptions}
          value={guilt}
          refCallback={(ref) => (this.guiltRef = ref)}
          onChange={this.createHandleSelectChange('guilt')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={false}
        />
        <Select
          placeholder="Distance or indifference from people or activities"
          options={yesNoOptions}
          value={isolation}
          refCallback={(ref) => (this.isolationRef = ref)}
          onChange={this.createHandleSelectChange('isolation')}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          isSearchable={false}
          placeholderIsTwoLines
        />

        <RiskContainer
          label="Suicidal ideation"
          value={suicideIdeation?.label}
          collectedAt={collectedAt}
          displayReminderIcon={
            suicideIdeation?.label === suicideIdeationOrPlanning.yes_in_the_past_30_days
          }
        />
        <RiskContainer
          label="Suicidal planning"
          value={suicidePlanning?.label}
          collectedAt={collectedAt}
          displayReminderIcon={
            suicidePlanning?.label === suicideIdeationOrPlanning.yes_in_the_past_30_days
          }
        />
        {nycTeensIntake && (
          <>
            <RiskContainer
              label="Homicidal ideation"
              value={homicidalIdeation?.label}
              collectedAt={collectedAt}
              displayReminderIcon={
                homicidalIdeation?.label === homicidalIdeationOrPlanning.yes_in_the_past_30_days
              }
            />
            <RiskContainer
              label="Homicidal planning"
              value={homicidalPlanning?.label}
              collectedAt={collectedAt}
              displayReminderIcon={
                homicidalPlanning?.label === homicidalIdeationOrPlanning.yes_in_the_past_30_days
              }
            />
            <RiskContainer
              label="Excessive anger or violence"
              value={angerAggression?.value ? 'Yes' : 'No'}
              collectedAt={collectedAt}
              displayReminderIcon={false}
            />
          </>
        )}
        <View ref={this.viewRef} />
        {nycTeensIntake ? (
          <StyledText>
            If the responses require a risk assessment, you can use our
            <RiskAssessmentLink label="risk assessment resources." nycTeensIntakeLink />
          </StyledText>
        ) : (
          <StyledText>
            If the responses require a risk assessment, you can use the
            <RiskAssessmentLink label="C-SSRS assessment." nycTeensIntakeLink={false} />
          </StyledText>
        )}
      </AccordionContainer>
    );
  }
}

ClinicalInformation.contextType = WindowWidthStateContext;
ClinicalInformation.propTypes = {
  preferredName: PropTypes.string,
  requestCustomerError: PropTypes.bool,
  clientUserID: PropTypes.number,
  getCustomerInformation: PropTypes.func,

  conditions: PropTypes.array,
  acuity: PropTypes.any,
  stageOfChange: PropTypes.any,
  physicalConditions: PropTypes.array,

  spinnerMessage: PropTypes.string,
  isUpdating: PropTypes.bool,
  isError: PropTypes.bool,

  customerList: PropTypes.array,

  getCurrentSurvey: PropTypes.func,
  currentSurvey: PropTypes.shape({
    surveyID: PropTypes.number,
    surveyLabel: PropTypes.string,
  }),
};

const mapStateToProps = ({
  clinicalInformation,
  customerList,
  customerInformation,
  journey,
  room,
}) => {
  return {
    preferredName: customerInformation.preferredName,

    sleepQuality: clinicalInformation.sleepQuality,
    sleepAidUse: clinicalInformation.sleepAidUse,
    socialSupportSystem: clinicalInformation.socialSupportSystem,
    experiencingSchoolCommunity: clinicalInformation.experiencingSchoolCommunity,
    experiencingSocialMedia: clinicalInformation.experiencingSocialMedia,
    hospitalizationHistory: clinicalInformation.hospitalizationHistory,
    traumaticExperience: clinicalInformation.traumaticExperience,
    traumaticFlashbacks: clinicalInformation.traumaticFlashbacks,
    guilt: clinicalInformation.guilt,
    isolation: clinicalInformation.isolation,
    suicidePlanning: clinicalInformation.suicidePlanning,
    suicideIdeation: clinicalInformation.suicideIdeation,
    homicidalIdeation: clinicalInformation.homicidalIdeation,
    homicidalPlanning: clinicalInformation.homicidalPlanning,
    angerAggression: clinicalInformation.angerAggression,
    previousRelativesMentalHealthIssues: clinicalInformation.previousRelativesMentalHealthIssues,
    previousMentalHealthIssues: clinicalInformation.previousMentalHealthIssues,
    previousMentalHealthTreatment: clinicalInformation.previousMentalHealthTreatment,
    treatmentGoal: customerInformation.treatmentGoal,

    conditions: clinicalInformation.conditions,
    acuity: clinicalInformation.acuity,
    stageOfChange: clinicalInformation.stageOfChange,
    physicalConditions: clinicalInformation.physicalConditions,
    presentingProblems: clinicalInformation.presentingProblems,

    spinnerMessage: clinicalInformation.spinnerMessage,
    isUpdating: clinicalInformation.isUpdating,
    isError: clinicalInformation.isError,

    isRequestingCustomer: customerInformation.isRequestingCustomer,
    requestCustomerError: customerInformation.requestCustomerError,

    clientUserID: customerInformation.clientUserID,
    roomID: room.roomID,
    customerList: customerList.list,
    isRequestingCustomerOptions: customerInformation.isRequestingCustomerOptions,
    requestCustomerOptionsError: customerInformation.requestCustomerOptionsError,

    currentSurvey: clinicalInformation.currentSurvey,
    conditionSurvey: clinicalInformation.conditionSurvey,

    journeyItems: journey.journeyItems,
    collectedAt: clinicalInformation.createdAt,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClinicalInformation,
      setClinicalInformation,
      getCustomerInformation,
      getPresentingProblems,
      postPresentingProblems,
      deletePresentingProblems,
      getConditions,
      postConditions,
      deleteConditions,
      getPhysicalConditions,
      postPhysicalConditions,
      deletePhysicalConditions,
      getCurrentSurvey,
    },
    dispatch
  );
const ClinicalInformationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicalInformation);

export default withRouter(withBusinessLine(ClinicalInformationContainer));
